import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Form, Formik } from 'formik'
import * as yup from 'yup'

import {
    Box,
    Button,
    CircularProgress,
    ClickAwayListener,
    Divider,
    IconButton,
    Paper,
    Popover,
    type PopoverOrigin,
    Tooltip
} from '@mui/material'

import PlayIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import CloseIcon from '@mui/icons-material/Close'

import { ConnectedCheckbox, ConnectedInput, SpacedGroup } from 'UI/Components'
import { type PronunciationType } from './PronunciationText'
import { LanguageDropdown } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

import { delay, fetchBlobData, logToAnalytics, type OptionType } from 'modules'
import { useBoolean, useDataMutation, useNotification } from 'hooks'

const wordValidation = /^(?![^]*[|{}])[^]*$/ //  no "|" , "||" "{" and "}"

const TOOLTIP_TEXT =
    'This word already exists in the Dictionary. Only the admin has the permission to make changes to it'

const ERROR_TEXT = "Invalid field name: Shouldn't contain ! {} or ||"

const DEFAULT_LANGUAGE = {
    value: 'en-US',
    label: 'English (US)'
}

const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
}

const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left'
}

export type Values = {
    alternate: string
    ipa: string
    workspaceDictionary: boolean
    newWord: string
}

type pronunciationPayload = {
    word: string
    type: PronunciationType
    phonetic: string
    langCode: string
}

type Props = {
    anchorEl: HTMLElement | null
    setAnchorEl: (val: HTMLElement | null) => void
    showPronunciationCheckbox: boolean
    initialValue: Values
    isAddToDictionaryDisabled: boolean
    selectedWord: string
    selectedLanguage?: string
    onSubmit?: (final: string) => void
    onReset?: () => void
    mode?: 'editor' | 'dictionary'
    contentEditable?: boolean
}

export const PronunciationPopup = ({
    anchorEl,
    setAnchorEl,
    initialValue,
    showPronunciationCheckbox,
    isAddToDictionaryDisabled,
    selectedWord,
    selectedLanguage,
    onSubmit,
    onReset,
    mode = 'editor',
    contentEditable = false
}: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const audioRef = useRef<HTMLMediaElement | null>(null)

    const playing = useBoolean()
    const isLoading = useBoolean()

    const [activeLanguage, setActiveLanguage] = useState<OptionType>(DEFAULT_LANGUAGE)
    const [blobUrl, setBlobUrl] = useState<string | null>(null)

    const Icon = playing.isFalse ? PlayIcon : PauseIcon

    const setPlaying = playing.set

    useEffect(() => {
        setPlaying(false)
        setBlobUrl(null)
    }, [selectedWord, setPlaying])

    const { showErrorNotification } = useNotification()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote } = steps?.[activeStep] ?? {}

    const speakerConfig = audioNote?.type === 'textToSpeech' ? audioNote.speakerConfig : null

    const langCode = speakerConfig?.langCode || 'en-US'

    const { audioConfigs } = useSelector(state => state.configs)

    const { speakers } = audioConfigs

    const $AddToDictionary = useDataMutation<pronunciationPayload, {}, Error>(
        '/c/v1/pronunciations',
        'POST'
    )

    const extractAnalyticsData = (values: Values) => {
        return {
            playbookId,
            original_text: mode === 'editor' ? selectedWord : values.newWord,
            type: values.ipa ? 'IPA' : 'alternative',
            pronounced_text: values.ipa || values.alternate
        }
    }

    useEffect(() => {
        if (!Object.values(speakers).length) return

        if (selectedLanguage) {
            setActiveLanguage({
                value: selectedLanguage,
                label: speakers[selectedLanguage].langName
            })
            return
        }

        const currentSpeaker = speakers[langCode]
        if (!currentSpeaker) return

        setActiveLanguage({
            value: langCode,
            label: currentSpeaker.langName
        })
    }, [selectedLanguage, langCode, speakers])

    const playAudio = async () => {
        const audioElement = audioRef.current

        if (!audioElement) return

        audioElement.load()
        audioElement.currentTime = 0
        await audioElement.play()
        playing.setTrue()
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
        >
            <Paper elevation={0}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box my={3} width={600}>
                        <SpacedGroup justifyContent="space-between" mx={3}>
                            <Box
                                fontWeight={500}
                                fontSize={20}
                                color="#090C10"
                                letterSpacing={0.15}
                            >
                                Pronunciation
                            </Box>
                            <IconButton aria-label="close" onClick={() => setAnchorEl(null)}>
                                <CloseIcon style={{ color: '#090C10', width: 20, height: 20 }} />
                            </IconButton>
                        </SpacedGroup>

                        <Formik
                            onSubmit={async values => {
                                // sub (|)  - alternate
                                // phoneme (||) - ipa

                                logToAnalytics('pronunciationModal_applyBtn_clicked', {
                                    ...extractAnalyticsData(values),
                                    state: values.workspaceDictionary ? 'check' : 'uncheck'
                                })

                                const pronunciation = values.ipa || values.alternate

                                const type = values.ipa ? '||' : '|'
                                const final = `{${selectedWord.trim()}${type}${pronunciation.trim()}}`

                                if (values.workspaceDictionary) {
                                    await $AddToDictionary
                                        .mutate({
                                            word: mode === 'editor' ? selectedWord : values.newWord,
                                            type: values.ipa ? 'phoneme' : 'sub',
                                            phonetic: pronunciation,
                                            langCode: activeLanguage.value
                                        })
                                        .catch(() => {
                                            showErrorNotification(
                                                'Something went wrong while adding to dictionary'
                                            )
                                        })
                                }
                                onSubmit?.(final)
                                setAnchorEl(null)
                            }}
                            initialValues={initialValue}
                            validateOnMount={true}
                            validateOnBlur={true}
                            validateOnChange={true}
                            validationSchema={yup.object().shape({
                                newWord: yup
                                    .string()
                                    .test(
                                        'newWord',
                                        'Field is required',
                                        (value, { createError }) => {
                                            if (!contentEditable || value) return true
                                            return createError({ path: 'newWord' })
                                        }
                                    ),
                                alternate: yup
                                    .string()
                                    .trim()
                                    .test(
                                        'either-or',
                                        'Either Alternate spelling or IPA is required',
                                        function (value) {
                                            // Accessing the value of the 'ipa' field from the parent object
                                            const { ipa } = this.parent
                                            // If both 'alternate' and 'ipa' are empty, return false to trigger the error
                                            return !!(value || ipa)
                                        }
                                    )
                                    .matches(wordValidation, {
                                        message: ERROR_TEXT,
                                        excludeEmptyString: true
                                    })
                                    .nullable(),
                                ipa: yup
                                    .string()
                                    .trim()
                                    .matches(wordValidation, {
                                        message: ERROR_TEXT,
                                        excludeEmptyString: true
                                    })
                                    .nullable()
                            })}
                        >
                            {({ setFieldValue, values }) => (
                                <Form>
                                    {mode === 'dictionary' && (
                                        <Box px={3} mb={2}>
                                            <LanguageDropdown
                                                language={activeLanguage}
                                                onSelect={e => {
                                                    setBlobUrl(null)
                                                    setActiveLanguage(e)
                                                    logToAnalytics(
                                                        'pronunciationModal_lng_selected',
                                                        extractAnalyticsData(values)
                                                    )
                                                }}
                                                showOther={false}
                                                anchorOriginHorizontal="center"
                                                transformOriginHorizontal="center"
                                            />
                                        </Box>
                                    )}

                                    {/*phrase*/}
                                    {contentEditable && (
                                        <Box mx={3}>
                                            <ConnectedInput
                                                fullWidth
                                                variant="filled"
                                                name="newWord"
                                                label="Enter a new word"
                                                InputLabelProps={{
                                                    style: { color: 'rgba(9, 12, 16, 0.6)' }
                                                }}
                                                InputProps={{
                                                    style: {
                                                        height: 54,
                                                        backgroundColor: '#F5F5F5'
                                                    }
                                                }}
                                                onChange={e => {
                                                    setFieldValue('newWord', e.target.value)
                                                }}
                                            />
                                        </Box>
                                    )}

                                    {!contentEditable && (
                                        <SpacedGroup
                                            fontWeight={400}
                                            fontSize={16}
                                            color="#090C10"
                                            letterSpacing={0.15}
                                            border="solid 1px rgba(9, 12, 16, 0.10)"
                                            height="54px"
                                            borderRadius={4}
                                            mx={3}
                                            my={2}
                                            pl={2}
                                        >
                                            {selectedWord}
                                        </SpacedGroup>
                                    )}
                                    <SpacedGroup
                                        justifyContent="center"
                                        fontSize={12}
                                        fontWeight={400}
                                        mt={3}
                                        mb={2}
                                    >
                                        Enter the correct pronunciation or use IPA. Preview and
                                        apply.
                                    </SpacedGroup>
                                    <SpacedGroup
                                        alignItems="flex-start"
                                        justifyContent="center"
                                        mb={2}
                                        mx={3}
                                    >
                                        <ConnectedInput
                                            fullWidth
                                            variant="filled"
                                            name="alternate"
                                            label="Type alternative spelling"
                                            InputLabelProps={{
                                                style: { color: 'rgba(9, 12, 16, 0.6)' }
                                            }}
                                            InputProps={{
                                                style: {
                                                    height: 54,
                                                    backgroundColor: '#F5F5F5'
                                                }
                                            }}
                                            onChange={e => {
                                                setBlobUrl(null)
                                                setFieldValue('alternate', e.target.value)
                                                if (e.target.value) {
                                                    setFieldValue('ipa', '')
                                                }
                                            }}
                                        />
                                        <Box
                                            fontWeight="400"
                                            fontSize="16"
                                            letterSpacing={0.15}
                                            color="rgba(9, 12, 16, 0.40)"
                                            mt={2}
                                        >
                                            OR
                                        </Box>
                                        <ConnectedInput
                                            fullWidth
                                            variant="filled"
                                            name="ipa"
                                            label="Enter IPA"
                                            InputLabelProps={{
                                                style: { color: 'rgba(9, 12, 16, 0.6)' }
                                            }}
                                            InputProps={{
                                                style: {
                                                    height: 54,
                                                    backgroundColor: '#F5F5F5'
                                                }
                                            }}
                                            onChange={e => {
                                                setBlobUrl(null)
                                                setFieldValue('ipa', e.target.value)
                                                if (e.target.value) {
                                                    setFieldValue('alternate', '')
                                                }
                                            }}
                                        />
                                    </SpacedGroup>

                                    {/*Play pronunciation*/}
                                    <audio
                                        style={{ display: 'none' }}
                                        ref={audioRef}
                                        src={blobUrl || ''}
                                        controls
                                        onEnded={() => {
                                            playing.setFalse()
                                        }}
                                    />

                                    <SpacedGroup
                                        color="rgba(9, 12, 16, 0.6)"
                                        fontSize={14}
                                        fontWeight={400}
                                        my={3}
                                        mx={3}
                                    >
                                        {isLoading.isTrue && (
                                            <Box height={24} width={24}>
                                                <CircularProgress size={18} />
                                            </Box>
                                        )}
                                        {isLoading.isFalse && (
                                            <IconButton
                                                style={{
                                                    height: 24,
                                                    width: 24
                                                }}
                                                size="small"
                                                disableRipple
                                                onClick={() => {
                                                    logToAnalytics(
                                                        'pronunciationModal_playIcon_clicked',
                                                        extractAnalyticsData(values)
                                                    )

                                                    if (playing.isTrue) {
                                                        playing.setFalse()
                                                        audioRef.current?.pause()
                                                        return
                                                    }
                                                    if (
                                                        values.ipa.trim() === '' &&
                                                        values.alternate.trim() === ''
                                                    )
                                                        return

                                                    if (blobUrl) {
                                                        playAudio()
                                                        return
                                                    }

                                                    isLoading.setTrue()

                                                    // prepare the preview object
                                                    // sub (|)  - alternate
                                                    // phoneme (||) - ipa
                                                    const payload = {
                                                        pronunciation: {
                                                            word:
                                                                mode === 'editor'
                                                                    ? selectedWord
                                                                    : values.newWord,
                                                            type: values.ipa ? 'phoneme' : 'sub',
                                                            phonetic:
                                                                values.ipa || values.alternate,
                                                            langCode: activeLanguage.value
                                                        },
                                                        ...(speakerConfig && {
                                                            config: speakerConfig
                                                        })
                                                    }

                                                    fetchBlobData(
                                                        `/c/v1/pronunciations/sample`,
                                                        payload
                                                    )
                                                        .then(objectURL => {
                                                            setBlobUrl(objectURL)

                                                            delay(1000).then(async () => {
                                                                isLoading.setFalse()
                                                                const audioElement =
                                                                    audioRef.current

                                                                if (audioElement) {
                                                                    audioElement.load()
                                                                    audioElement.currentTime = 0
                                                                    playing.setTrue()
                                                                    await audioElement.play()
                                                                }
                                                            })
                                                        })
                                                        .catch(() => {
                                                            playing.setFalse()
                                                            showErrorNotification(
                                                                'Something went wrong'
                                                            )
                                                        })
                                                }}
                                            >
                                                <Icon
                                                    style={{
                                                        height: 18,
                                                        width: 18,
                                                        color: '#000000'
                                                    }}
                                                />
                                            </IconButton>
                                        )}
                                        <Box>Play pronunciation</Box>
                                    </SpacedGroup>

                                    {/*Add to workspace dictionary checkbox*/}
                                    {showPronunciationCheckbox && (
                                        <Tooltip
                                            title={isAddToDictionaryDisabled ? TOOLTIP_TEXT : ''}
                                        >
                                            <Box mb={2} mx={3}>
                                                <ConnectedCheckbox
                                                    disabled={isAddToDictionaryDisabled}
                                                    name="workspaceDictionary"
                                                    label="Add to workspace dictionary"
                                                    color={'primary'}
                                                />
                                            </Box>
                                        </Tooltip>
                                    )}

                                    <Divider variant="fullWidth" />

                                    <SpacedGroup
                                        mx={3}
                                        pt={3}
                                        justifyContent={
                                            mode === 'editor' ? 'space-between' : 'flex-end'
                                        }
                                        alignItems="center"
                                    >
                                        {mode === 'editor' && (
                                            <Button
                                                disabled={!values.ipa && !values.alternate}
                                                name="restore"
                                                size="small"
                                                variant="contained"
                                                color="secondary"
                                                type="button"
                                                style={{
                                                    color: 'rgba(9, 12, 16, 0.6)',
                                                    backgroundColor: '#EDEEEF'
                                                }}
                                                onClick={() => {
                                                    logToAnalytics(
                                                        'pronunciationModal_restoreBtn_clicked',
                                                        extractAnalyticsData(values)
                                                    )
                                                    onReset?.()
                                                    setAnchorEl(null)
                                                }}
                                            >
                                                RESTORE
                                            </Button>
                                        )}
                                        <Button
                                            name="apply"
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            style={{
                                                color: 'rgba(255, 255, 255, 1)'
                                            }}
                                        >
                                            APPLY
                                        </Button>
                                    </SpacedGroup>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </ClickAwayListener>
            </Paper>
        </Popover>
    )
}
