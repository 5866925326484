import { memo } from 'react'

import { faLanguage } from '@fortawesome/pro-regular-svg-icons/faLanguage'
import { Box } from '@mui/material'

import { Button, Icon, ThemeProvider, Tooltip } from '@guidde/design-system'

import TranslateImage from 'assets/images/translate.png'

import { type QuickGuiddeType } from 'app/types'
import { links } from 'app/links'

import { openLink } from 'modules'

import { UpgradePlanPopup } from 'UI/Components'
import { languageOptionOther } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

import { SpeakersTranslationDialog } from 'UI/Routes/quick-guidde/Header/SpeakersTranslationDialog'
import { TranslateProgressDialog } from 'UI/Routes/quick-guidde/Header/TranslateProgressDialog'
import { TranslateInProgressPopper } from 'UI/Routes/quick-guidde/Header/TranslateInProgressPopper'
import { useQuickGuiddeTranslate } from 'UI/Routes/quick-guidde/Header/hooks'

type Props = {
    playbook: QuickGuiddeType
    isDisabled?: boolean
    disabledText?: string
}

export const QuickGuiddeTranslate = memo(({ playbook, isDisabled, disabledText }: Props) => {
    const {
        buttonRef,
        initialLanguage,
        languagesList,
        translationInProgress,
        handleOpenTranslationDialog,
        handleCloseSpeakersTranslationDialog,
        progressDialog,
        progressData,
        handleCloseProgressDialog,
        handleTryAgainFailedTranslation,
        handleDeleteFailedTranslation,
        handleFinishedTranslation,
        speakersDialog,
        onTranslateClick,
        upgradePopup,
        anchorEl,
        setAnchorEl,
        updateDialogPoints,
        popperAnchorEl
    } = useQuickGuiddeTranslate(playbook)

    if (
        !initialLanguage ||
        initialLanguage.value === languageOptionOther.value ||
        languagesList.length <= 1
    )
        return null

    return (
        <ThemeProvider>
            <Tooltip title={isDisabled ? disabledText : ''}>
                <Box ref={buttonRef}>
                    <Button
                        variant="outlined"
                        fullWidth
                        onClick={handleOpenTranslationDialog}
                        disabled={isDisabled || translationInProgress.isTrue}
                        size="small"
                        color="secondary"
                        startIcon={<Icon icon={faLanguage} size="1x" />}
                    >
                        Translate this Guidde
                    </Button>
                </Box>
            </Tooltip>
            <TranslateInProgressPopper
                isOpen={translationInProgress.isTrue && progressDialog.isFalse}
                popperAnchorEl={popperAnchorEl}
                onViewProgressClick={progressDialog.setTrue}
            />
            <TranslateProgressDialog
                isOpen={progressDialog.isTrue && translationInProgress.isTrue}
                onClose={handleCloseProgressDialog}
                data={progressData}
                onTryAgain={handleTryAgainFailedTranslation}
                onDelete={handleDeleteFailedTranslation}
                onFinished={handleFinishedTranslation}
            />

            <SpeakersTranslationDialog
                isOpen={speakersDialog.isTrue}
                onClose={handleCloseSpeakersTranslationDialog}
                languages={languagesList.filter(it => it.value !== initialLanguage.value)}
                onSave={onTranslateClick}
                title="Select languages and speakers"
                subtitle="Select the languages you'd like to translate this Guidde into, and choose a speaker for each language"
                submitText="Translate"
            />

            {upgradePopup.isTrue && (
                <UpgradePlanPopup
                    title="Upgrade your Account"
                    text="Add translations, add natural sounding voiceover using typed text and more."
                    anchorEl={anchorEl}
                    onClose={upgradePopup.setFalse}
                    setAnchorEl={setAnchorEl}
                    imageSrc={TranslateImage}
                    openFeatures={true}
                    points={updateDialogPoints}
                    hidePrice={true}
                    customActionButton={
                        <Button variant="contained" onClick={() => openLink(links.contactSales)}>
                            CONTACT US
                        </Button>
                    }
                />
            )}
        </ThemeProvider>
    )
})
