import { type ThemeOptions, createTheme } from '@mui/material/styles'

const theme: ThemeOptions = {
    fontWeightBold: 600,
    overrides: {
        MuiTypography: {
            body2: {
                fontSize: '0.75rem' // 12px
            },
            subtitle1: {
                fontSize: '1.2rem',
                fontWeight: 'bold'
            },
            h2: {
                fontSize: '1rem',
                fontWeight: 'bold',
                lineHeight: '1.5'
            }
        },
        MuiFormHelperText: {
            root: {
                color: 'red'
            }
        }
    },
    palette: {
        primary: {
            main: '#cd0000'
        },
        secondary: {
            main: '#1976d2'
        },
        text: {
            // @ts-ignore
            main: '#212121'
        }
    },
    typography: {
        // @ts-ignore
        color: '#212121',
        fontFamily: 'Inter, sans-serif',
        button: {
            textTransform: 'none'
        }
    },
    props: {
        MuiButton: {
            variant: 'contained',
            color: 'primary',
            size: 'medium'
        }
    },
    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard'
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: '4px 10px'
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    '&.MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        '&.Mui-focused': {
                            backgroundColor: 'rgba(0, 0, 0, 0.12) !important'
                        }
                    }
                }
            }
        }
    }
}
export const newTheme = createTheme({
    ...theme,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1280,
            xl: 1536,
            xxl: 1920
        }
    }
} as ThemeOptions)
