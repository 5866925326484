import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { type BoxProps, Box } from '@mui/material'

import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import FilterListIcon from '@mui/icons-material/FilterList'
import LanguageIcon from '@mui/icons-material/Language'

import { FilterSelectMultiple, SpacedGroup } from 'UI/Components'

import IconApps from 'assets/icons/icon-apps.svg'
import IconLabel from 'assets/icons/icon-label.svg'
import IconPerson from 'assets/icons/icon-person.svg'
import { ReactComponent as SpacesIcon } from 'assets/icons/menu/icon-spaces.svg'

import { type FacetsType, type LibraryType } from 'app/types'

import {
    type ObjectType,
    generateAppOptions,
    generateOptions,
    generateLanguageOptions,
    generateTypesOptions,
    generateSpaceOptions,
    useFilterState,
    typeList
} from './playbook-filters'
import { ClearFiltersButton } from './ClearFiltersButton'

import {
    resetLibraryPlaybookFilters,
    resetLibraryPlaybookMode,
    setLibraryPlaybookFilters
} from 'ducks/actions'

import {
    type UseBooleanType,
    useApplications,
    useFiltersQuery,
    useSpaces,
    useWindowView
} from 'hooks'
import { isDeepEqual } from 'modules'

const PREFIX = 'LibraryFilters'

const classes = {
    list: `${PREFIX}-list`
}

const MIN_WIDTH = 220

export const filterEmptyFields = (obj: ObjectType) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v.length > 0))
}

type Props = {
    hideSpaceFilter?: boolean
    hideLanguageFilter?: boolean
    hideTypesFilter?: boolean
    facets: FacetsType
    filterWidth?: string | number
    fullWidth?: boolean
    showNewDSFilters?: boolean
    minFilterWidth?: number
}

export type LibraryFiltersType = {
    apps: Array<string>
    creators: Array<string>
    modes: Array<string>
    languages: Array<string>
    tags: Array<string>
    spaceIds: Array<string>
    visibility?: Array<string>
}

const allValuesIsEmpty = (o: { [key: string]: any }) => {
    return Object.values(o)
        .filter(Array.isArray)
        .every(it => !it.length)
}

export const getIconColor = (isActive: boolean) =>
    isActive ? 'rgba(255, 255, 255, 1)' : 'rgba(9, 12, 16, 0.6)'

export const getButtonColor = (isActive: boolean) => (isActive ? 'rgba(9, 12, 16, 0.6)' : '#F5F5F5')

const guiddeTypes = Object.keys(typeList).filter(it => it !== typeList.playlist.toLowerCase())
// this code should be based on BE. I hope it's temporary
const generateTypesValue = (arr: Array<string>) => {
    if (!arr.length) return arr

    if (arr.every(it => guiddeTypes.includes(it))) {
        return [typeList.playbook]
    }

    return arr
}
export const LibraryFilters = memo(
    ({
        facets = {} as FacetsType,
        hideTypesFilter,
        hideSpaceFilter,
        filterWidth = MIN_WIDTH,
        showNewDSFilters = false,
        fullWidth,
        hideLanguageFilter
    }: Props) => {
        const dispatch = useDispatch()
        const history = useHistory()
        const location = useLocation()

        const [cachedFacets, setCachedFacets] = useState(facets)

        useEffect(() => {
            if (Object.values(cachedFacets).length) return

            setCachedFacets(facets)
        }, [cachedFacets, facets])

        const { isMobileView, isTabletView } = useWindowView()
        const { applicationsData } = useApplications()

        const queryParams = new URLSearchParams(location.search)

        const playbookFilters = useSelector(state => state.playbookFilters.libraryPlaybooks)

        const { updateFilterQuery, resetAllQueryFilters, getFiltersFromQuery } = useFiltersQuery(
            Object.keys(playbookFilters)
        )

        const { apps, tags, creators, modes, languages, spaceIds } = playbookFilters

        const { mySpaces } = useSpaces()

        const spacesFilter = useFilterState(spaceIds)
        const appsFilter = useFilterState(apps)
        const tagsFilter = useFilterState(tags)
        const creatorsFilter = useFilterState(creators)
        const modesFilter = useFilterState(modes)
        const languagesFilter = useFilterState(languages)

        useEffect(() => {
            if (appsFilter.state !== apps) appsFilter.updateState(apps)
            // eslint-disable-next-line
        }, [apps])

        useEffect(() => {
            if (tagsFilter.state !== tags) tagsFilter.updateState(tags)
            // eslint-disable-next-line
        }, [tags])

        useEffect(() => {
            if (creatorsFilter.state !== creators) creatorsFilter.updateState(creators)
            // eslint-disable-next-line
        }, [creators])

        useEffect(() => {
            if (modesFilter.state !== modes) modesFilter.updateState(modes)

            // eslint-disable-next-line
        }, [modes])

        useEffect(() => {
            if (languagesFilter.state !== languages) languagesFilter.updateState(languages)
            // eslint-disable-next-line
        }, [languages])

        useEffect(() => {
            if (spacesFilter.state !== spaceIds) spacesFilter.updateState(spaceIds)
            // eslint-disable-next-line
        }, [spaceIds])

        useEffect(() => {
            if (!Object.values(cachedFacets).length) return

            const queryFilters = getFiltersFromQuery() as LibraryType

            dispatch(setLibraryPlaybookFilters(queryFilters))
            // eslint-disable-next-line
        }, [cachedFacets])

        useEffect(() => {
            const activeFilters = {
                apps: appsFilter.state,
                tags: tagsFilter.state,
                creators: creatorsFilter.state,
                modes: modesFilter.state,
                languages: languagesFilter.state,
                spaceIds: spacesFilter.state
            }
            const data = {
                apps,
                tags,
                creators,
                modes,
                languages,
                spaceIds
            }

            if (allValuesIsEmpty(data) && allValuesIsEmpty(activeFilters)) {
                if (Object.values(activeFilters).every(it => it.length === 0)) {
                    resetFilters()
                }

                return
            }

            if (!isDeepEqual(activeFilters, data)) {
                // reset active page in pagination component
                queryParams.append('activePage', '1')
                history.replace({
                    search: queryParams.toString()
                })

                updateFilterQuery(activeFilters)
                dispatch(setLibraryPlaybookFilters(activeFilters))
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
            dispatch,
            appsFilter.state,
            tagsFilter.state,
            creatorsFilter.state,
            modesFilter.state,
            languagesFilter.state,
            spacesFilter.state
        ])

        const appOptions = useMemo(
            () => generateAppOptions(applicationsData, cachedFacets['applications.applicationId']),
            [applicationsData, cachedFacets]
        )

        const tagsOptions = useMemo(() => generateOptions(cachedFacets.tags), [cachedFacets.tags])
        const modesOptions = useMemo(
            () => generateTypesOptions(cachedFacets.mode),
            [cachedFacets.mode]
        )

        const creatorOptions = useMemo(
            () => generateOptions(cachedFacets.creator_name),
            [cachedFacets.creator_name]
        )
        const languageOptions = useMemo(
            () => generateLanguageOptions(cachedFacets.currentTranscriptLang),
            [cachedFacets.currentTranscriptLang]
        )

        const spaceOptions = useMemo(
            // we should use teamIds instead of key name 'spaces',
            // because the backend should do many migrations for adding key name 'spaces'
            () => generateSpaceOptions(Object.keys(cachedFacets?.teamIds || {}), mySpaces),
            [cachedFacets, mySpaces]
        )

        const resetFilters = useCallback(() => {
            resetAllQueryFilters()
            dispatch(resetLibraryPlaybookFilters())
        }, [dispatch, resetAllQueryFilters])

        if (isMobileView && showNewDSFilters) return null

        return (
            <SpacedGroup
                spacing={isMobileView ? 0.5 : 2}
                mt={0.5}
                className={classes.list}
                mr={isMobileView || isTabletView ? -1 : 0}
                width={fullWidth ? '100%' : 'auto'}
            >
                {!hideSpaceFilter && (
                    <FilterSelectMultiple
                        value={spacesFilter.state}
                        data-test="spaces-filter"
                        showNewDSFilters={showNewDSFilters}
                        onChange={spacesFilter.updateState}
                        multipleItemsLabel="spaces"
                        labelIcon={<SpacesIcon />}
                        options={spaceOptions}
                        width={filterWidth}
                        minWidth={MIN_WIDTH}
                    />
                )}
                <FilterSelectMultiple
                    value={appsFilter.state}
                    data-test="apps-filter"
                    showNewDSFilters={showNewDSFilters}
                    onChange={appsFilter.updateState}
                    multipleItemsLabel="apps"
                    labelIcon={<img src={IconApps} alt="app" />}
                    options={appOptions}
                    width={filterWidth}
                    minWidth={MIN_WIDTH}
                />

                <FilterSelectMultiple
                    value={tagsFilter.state}
                    data-test="tags-filter"
                    showNewDSFilters={showNewDSFilters}
                    onChange={tagsFilter.updateState}
                    multipleItemsLabel="tags"
                    labelIcon={<img src={IconLabel} alt="tag" />}
                    options={tagsOptions}
                    width={filterWidth}
                    minWidth={MIN_WIDTH}
                />

                <FilterSelectMultiple
                    value={creatorsFilter.state}
                    data-test="creators-filter"
                    showNewDSFilters={showNewDSFilters}
                    onChange={creatorsFilter.updateState}
                    multipleItemsLabel="creators"
                    labelIcon={<img src={IconPerson} alt="person" />}
                    options={creatorOptions}
                    width={filterWidth}
                    minWidth={MIN_WIDTH}
                />
                {!hideTypesFilter && (
                    <FilterSelectMultiple
                        value={generateTypesValue(modesFilter.state)}
                        data-test="types-filter"
                        showNewDSFilters={showNewDSFilters}
                        onChange={values => {
                            if (!values.length) {
                                // it's temporary solution for deploy. it should be fixed and removed, it's a hardcode fix of the problem
                                dispatch(resetLibraryPlaybookMode())
                                modesFilter.updateState([])
                                return
                            }

                            // this code should be based on BE. I hope it's temporary
                            if (values.includes(typeList.playbook)) {
                                values.push(...guiddeTypes)
                            }

                            modesFilter.updateState(values)
                        }}
                        multipleItemsLabel="types"
                        labelIcon={<VideoLibraryIcon />}
                        options={modesOptions}
                        width={filterWidth}
                        minWidth={MIN_WIDTH}
                    />
                )}
                {!hideLanguageFilter && (
                    <FilterSelectMultiple
                        value={languagesFilter.state}
                        data-test="languages-filter"
                        showNewDSFilters={showNewDSFilters}
                        onChange={languagesFilter.updateState}
                        multipleItemsLabel="languages"
                        labelIcon={<LanguageIcon />}
                        options={languageOptions}
                        width={filterWidth}
                        minWidth={MIN_WIDTH}
                    />
                )}

                {!showNewDSFilters && (
                    <ClearFiltersButton filters={playbookFilters} clickHandler={resetFilters} />
                )}
            </SpacedGroup>
        )
    }
)

type FiltersToggleButtonProps = {
    showFilters: UseBooleanType
} & BoxProps

export const FiltersToggleButton = ({ showFilters, ...rest }: FiltersToggleButtonProps) => (
    <Box
        style={{
            width: 35,
            height: 35,
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
        }}
        bgcolor={getButtonColor(showFilters.isTrue)}
        onClick={showFilters.toggle}
        {...rest}
    >
        <FilterListIcon
            data-test="filter-button"
            style={{ color: getIconColor(showFilters.isTrue) }}
        />
    </Box>
)
