import { memo } from 'react'

import { Box, Button, DialogActions, Divider } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'

import { type FormikHelpers, Form, Formik } from 'formik'

import { useAuth } from 'hooks'
import { SPACE_ROLES } from 'modules'

import { SpacedGroup } from 'UI/Components'
import { MainTab } from './MainTab'
import { MembersTab } from './MembersTab'

import { type FormSpaceType, type UserOption } from './types'

export const tabs = {
    main: 'main',
    members: 'members'
} as const

export type TabsType = keyof typeof tabs

type Props = {
    activeTab: TabsType
    initialValues: FormSpaceType
    title: string
    disabled: boolean
    isLoading: boolean
    handleSubmit: (data: FormSpaceType, formHelpers: FormikHelpers<FormSpaceType>) => void
    setActiveTab: (tabs: TabsType) => void
    onClose: () => void
}

export const SpaceFields = memo(
    ({
        handleSubmit,
        setActiveTab,
        onClose,
        activeTab,
        title,
        initialValues,
        isLoading,
        disabled
    }: Props) => {
        const { uid } = useAuth()

        const initialSpaceMembershipDetails = initialValues.members[uid]

        return (
            <Formik onSubmit={handleSubmit} enableReinitialize={true} initialValues={initialValues}>
                {({ setFieldValue, values, dirty }) => {
                    const members = Object.values<UserOption>(values.members).filter(
                        it => it.isSelected
                    )

                    const withOwnerRole = members.filter(it => it.spaceRole === SPACE_ROLES.OWNER)
                    const withNotOwnerRole = members.filter(
                        it => it.spaceRole !== SPACE_ROLES.OWNER
                    )

                    return (
                        <Form>
                            <SpacedGroup
                                px={3.5}
                                flexDirection="column"
                                pb={3}
                                spacing={activeTab === tabs.members ? 1 : 3}
                            >
                                {activeTab === tabs.main && (
                                    <MainTab
                                        setActiveTab={setActiveTab}
                                        setFieldValue={setFieldValue}
                                        onClose={onClose}
                                        values={values}
                                        title={title}
                                        withNotOwnerRole={withNotOwnerRole}
                                        withOwnerRole={withOwnerRole}
                                    />
                                )}
                                {activeTab === tabs.members && (
                                    <MembersTab
                                        setActiveTab={setActiveTab}
                                        setFieldValue={setFieldValue}
                                        onClose={onClose}
                                        values={values}
                                        disabled={disabled}
                                        members={members}
                                        initialSpaceMembershipDetails={
                                            initialSpaceMembershipDetails
                                        }
                                    />
                                )}
                            </SpacedGroup>

                            <Divider />

                            <DialogActions disableSpacing>
                                <Box px={2} py={1}>
                                    {activeTab === tabs.main && (
                                        <Button
                                            data-test="spaces-save-changes-button"
                                            size="large"
                                            variant="contained"
                                            type="submit"
                                            startIcon={<CheckIcon />}
                                            disabled={isLoading || !dirty || !values.name.length}
                                            style={{ textTransform: 'uppercase' }}
                                        >
                                            Save
                                        </Button>
                                    )}
                                    {activeTab === tabs.members && (
                                        <Button
                                            size="large"
                                            variant="contained"
                                            style={{ textTransform: 'uppercase' }}
                                            disabled={isLoading}
                                            onClick={() => setActiveTab(tabs.main)}
                                        >
                                            Save changes
                                        </Button>
                                    )}
                                </Box>
                            </DialogActions>
                        </Form>
                    )
                }}
            </Formik>
        )
    }
)
