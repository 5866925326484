import { memo, type MouseEvent } from 'react'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import { faPen } from '@fortawesome/pro-regular-svg-icons'

import { SpacedGroup } from 'UI/Components'

import VoiceoverAnalytics from 'analytics/voiceover'
import { Icon, IconButton, ThemeProvider, Tooltip } from '@guidde/design-system'
import {
    SpeakerPreview,
    SpeakerSelectionDialog,
    type SpeakerSelectionDialogProps
} from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'
import { useParams } from 'react-router-dom'

type Props = {
    currentSpeaker: SpeakerSelectionDialogProps['currentSpeaker']
    onSave: SpeakerSelectionDialogProps['onSave']
    source?: SpeakerSelectionDialogProps['source']
    onAudioPlay: SpeakerSelectionDialogProps['onAudioPlay']
    onEdit?: (e: MouseEvent<HTMLElement>, callback: () => void) => void
}

export const SpeakerSelection = memo(
    ({ currentSpeaker, onSave, source, onAudioPlay, onEdit }: Props) => {
        const { playbookId } = useParams<{ playbookId?: string }>()

        const showSpeakerSelectionDialog = useBoolean(false)

        const handleClickOnEdit = (e: MouseEvent<HTMLElement>) => {
            if (playbookId) VoiceoverAnalytics.voiceoverT2VTabSpeakerClicked({ playbookId })

            if (onEdit) onEdit(e, showSpeakerSelectionDialog.setTrue)
            else showSpeakerSelectionDialog.setTrue()
        }

        if (currentSpeaker === null) return null

        return (
            <ThemeProvider>
                <SpacedGroup justifyContent="space-between" my={1} py={1} alignItems="center">
                    <SpeakerPreview speaker={currentSpeaker} />
                    <Tooltip title="Edit Speaker" shouldHighlightChildIcon>
                        <IconButton
                            label="edit"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            onClick={handleClickOnEdit}
                        >
                            <Icon icon={faPen} size="1x" />
                        </IconButton>
                    </Tooltip>
                </SpacedGroup>
                <SpeakerSelectionDialog
                    isOpen={showSpeakerSelectionDialog.isTrue}
                    onClose={showSpeakerSelectionDialog.setFalse}
                    onSave={onSave}
                    currentSpeaker={currentSpeaker}
                    source={source}
                    onAudioPlay={onAudioPlay}
                />
            </ThemeProvider>
        )
    }
)
