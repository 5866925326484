import { useLayoutEffect, useRef } from 'react'

import { type TimelineVideoChunkType } from '../types'
import { thumbnailFrameHeight } from './useVideoThumbnailCanvas'

const cropThumbnail = ({
    parentCanvas,
    currentCanvas,
    startPercentage,
    endPercentage,
    parentCanvasWidth
}: {
    parentCanvas: HTMLCanvasElement
    currentCanvas: HTMLCanvasElement
    startPercentage: number
    endPercentage: number
    parentCanvasWidth: number
}) => {
    const cropStartX = parentCanvasWidth * startPercentage
    const cropEndX = Math.ceil(parentCanvasWidth * endPercentage)

    const cropWidth = cropEndX - cropStartX

    currentCanvas.width = cropWidth

    const ctx = currentCanvas.getContext('2d')
    ctx?.drawImage(
        parentCanvas,
        cropStartX,
        0,
        cropWidth,
        thumbnailFrameHeight,
        0,
        0,
        cropWidth,
        thumbnailFrameHeight
    )
}

type Props = {
    chunk: TimelineVideoChunkType
    duration: number
    videoThumbnailCanvas: HTMLCanvasElement | null
    videoThumbnailCanvasWidth: number
}

export const TimelineChunkThumbnail = ({
    videoThumbnailCanvas,
    chunk,
    duration,
    videoThumbnailCanvasWidth
}: Props) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)

    useLayoutEffect(() => {
        if (!canvasRef.current || !videoThumbnailCanvas) return

        const startPercentage = chunk.start / duration
        const endPercentage = chunk.end / duration

        cropThumbnail({
            parentCanvas: videoThumbnailCanvas,
            currentCanvas: canvasRef.current,
            startPercentage,
            endPercentage,
            parentCanvasWidth: videoThumbnailCanvasWidth
        })
    }, [chunk, duration, videoThumbnailCanvas, videoThumbnailCanvasWidth])

    return (
        <canvas
            ref={canvasRef}
            height={thumbnailFrameHeight}
            style={{ position: 'absolute', left: 0, top: 0, borderRadius: '6px' }}
        />
    )
}
