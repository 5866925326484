import { logToAnalytics } from 'modules'

class BrandkitAnalytics {
    static brandKitAppearanceBackgroundBtnClicked(
        props: BrandKitAppearanceBackgroundBtnClickedProps
    ) {
        logToAnalytics('brandKit_appearance_backgroundBtn_clicked', props)
    }

    static brandKitAppearanceElementBtnClicked(props: BrandKitAppearanceElementBtnClickedProps) {
        logToAnalytics('brandKit_appearance_elementBtn_clicked', props)
    }

    static brandKitAppearanceNumberBtnClicked(props: BrandKitAppearanceNumberBtnClickedProps) {
        logToAnalytics('brandKit_appearance_numberBtn_clicked', props)
    }

    static brandKitChkSelected(props: BrandKitChkSelectedProps) {
        const { brandKitId, newStatus, outputType, setting } = props
        const eventType = `brandkit_${outputType === 'videoOutputSettings' ? 'appearance' : 'document'}_${setting.label.toLowerCase()}Chk_selected`

        logToAnalytics(eventType, {
            workspaceId: brandKitId,
            state: newStatus ? 'on' : 'off',
            outputType
        })
    }
}

type BrandKitAppearanceBackgroundBtnClickedProps = {
    workspaceId: string
    color: string
    type: 'color' | 'gradient' | 'image'
}

type BrandKitAppearanceElementBtnClickedProps = {
    workspaceId: string
    newColor: string
}

type BrandKitAppearanceNumberBtnClickedProps = {
    workspaceId: string
    newColor: string
}

type BrandKitChkSelectedProps = {
    brandKitId: string
    newStatus: boolean
    outputType: string
    setting: {
        label: string
    }
}

export default BrandkitAnalytics
