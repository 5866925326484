import { Typography } from '@guidde/design-system'
import { Stack } from '@mui/material'
import { ReactComponent as Icon } from 'assets/icons/nothing_find.svg'

export const NoSearchResults = () => (
    <Stack
        flex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        spacing={3}
    >
        <Icon />
        <Typography variant="text" size="sm" color="textSecondary">
            Oops! Nothing here!
        </Typography>
    </Stack>
)
