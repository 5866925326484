import { type ORG_SHARE_OPTIONS } from 'hooks'

export const PRIVACY_SETTINGS_OPTIONS = {
    internal: 'internal',
    restricted: 'restricted'
} as const

export type OrganizationType = {
    domains: Array<string>
    id: string
    logo: string
    name: string
    vanityUrl: string
    defaultSpaces: Array<string>
    extLogo?: string
    isPosterInEmbedON: boolean
    defaultSharingSettings?: keyof typeof ORG_SHARE_OPTIONS
    extColor?: string
    isDomainEditNotAllowed?: boolean
    requestRouting: string
    scheduleURL: string
    hasCustomers?: boolean
    defaultLanguage?: string
    privacySettings: keyof typeof PRIVACY_SETTINGS_OPTIONS
    allowSsoOnly?: boolean
    companySize: string
}
