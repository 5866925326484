import { type ReactNode, createContext, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useBoolean, useDataMutation, useNotification } from 'hooks'
import { setAudioNote, setVideoPreview } from 'ducks'
import { getAudioDuration } from 'modules'

import { type SubtitlesType } from 'app/types'
import { HTTP_CODES } from 'app/http-codes'

type SplitAudioPayload = {
    playbookId: string
    audioUrl: string
    langCode: string
    isTempStorage?: boolean // true by default
    segments: Array<{
        start: number
        end: number
        stepId: string
    }>
}

type SplitAudioResponse = {
    segments: Array<{
        stepId: string
        url: string | null
        start: number
        end: number
        subGenerationId: string | null
    }>
}

type ExtractStepPayload = {
    playbookId: string
    stepId: string
    videoUrl: string
    languageCode: string
    languageName: string
    start?: number
    end?: number
    generateThumbnailPreview?: boolean
}

type ExtractStepResponse = {
    audioNote: {
        text: string
        markdown: string
    }
    stepId: string
    audioUrl: string
    subtitles: SubtitlesType
    thumbnailPreviewUrl?: string
    subGenerationId?: string
}

export const VoiceOverContext = createContext({
    voiceOverProcessing: false as boolean,
    setVoiceOverProcessing: (_value: boolean) => {},
    processingVideoStepsId: [] as Array<string>,
    extractVideoStep: (_input: ExtractStepPayload) => {},
    splitAudioFile: (_input: SplitAudioPayload) =>
        Promise.resolve({} as SplitAudioResponse | undefined)
})

type Props = {
    children: ReactNode
}

export const VoiceOverProvider = ({ children }: Props) => {
    const dispatch = useDispatch()

    const loading = useBoolean()

    const [stepsId, setStepsId] = useState<Array<string>>([])

    const { showErrorNotification } = useNotification()

    const $extractVideo = useDataMutation<
        ExtractStepPayload,
        ExtractStepResponse,
        Error & { code: number }
    >('/c/v1/quickguidde/extract-video', 'POST', {
        onSuccess: async (data, input) => {
            const { subtitles, stepId, audioUrl, thumbnailPreviewUrl, audioNote, subGenerationId } =
                data
            const { languageCode, languageName } = input

            if (audioUrl) {
                const audioLength = await getAudioDuration(audioUrl)
                dispatch(
                    setAudioNote({
                        audioNote: {
                            text: audioNote?.text || '',
                            markdown: audioNote?.markdown || '',
                            audioUrl,
                            audioDuration: audioLength,
                            languageCode,
                            languageName,
                            type: 'speechToText'
                        },
                        stepId,
                        subtitles,
                        ...(subGenerationId && { subGenerationId })
                    })
                )
            }

            if (thumbnailPreviewUrl) dispatch(setVideoPreview(thumbnailPreviewUrl))
        },
        onFailure: ({ message, code }) => {
            if (code !== HTTP_CODES.NotFound && message) showErrorNotification(message)
        },
        disableDefaultErrorHandling: true
    })

    const $splitAudio = useDataMutation<
        SplitAudioPayload,
        SplitAudioResponse,
        Error & { code: number }
    >('/c/v1/quickguidde/split-audio', 'POST', {
        onFailure: ({ message, code }) => {
            if (code !== HTTP_CODES.NotFound && message) showErrorNotification(message)
        }
    })

    return (
        <VoiceOverContext.Provider
            value={{
                processingVideoStepsId: stepsId,
                voiceOverProcessing: loading.isTrue,
                setVoiceOverProcessing: loading.set,
                extractVideoStep: (payload: ExtractStepPayload) => {
                    loading.setTrue()
                    setStepsId(prevValue => [...prevValue, payload.stepId])

                    $extractVideo.mutate(payload).finally(() => {
                        setStepsId(prevValue => prevValue.filter(id => id !== payload.stepId))
                        loading.setFalse()
                    })
                },
                splitAudioFile: $splitAudio.mutate
            }}
        >
            {children}
        </VoiceOverContext.Provider>
    )
}
