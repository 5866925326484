import { memo } from 'react'
import { Box } from '@mui/material'
import { SpacedGroup } from 'UI/Components'
import { ThemeProvider, Radio } from '@guidde/design-system'
import { StepAppearancePreview } from './StepAppearancePreview'
import { AdvancedSettings } from './AdvancedSettings'
import { type BrandKitType, type BrandKitBackgroundType } from 'app/types'
import { type SectionProps, Section, ColorPopper } from '..'
import { useStepAppearance } from './use-step-appearance'

type Props = {
    values: {
        background: BrandKitType['background']
        qgHighlightColor: BrandKitType['qgHighlightColor']
        colors: BrandKitType['color']
        stepsNumberingColor: BrandKitType['stepsNumberingColor']
        documentOutputSettings: BrandKitType['documentOutputSettings']
        videoOutputSettings: BrandKitType['videoOutputSettings']
    }
    active: {
        background?: BrandKitBackgroundType
        qgHighlightColor: string
        stepsNumberingColor: string
    }
} & SectionProps

export const StepAppearance = memo(
    ({ values, isLoading, active, popupProps, onAdd, onDelete, onSetActive, onEdit }: Props) => {
        const {
            selection,
            setSelection,
            currentOutputSettings,
            colorPopperConfig,
            activeBackground,
            activeElementsColor,
            activeStepNumberingColor,
            activeBackgroundIsNoBg,
            activeBackgroundIsImage,
            scaleFactor
        } = useStepAppearance(values, active, onSetActive, onAdd, onDelete)

        return (
            <Section
                popupProps={popupProps}
                title="Step Appearance"
                subtitle="Enable or disable layout default elements. Specify colors for a cohesive visual language."
                content={
                    <SpacedGroup alignItems="flex-start" ml={2}>
                        <SpacedGroup flexDirection="column">
                            <StepAppearancePreview
                                scaleFactor={scaleFactor}
                                background={activeBackground}
                                numberingColor={activeStepNumberingColor}
                                backgroundIsNoBg={activeBackgroundIsNoBg}
                                backgroundIsImage={activeBackgroundIsImage}
                                isOverlayEnabled={!!currentOutputSettings?.isOverlayEnabled}
                                isCalloutsEnabled={!!currentOutputSettings?.isCalloutsEnabled}
                                isArrowEnabled={!!currentOutputSettings?.isArrowEnabled}
                                elementsColor={activeElementsColor}
                            />
                            <ThemeProvider>
                                <SpacedGroup flexDirection="column" spacing={1}>
                                    {(['video', 'document'] as const).map(selectionOption => (
                                        <Radio
                                            key={selectionOption}
                                            size="small"
                                            checked={selectionOption === selection}
                                            label={`${
                                                selectionOption === 'video' ? 'Video' : 'Document'
                                            } preview`}
                                            onChange={() => setSelection(selectionOption)}
                                        />
                                    ))}
                                </SpacedGroup>
                            </ThemeProvider>
                        </SpacedGroup>
                        <Box>
                            <SpacedGroup mb={1}>
                                {colorPopperConfig.map((config, index) => (
                                    <ColorPopper
                                        key={index}
                                        popupProps={popupProps}
                                        disabled={isLoading}
                                        isLoading={isLoading}
                                        {...config}
                                    />
                                ))}
                            </SpacedGroup>
                            <ThemeProvider>
                                <AdvancedSettings
                                    values={values}
                                    onEdit={onEdit}
                                    isLoading={isLoading}
                                    popupProps={popupProps}
                                />
                            </ThemeProvider>
                        </Box>
                    </SpacedGroup>
                }
                showActionCard={false}
            />
        )
    }
)
