import { Icon, Tooltip } from '@guidde/design-system'
import { Box } from '@mui/material'

type Props = {
    tooltip: string
    icon: any
    id?: string
    disabled?: boolean
    onClick: () => void
    shouldHighlightChildIcon?: boolean
}

export const ActionIcon = ({
    icon,
    disabled,
    id,
    shouldHighlightChildIcon,
    tooltip,
    onClick
}: Props) => (
    <Tooltip title={tooltip} shouldHighlightChildIcon={shouldHighlightChildIcon}>
        <Box
            onClick={disabled ? undefined : onClick}
            {...(id && { id })}
            p={0.5}
            sx={{
                color: theme => theme.palette.grey[600]
            }}
        >
            <Icon icon={icon} />
        </Box>
    </Tooltip>
)
