import React, { memo, type MouseEvent, type ChangeEvent } from 'react'

import { useNotification, useBoolean } from 'hooks'
import { useParams } from 'react-router-dom'

import { faCaretUp } from '@fortawesome/pro-solid-svg-icons'
import { SpacedGroup, type UpgradePlanProps } from 'UI/Components'
import { Typography, Checkbox, Icon } from '@guidde/design-system'

import { type OutputSettings } from 'app/types'
import { type SectionProps } from '..'
import { Box, styled, Collapse, CircularProgress } from '@mui/material'
import BrandkitAnalytics from 'analytics/brandkit'

type SettingKey = keyof OutputSettings

interface Setting {
    key: SettingKey
    label: string
}

interface Props {
    values: {
        videoOutputSettings: OutputSettings
        documentOutputSettings: OutputSettings
    }
    onEdit: SectionProps['onEdit']
    isLoading: boolean
    popupProps: UpgradePlanProps & {
        validateClick: (e: MouseEvent<HTMLElement>, callback: () => void) => void
    }
}

const settings: Setting[] = [
    { key: 'isOverlayEnabled', label: 'Overlay' },
    { key: 'isCalloutsEnabled', label: 'Callouts' },
    { key: 'isArrowEnabled', label: 'Arrows' }
]

const AnimatedContent = styled(Box)(({ theme }) => ({
    overflow: 'hidden',
    transition: theme.transitions.create('max-height', {
        duration: theme.transitions.duration.shortest
    })
}))

type OutputType = keyof Props['values']

const AdvancedSettingsBlock = memo(
    ({
        outputType,
        values,
        handleSettingChange,
        popupProps
    }: Props & {
        handleSettingChange: (setting: Setting, outputType: OutputType) => void
        outputType: OutputType
    }) => {
        const advancedBlockExpanded = useBoolean()

        const handleCheckboxChange =
            (setting: Setting) => (event: ChangeEvent<HTMLInputElement>) => {
                const syntheticMouseEvent = {
                    preventDefault: () => {},
                    stopPropagation: () => {},
                    target: event.target,
                    currentTarget: event.currentTarget
                } as unknown

                popupProps.validateClick(syntheticMouseEvent as MouseEvent<HTMLElement>, () => {
                    handleSettingChange(setting, outputType)
                })
            }

        return (
            <SpacedGroup flexDirection="column" spacing={1} ml={1}>
                <SpacedGroup
                    style={{ cursor: 'pointer' }}
                    onClick={advancedBlockExpanded.toggle}
                    spacing={1}
                >
                    <Typography variant="text" size="sm" fontWeight="bold">
                        {outputType === 'videoOutputSettings' ? 'Video' : 'Document'} layout
                    </Typography>
                    <Icon
                        icon={faCaretUp}
                        size="xs"
                        style={{
                            transition: 'transform 0.3s ease 0s',
                            transform: `rotate(${advancedBlockExpanded.isFalse ? 180 : 0}deg)`
                        }}
                    />
                </SpacedGroup>

                <Collapse in={advancedBlockExpanded.isTrue} timeout="auto">
                    <AnimatedContent>
                        <SpacedGroup>
                            {settings.map(setting => (
                                <Checkbox
                                    key={setting.key + outputType}
                                    checked={values[outputType]?.[setting.key]}
                                    onChange={handleCheckboxChange(setting)}
                                    size="small"
                                    disabled={
                                        outputType === 'documentOutputSettings' &&
                                        !values.videoOutputSettings[setting.key]
                                    }
                                    disabledReason={`Enable ${setting.label.toLowerCase()} in the video step to access this option.`}
                                    label={setting.label}
                                    indeterminate={false}
                                />
                            ))}
                        </SpacedGroup>
                    </AnimatedContent>
                </Collapse>
            </SpacedGroup>
        )
    }
)

const getUpdatedSettings = (
    setting: Setting,
    outputType: OutputType,
    newStatus: boolean,
    currentValues: Props['values']
): Partial<Props['values']> => {
    const { videoOutputSettings, documentOutputSettings } = currentValues

    if (outputType === 'videoOutputSettings') {
        return {
            videoOutputSettings: {
                ...videoOutputSettings,
                [setting.key]: newStatus
            },
            documentOutputSettings: {
                ...documentOutputSettings,
                [setting.key]: newStatus ? documentOutputSettings[setting.key] : false
            }
        }
    } else {
        return {
            [outputType]: {
                ...currentValues[outputType],
                [setting.key]: newStatus
            }
        }
    }
}

const LoadingOverlay = styled(Box)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    zIndex: 1
}))

export const AdvancedSettings: React.FC<Props> = memo(
    ({ values, onEdit, isLoading, popupProps }) => {
        const { id: brandKitId } = useParams<{ id: string }>()
        const { showErrorNotification, showSuccessNotification } = useNotification()

        const handleSettingChange = async (setting: Setting, outputType: OutputType) => {
            const newStatus = !values[outputType]?.[setting.key]
            const status = newStatus ? 'enabled' : 'disabled'

            BrandkitAnalytics.brandKitChkSelected({
                brandKitId,
                newStatus,
                outputType,
                setting
            })

            try {
                const updatedSettings: Partial<Props['values']> = getUpdatedSettings(
                    setting,
                    outputType,
                    newStatus,
                    values
                )

                const brandkitResponse = await onEdit(updatedSettings)

                if (brandkitResponse === undefined) {
                    showErrorNotification(`Failed to update: ${setting.label}`)
                } else {
                    showSuccessNotification(`${setting.label} ${status}`)
                }
            } catch (error) {
                console.error(`Failed to update ${setting.label}:`, error)
            }
        }

        return (
            <SpacedGroup flexDirection="column" spacing={0} gap={1} position="relative">
                {isLoading && (
                    <LoadingOverlay>
                        <CircularProgress />
                    </LoadingOverlay>
                )}
                {(['videoOutputSettings', 'documentOutputSettings'] as OutputType[]).map(
                    outputType => (
                        <AdvancedSettingsBlock
                            key={outputType}
                            outputType={outputType}
                            values={values}
                            onEdit={onEdit}
                            isLoading={isLoading}
                            popupProps={popupProps}
                            handleSettingChange={handleSettingChange}
                        />
                    )
                )}
            </SpacedGroup>
        )
    }
)
