import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getDatabase, set, ref as rdtbRef } from 'firebase/database'

import { type PlaybookTranscriptType } from 'UI/Components'

import { type PlaybookApplicationType } from 'app/types'

import { host, uuid, getFirebaseUrl } from './utils'
import { uploadWithSignedUrl } from './upload'
import { db } from './firebase'

const playbookRef = (id: string) => doc(db, `playbooks/${id}`)

const fetchStatus = (url: string, requestData: any): Promise<boolean> =>
    fetch(`${host}${url}`, requestData).then(res => res.ok)

const fallbackTranscript = { segments: [], playbookId: '' }

export const fetchTranscript = async (url: string): Promise<PlaybookTranscriptType> => {
    try {
        const response = await fetch(url)
        const data = await response.text()
        return data ? JSON.parse(data) : fallbackTranscript
    } catch (e) {
        console.error(e)
        return fallbackTranscript
    }
}

export const autoUploadRef = (uid: string) => doc(db, 'autoUpload', uid)

export const deletePlaybook = (playbookId: string) =>
    deleteDoc(playbookRef(playbookId)).catch(console.error)

type BatchUploadFromGdriveProps = {
    uploadedBy: UploadedByType
    uid: string
    batchId: string
    // @TODO: set normal type here when somebody will work with this function
    files: Array<unknown>
    auth?: {
        access_token: string
    }
}

type UploadedByType = {
    displayName: string
    photoURL: string
    email: string
}

export const batchUploadFromGdrive = ({
    uploadedBy,
    uid,
    batchId,
    files,
    auth
}: BatchUploadFromGdriveProps) => {
    return set(rdtbRef(getDatabase(), `/batchGDriveUpload/${uid}/${batchId}/data`), {
        uploadedBy,
        uid,
        batchId,
        files,
        auth
    }).catch(err => console.error('uploadFromGdrive', err))
}
type UploadFromGdriveProps = {
    uploadedBy: UploadedByType
    uid: string
    uploadId: string
    // @TODO: set normal type here when somebody will work with this function
    file: {
        id: string
    }
    auth: {
        code: string
    }
}

export type DriveType = 'gdrive' | 'onedrive' | 'local' | 'zoom' | 'youtube'

export const uploadFromDrive = (data: UploadFromGdriveProps, drive: DriveType = 'gdrive') => {
    const { uid, file } = data
    if (!data.uploadedBy?.photoURL) data.uploadedBy.photoURL = ''

    const fileRef = rdtbRef(getDatabase(), `${drive}/${uid}/${file.id}`)

    return set(fileRef, { data, progress: -1 }).catch(err => console.error('uploadFromDrive', err))
}

type BatchUploadType = {
    id?: string
    uploadedBy: string
    fileList: Array<unknown>
    drive: DriveType
    isPlaylists?: boolean
}
// fileList = array of the ids
// drive - gDrive, zoom, local, oneDrive, youtube
export const batchUpload = ({
    id = uuid(),
    uploadedBy,
    fileList,
    drive,
    isPlaylists
}: BatchUploadType) => {
    return set(rdtbRef(getDatabase(), `/batchUpload/${uploadedBy}/${id}`), {
        fileList,
        drive,
        ...(isPlaylists && { isPlaylists })
    }).catch(err => console.error('batchUpload', err))
}

type UpdateTitle = {
    playbookId: string
    title: string
}

export const updateTitle = ({ playbookId, title }: UpdateTitle) =>
    updateDoc(playbookRef(playbookId), { title }).catch(console.error)

type FileType = {
    id: string
    name: string
    uid: string
    url: string
}

export const updateFiles = (playbookId: string, files: Array<FileType>) => {
    return updateDoc(playbookRef(playbookId), { files }).catch(console.error)
}

type UpdateLinksType = Omit<FileType, 'name'> & { name: string | null }

export const updateLinks = (playbookId: string, links: Array<UpdateLinksType>) => {
    return updateDoc(playbookRef(playbookId), { links }).catch(console.error)
}

type UpdateDescription = {
    playbookId: string
    description: string
}
export const updateDescription = ({ playbookId, description }: UpdateDescription) =>
    updateDoc(playbookRef(playbookId), { description }).catch(console.error)

type UpdateLinkType = {
    playbookId: string
    link: string
}
export const updateLink = ({ playbookId, link }: UpdateLinkType) =>
    updateDoc(playbookRef(playbookId), { link }).catch(console.error)

type UpdatePlayListType = {
    playbookId: string
    playbookList: Array<string>
    applications?: Array<PlaybookApplicationType>
}

export const updatePlayList = ({ playbookId, playbookList, applications }: UpdatePlayListType) => {
    return updateDoc(playbookRef(playbookId), {
        playbookList,
        ...(applications && { applications })
    }).catch(console.error)
}

type TriggerPlaybookUploadFinishListenerType = {
    playbookId: string
    originalFileName: string
    title: string
    uid: string
    type: 'manual' | 'webapp'
    uploadedBy: { displayName: string; photoURL: string; email: string }
    isRecording: boolean
    mimeType?: string
    fileSize?: number
    url: string
}
export const triggerPlaybookUploadFinishListener = async ({
    playbookId,
    originalFileName,
    title,
    uid,
    type = 'manual',
    uploadedBy,
    isRecording,
    mimeType = '',
    fileSize,
    url
}: TriggerPlaybookUploadFinishListenerType) => {
    const token = await getAuth().currentUser?.getIdToken()

    if (!token) throw new Error('Token is empty')

    try {
        return fetchStatus('/b/v1/playbookAction', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
                action: 'uploaded',
                playbookId,
                uid,
                data: {
                    type,
                    title,
                    url,
                    isRecording,
                    originalFileName,
                    id: playbookId,
                    uploadedByUid: uid,
                    uploadedBy,
                    mimeType,
                    fileSize
                }
            })
        })
    } catch (e) {
        console.error('[Upload trigger error]:', e)
        return false
    }
}

type UpdateCoverType = {
    playbookId: string
    previousScreenshotUrl: string
    newCoverUrl: string
}
export const updateCover = ({
    playbookId,
    previousScreenshotUrl,
    newCoverUrl
}: UpdateCoverType) => {
    return updateDoc(playbookRef(playbookId), {
        screenshotUrl: newCoverUrl || previousScreenshotUrl,
        previousScreenshotUrl: newCoverUrl ? previousScreenshotUrl : null
    }).catch(console.error)
}

type SetPlaybookColorType = {
    playbookId: string
    coverColor: string
}
export const updatePlaybookCoverColor = ({ playbookId, coverColor }: SetPlaybookColorType) => {
    return updateDoc(playbookRef(playbookId), { coverColor }).catch(console.error)
}

export type UpdatePlaylistCover = {
    id: string
    coverColor?: string
    screenshotUrl?: string
    previousScreenshotUrl?: string
}

export const updatePlaylistCover = ({ id, ...data }: UpdatePlaylistCover) => {
    return updateDoc(playbookRef(id), data).catch(console.error)
}

type UploadThumbnailProps = {
    playbookId: string
    orgId: string
    uid: string
    fileName: string
    base64Data: string
}

export const uploadThumbnail = async ({
    playbookId,
    orgId,
    uid,
    fileName,
    base64Data
}: UploadThumbnailProps): Promise<string> => {
    const storagePath = `chaptersScreenshot/${orgId}/${uid}/${playbookId}/${fileName}`

    // convert base64 to File object
    const res = await fetch(base64Data)
    const blob = await res.blob()
    const file = new File([blob], fileName, { type: 'image/png' })

    // upload it to storage
    await uploadWithSignedUrl({ url: 'gs:///' + storagePath, withOrgId: true }, file)
    // return url link from storage
    return getFirebaseUrl(storagePath)
}
