import { deleteDoc, doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore'

import { db, uuid } from 'modules'

const orgDictionariesRef = (id: string) => doc(db, `customPhrases`, id)

type DescriptionType =
    | 'competitor'
    | 'customer'
    | 'feature'
    | 'industry'
    | 'product'
    | 'name'
    | 'other'

type DataType = {
    appId: string
    description: DescriptionType
    lang: string
    orgId: string
    phrase: string
    source: 'player' | 'admin'
    uid: string
}
export const createDictionaryWord = (data: DataType) => {
    const id = uuid()

    return setDoc(orgDictionariesRef(id), {
        ...data,
        id,
        timestamp: Timestamp.fromDate(new Date())
    })
}

type UpdateType = {
    id: string
    timestamp: Timestamp
} & DataType

export const updateDictionaryWord = (data: UpdateType) => {
    return updateDoc(orgDictionariesRef(data.id), data)
}

export const deleteDictionaryWord = (id: string) => {
    return deleteDoc(orgDictionariesRef(id))
}
