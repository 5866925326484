import {
    TimelineChunkVariant,
    type TimelineChunkType,
    type TimelineSnapshotChunkType,
    type TimelineVideoChunkType
} from './types'

export const checkIsVideoChunk = (chunk: TimelineChunkType): chunk is TimelineVideoChunkType => {
    return chunk?.type === TimelineChunkVariant.video
}

export const checkIsSnapshotChunk = (
    chunk?: TimelineChunkType
): chunk is TimelineSnapshotChunkType => {
    return chunk?.type === TimelineChunkVariant.snapshot
}

export const getChunkDuration = (chunk: { duration: number } | { start: number; end: number }) => {
    return 'duration' in chunk ? chunk.duration : chunk.end - chunk.start
}
