import { memo, useCallback, useMemo, useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import {
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Tooltip
} from '@mui/material'
import { styled } from '@mui/material/styles'

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import ShareIcon from '@mui/icons-material/Share'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import EditIcon from '@mui/icons-material/Edit'

import { ReactComponent as DeleteIcon } from 'assets/icons/trash-outlined.svg'
import { ReactComponent as PinIcon } from 'assets/icons/full_pin-icon.svg'
import { ReactComponent as NotPinnedIcon } from 'assets/icons/not_pinned-icon.svg'
import { ReactComponent as SpaceIcon } from 'assets/icons/add-to-space.svg'

import { type FeaturedProps, useCanEditPlaybook } from './PlaybookHits'

import { useBoolean, useDuplicate, useRoles } from 'hooks'
import {
    isQG,
    logPlaybookDelete,
    logToAnalytics,
    playbookToAnalyticsProps,
    deletePlaybook
} from 'modules'

import { paths } from 'app/paths'
import { type AnyPlaybookType, type PlaybookModeType, PlaybookMode } from 'app/types'

import {
    ConfirmationDialog,
    DuplicateQGLoader,
    MoveToTrashDialog,
    ShareOnlyDialog,
    SharePublishDialog,
    ShareToPlaylist,
    ShareToSpaceDialog
} from 'UI/Components'

const PREFIX = 'PlaybookMenuItems'

const classes = {
    tooltip: `${PREFIX}-tooltip`,
    itemIcon: `${PREFIX}-itemIcon`,
    divider: `${PREFIX}-divider`
}

const StyledBox = styled(Box)({
    [`& .${classes.tooltip}`]: {
        marginTop: '-12px'
    },
    [`& .${classes.itemIcon}`]: {
        minWidth: '35px',
        color: 'black'
    },
    [`& .${classes.divider}`]: {
        margin: '5px',
        borderColor: 'rgba(9, 12, 16, 0.08)'
    }
})

export type MenuItemSourceType = 'home' | 'myguides' | 'space' | 'applications'

export type PlaybookMenuItemsProps = {
    playbook: AnyPlaybookType
    source?: MenuItemSourceType
    featuredProps?: FeaturedProps
    removeFromSpaceOption?: OverwrittenMenuItemType
    moveToFolderOption?: OverwrittenMenuItemType
    searchRefresh?: (playbookId?: string) => void
    onClose?: () => void
}

export type MenuItemType = {
    icon: any
    title: string
    onClick: (playbook: AnyPlaybookType) => void
    selected?: boolean
    disabled?: boolean | ((playbook: AnyPlaybookType) => boolean)
    showDivider?: boolean
    protected?: boolean
    hideForPlaylist?: boolean
    tooltipText?: (playbook: AnyPlaybookType) => string
}

export type OverwrittenMenuItemType = Omit<MenuItemType, 'onClick' | 'disabled'> & {
    hidden?: boolean
    onClick: (playbook: AnyPlaybookType) => void
    disabled?: boolean | ((playbook: AnyPlaybookType) => boolean)
    tooltipText?: (playbook: AnyPlaybookType) => string
}
type MenuItemsType = Array<OverwrittenMenuItemType>

export const PlaybookMenuItems = memo(
    ({
        playbook,
        featuredProps,
        source,
        searchRefresh = () => {},
        removeFromSpaceOption,
        moveToFolderOption,
        onClose
    }: PlaybookMenuItemsProps) => {
        const history = useHistory()
        const historyPush = history.push

        const loading = useBoolean()
        const setLoading = loading.set

        const [activePlaybookId, setActivePlaybookId] = useState<string | null>(null)

        const isPlaylist = playbook.mode === PlaybookMode.Playlist

        const { search: locationQuery } = useLocation()

        const { duplicateQG, duplicatePlaylist, duplicationIsLoading, isDuplicationAvailable } =
            useDuplicate({
                uploadedByOrgId: playbook.uploadedByOrgId
            })

        const canEdit = useCanEditPlaybook(playbook)

        const { isViewer, isMember, checkRoles } = useRoles()
        const { isMember: isMemberInPlaybookWorkspace } = checkRoles(playbook.uploadedByOrgId)

        const showDeleteDialog = useBoolean()
        const publishDialog = useBoolean()
        const showAddToDialog = useBoolean()
        const addToSpaceDialog = useBoolean()

        const openPublishDialog = publishDialog.setTrue
        const openAddToDialog = showAddToDialog.setTrue
        const openAddToSpaceDialog = addToSpaceDialog.setTrue

        const setDeleteDialog = showDeleteDialog.set

        const handleEdit = useCallback(
            ({ mode, id }: AnyPlaybookType) => {
                switch (mode as PlaybookModeType) {
                    case 'playbook':
                        historyPush({
                            search: locationQuery,
                            pathname: generatePath(paths.editPlaybook, {
                                playbookId: id
                            })
                        })
                        break
                    case 'playlist':
                        historyPush({
                            search: locationQuery,
                            pathname: generatePath(paths.editPlaylist, {
                                playbookId: id
                            })
                        })
                        break
                    case 'quickGuidde':
                        historyPush({
                            search: locationQuery,
                            pathname: generatePath(paths.editQuickGuidde, {
                                playbookId: id
                            })
                        })
                        break
                    default:
                        break
                }
            },
            [historyPush, locationQuery]
        )

        const menuItems = useMemo((): Array<MenuItemType> => {
            const isFeatured = Boolean(
                featuredProps?.featuredList?.find(it => it.id === playbook.id)
            )
            const featuredItemsCount = featuredProps?.featuredList.length

            let list: MenuItemsType = [
                {
                    title: 'Share',
                    icon: ShareIcon,
                    onClick: () => {
                        setActivePlaybookId(playbook.id)
                        openPublishDialog()

                        if (source) {
                            logToAnalytics(`contextMenu_share_clicked`, {
                                source,
                                playbookId: playbook.id,
                                ...playbookToAnalyticsProps(playbook)
                            })
                        } else {
                            console.error('source is not defined')
                        }
                    },

                    hidden: isQG(playbook) && playbook.isFakeDraft
                },
                {
                    title: 'Save to Playlist',
                    icon: PlaylistAddIcon,
                    onClick: () => {
                        setActivePlaybookId(playbook.id)
                        openAddToDialog()
                    },
                    hidden: isPlaylist || isViewer || (isQG(playbook) && playbook.isFakeDraft)
                },
                {
                    title: 'Add to Space',
                    icon: SpaceIcon,
                    onClick: () => {
                        setActivePlaybookId(playbook.id)
                        openAddToSpaceDialog()
                    },
                    hidden: !isMemberInPlaybookWorkspace
                },
                {
                    title: 'Edit',
                    icon: EditIcon,
                    onClick: () => handleEdit(playbook),
                    hidden: !canEdit || isViewer
                },
                {
                    title: isFeatured ? 'Unpin' : 'Pin to Top',
                    icon: isFeatured ? NotPinnedIcon : PinIcon,
                    disabled: !isFeatured && featuredItemsCount === 5,
                    tooltipText: () =>
                        !isFeatured && featuredItemsCount === 5 ? 'You can pin up to 5 guides' : '',
                    onClick: () => featuredProps?.toggle?.(playbook.id),
                    hidden: !featuredProps?.showFeatured || featuredProps?.hideVisibility
                },
                {
                    title: 'Make a Copy',
                    icon: FileCopyOutlinedIcon,
                    hidden: !isDuplicationAvailable || (!isQG(playbook) && !isPlaylist) || isViewer,
                    onClick: () => {
                        if (isQG(playbook)) duplicateQG(playbook)
                        if (isPlaylist) duplicatePlaylist(playbook)
                    },
                    disabled: duplicationIsLoading
                },
                {
                    title: 'Move to Trash',
                    icon: DeleteIcon,
                    onClick: () => {
                        setActivePlaybookId(playbook.id)
                        setDeleteDialog(true)
                        logToAnalytics('contextMenu_moveToTrash_clicked', {
                            source,
                            playbookId: playbook.id,
                            ...playbookToAnalyticsProps(playbook)
                        })
                    },
                    hidden: !canEdit || isViewer || isPlaylist
                },
                {
                    title: 'Delete',
                    icon: DeleteIcon,
                    onClick: () => {
                        setActivePlaybookId(playbook.id)
                        setDeleteDialog(true)
                    },
                    hidden: !isPlaylist
                }
            ]

            if (removeFromSpaceOption) {
                list[2] = {
                    ...removeFromSpaceOption,
                    onClick: () => removeFromSpaceOption.onClick(playbook),
                    hidden: removeFromSpaceOption.hidden || !canEdit
                }
            }

            if (moveToFolderOption) {
                list.splice(2, 0, moveToFolderOption)
            }

            return list.filter(it => !it.hidden)
        }, [
            featuredProps,
            playbook,
            isPlaylist,
            isViewer,
            isMemberInPlaybookWorkspace,
            canEdit,
            isDuplicationAvailable,
            duplicationIsLoading,
            removeFromSpaceOption,
            moveToFolderOption,
            openPublishDialog,
            source,
            openAddToDialog,
            openAddToSpaceDialog,
            handleEdit,
            duplicateQG,
            duplicatePlaylist,
            setDeleteDialog
        ])

        const handleClose = (func: () => void) => () => {
            onClose?.()
            func()
        }

        const handleDelete = useCallback(
            (playbook: AnyPlaybookType) => {
                setLoading(true)
                deletePlaybook(playbook.id)
                    .then(() => {
                        logPlaybookDelete(playbook)
                        setLoading(false)
                        setDeleteDialog(false)
                    })
                    .then(() => searchRefresh?.(playbook.id))
                    .catch(e => console.error('[DELETING ERROR]:', e))
            },
            [setLoading, setDeleteDialog, searchRefresh]
        )

        const showFullShareDialog = canEdit || isMember || isViewer

        return (
            <>
                <DuplicateQGLoader isOpen={duplicationIsLoading} />
                <MenuList autoFocusItem={false}>
                    {menuItems.map((it, i) => {
                        const Icon = it.icon
                        const disabled =
                            typeof it.disabled === 'function' ? it.disabled(playbook) : it.disabled

                        const tooltipText = it.tooltipText?.(playbook) || ''

                        return (
                            <StyledBox key={i}>
                                <Tooltip
                                    title={tooltipText}
                                    classes={{
                                        tooltip: classes.tooltip
                                    }}
                                >
                                    <Box>
                                        <MenuItem
                                            selected={it.selected}
                                            disabled={disabled}
                                            onClick={e => {
                                                e.stopPropagation()
                                                it.onClick(playbook)
                                            }}
                                            data-test={`${it.title}-menu-item`}
                                            sx={{ height: 44 }}
                                        >
                                            <ListItemIcon className={classes.itemIcon}>
                                                <Icon fontSize="small" />
                                            </ListItemIcon>
                                            <ListItemText primary={it.title} />
                                        </MenuItem>
                                    </Box>
                                </Tooltip>

                                <Divider variant="middle" className={classes.divider} />
                            </StyledBox>
                        )
                    })}
                </MenuList>
                {activePlaybookId && (
                    <ShareToSpaceDialog
                        isOpen={addToSpaceDialog.isTrue}
                        onClose={handleClose(() => {
                            addToSpaceDialog.setFalse()
                            setActivePlaybookId(null)
                        })}
                        playbookMode={playbook.mode}
                        playbooksIds={[playbook.id]}
                    />
                )}
                {playbook && (
                    <>
                        {!isPlaylist && showAddToDialog.isTrue && (
                            <ShareToPlaylist
                                onClose={handleClose(showAddToDialog.setFalse)}
                                selectedPlaybooks={[playbook]}
                                searchRefresh={searchRefresh}
                            />
                        )}

                        {publishDialog.isTrue && (
                            <>
                                {showFullShareDialog ? (
                                    <SharePublishDialog
                                        isOpen={true}
                                        onClose={handleClose(publishDialog.setFalse)}
                                        playbook={playbook}
                                        onDone={searchRefresh}
                                        title={`Share your ${isPlaylist ? 'playlist' : 'video'}`}
                                    />
                                ) : (
                                    <ShareOnlyDialog
                                        isOpen={true}
                                        subtitle=""
                                        warning=""
                                        onClose={handleClose(publishDialog.setFalse)}
                                        playbook={playbook}
                                        onDone={searchRefresh}
                                        title={`Share your ${isPlaylist ? 'playlist' : 'video'}`}
                                    />
                                )}
                            </>
                        )}
                        {activePlaybookId && (
                            <MoveToTrashDialog
                                isOpen={showDeleteDialog.isTrue && !isPlaylist}
                                playbookIds={[activePlaybookId]}
                                playbooks={[playbook]}
                                onSuccess={() => {
                                    logPlaybookDelete(playbook)
                                    setDeleteDialog(false)
                                    searchRefresh?.(playbook.id)
                                }}
                                onClose={handleClose(showDeleteDialog.setFalse)}
                            />
                        )}
                        {showDeleteDialog.isTrue && (
                            <ConfirmationDialog
                                isOpen={isPlaylist}
                                title={`Delete ${isPlaylist ? 'Playlist' : 'Video'}`}
                                text={
                                    <>
                                        Are you sure you want to delete <b>{playbook.title}</b>{' '}
                                        {isPlaylist ? 'playlist' : 'video'}?
                                    </>
                                }
                                loading={loading.isTrue}
                                confirmBtnText="Delete"
                                onClose={handleClose(showDeleteDialog.setFalse)}
                                onConfirm={() => handleDelete(playbook)}
                            />
                        )}
                    </>
                )}
            </>
        )
    }
)
