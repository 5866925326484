import { useCallback, useMemo, useState } from 'react'

import { Box, Popper, Typography, LinearProgress, Tooltip, ClickAwayListener } from '@mui/material'

import ArrowIcon from '@mui/icons-material/ArrowDropDown'
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined'

import { ReactComponent as NoColorIcon } from 'assets/icons/no-color.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'

import { BackgroundDialog, ColorDialog } from 'UI/Routes/brand-kit/sections/dialogs'
import { DEFAULT_STEP } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'

import { ImageCropper } from 'UI/Components'
import { type SectionProps } from './Section'
import { ButtonAdd, ColorButton } from './popper-buttons'

import { useAuth, useBoolean, useGlobalFeatureFlags } from 'hooks'
import { uuid } from 'modules'
import { type ImageAsBackgroundType } from 'app/types'

const greyColor = 'rgba(9, 12, 16, 0.08)'
const borderStyle = `1px solid ${greyColor}`

export const generateColorRows = <T extends string | object>(
    inputArray: Array<T>,
    itemsPerRow: number = 10
) => {
    return Array.from({ length: Math.ceil(inputArray.length / itemsPerRow) }, (_, i) =>
        inputArray.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow)
    )
}

const generateGradientsFromColor = ([color1, color2]: Array<string>, customAngle: number) => {
    return `linear-gradient(${customAngle}deg, ${color1} 0%, ${color2} 100%)`
}

export const includesDifferentColors = (color: string) => {
    if (color.startsWith('#')) return false

    const colorRegex = /#[0-9a-fA-F]{6}/g
    const matches = color.match(colorRegex) || []

    return [...new Set(matches)].length > 1
}

const uploadBgImage = 'uploadBgImage'
const buttonCode = 'actionButton'

export type Props = {
    title: string
    disabled: boolean
    activeColor: string
    overLimitTooltip: string
    customAngle?: number
    isOverLimit: boolean
    colors: Array<string | ImageAsBackgroundType>
    isLoading: boolean
    popupProps: SectionProps['popupProps']
    onAddColor: (colors: [string, string] | ImageAsBackgroundType) => Promise<void>
    onSetActiveColor: (color: string, type: 'color' | 'gradient' | 'image') => void
    onDeleteColor?: (color: string) => void
}

export const ColorPopper = ({
    title,
    disabled,
    popupProps,
    activeColor,
    isOverLimit,
    customAngle,
    overLimitTooltip,
    isLoading,
    colors,
    onAddColor,
    onDeleteColor,
    onSetActiveColor
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

    const colorDialog = useBoolean()
    const backgroundDialog = useBoolean()

    const circleBorder = customAngle ? 'none' : borderStyle
    const activeColorIsNoColor = activeColor === ''
    const activeColorIsImage = activeColor.includes('http')

    const { enableImageAsBackground } = useGlobalFeatureFlags()

    const validateIfBackgroundAlreadyExists = useCallback(
        ([color1, color2]: [string, string]) => {
            if (!color1 && !color2) return false

            const target = color2 ? [color1, color2] : [color1, color1]
            const gradient = generateGradientsFromColor(target, customAngle || 0).toLowerCase()

            return colors
                .map(color => {
                    if (typeof color === 'string') return color.toLowerCase()
                    return ''
                })
                .includes(gradient)
        },
        [colors, customAngle]
    )

    const colorGroups = useMemo(() => {
        if (!customAngle) return [generateColorRows([...colors, buttonCode])]

        const imagesAsBackground: Array<ImageAsBackgroundType> = []
        const multipleColors: Array<string> = []
        const singleColors: Array<string> = []

        colors.forEach(color => {
            if (typeof color !== 'string') {
                imagesAsBackground.push(color)
                return
            }
            if (includesDifferentColors(color)) multipleColors.push(color)
            else singleColors.push(color)
        })

        return [
            ...(enableImageAsBackground
                ? [
                      generateColorRows([
                          ...imagesAsBackground,
                          ...(imagesAsBackground.length < 5 ? [uploadBgImage] : [])
                      ])
                  ]
                : []),
            generateColorRows([...singleColors, buttonCode]),
            generateColorRows([...multipleColors, buttonCode])
        ]
    }, [colors, customAngle, enableImageAsBackground])

    const oDropdownClick = (e: any) => {
        if (disabled) return

        popupProps.validateClick(e, () => {
            setAnchorEl(e.currentTarget)
        })
    }

    return (
        <>
            <Box p={1} minWidth={75}>
                <Typography style={{ color: 'rgba(9, 12, 16, 0.6)', fontSize: 14 }}>
                    {title}
                </Typography>
                <Box my={1.5} bgcolor={greyColor} height={1} width="100%" />
                <Box
                    width={64}
                    height={32}
                    borderRadius="5px"
                    bgcolor="#FFF"
                    style={{ cursor: disabled ? 'auto' : 'pointer' }}
                    border={borderStyle}
                    display="flex"
                    onClick={oDropdownClick}
                >
                    <Box
                        height="100%"
                        width="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        borderRight={borderStyle}
                    >
                        <Box
                            width={20}
                            height={20}
                            overflow="hidden"
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                border: circleBorder,
                                ...(activeColorIsImage
                                    ? {
                                          background: `url(${activeColor})`,
                                          backgroundSize: 'contain',
                                          backgroundPosition: 'center',
                                          backgroundRepeat: 'no-repeat',
                                          width: '30px'
                                      }
                                    : {
                                          background: activeColor,
                                          borderRadius: '50%'
                                      })
                            }}
                        >
                            {activeColorIsNoColor && (
                                <NoColorIcon style={{ width: 20, height: 20 }} />
                            )}
                        </Box>
                    </Box>
                    <Box
                        height="100%"
                        width="50%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <ArrowIcon
                            style={{
                                transition: 'rotate 0.2s linear',
                                rotate: Boolean(anchorEl) ? '180deg' : '0deg'
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="top-start">
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box
                        width={370}
                        borderRadius="4px"
                        bgcolor="#FFF"
                        border={borderStyle}
                        overflow="hidden"
                        position="relative"
                    >
                        <Box position="absolute" height={4} width="100%">
                            {isLoading && <LinearProgress />}
                        </Box>
                        <Box p={2}>
                            <Typography style={{ fontWeight: 700, fontSize: 14 }}>
                                {title}
                            </Typography>

                            <Box mt={1} mb={2} bgcolor={greyColor} height="1px" width="100%" />

                            {colorGroups.map((rows, rowIdx) => (
                                <Box mt={1.5} key={rowIdx}>
                                    {rows.map((items, idx) => {
                                        const isFirstRow = rowIdx === 0
                                        const imageAsBgRow =
                                            Array.isArray(items) && typeof items[0] === 'object'
                                        const uploadBgImageItem =
                                            Array.isArray(items) && items[0] === uploadBgImage

                                        const imageAsBg =
                                            isFirstRow && (imageAsBgRow || uploadBgImageItem)

                                        return (
                                            <Box
                                                key={idx + rowIdx}
                                                gap={imageAsBg ? '4px' : 'unset'}
                                                display="flex"
                                                flexWrap={imageAsBg ? 'wrap' : 'unset'}
                                                height={imageAsBg ? 'auto' : 32}
                                                width={imageAsBg ? 'auto' : 'min-content'}
                                                border={imageAsBg ? 'unset' : borderStyle}
                                                borderTop={idx === 0 ? '' : 'none'}
                                                borderRadius="4px"
                                            >
                                                {items.map((item, i) => {
                                                    const imageAsBackground =
                                                        (typeof item !== 'string' &&
                                                            'url' in item) ||
                                                        item === uploadBgImage

                                                    if (imageAsBackground) {
                                                        return (
                                                            <ImageAsBackgroundItem
                                                                key={i}
                                                                item={item}
                                                                isActive={
                                                                    typeof item !== 'string' &&
                                                                    item.url === activeColor
                                                                }
                                                                onAddColor={onAddColor}
                                                                onSetActiveColor={onSetActiveColor}
                                                                onDeleteColor={onDeleteColor}
                                                            />
                                                        )
                                                    }

                                                    const isActiveGradient = item === activeColor

                                                    return (
                                                        <GradientItem
                                                            key={i}
                                                            item={item}
                                                            isActive={isActiveGradient}
                                                            isOverLimit={isOverLimit}
                                                            overLimitTooltip={overLimitTooltip}
                                                            colorDisabled={
                                                                isLoading || isActiveGradient
                                                            }
                                                            circleBorder={circleBorder}
                                                            colorBorderRight={
                                                                i !== items.length - 1
                                                                    ? borderStyle
                                                                    : 'none'
                                                            }
                                                            onAddClick={() => {
                                                                const dialog = customAngle
                                                                    ? backgroundDialog
                                                                    : colorDialog
                                                                dialog.toggle()
                                                                setAnchorEl(null)
                                                            }}
                                                            onColorClick={() => {
                                                                const type =
                                                                    includesDifferentColors(item)
                                                                        ? 'gradient'
                                                                        : 'color'

                                                                onSetActiveColor(item, type)
                                                            }}
                                                        />
                                                    )
                                                })}
                                            </Box>
                                        )
                                    })}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </ClickAwayListener>
            </Popper>

            <ColorDialog
                title={title}
                isOpen={colorDialog.isTrue}
                onClose={colorDialog.setFalse}
                validateIfColorAlreadyExists={color => colors.includes(color)}
                onSubmit={color => onAddColor([color, '']).finally(colorDialog.setFalse)}
                isLoading={isLoading}
                inputFullWidth={true}
            />
            <BackgroundDialog
                title={title}
                validateIfBackgroundAlreadyExists={validateIfBackgroundAlreadyExists}
                isOpen={backgroundDialog.isTrue}
                onClose={backgroundDialog.setFalse}
                onSubmit={colors => onAddColor(colors).finally(backgroundDialog.setFalse)}
                isLoading={isLoading}
                buttonLabel="SAVE CHANGES"
                customAngle={customAngle || 133}
            />
        </>
    )
}

type ImageAsBackgroundItemProps = {
    item: typeof uploadBgImage | ImageAsBackgroundType
    isActive: boolean
    onAddColor: (colors: ImageAsBackgroundType) => Promise<void>
    onSetActiveColor: Props['onSetActiveColor']
    onDeleteColor: Props['onDeleteColor']
}

const ImageAsBackgroundItem = ({
    item,
    isActive,
    onAddColor,
    onSetActiveColor,
    onDeleteColor
}: ImageAsBackgroundItemProps) => {
    const { uid } = useAuth()

    const hover = useBoolean()

    const canDelete = onDeleteColor && !isActive && hover.isTrue

    if (item === uploadBgImage) {
        return (
            <ImageCropper
                aspectRatio={
                    DEFAULT_STEP.windowDimensions.innerWidth /
                    DEFAULT_STEP.windowDimensions.innerHeight
                }
                isTempStorage
                storagePath={`quickguiddes/${uid}/${uuid()}/uploads`}
                labelComponent={
                    <Tooltip title="Upload image as background">
                        <Box
                            width={80}
                            height={45}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            border={borderStyle}
                            borderRadius="4px"
                        >
                            <AddPhotoAlternateOutlinedIcon fontSize="medium" />
                        </Box>
                    </Tooltip>
                }
                onDone={({ url }) => onAddColor({ url, scaleFactor: 0.8, id: uuid() })}
            />
        )
    }

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="relative"
            width={80}
            height={45}
            p={0.5}
            borderRadius="4px"
            border={isActive ? '1px solid #cb0000' : borderStyle}
            onClick={() => onSetActiveColor(item.url, 'image')}
            style={{ cursor: 'pointer' }}
            onMouseEnter={hover.setTrue}
            onMouseLeave={hover.setFalse}
        >
            <img
                height="100%"
                width="100%"
                src={item.url}
                alt="bg_image"
                style={{ objectFit: 'fill' }}
            />
            {canDelete && (
                <Box
                    width={18}
                    height={18}
                    bgcolor="rgb(0 0 0 / 30%)"
                    color="white"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    position="absolute"
                    right={6}
                    top={6}
                    borderRadius="3px"
                    sx={{ '&:hover': { backgroundColor: 'rgb(0 0 0 / 60%)' } }}
                    onClick={e => {
                        e.stopPropagation()
                        onDeleteColor?.(item.id)
                    }}
                >
                    <DeleteIcon style={{ color: 'white' }} />
                </Box>
            )}
        </Box>
    )
}

type GradientItemProps = {
    item: string
    isActive: boolean
    circleBorder: string
    colorDisabled: boolean
    isOverLimit: Props['isOverLimit']
    overLimitTooltip: Props['overLimitTooltip']
    colorBorderRight: string
    onAddClick: () => void
    onColorClick: () => void
}

const GradientItem = ({
    item,
    isOverLimit,
    isActive,
    overLimitTooltip,
    colorBorderRight,
    colorDisabled,
    circleBorder,
    onAddClick,
    onColorClick
}: GradientItemProps) => {
    if (item === buttonCode) {
        return (
            <ButtonAdd
                onClick={onAddClick}
                disabled={isOverLimit}
                tooltip={isOverLimit ? overLimitTooltip : ''}
            />
        )
    }

    return (
        <ColorButton
            value={item}
            onClick={onColorClick}
            disabled={colorDisabled}
            isActive={isActive}
            borderRight={colorBorderRight}
            circleBorder={circleBorder}
        />
    )
}
