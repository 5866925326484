import { type ReactNode, createContext, useContext, useEffect } from 'react'
import { useRouteMatch, useLocation } from 'react-router-dom'

import { useSessionStorage } from 'hooks'
import { paths } from 'app/paths'

const MenuStatusContext = createContext({
    isMenuClosed: false,
    setIsMenuClosed: (_value: boolean) => {}
})

export const useMenuStatus = () => useContext(MenuStatusContext)

const pathsMenuClosed = [
    paths.editQuickGuidde,
    paths.editPlaybook,
    paths.playbookDetails,
    paths.searchPlaybook
]

type ProviderProps = {
    children: ReactNode
}

export const MenuStatusProvider = ({ children }: ProviderProps) => {
    const shouldCloseMenu = useRouteMatch(pathsMenuClosed)?.isExact || false

    const [isMenuClosed, setIsMenuClosed] = useSessionStorage('isMainMenuClosed', shouldCloseMenu)

    const location = useLocation<{ prevPath: string }>()

    useEffect(() => {
        if (!location || !shouldCloseMenu) return

        setIsMenuClosed(true)
    }, [location, setIsMenuClosed, shouldCloseMenu])

    return (
        <MenuStatusContext.Provider
            value={{
                setIsMenuClosed,
                isMenuClosed
            }}
        >
            {children}
        </MenuStatusContext.Provider>
    )
}
