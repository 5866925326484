import {
    useMemo,
    type Dispatch,
    type MutableRefObject,
    type SetStateAction,
    useEffect,
    useRef
} from 'react'

import { Stack } from '@mui/material'

import { type UseBooleanType } from 'hooks'

import { VideoPlayer } from './VideoPlayer'
import { useMapTimelineToVideoTime } from '../useMapTimelineToVideoTime'
import { type TimelineChunksType } from '../types'
import { checkIsSnapshotChunk, checkIsVideoChunk } from '../splitAndTrimUtils'
import { SnapshotPlayer } from './SnapshotPlayer'
import { AudioPlayer } from './AudioPlayer'

type Props = {
    chunks: TimelineChunksType
    pointerTime: number
    videoUrl: string
    videoPlayerRef: MutableRefObject<HTMLVideoElement | null>
    playing: UseBooleanType
    playerTime: number
    setPlayerTime: Dispatch<SetStateAction<number>>
}

export const StepsPlayer = ({
    videoPlayerRef,
    videoUrl,
    chunks,
    pointerTime,
    playing,
    playerTime,
    setPlayerTime
}: Props) => {
    const videoPlayer = videoPlayerRef.current

    const audioPlayerRef = useRef<HTMLAudioElement | null>(null)
    const audioPlayer = audioPlayerRef.current

    const { getActiveChunkDetails } = useMapTimelineToVideoTime({
        pointerTime,
        chunks
    })

    const activeChunk = useMemo(() => {
        return getActiveChunkDetails({
            canReachChapterBounds: true,
            currentTime: playerTime
        })
    }, [getActiveChunkDetails, playerTime])

    const isActiveVideoChunk = Boolean(activeChunk && checkIsVideoChunk(activeChunk.originalChunk))
    const isVideoEnded = chunks.findLast(checkIsVideoChunk)!.end <= (videoPlayer?.currentTime || 0)

    useEffect(() => {
        if (!isActiveVideoChunk || !videoPlayer) return

        if (playing.isTrue) {
            videoPlayer.play()
            if (isVideoEnded || !audioPlayer?.ended) audioPlayer?.play()
        } else {
            videoPlayer.pause()
            audioPlayer?.pause()
        }
    }, [audioPlayer, isActiveVideoChunk, isVideoEnded, playing.isTrue, videoPlayer])

    return (
        <Stack
            height={330}
            sx={{ borderRadius: '8px', overflow: 'hidden', flexShrink: 0, position: 'relative' }}
        >
            {activeChunk && (
                <>
                    {checkIsSnapshotChunk(activeChunk.originalChunk) && (
                        <SnapshotPlayer
                            imageUrl={activeChunk.originalChunk.imageUrl}
                            setPlayerTime={setPlayerTime}
                            isPlaying={playing.isTrue}
                        />
                    )}
                    <VideoPlayer
                        videoPlayerRef={videoPlayerRef}
                        videoUrl={videoUrl}
                        setPlayerTime={setPlayerTime}
                        playing={playing}
                        chunks={chunks}
                        activeChunk={activeChunk}
                    />
                    <AudioPlayer
                        audioPlayerRef={audioPlayerRef}
                        videoElement={videoPlayerRef.current}
                    />
                </>
            )}
        </Stack>
    )
}
