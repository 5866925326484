import { Box, Stack } from '@mui/material'
import { Button, Icon } from '@guidde/design-system'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'

import { ReactComponent as RocketIcon } from './rocket.svg'

type Props = {
    onCreateSpace: () => void
}

export const CreateFirstSpaceMessage = ({ onCreateSpace }: Props) => (
    <Stack
        flex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        spacing={3}
    >
        <RocketAnimation />

        <Button
            variant="text"
            color="primary"
            startIcon={<Icon icon={faCirclePlus} />}
            size="medium"
            onClick={onCreateSpace}
        >
            Create your first Space
        </Button>
    </Stack>
)

// @TODO: rocket animation
const RocketAnimation = () => (
    <Box>
        <RocketIcon />
    </Box>
)
