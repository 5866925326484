import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type SpeakerType } from 'app/types'
import { useBrandKit, useOrganization, useServiceUsage, useServerUserPreferences } from 'hooks'
import { type OptionType, option, uniqueArray } from 'modules'

import { useLastSpeaker } from './use-last-speaker'

export const enUScode = 'en-US'
export const enGBcode = 'en-GB'

export type TParsedSpeakersForSelectionPanel = {
    recentlyUsedSpeakers: SpeakerType[]
    restSpeakers: SpeakerType[]
}

export const useSpeakerOptions = () => {
    const { hasStudioVoices } = useServiceUsage()
    const { serverUserPreferences, isServerUserPreferencesLoading } = useServerUserPreferences()
    const { brandKitVoiceover, brandKitLoading } = useBrandKit()

    const { speakerId: lastSpeakerId, setLastSpeaker: setLastSpeakerId } = useLastSpeaker()
    const { defaultLanguage } = useOrganization()

    const { audioConfigs } = useSelector(state => state.configs)

    const speakers = audioConfigs.speakers

    const languagesList: Array<OptionType> = useMemo(() => {
        if (!speakers) return []

        return uniqueArray([
            ...Object.entries(speakers)
                ?.map(([langCode, { langName }]) => option(langCode, langName))
                ?.sort((a, b) => (a.label > b.label ? 1 : -1))
        ])
    }, [speakers])

    /**
    @deprecated use getLastSelectedLanguage instead
    */
    const _getLastSelectedLanguageUsingLastSpeakerId = useCallback(
        (showOther?: boolean) => {
            if (!lastSpeakerId) return null
            if (showOther && lastSpeakerId === 'other') return option('other', 'Other')
            const speaker = Object.values(speakers)
                .flatMap(item => item.options)
                .find(speaker => speaker.id === lastSpeakerId)

            if (speaker) return option(speaker.langCode, speaker.langName)

            return null
        },
        [lastSpeakerId, speakers]
    )

    const getLastSelectedLanguage = useCallback(
        (showOther?: boolean) => {
            if (!serverUserPreferences) return null

            if (showOther) return option('other', 'Other')

            const lastLanguageCodeUsed = serverUserPreferences.speaker?.lastLanguageUsed

            if (!lastLanguageCodeUsed) return null

            const lastLangaugeLabelUsed = languagesList.find(
                option => option.value === lastLanguageCodeUsed
            )?.label

            if (!lastLangaugeLabelUsed) return null

            return option(lastLanguageCodeUsed, lastLangaugeLabelUsed)
        },
        [serverUserPreferences, languagesList]
    )

    const getSpeakersList = useCallback(
        (languageCode: string) => speakers?.[languageCode]?.options || [],
        [speakers]
    )

    /**
    @deprecated use getDefaultSpeaker instead
    */
    const _getDefaultSpeakerUsingLastSpeakerId = useCallback(
        (options: Array<SpeakerType>) => {
            if (!options?.length) return null
            const fallbackOption =
                options.find(option => option.isStudio === Boolean(hasStudioVoices)) || options[0]

            if (lastSpeakerId) {
                const initialOption = options.find(option => option.id === lastSpeakerId)
                if (!initialOption) return fallbackOption
                const isDisabled = initialOption.isStudio && !hasStudioVoices

                return isDisabled ? fallbackOption : initialOption
            }

            return fallbackOption
        },
        [hasStudioVoices, lastSpeakerId]
    )

    const getSpeakerLang = useCallback((speakers: SpeakerType[]) => {
        if (!speakers.length) return

        return speakers[0].langCode
    }, [])

    const getDefaultSpeakerId = useCallback(
        (langCode: string) => {
            if (!serverUserPreferences || !langCode) return

            return serverUserPreferences.speaker?.languages[langCode]?.defaultSpeakerId
        },
        [serverUserPreferences]
    )

    const getDefaultSpeaker = useCallback(
        (options: SpeakerType[]) => {
            const langCode = getSpeakerLang(options)

            if (!langCode) return

            const defaultSpeakerId = getDefaultSpeakerId(langCode)

            return options.find(option => option.id === defaultSpeakerId)
        },
        [getDefaultSpeakerId, getSpeakerLang]
    )

    const getBrandkitSpeakerId = useCallback(
        (langCode: string) => {
            if (!langCode) return
            return brandKitVoiceover?.find(s => s.langCode === langCode)?.speaker.id
        },
        [brandKitVoiceover]
    )

    const getBrandkitSpeaker = useCallback(
        (options: SpeakerType[]) => {
            const langCode = getSpeakerLang(options)

            if (!langCode) return

            const brandkitSpeakerId = getBrandkitSpeakerId(langCode)

            return options.find(option => option.id === brandkitSpeakerId)
        },
        [getBrandkitSpeakerId, getSpeakerLang]
    )

    const getRecentlyUsedSpeakerIds = useCallback(
        (langCode: string) => {
            if (!langCode) return []
            return serverUserPreferences.speaker?.languages[langCode]?.recentlyUsedSpeakerIds ?? []
        },
        [serverUserPreferences]
    )

    const getParsedSpeakers = useCallback(
        (speakers: SpeakerType[]): TParsedSpeakersForSelectionPanel | null => {
            const langCode = getSpeakerLang(speakers)
            if (!langCode) return null

            const recentlyUsedSpeakerIds = [
                getBrandkitSpeakerId(langCode),
                ...getRecentlyUsedSpeakerIds(langCode)
            ].filter(Boolean)

            const recentlyUsedSpeakers = Array.from(new Set(recentlyUsedSpeakerIds))
                .map(id => speakers.find(s => s.id === id))
                .filter(Boolean) as SpeakerType[]
            const restSpeakers = speakers.filter(s => !recentlyUsedSpeakerIds.includes(s.id))

            return {
                recentlyUsedSpeakers,
                restSpeakers
            }
        },
        [getRecentlyUsedSpeakerIds, getBrandkitSpeakerId, getSpeakerLang]
    )

    /**
     * Gets the initial speaker for the language.
     * The priority is determined as follows:
     * 1. Default speaker
     * 2. Brand kit speaker
     * 3. First non-studio speaker
     *
     * @returns {SpeakerType} The selected initial speaker based on priority. is the data is loading we return null
     */
    const getInitialSpeaker = useCallback(
        (langCode: string) => {
            const currentLangSpeakersList = getSpeakersList(langCode)
            const defaultSpeaker = getDefaultSpeaker(currentLangSpeakersList)
            const brandKitSpeaker = getBrandkitSpeaker(currentLangSpeakersList)

            if (defaultSpeaker) return defaultSpeaker
            if (brandKitSpeaker) return brandKitSpeaker

            if (hasStudioVoices) {
                const firstStudioSpeaker = currentLangSpeakersList.findIndex(
                    speaker => speaker.isStudio
                )

                if (firstStudioSpeaker >= 0) {
                    return currentLangSpeakersList[firstStudioSpeaker]
                }
            }

            const firstNonStudioSpeaker = currentLangSpeakersList.findIndex(
                speaker => !speaker.isStudio
            )

            if (firstNonStudioSpeaker < 0) {
                console.error('[getInitialSpeaker]: can not select default speaker', {
                    currentLangSpeakersList,
                    hasStudioVoices
                })
                return null
            }

            return currentLangSpeakersList[firstNonStudioSpeaker]
        },
        [getSpeakersList, getDefaultSpeaker, getBrandkitSpeaker, hasStudioVoices]
    )

    return useMemo(
        () => ({
            isLoading: isServerUserPreferencesLoading || brandKitLoading,
            languagesList,
            hasStudioVoices,
            defaultLanguage,
            speakers,
            speakerId: lastSpeakerId,
            setLastSpeaker: setLastSpeakerId,
            getLastSelectedLanguage,
            getSpeakersList,
            getDefaultSpeaker,
            getDefaultSpeakerId,
            getBrandkitSpeaker,
            getBrandkitSpeakerId,
            getParsedSpeakers,
            getInitialSpeaker,
            _getDefaultSpeakerUsingLastSpeakerId,
            _getLastSelectedLanguageUsingLastSpeakerId
        }),
        [
            languagesList,
            brandKitLoading,
            lastSpeakerId,
            hasStudioVoices,
            speakers,
            defaultLanguage,
            setLastSpeakerId,
            getLastSelectedLanguage,
            getSpeakersList,
            getDefaultSpeaker,
            getDefaultSpeakerId,
            getBrandkitSpeaker,
            getBrandkitSpeakerId,
            getParsedSpeakers,
            getInitialSpeaker,
            _getDefaultSpeakerUsingLastSpeakerId,
            _getLastSelectedLanguageUsingLastSpeakerId,
            isServerUserPreferencesLoading
        ]
    )
}
