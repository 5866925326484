import { useMemo } from 'react'

import { Box, Button, Divider, ListItemButton, ListItemIcon } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import { ReactComponent as InviteIcon } from 'assets/icons/invite.svg'

import { type UserType, useAuth, useBoolean, useOrgFeatureFlags, useQuery, useRoles } from 'hooks'
import { logToAnalytics } from 'modules'

import { InviteDialog, WorkspaceLogo } from 'UI/Components'

import { WorkspaceList } from './WorkspaceList'
import { CollapsedMenuTooltip } from '../common/CollapsedMenuTooltip'

export type WorkspaceType = {
    orgId: string
    logo: string
    name: string
    members: Array<UserType>
}

export type WorkspacesResponse = {
    workspaces: Array<WorkspaceType>
    activeOrgId: string
    canCreateWorkspace: boolean
}

type Props = {
    isMenuClosed: boolean
}

export const DesktopWorkspacePanel = ({ isMenuClosed }: Props) => {
    const inviteDialog = useBoolean()

    const { uid, orgId } = useAuth()
    const { isViewer } = useRoles()
    const { disableViewerWorkspaceCreation, disableViewerUserInvite, isLoading } =
        useOrgFeatureFlags()

    const $workspaces = useQuery<WorkspacesResponse>('/c/v1/workspaces', { method: 'GET' })

    const activeWorkspace = useMemo(() => {
        return $workspaces.data?.workspaces?.find(workspace => workspace.orgId === orgId)
    }, [$workspaces.data, orgId])

    if (!activeWorkspace) {
        return null
    }

    const onInviteClick = () => {
        inviteDialog.setTrue()
        logToAnalytics('teamDiscovery_inviteButton_clicked', {
            uid,
            source: 'primarySideBar',
            workspaceName: activeWorkspace.name,
            workspaceId: activeWorkspace.orgId
        })
    }

    const isHideCreateWorkspaceButton = isLoading || (disableViewerWorkspaceCreation && isViewer)
    const isHideInviteMemberButton = isLoading || (disableViewerUserInvite && isViewer)

    return (
        <Box width="100%">
            {isMenuClosed ? (
                <Box>
                    <Box height={55} width="100%" display="flex" alignItems="center" py="0" px={2}>
                        <Box mt={1}>
                            <WorkspaceLogo src={activeWorkspace.logo} size={36} />
                        </Box>
                    </Box>
                    <CollapsedMenuTooltip title="Invite new member">
                        <ListItemButton
                            onClick={onInviteClick}
                            sx={{
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                paddingTop: 0,
                                paddingBottom: 0
                            }}
                        >
                            <ListItemIcon
                                sx={theme => ({
                                    color: '#78909c',
                                    [theme.breakpoints.down('md')]: {
                                        color: '#828282'
                                    },
                                    minWidth: '25px'
                                })}
                            >
                                <InviteIcon />
                            </ListItemIcon>
                        </ListItemButton>
                    </CollapsedMenuTooltip>

                    <Box px={2}>
                        <Divider />
                    </Box>
                </Box>
            ) : (
                <Box
                    style={{ border: '1px solid #E0E0E0' }}
                    overflow="hidden"
                    borderRadius="8px"
                    width="100%"
                    height="100%"
                    data-test="workspaces-menu-open"
                >
                    <Box
                        px={1}
                        style={{
                            background: '#FFF',
                            borderRadius: '8px 8px 0 0'
                        }}
                        height={60}
                        display="flex"
                        alignItems="center"
                        width="100%"
                        overflow="hidden"
                        whiteSpace="nowrap"
                    >
                        <WorkspaceLogo src={activeWorkspace.logo} size={36} />
                        <Box ml={1} sx={{ overflowX: 'hidden' }}>
                            <WorkspaceList
                                data-test="teamDiscovery_workspaceListButton"
                                activeWorkspace={activeWorkspace}
                                workspaces={$workspaces.data?.workspaces || []}
                                canCreateWorkspace={
                                    !isHideCreateWorkspaceButton &&
                                    ($workspaces.data?.canCreateWorkspace || false)
                                }
                            />
                        </Box>
                    </Box>

                    {!isHideInviteMemberButton && (
                        <Button
                            data-test="teamDiscovery_inviteBtn"
                            variant="text"
                            sx={theme => ({
                                background: 'rgba(250, 250, 250, 1)',
                                color: '#78909c',
                                [theme.breakpoints.down('md')]: {
                                    color: '#828282'
                                },
                                justifyContent: 'flex-start',
                                fontWeight: 500,
                                fontSize: 14,
                                height: 32,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap'
                            })}
                            color="secondary"
                            onClick={onInviteClick}
                            fullWidth
                            startIcon={
                                <AddIcon color="inherit" style={{ width: 24, height: 24 }} />
                            }
                            size="small"
                        >
                            Invite new member
                        </Button>
                    )}
                </Box>
            )}

            {inviteDialog.isTrue && (
                <InviteDialog
                    onClose={inviteDialog.setFalse}
                    isOpen={true}
                    activeWorkspace={{
                        teamId: activeWorkspace.orgId,
                        teamName: activeWorkspace.name
                    }}
                />
            )}
        </Box>
    )
}
