import { useEffect } from 'react'

import MicIcon from '@mui/icons-material/Mic'
import { TextField, MenuItem, Box } from '@mui/material'

export const MicSetting = ({ chosenMic, micOptions, onMicChange }) => {
    useEffect(() => {
        // On mount pre-select any first device, if none is selected
        if (!chosenMic && micOptions.length) {
            onMicChange(micOptions[0].deviceId || '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [micOptions.length])

    return (
        <Box display="flex" alignItems="center">
            <Box mr={2}>
                <MicIcon />
            </Box>

            <TextField
                placeholder="No microphone found"
                select={Boolean(micOptions.length)}
                disabled={!micOptions.length}
                color="secondary"
                value={chosenMic}
                onChange={e => onMicChange(e.target.value)}
                label="Choose default microphone"
                InputLabelProps={{ shrink: true }}
                style={{ width: '100%' }}
            >
                {micOptions.length !== 0 &&
                    micOptions.map((mic, index) => (
                        <MenuItem key={mic.deviceId} value={mic.deviceId}>
                            {mic.label || `Microphone ${index + 1}`}
                        </MenuItem>
                    ))}
            </TextField>
        </Box>
    )
}
