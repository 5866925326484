import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Box, ClickAwayListener } from '@mui/material'

import { useLayers, DEFAULT_TEXT_LAYER } from 'UI/Routes/quick-guidde/CanvasEditor'
import {
    DEFAULT_STEP,
    useAddStepBackground
} from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel'
import { DndArea, ImageCropper } from 'UI/Components'

import { addNewStep, setImageStepArea } from 'ducks'
import { useNotification, useAuth, useServiceUsage, useBrandKit } from 'hooks'
import { logToAnalytics, request, uuid } from 'modules'

import { type BrowserBarShapeType, Shape } from 'app/types'

export const QG_BROWSER_BAR_HEIGHT = 78

export const ImageStepUploader = () => {
    const { showErrorNotification } = useNotification()

    const dispatch = useDispatch()

    const [previewURL, setPreviewURL] = useState('')

    const { playbookId } = useParams<{ playbookId: string }>()

    const { uid } = useAuth()

    const newStepBackground = useAddStepBackground()
    const { isFreePlan } = useServiceUsage()

    const {
        videoOutputSettings: { isOverlayEnabled, isCalloutsEnabled, isArrowEnabled }
    } = useBrandKit()

    const { stepNumbering, watermark, overlay, rectangle, arrow, text, backgroundRectangle } =
        useLayers()

    const {
        present: { activeStep, steps, imageStepArea }
    } = useSelector(state => state.qgEditor)

    const {
        kind,
        windowDimensions,
        windowDimensions: { innerWidth, innerHeight }
    } = steps[activeStep] || {}

    const needStepNumbering = steps.some(step =>
        step.layers?.some(layer => layer.type === Shape.CircleWithText)
    )

    const isComplexBg =
        newStepBackground &&
        (newStepBackground?.fillLinearGradientColorStops?.length !== 0 || newStepBackground.url)

    const scaleFactor = isComplexBg ? newStepBackground.scaleFactor || 0.8 : 1

    const extraX = isComplexBg ? (innerWidth * (1 - scaleFactor)) / 2 : 0
    const extraY = isComplexBg
        ? (innerHeight * (1 - scaleFactor)) / 2 + QG_BROWSER_BAR_HEIGHT / 2
        : 0

    const [browserLayer, setBrowserLayer] = useState<BrowserBarShapeType | null>(null)

    const hideDndArea = () => dispatch(setImageStepArea({ visible: false, placement: 'after' }))

    useEffect(() => {
        request('/c/v1/quickguidde/browserBar', 'POST', {
            width: innerWidth * scaleFactor
        }).then(defaultLayer => {
            setBrowserLayer({
                ...defaultLayer,
                x: (innerWidth * (1 - scaleFactor)) / 2,
                y: (innerHeight * (1 - scaleFactor)) / 2 - QG_BROWSER_BAR_HEIGHT / 2
            })
        })
    }, [innerHeight, innerWidth, scaleFactor])

    return (
        <Box position="absolute" top={0} bottom={0} right={0} left={0} zIndex={2}>
            <ClickAwayListener onClickAway={hideDndArea}>
                <Box width="100%" height="100%">
                    <DndArea
                        accept={['image/png', 'image/jpeg']}
                        multiple={false}
                        onUpload={([file]) => {
                            if (!file) return

                            setPreviewURL(URL.createObjectURL(file))
                        }}
                        onError={() => {
                            showErrorNotification('You can upload .jpg or .png images only')
                        }}
                    />
                    <ImageCropper
                        initialPreviewURL={previewURL}
                        labelComponent={null}
                        showReplacePhotoButton={true}
                        aspectRatio={innerWidth / innerHeight}
                        isTempStorage
                        storagePath={`imageUploads/${uid}/${playbookId}_${uuid()}`}
                        onDone={({ height, width, url }) => {
                            dispatch(
                                addNewStep({
                                    kind: 'step',
                                    position: ['end', 'outro'].includes(kind)
                                        ? 'before'
                                        : imageStepArea.placement,
                                    step: {
                                        ...DEFAULT_STEP,
                                        id: uuid(),
                                        timeStamp: Date.now(),
                                        windowDimensions,
                                        layers: [
                                            ...(isComplexBg ? [backgroundRectangle] : []),
                                            {
                                                type: Shape.Image,
                                                x: extraX,
                                                y: extraY,
                                                scaleX: (innerWidth * scaleFactor) / width, // to make sure it's 100%
                                                scaleY: (innerHeight * scaleFactor) / height, // to make sure it's 100%
                                                width,
                                                height,
                                                isBackground: true,
                                                id: uuid(),
                                                url
                                            },
                                            ...(isComplexBg && browserLayer ? [browserLayer] : []),
                                            ...(isFreePlan ? [watermark] : []),
                                            ...(isOverlayEnabled
                                                ? [
                                                      {
                                                          ...overlay,
                                                          width,
                                                          height,
                                                          scaleX:
                                                              (innerWidth * scaleFactor) / width, // to make sure it's 100%
                                                          scaleY:
                                                              (innerHeight * scaleFactor) / height, // to make sure it's 100%
                                                          x: extraX,
                                                          y: extraY
                                                      }
                                                  ]
                                                : []),
                                            ...(isCalloutsEnabled
                                                ? [
                                                      {
                                                          ...text,
                                                          isTitle: true,
                                                          title: DEFAULT_TEXT_LAYER.title,
                                                          x:
                                                              (needStepNumbering
                                                                  ? stepNumbering.circle.radius *
                                                                        2 +
                                                                    stepNumbering.x * 2
                                                                  : DEFAULT_TEXT_LAYER.x) + extraX,
                                                          y: DEFAULT_TEXT_LAYER.y + extraY
                                                      }
                                                  ]
                                                : []),
                                            {
                                                ...rectangle,
                                                x: extraX + 30,
                                                y: extraY + 300
                                            },
                                            ...(isArrowEnabled
                                                ? [
                                                      {
                                                          ...arrow,
                                                          x: extraX + rectangle.width + 60,
                                                          y: extraY + 250
                                                      }
                                                  ]
                                                : []),
                                            // if any step has step numbering - new step should have it as well
                                            ...(needStepNumbering
                                                ? [
                                                      {
                                                          ...stepNumbering,
                                                          // We need to scale step numbering in case of Image step with complex background
                                                          scaleX: scaleFactor,
                                                          scaleY: scaleFactor
                                                      }
                                                  ]
                                                : [])
                                        ]
                                    }
                                })
                            )
                            hideDndArea()

                            logToAnalytics('qg-add-chapter', {
                                activeChapter:
                                    activeStep + imageStepArea.placement === 'after' ? 1 : 0,
                                type: 'a blank slide'
                            })
                        }}
                    />
                </Box>
            </ClickAwayListener>
        </Box>
    )
}
