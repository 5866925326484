import { memo, useRef } from 'react'
import { ThemeProvider, type ButtonProps, capitalize, Box } from '@mui/material'

import { Button, spacings } from '@guidde/design-system'

import { ShareOnlyDialog, SharePublishDialog } from 'UI/Components'

import { useBoolean, useRoles } from 'hooks'
import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

import { type AnyPlaybookType, PlaybookMode } from 'app/types'
import { newTheme } from 'app/theme'

type Props = {
    playbook: AnyPlaybookType
    canShare: boolean
    isEditMode: boolean
    revalidatePlaybook?: () => void
} & ButtonProps

export const Share = memo(
    ({ playbook, isEditMode, canShare, revalidatePlaybook, ...buttonProps }: Props) => {
        const { isPublished, mode } = playbook

        const shareDropdownRef = useRef(null)

        const { isMember, isViewer } = useRoles()

        const showShareDialog = useBoolean()

        const isPlaylist = mode === PlaybookMode.Playlist
        const target = isPlaylist ? 'playlist' : 'video'

        const showFullShareDialog = canShare || isMember || isViewer

        return (
            <>
                <Box
                    position="relative"
                    sx={theme => ({
                        minWidth: theme.spacing(spacings['10xl'])
                    })}
                >
                    <Button
                        variant="outlined"
                        ref={shareDropdownRef}
                        fullWidth
                        size="small"
                        onClick={() => {
                            showShareDialog.setTrue()

                            logToAnalytics(`shareBtn_clicked`, {
                                source: isEditMode ? 'editor' : 'view',
                                playbookId: playbook.id,
                                ...playbookToAnalyticsProps(playbook)
                            })
                        }}
                        title={`Share ${target}`}
                        data-test="videoPage-share-button"
                        {...buttonProps}
                    >
                        Share
                    </Button>
                </Box>
                <ThemeProvider theme={newTheme}>
                    {showFullShareDialog ? (
                        <>
                            {showShareDialog.isTrue && (
                                <SharePublishDialog
                                    isOpen={true}
                                    onClose={showShareDialog.toggle}
                                    onDone={() => revalidatePlaybook?.()}
                                    playbook={playbook}
                                    title={`Share your ${target}`}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {showShareDialog.isTrue && (
                                <ShareOnlyDialog
                                    isOpen={true}
                                    onDone={() => {}}
                                    onClose={showShareDialog.setFalse}
                                    playbook={playbook}
                                    title={`Share your ${target}`}
                                    subtitle={`Invite people to view your ${capitalize(target)}`}
                                    warning={
                                        isPublished
                                            ? ''
                                            : 'Anyone in your team with this link can view'
                                    }
                                />
                            )}
                        </>
                    )}
                </ThemeProvider>
            </>
        )
    }
)
