import { useContext, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'

import Konva from 'konva'
import { Image } from 'react-konva'

import { SmartPreviewContext } from 'UI/Routes/quick-guidde/CanvasEditor/SmartPreview'

import { type VideoShapeType } from 'app/types'
import { round } from 'modules'
import { useBoolean } from 'hooks'

type Props = {
    layer: VideoShapeType
    isSmartPreview: boolean
}

export const VideoShape = ({ layer, isSmartPreview }: Props) => {
    const imageRef = useRef<Konva.Image>(null)

    const {
        present: { steps }
    } = useSelector(state => state.qgEditor)

    const loaded = useBoolean()
    const setLoaded = loaded.set

    const { sourceVideoUrl, width, height, x, y, scaleX, scaleY, start, end } = layer

    const animateInstance = useRef<Konva.Animation | null>(null)

    const { status, activeIndex, time, stepsTimeline } = useContext(SmartPreviewContext)

    const { stepStart, transitionDuration } = stepsTimeline.steps[activeIndex]
    const videoStartTime = stepStart + transitionDuration

    const index = useMemo(() => {
        let isActive = false
        let isPrevious = false
        let isNext = false

        steps.forEach((step, index) => {
            if (step.layers.find(l => l.id === layer.id)) {
                if (index === activeIndex) isActive = true
                else if (index === activeIndex - 1) isPrevious = true
                else if (index === activeIndex + 1) isNext = true
            }
        })

        return { isActive, isPrevious, isNext }
    }, [steps, activeIndex, layer.id])

    const videoTimeOnLoad = useMemo(() => {
        if (index.isPrevious) return end
        else if (index.isNext) return start
        else {
            const timePassed = round(time - stepsTimeline.steps[activeIndex].stepStart)
            return start + timePassed
        }
        // In order to prevent unnecessary re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index.isPrevious, index.isNext, end, start, stepsTimeline.steps, activeIndex])

    const videoElement = useMemo(() => {
        const element = document.createElement('video')
        element.preload = 'metadata'
        element.crossOrigin = 'anonymous'
        element.muted = true
        element.src = sourceVideoUrl

        return element
    }, [sourceVideoUrl])

    // Add event listener to video element to set loaded state and initial time
    // Important note: it should be isolated from videoElement in order to avoid 'flickering' issue on changing step
    useEffect(() => {
        if (!videoElement) return

        videoElement.onloadedmetadata = () => {
            setLoaded(true)
            // set initial time when active step changes (on first render)
            videoElement.currentTime = videoTimeOnLoad

            // Set Animation instance to the layer which will render correct frames based on video time
            animateInstance.current = new Konva.Animation(() => {}, imageRef.current?.getLayer())
            animateInstance.current.start()
        }

        videoElement.ontimeupdate = () => {
            if (videoElement.currentTime >= end) videoElement.pause()
        }
    }, [end, setLoaded, videoElement, videoTimeOnLoad])

    // Logic to play/pause video and animation on SmartPreview status change
    useEffect(() => {
        if (loaded.isFalse) return

        switch (status) {
            case 'running':
                if (!index.isActive || !isSmartPreview) return
                // Video is not started yet or already ended
                const videoDuration = end - start
                if (time < videoStartTime || time >= videoStartTime + videoDuration) return

                if (videoElement.paused) {
                    videoElement.play()
                    animateInstance.current?.start()
                }
                break
            case 'stopped':
                if (!videoElement.paused) {
                    videoElement.pause()
                    animateInstance.current?.stop()
                }

                // Update video time (both editor and smart preview) if smart preview is stopped and its time changes
                const expectedTime = round(time - videoStartTime + start)
                const offset = expectedTime - videoElement.currentTime

                if (Math.abs(offset) > 0.2) videoElement.currentTime = expectedTime
                break
        }
    }, [
        end,
        index.isActive,
        isSmartPreview,
        loaded.isFalse,
        start,
        status,
        time,
        videoElement,
        videoStartTime
    ])

    // On page leave we need to stop video, remove it from DOM and stop animation
    useEffect(() => {
        return () => {
            if (!videoElement) return
            videoElement.pause()
            videoElement.remove()

            if (!animateInstance.current) return
            animateInstance.current.stop()
            animateInstance.current = null
        }
    }, [videoElement])

    return (
        <Image
            ref={imageRef}
            name="video"
            image={videoElement}
            x={x}
            y={y}
            width={width}
            height={height}
            scaleX={scaleX}
            scaleY={scaleY}
        />
    )
}
