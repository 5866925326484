import { memo, useMemo } from 'react'

import { type OptionType, option } from 'modules'

import { NewMultipleSelect, OldMultipleSelect } from './filter-components'

type ValuesType = Array<string>

export const allIfEmpty = (values: ValuesType) => (values.length === 0 ? ['ALL'] : values)

export const handleChange =
    (allValues: ValuesType) => (values: ValuesType, prevValues: ValuesType) => {
        if (values.length === 0 && prevValues[0] === 'ALL') {
            return allValues
        }

        if (values.length === 0 || values[values.length - 1] === 'ALL') {
            return []
        }

        return values.filter(value => value !== 'ALL')
    }

type MultipleProps = {
    value: ValuesType
    onChange: (values: ValuesType) => void
    options: Array<OptionType>
    labelIcon?: null | JSX.Element
    multipleItemsLabel?: string
    width?: string | number
    minWidth?: number
    showNewDSFilters?: boolean
}

export const FilterSelectMultiple = memo(
    ({ showNewDSFilters, multipleItemsLabel = '', ...props }: MultipleProps) => {
        const getNewValue = useMemo(
            () => handleChange(props.options.map(option => option.value)),
            [props.options]
        )

        const FilterComponents = useMemo(
            () => (showNewDSFilters ? NewMultipleSelect : OldMultipleSelect),
            [showNewDSFilters]
        )

        return (
            <FilterComponents
                value={allIfEmpty(props.value)}
                width={props.width || 'auto'}
                minWidth={props.minWidth || 0}
                onChange={(values, prevValues) => {
                    // TEMPERARY SOLUTION will remove once we will migrate all filters to new DS
                    const isSelectedAll =
                        props.options.length > 1 && values.length === props.options.length
                    const isNewDSSelectedAll = showNewDSFilters && values.includes('')

                    const newValues = isNewDSSelectedAll || isSelectedAll ? ['ALL'] : values

                    props.onChange(getNewValue(newValues, prevValues))
                }}
                multipleItemsLabel={multipleItemsLabel}
                defaultLabel={`All ${multipleItemsLabel}`}
                labelIcon={props.labelIcon}
                options={props.options.filter(o => o.value)}
                defaultOption={option('ALL', `All ${multipleItemsLabel}`)}
            />
        )
    }
)
