import React, { useState } from 'react'

import {
    LinearProgress,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControlLabel,
    Checkbox,
    Typography as MuiTypography,
    Box,
    Stack,
    Divider
} from '@mui/material'
import {
    ThemeProvider,
    IconButton,
    Icon,
    Typography,
    Button,
    Textarea,
    roundings
} from '@guidde/design-system'
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark'

import { useDataMutation, useAuth } from 'hooks'
import { logToAnalytics } from 'modules'

const otherReason = 'Other'

const reasonsList = [
    'Cost - Too Expensive',
    "We haven't used it enough",
    'Technical Issues',
    'Better Alternatives',
    'Customer Support',
    'Changing Needs',
    otherReason
]

type Payload = {
    reasons: Array<string>
}

type UnsubscribingDialogProps = {
    isOpen: boolean
    isLoading: boolean
    onClose: () => void
    onConfirm: () => Promise<void>
}

export const UnsubscribeDialog = ({
    isOpen,
    isLoading,
    onClose,
    onConfirm
}: UnsubscribingDialogProps) => {
    const { email } = useAuth()

    const [selectedReasons, setSelectedReasons] = useState<Array<string>>([])
    const [customReason, setCustomReason] = useState('')

    const $saveUnsubscribeReasons = useDataMutation<Payload, { message: string }, Error>(
        '/c/v1/save-downgrade-to-free-plan-reasons',
        'POST',
        {
            onFailure: (e, input) => {
                console.error('[saveUnsubscribeReasons]:', e, input)
            },
            onSuccess: onClose
        }
    )

    const handleClose = () => {
        if ($saveUnsubscribeReasons.isLoading) return

        logToAnalytics('unsubscribe_backBtn_clicked', {
            source: 'cancelSubscription',
            email
        })
        onClose()
    }

    const handleRadioChange = (reason: string) => {
        setSelectedReasons(prev => {
            const isChecked = prev.includes(reason)
            const isOtherReason = reason === otherReason

            if (isChecked) {
                if (isOtherReason) setCustomReason('')
                return prev.filter(item => item !== reason)
            }
            return [...prev, reason]
        })
    }

    const handleConfirm = async () => {
        const otherReasonIndex = selectedReasons.indexOf(otherReason)
        const reasons =
            otherReasonIndex > -1
                ? selectedReasons.with(otherReasonIndex, customReason)
                : selectedReasons

        logToAnalytics('unsubscribe_cancelSubscriptionBtn_clicked', { reasons })

        await onConfirm()

        $saveUnsubscribeReasons.mutate({
            reasons
        })
    }

    const isOtherReasonSelected = selectedReasons.includes(otherReason)
    const isOtherReasonEmpty = customReason.trim().length < 1
    const isOtherReasonInvalid = isOtherReasonSelected && isOtherReasonEmpty

    const isSelectedReasonsEmpty = selectedReasons.length < 1

    const isCancelingLoading = isLoading || $saveUnsubscribeReasons.isLoading
    const disableCancel = isCancelingLoading || isSelectedReasonsEmpty || isOtherReasonInvalid

    return (
        <ThemeProvider>
            <Dialog
                PaperProps={{
                    sx: { borderRadius: roundings.xl, maxWidth: 624 }
                }}
                open={isOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
            >
                <Box height={4}>{isCancelingLoading && <LinearProgress />}</Box>

                <Box sx={{ position: 'absolute', right: 10, top: 10 }}>
                    <IconButton
                        size="small"
                        variant="text"
                        color="secondary"
                        label="Close"
                        disabled={$saveUnsubscribeReasons.isLoading}
                        onClick={handleClose}
                    >
                        <Icon icon={faXmark} />
                    </IconButton>
                </Box>
                <DialogTitle pb={2.5} mt={3} align="center">
                    <Typography variant="heading" size="xs" color="grey.950">
                        Sorry to see you go! 🙁
                    </Typography>
                    <Box mt="10px" />
                    <Typography variant="text" color="grey.600">
                        Your feedback will help us improve the Guidde experience
                    </Typography>
                    <Typography variant="text" color="grey.600">
                        - it will take less than one minute.
                    </Typography>
                </DialogTitle>
                <Box m={4} my={0}>
                    <Typography variant="text" size="lg" color="grey.950" fontWeight="semibold">
                        Why are you cancelling your subscription?
                    </Typography>
                    <Stack my={1}>
                        {reasonsList.map(reason => (
                            <FormControlLabel
                                key={reason}
                                value={reason}
                                control={<Checkbox checked={selectedReasons.includes(reason)} />}
                                label={
                                    <MuiTypography
                                        variant="body1"
                                        sx={theme => ({
                                            color: theme.palette.grey[700],
                                            fontWeight: theme.typography.fontWeightMedium
                                        })}
                                    >
                                        {reason}
                                    </MuiTypography>
                                }
                                onClick={(e: any) => {
                                    const value = e.target.defaultValue
                                    if (value) handleRadioChange(value)
                                }}
                            />
                        ))}
                    </Stack>

                    {isOtherReasonSelected ? (
                        <Box width={300}>
                            <Textarea
                                label="Other reason"
                                value={customReason}
                                rows={2}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                                    setCustomReason(e.target.value)
                                }
                            />
                        </Box>
                    ) : (
                        <Box height={88} />
                    )}
                </Box>
                <Box mt={3} mx={4}>
                    <Divider />
                </Box>
                <DialogActions sx={{ justifyContent: 'center', p: 2.5 }}>
                    <Stack direction="row" spacing={7}>
                        <Box width={155}>
                            <Button
                                size="small"
                                onClick={handleClose}
                                color="secondary"
                                variant="outlined"
                                fullWidth
                            >
                                Back
                            </Button>
                        </Box>
                        <Box width={170}>
                            <Button
                                size="small"
                                color="error"
                                onClick={handleConfirm}
                                disabled={disableCancel}
                                variant="contained"
                                fullWidth
                                data-test="confirmation-dialog-submit-button"
                            >
                                Cancel Subscription
                            </Button>
                        </Box>
                    </Stack>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
