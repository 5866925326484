import { useState } from 'react'

import { Box, Tooltip, Typography } from '@mui/material'

import ArrowIcon from '@mui/icons-material/ArrowDropDown'

import { useOrgFeatureFlags, useRoles, useServiceUsage } from 'hooks'

import { WorkspaceMembersPopover } from './WorkspaceMembersPopover'
import { WorkspaceSwitcher } from './WorkspaceSwitcher'
import { type WorkspaceType } from './DesktopWorkspacePanel'

type Props = {
    workspaces: Array<WorkspaceType>
    activeWorkspace: WorkspaceType
    canCreateWorkspace: boolean
}
export const WorkspaceList = ({ workspaces, activeWorkspace, canCreateWorkspace }: Props) => {
    const [membersAnchorEl, setMembersAnchorEl] = useState<HTMLElement | null>(null)
    const [workspacesAnchorEl, setWorkspacesAnchorEl] = useState<HTMLElement | null>(null)

    const { userSeatsQuota, usedUsersSeats } = useServiceUsage()
    const { hideMemberInfoForViewers, isLoading } = useOrgFeatureFlags()
    const { isViewer } = useRoles()

    const membersCount = activeWorkspace.members.length

    const isHideMembers = isLoading || (hideMemberInfoForViewers && isViewer)

    return (
        <>
            <Box style={{ cursor: 'pointer' }} width="100%">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    color="#090C10"
                    onClick={e => setWorkspacesAnchorEl(e.currentTarget)}
                >
                    <Tooltip
                        title={activeWorkspace.name}
                        placement="top"
                        enterDelay={500}
                        disableInteractive
                    >
                        <Typography
                            data-test="teamDiscovery_activeWorkspaceName"
                            style={{
                                color: 'inherit',
                                fontSize: 14,
                                fontWeight: 700,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            {activeWorkspace.name}
                        </Typography>
                    </Tooltip>
                    <ArrowIcon
                        data-test="teamDiscovery_workspaceListButton"
                        style={{
                            transition: 'all 0.2s linear',
                            transform: `rotate(${Boolean(workspacesAnchorEl) ? 180 : 0}deg)`,
                            color: 'inherit'
                        }}
                    />
                </Box>
                {!isHideMembers && (
                    <Typography
                        data-test="teamDiscovery_membersNum"
                        variant="caption"
                        display="block"
                        style={{
                            cursor: 'pointer',
                            color: 'rgba(9, 12, 16, 0.60)',
                            textDecoration: 'underline'
                        }}
                        onClick={e => setMembersAnchorEl(e.currentTarget)}
                    >
                        {membersCount} member{membersCount > 1 ? 's' : ''}
                    </Typography>
                )}
            </Box>

            <WorkspaceMembersPopover
                members={activeWorkspace.members}
                anchorEl={membersAnchorEl}
                isUsersExceeded={Boolean(userSeatsQuota && usedUsersSeats > userSeatsQuota)}
                onClose={() => setMembersAnchorEl(null)}
            />
            <WorkspaceSwitcher
                workspaces={workspaces}
                activeWorkspace={activeWorkspace}
                onClose={() => setWorkspacesAnchorEl(null)}
                anchorEl={workspacesAnchorEl}
                canCreateWorkspace={canCreateWorkspace}
            />
        </>
    )
}
