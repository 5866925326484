import { useMemo, type CSSProperties } from 'react'
import { generatePath } from 'react-router-dom'

import { type QuickGuiddeType } from 'app/types'
import { paths } from 'app/paths'

import { host } from 'modules'

type Props = {
    playbook: QuickGuiddeType
    enableNgnixLinks?: boolean
    width?: CSSProperties['width']
}

export const GifImageWithPlayButton = ({ playbook, enableNgnixLinks, width = '100%' }: Props) => {
    const { steps = [] } = playbook

    const pbLink = generatePath(paths.publicPlaybook, {
        playbookId: playbook.id
    })

    const screenshotUrl = useMemo(() => {
        const coverImgField = enableNgnixLinks ? 'docCoverPublicScreenshot' : 'docCoverScreenshot'

        const coverStep = steps.find(step => step.kind === 'cover')

        const coverImg = coverStep?.[coverImgField]

        if (!coverImg) return playbook.gifUrl

        // TEMPORARY: Replace the storage URL with the static URL on the BE side
        const temporaryImg = coverImg
            .replace('storage.stg.guidde.com', 'static.stg.guidde.com')
            .replace('storage.app.guidde.com', 'static.guidde.com')
            .replace('b/guidde-dev-staging.appspot.com/o/', '')
            .replace('b/guidde-production.appspot.com/o/', '')
            .replace('quickguiddeScreenshots', 'qg')

        return temporaryImg
    }, [enableNgnixLinks, playbook.gifUrl, steps])

    if (!screenshotUrl) return null

    return (
        <div style={{ maxWidth: '100%' }}>
            <a href={host + pbLink} target="_blank" rel="noreferrer" style={{ maxWidth: '100%' }}>
                Click here to watch
            </a>

            <h3 style={{ maxWidth: '100%' }}>
                <img src={screenshotUrl} alt="Quick guidde" width={width} />
            </h3>
        </div>
    )
}
