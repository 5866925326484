import { type ReactNode } from 'react'
import { Box } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'

import {
    ThemeProvider,
    spacings,
    Typography,
    Breadcrumbs,
    type TBreadcrumb
} from '@guidde/design-system'

type Props = {
    title: string
    subtitle?: string
    actionsSlot?: ReactNode
    topRowStartSlot?: ReactNode
    topRowEndSlot?: ReactNode
}

export const PageHeader = ({
    title,
    subtitle,
    actionsSlot,
    topRowStartSlot,
    topRowEndSlot
}: Props) => (
    <ThemeProvider>
        <Box
            p={spacings['4xl']}
            borderBottom={theme => `1px solid ${theme.palette.grey[200]}`}
            bgcolor={theme => theme.palette.grey[25]}
        >
            {(topRowStartSlot || topRowEndSlot) && (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={spacings['2xl']}
                    mb={spacings['2xl']}
                >
                    {topRowStartSlot && <Box>{topRowStartSlot}</Box>}
                    {topRowEndSlot && <Box ml="auto">{topRowEndSlot}</Box>}
                </Box>
            )}
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={spacings.xl}
            >
                <Box display="flex" flexDirection="column" gap={spacings.xs}>
                    <Typography variant="heading" size="sm" fontWeight="semibold" color="grey.900">
                        {title}
                    </Typography>

                    {subtitle && (
                        <Box sx={{ wordBreak: 'break-all' }}>
                            <Typography variant="text" size="sm" color="grey.600">
                                {subtitle}
                            </Typography>
                        </Box>
                    )}
                </Box>
                {actionsSlot && (
                    <Box display="flex" alignItems="center">
                        {actionsSlot}
                    </Box>
                )}
            </Box>
        </Box>
    </ThemeProvider>
)

PageHeader.Breadcrumbs = ({ breadcrumbs }: { breadcrumbs: TBreadcrumb<LinkProps>[] }) => {
    return <Breadcrumbs LinkComponent={Link} variant="button" breadcrumbs={breadcrumbs} />
}
