import { useContext, useState } from 'react'

import { Stack, Typography } from '@mui/material'

import { ReactComponent as VideoIcon } from 'assets/icons/video.svg'

import { formatStepDuration } from 'UI/Routes/quick-guidde/CanvasEditor/StepsPreview/Step'

import { TimelineChunkThumbnail } from './TimelineChunkThumbnail'
import { TimelineChunkTrim } from './TimelineChunkTrim'

import { thumbnailFrameHeight, thumbnailFrameWidth } from './useVideoThumbnailCanvas'

import { type TimelineChunksType, type TimelineVideoChunkType, type TrimmedChunk } from '../types'
import { checkIsSnapshotChunk, checkIsVideoChunk, getChunkDuration } from '../splitAndTrimUtils'
import { ManualSplitProvider } from '../ManualSplitProvider'

const labelStyles = {
    position: 'absolute',
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '2px',
    height: 14
}

export const minChunkDuration = 1

type TrimSide = 'left' | 'right'

type Props = {
    endPoint: number
    videoThumbnailCanvas: HTMLCanvasElement | null
    videoThumbnailCanvasWidth: number
    duration: number
    timelineContainerElement: HTMLDivElement | null
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    chunkIndex: number
    widthDelta: number
    onTimeChange: (value: number) => void
}

export const TimelineChunk = ({
    endPoint,
    videoThumbnailCanvas,
    videoThumbnailCanvasWidth,
    duration,
    timelineContainerElement,
    chunks,
    chunkIndex,
    setChunks,
    widthDelta,
    onTimeChange
}: Props) => {
    const { tempChunks, setTempChunks } = useContext(ManualSplitProvider)

    const chunk = chunks[chunkIndex]
    const trimmedChunk = tempChunks?.[chunkIndex] || {}

    const isVideoChunk = checkIsVideoChunk(chunk)
    const isSnapshotChunk = checkIsSnapshotChunk(chunk)

    const setTrimmedChunk = (value: Exclude<TrimmedChunk, null>) => {
        const { type, id } = trimmedChunk as TimelineVideoChunkType

        const updatedChunks: TimelineChunksType = [
            ...tempChunks.slice(0, chunkIndex),
            {
                id: id,
                type: type,
                start: value.start,
                end: value.end
            },
            ...tempChunks.slice(chunkIndex + 1, tempChunks.length)
        ]

        setTempChunks(updatedChunks)
    }

    const [trimSide, setTrimSide] = useState<TrimSide | null>(null)
    const isTrimming = Boolean(trimSide)

    const chunkDurationBeforeTrim = getChunkDuration(chunk)
    const chunkDurationAfterTrim = trimmedChunk ? getChunkDuration(trimmedChunk) : 0
    const chunkWidth = (Math.max(chunkDurationAfterTrim, chunkDurationBeforeTrim) / endPoint) * 100

    return (
        <Stack
            sx={theme => ({
                width: `calc(${chunkWidth}% + ${widthDelta}px)`,
                minWidth: '1px',
                position: 'relative',
                height: thumbnailFrameHeight,
                overflow: 'hidden',
                borderRadius: '6px',
                outline: isTrimming ? `2px solid ${theme.palette.primary[400]}` : undefined,
                ...(isSnapshotChunk && {
                    background: `url(${chunk.imageUrl})`,
                    backgroundSize: `${thumbnailFrameWidth}px 100%`,
                    backgroundRepeat: 'repeat-x'
                })
            })}
        >
            {isVideoChunk && (
                <>
                    <TimelineChunkThumbnail
                        videoThumbnailCanvas={videoThumbnailCanvas}
                        videoThumbnailCanvasWidth={videoThumbnailCanvasWidth}
                        chunk={
                            chunkDurationAfterTrim > chunkDurationBeforeTrim
                                ? {
                                      ...chunk,
                                      ...(checkIsVideoChunk(trimmedChunk)
                                          ? { start: trimmedChunk.start, end: trimmedChunk.end }
                                          : {})
                                  }
                                : chunk
                        }
                        duration={duration}
                    />
                    <TimelineChunkTrim
                        endPoint={endPoint}
                        setChunks={setChunks}
                        setTrimSide={setTrimSide}
                        setTrimmedChunk={setTrimmedChunk}
                        chunkIndex={chunkIndex}
                        chunks={chunks}
                        duration={duration}
                        timelineContainerElement={timelineContainerElement}
                        trimSide={trimSide}
                        trimmedChunk={trimmedChunk as Exclude<TrimmedChunk, null>}
                        onTimeChange={onTimeChange}
                    />
                </>
            )}
            {!isTrimming && (
                <>
                    {isVideoChunk && (
                        <Stack sx={{ ...labelStyles, top: 4, left: 4, width: 18 }}>
                            <VideoIcon />
                        </Stack>
                    )}
                    <Stack
                        sx={{
                            ...labelStyles,
                            top: 4,
                            right: 4,
                            px: 0.5,
                            justifyContent: 'center'
                        }}
                    >
                        <Typography sx={{ fontWeight: 500, fontSize: 10, color: '#FFF' }}>
                            {formatStepDuration(Math.floor(chunkDurationBeforeTrim))}
                        </Typography>
                    </Stack>
                </>
            )}
        </Stack>
    )
}
