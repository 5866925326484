import { createContext, type Dispatch, type ReactNode, type SetStateAction, useState } from 'react'
import { type TimelineChunksType } from './types'

export const ManualSplitProvider = createContext({
    tempChunks: [] as TimelineChunksType,
    setTempChunks: ((_newPresent: TimelineChunksType) => {}) as Dispatch<
        SetStateAction<TimelineChunksType>
    >
})

type Props = {
    children: ReactNode
    initialChunks: TimelineChunksType
}

export const ChunksProvider = ({ children, initialChunks }: Props) => {
    const [tempChunks, setTempChunks] = useState<TimelineChunksType>(initialChunks)

    return (
        <ManualSplitProvider.Provider value={{ tempChunks, setTempChunks }}>
            {children}
        </ManualSplitProvider.Provider>
    )
}
