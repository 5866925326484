import { useMemo } from 'react'
import { Stack } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'

import { Typography } from '@guidde/design-system'

import { formatTime } from 'modules'

export const minInitialTimelineWidth = 684
export const labelWidth = 36
export const minDistanceBetweenLabels = 31
export const maxZoomDistanceBetweenLabels = 100

export const getRoundedValue = (timestamp: number, roundPoint: number) => {
    const remainderToRoundValue = timestamp % roundPoint

    if (!remainderToRoundValue) return timestamp

    const leftToRoundValue = roundPoint - remainderToRoundValue

    return timestamp + leftToRoundValue
}

type TimelinePattern = { duration: number; delta: number }
const biggestTimelinePattern: TimelinePattern = { duration: 60 * 15, delta: 60 * 10 }
const timelinePatterns: Array<TimelinePattern> = [
    { duration: 10, delta: 1 },
    { duration: 60, delta: 5 },
    { duration: 60 * 5, delta: 30 },
    { duration: 60 * 15, delta: 60 }
]

export const getTimestampDelta = (duration: number) => {
    const currentTimelinePattern =
        timelinePatterns.find(timelinePattern => duration <= timelinePattern.duration) ||
        biggestTimelinePattern

    return currentTimelinePattern.delta
}

export const getEndPoint = ({ duration, delta }: { duration: number; delta: number }) => {
    return getRoundedValue(duration, delta)
}

export const getLabelsAmount = ({ endPoint, delta }: { endPoint: number; delta: number }) => {
    return endPoint / delta + 1
}

export const getTimelineWidth = ({
    distanceBetweenLabels,
    labelsAmount
}: {
    distanceBetweenLabels: number
    labelsAmount: number
}) => {
    const oneLabelWidth = labelWidth + distanceBetweenLabels

    return (labelsAmount - 1) * oneLabelWidth + labelWidth
}

const getCurrentTimelinePattern = ({
    timelinePatterns,
    timelineWidth,
    endPoint,
    possibleTimelinePatternIndex
}: {
    timelinePatterns: Array<TimelinePattern>
    timelineWidth: number
    endPoint: number
    possibleTimelinePatternIndex: number
}): TimelinePattern => {
    const currentTimelinePattern = timelinePatterns[possibleTimelinePatternIndex]
    const labelsAmount = getLabelsAmount({ endPoint, delta: currentTimelinePattern.delta }) - 1

    const timestampWidth = timelineWidth / labelsAmount

    if (timestampWidth > minInitialTimelineWidth)
        return getCurrentTimelinePattern({
            timelinePatterns,
            timelineWidth,
            endPoint,
            possibleTimelinePatternIndex: possibleTimelinePatternIndex + 1
        })

    const resultingWidth = getTimelineWidth({
        distanceBetweenLabels: minDistanceBetweenLabels,
        labelsAmount
    })
    const previousTimelinePattern = timelinePatterns[possibleTimelinePatternIndex - 1]

    if (resultingWidth > timelineWidth) return previousTimelinePattern || currentTimelinePattern

    return currentTimelinePattern
}

const generateTimelineLabels = ({
    zoomPercentage,
    timelineWidth,
    possibleTimelinePatterns,
    endPoint
}: {
    zoomPercentage: number
    timelineWidth: number
    possibleTimelinePatterns: Array<TimelinePattern>
    endPoint: number
}) => {
    const percentageStepToTriggerTimelineChange = 100 / possibleTimelinePatterns.length

    const roundedZoomPercentage =
        zoomPercentage < percentageStepToTriggerTimelineChange
            ? percentageStepToTriggerTimelineChange
            : zoomPercentage
    const currentTimelineIndex =
        Math.ceil(roundedZoomPercentage / percentageStepToTriggerTimelineChange) - 1

    // Target potential timeline pattern according to zoom level, but ensure that it doesn't contain big gaps or take more than the expected width
    const currentTimelinePattern = getCurrentTimelinePattern({
        timelinePatterns: possibleTimelinePatterns,
        endPoint,
        timelineWidth,
        possibleTimelinePatternIndex: currentTimelineIndex
    })

    const delta = currentTimelinePattern.delta

    const labelsAmount = getLabelsAmount({ endPoint, delta })

    const timestamps = [...Array(labelsAmount).keys()].map(index => delta * index)

    return timestamps.map(timestamp => formatTime(timestamp))
}

const estimateItemWidth = ({
    index,
    labelsAmount,
    timelineWidth
}: {
    index: number
    labelsAmount: number
    timelineWidth: number
}) => {
    if (index === labelsAmount - 1) return labelWidth

    return timelineWidth / (labelsAmount - 1)
}

type Props = {
    timelineWidth: number
    zoomPercentage: number
    initialDelta: number
    endPoint: number
    scrollElement: HTMLDivElement | null
}

export const TimelineLabels = ({
    timelineWidth,
    zoomPercentage,
    initialDelta,
    endPoint,
    scrollElement
}: Props) => {
    const possibleTimelinePatterns = useMemo(() => {
        const initialTimelinePatternIndex = timelinePatterns.findIndex(
            pattern => pattern.delta === initialDelta
        )

        const possibleTimelinePatterns =
            initialTimelinePatternIndex < 0
                ? [...timelinePatterns, biggestTimelinePattern]
                : timelinePatterns.slice(0, initialTimelinePatternIndex + 1)

        return possibleTimelinePatterns.toReversed()
    }, [initialDelta])

    const timelineLabels = useMemo(() => {
        return generateTimelineLabels({
            zoomPercentage,
            timelineWidth,
            endPoint,
            possibleTimelinePatterns
        })
    }, [endPoint, possibleTimelinePatterns, timelineWidth, zoomPercentage])

    const virtualizer = useVirtualizer({
        horizontal: true,
        count: timelineLabels.length,
        getScrollElement: () => scrollElement,
        estimateSize: index =>
            estimateItemWidth({ index, timelineWidth, labelsAmount: timelineLabels.length }),
        getItemKey: index => timelineLabels[index]
    })

    return (
        <Stack>
            <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                position="relative"
                height={18}
            >
                {virtualizer.getVirtualItems().map(virtualItem => {
                    const index = virtualItem.index
                    const label = timelineLabels[index]

                    return (
                        <Stack
                            key={virtualItem.key}
                            data-index={index}
                            ref={virtualizer.measureElement}
                            sx={{
                                width: estimateItemWidth({
                                    index,
                                    timelineWidth,
                                    labelsAmount: timelineLabels.length
                                }),
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                transform: `translateX(${virtualItem.start}px)`,
                                alignItems: index === timelineLabels.length - 1 ? 'end' : 'start'
                            }}
                        >
                            <Typography
                                variant="text"
                                size="xs"
                                fontWeight="semibold"
                                color="grey.400"
                            >
                                {label}
                            </Typography>
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}
