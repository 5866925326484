import { useCallback } from 'react'

import { Button, Icon, Tooltip } from '@guidde/design-system'
import { faScissors } from '@fortawesome/pro-regular-svg-icons/faScissors'

import { type TimelineChunksType, type TimelineVideoChunkType } from '../types'
import { logToAnalytics } from 'modules'

type Props = {
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    isDisabled: boolean
    disabledTooltip?: string
    getChunkSplitResult: () => {
        resultingChunks: Array<TimelineVideoChunkType>
        activeChunkIndex: number
    } | null
}

export const SplitButton = ({
    chunks,
    setChunks,
    isDisabled,
    disabledTooltip,
    getChunkSplitResult
}: Props) => {
    const splitChunk = useCallback(() => {
        const splitResult = getChunkSplitResult()
        if (!splitResult) return

        const { resultingChunks, activeChunkIndex } = splitResult

        const newChunks = [
            ...chunks.slice(0, activeChunkIndex),
            ...resultingChunks,
            ...chunks.slice(activeChunkIndex + 1, chunks.length)
        ]

        setChunks(newChunks)
        logToAnalytics('editVideoStep_splitBtn_clicked')
    }, [chunks, getChunkSplitResult, setChunks])

    return (
        <Tooltip title={disabledTooltip} shouldHighlightChildIcon={false}>
            <Button
                variant="text"
                color="secondary"
                startIcon={<Icon icon={faScissors} />}
                size="medium"
                disabled={isDisabled}
                onClick={splitChunk}
            >
                Split
            </Button>
        </Tooltip>
    )
}
