import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, ClickAwayListener, IconButton, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'

import { useStepSpeakingRate } from 'UI/Routes/quick-guidde/hooks'
import { SpacedGroup } from 'UI/Components'
import { PronunciationText } from './PronunciationText'
import TranscriptHeader from './TranscriptHeader'
import { TranscriptBlock } from './TranscriptBlock'

import { copyToClipboard, logToAnalytics } from 'modules'
import { useNotification, useBoolean } from 'hooks'
import { type SpeakerType, type SpeechToTextType, type TextToSpeechType } from 'app/types'
import { setTempAudioNote, setAudioNoteTempMarkdown } from 'ducks'

type Props = {
    text: string
    markdown: string
    speakerConfig?: SpeakerType | null
    type: SpeechToTextType['type'] | TextToSpeechType['type']
    language: string
    maxLength?: number
    onChange?: (markdown: string) => void
    onSave?: (markdown?: string) => void
    onCancel?: () => void
}

export const QgTranscript = ({
    text,
    markdown,
    speakerConfig,
    type,
    language,
    onChange,
    onCancel,
    onSave
}: Props) => {
    const dispatch = useDispatch()

    const { playbookId } = useParams<{ playbookId: string }>()

    const { getStepSpeakingRate } = useStepSpeakingRate()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote } = steps?.[activeStep]

    // if no audioNote, or defaultSubtitles, or we have audioNote with tempMarkdown - we turn on edit mode by default
    const isEditableInitialState =
        !Boolean(audioNote) ||
        audioNote?.type === 'defaultSubtitles' ||
        Boolean(audioNote?.tempMarkdown)

    const editable = useBoolean(isEditableInitialState)

    const changed = useBoolean()
    const setChanged = changed.set

    const { showSuccessNotification } = useNotification()

    const handleUpdate = useCallback(
        (newValue: string) => {
            onChange?.(newValue)
            setChanged(true)
        },
        [onChange, setChanged]
    )

    return (
        <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={() => {
                // If user made some changes but didn't save them, we save them as a temp audio note
                // so that user can continue editing them later and we can save them during publish
                if (changed.isFalse || editable.isFalse) return
                // If there is audio note, we save the temp state inside audioNote
                if (Boolean(audioNote)) dispatch(setAudioNoteTempMarkdown(markdown))
                // Otherwise, we save the temp state inside tempAudioNote
                else {
                    if (!speakerConfig) return

                    dispatch(
                        setTempAudioNote({
                            type: 'textToSpeech',
                            text,
                            markdown,
                            speakerConfig,
                            speakingRate: getStepSpeakingRate(steps[activeStep])
                        })
                    )
                }

                changed.setFalse()
            }}
        >
            <div>
                <SpacedGroup justifyContent="space-between" height={50}>
                    <TranscriptHeader language={language} />

                    <SpacedGroup spacing={1}>
                        {type !== 'speechToText' && editable.isFalse && (
                            <Tooltip title="Edit transcript">
                                <IconButton
                                    data-test="voiceover-edit-transcript"
                                    size="small"
                                    onClick={() => {
                                        editable.setTrue()

                                        if (type === 'textToSpeech') {
                                            logToAnalytics(
                                                'voiceover_t2vTab_editTranscriptIcon_clicked',
                                                { playbookId }
                                            )
                                        }
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {text?.length > 0 && (
                            <Tooltip title="Copy to clipboard">
                                <IconButton
                                    data-test="editor-copy-to-clipboard"
                                    data-intercom="editor-copy-to-clipboard"
                                    size="small"
                                    onClick={() => {
                                        copyToClipboard(text)
                                        showSuccessNotification(
                                            'Transcript copied to the clipboard'
                                        )

                                        if (type === 'textToSpeech') {
                                            logToAnalytics(
                                                'voiceover_t2vTab_copyToClipboardIcon_clicked',
                                                { playbookId }
                                            )
                                        }
                                    }}
                                >
                                    <FileCopyOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </SpacedGroup>
                </SpacedGroup>

                {editable.isFalse && type === 'speechToText' && (
                    <Box mt={2} maxHeight="40vh" overflow="auto">
                        <Typography variant="body2">{text}</Typography>
                    </Box>
                )}

                {editable.isFalse && type === 'textToSpeech' && (
                    <Box mt={2} maxHeight="calc(100vh - 490px)" overflow="auto">
                        <PronunciationText
                            subtitle={markdown}
                            onPronunciationChange={value => {
                                onSave?.(value)
                            }}
                        />
                    </Box>
                )}

                {editable.isTrue && (
                    <Box
                        maxHeight={
                            !!(onCancel && onSave && audioNote)
                                ? 'calc(100vh - 520px)'
                                : 'calc(100vh - 460px)'
                        }
                        overflow="auto"
                    >
                        <TranscriptBlock
                            extraText={<i>“Shift+Enter” - pause</i>}
                            value={markdown}
                            onChange={handleUpdate}
                            onFocus={() => {
                                if (type === 'textToSpeech') {
                                    logToAnalytics('voiceover_t2vTab_transcriptTextbox_clicked', {
                                        playbookId,
                                        transcriptTextbox: isEditableInitialState
                                            ? 'creation'
                                            : 'edit'
                                    })
                                }
                            }}
                        />
                    </Box>
                )}

                {onCancel && onSave && audioNote && editable.isTrue && (
                    <SpacedGroup mt={1.5} justifyContent="flex-end">
                        <Button
                            size="large"
                            variant="contained"
                            color="inherit"
                            onClick={() => {
                                editable.setFalse()
                                onCancel()

                                if (audioNote.tempMarkdown) dispatch(setAudioNoteTempMarkdown())

                                if (type === 'textToSpeech') {
                                    logToAnalytics(
                                        'voiceover_t2vTab_transcriptTextbox_cancel_clicked',
                                        {
                                            playbookId,
                                            transcriptTextbox: 'edit'
                                        }
                                    )
                                }
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            data-test="voiceover-edit-save-button"
                            disabled={markdown.length === 0}
                            onClick={() => {
                                editable.setFalse()
                                onSave()

                                if (type === 'textToSpeech') {
                                    logToAnalytics(
                                        'voiceover_t2vTab_transcriptTextbox_save_clicked',
                                        {
                                            playbookId,
                                            transcriptTextbox: 'edit'
                                        }
                                    )
                                }
                            }}
                        >
                            SAVE
                        </Button>
                    </SpacedGroup>
                )}
            </div>
        </ClickAwayListener>
    )
}
