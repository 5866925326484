import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Stack, Typography } from '@mui/material'

import { ReactComponent as WizardPremiumIcon } from 'assets/icons/wizard/wizard_premium.svg'
import UpgradeImage from 'assets/icons/wizard/upgradeImage.png'
import MicOffIcon from '@mui/icons-material/MicOff'

import { logToAnalytics } from 'modules'
import { useServiceUsage } from 'hooks'
import { type SpeakerType } from 'app/types'

import { UpgradePlanPopup } from 'UI/Components/UpgradePlanPopup'
import {
    LanguageSpeakers,
    UpgradePopup,
    useLangaugeSpeakers
} from 'UI/Routes/quick-guidde/LeftPanel'

import { type WizardData, preloadImages } from '../wizard-utils'
import { WizardDialogContent } from './WizardDialogContent'

type Props = {
    speakers: Array<SpeakerType>
    activeSpeakerId: string | null
    onChangeData: (wizardData: Partial<WizardData>) => void
}

export const WizardSpeakerStep = ({ onChangeData, speakers, activeSpeakerId }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()

    const { hasVoiceOverTextToSpeech, hasStudioVoices, isLoading } = useServiceUsage()

    const [upgradeAccountAnchor, setUpgradeAccountAnchor] = useState<HTMLElement | null>(null)
    const [contactUsAnchor, setContactUsAnchor] = useState<HTMLElement | null>(null)

    const { defaultSpeakerId, brandkitSpeakerId, recentlyUsedSpeakers, restSpeakers } =
        useLangaugeSpeakers(speakers)

    const activeSpeaker = speakers.find(speaker => speaker.id === activeSpeakerId) ?? null

    useEffect(() => {
        preloadImages([UpgradeImage])
    }, [])

    if (isLoading) return null

    const speakerEmptyOption = (
        <SpeakerEmptyOption
            isSelected={activeSpeaker === null}
            onClick={() => onChangeData({ speakerId: null })}
        />
    )

    return (
        <WizardDialogContent
            title="Choose your speaker"
            activeStep={1}
            onChangeStep={step => onChangeData({ currentStep: step })}
            disableBackClick
            onPrimaryActionClick={() =>
                logToAnalytics('wizard_speakerNextBtn_clicked', { playbookId })
            }
        >
            <UpgradePlanPopup
                imageSrc={UpgradeImage}
                title="Upgrade your account"
                text="Find the perfect voice for your video"
                hideSeePlanFeatures={true}
                openFeatures={true}
                anchorEl={upgradeAccountAnchor}
                setAnchorEl={setUpgradeAccountAnchor}
                points={['Text to voice', 'Video privacy controls', 'Insights', 'Tracking links']}
            />

            <UpgradePopup
                anchorEl={contactUsAnchor}
                options={speakers.filter(speaker => speaker.isStudio).slice(0, 3)}
                onClose={() => setContactUsAnchor(null)}
            />
            <Stack spacing={4.5} alignItems="center" width="100%">
                {!hasVoiceOverTextToSpeech && (
                    <Stack direction="row" spacing={1} alignItems="center" sx={{}}>
                        <Button
                            variant="text"
                            startIcon={<WizardPremiumIcon />}
                            sx={{
                                p: 0,
                                color: '#920000',
                                textDecorationLine: 'underline',
                                textTransform: 'uppercase',
                                '&:hover': {
                                    textDecorationLine: 'underline',
                                    background: 'none'
                                }
                            }}
                            onClick={({ currentTarget }) => setUpgradeAccountAnchor(currentTarget)}
                        >
                            <Typography
                                fontWeight={500}
                                letterSpacing="1.25px"
                                fontSize={14}
                                lineHeight="16px"
                            >
                                Upgrade your account
                            </Typography>
                        </Button>
                        <Typography
                            fontWeight={500}
                            letterSpacing="1.25px"
                            fontSize={14}
                            lineHeight="16px"
                            color="#828282"
                            textTransform="uppercase"
                        >
                            to get a natural sounding voiceover
                        </Typography>
                    </Stack>
                )}
                <LanguageSpeakers
                    startItems={!hasVoiceOverTextToSpeech && speakerEmptyOption}
                    endItems={hasVoiceOverTextToSpeech && speakerEmptyOption}
                    currentSpeaker={activeSpeaker}
                    onSelect={(event, speaker) => {
                        if (!hasVoiceOverTextToSpeech) {
                            setUpgradeAccountAnchor(event.currentTarget)
                            return
                        }

                        if (speaker.isStudio && !hasStudioVoices) {
                            setContactUsAnchor(event.currentTarget)
                            return
                        }

                        onChangeData({ speakerId: speaker.id })
                        logToAnalytics('wizard_speaker_clicked', {
                            playbookId,
                            selectedId: speaker.id
                        })
                    }}
                    hasStudioVoices={hasStudioVoices}
                    brandkitSpeakerId={brandkitSpeakerId}
                    defaultSpeakerId={defaultSpeakerId}
                    recentlyUsedSpeakers={recentlyUsedSpeakers}
                    restSpeakers={restSpeakers}
                    speakerCardSx={{
                        border: 'none',
                        overflow: 'visible',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)'
                        },
                        '&.SpeakerItem-speakerSelected': {
                            border: 'none',
                            outline: '4px solid #FED243',
                            backgroundColor: 'common.white'
                        }
                    }}
                    premiumDisplayMode="lock"
                />
            </Stack>
        </WizardDialogContent>
    )
}

const SpeakerEmptyOption = ({
    onClick,
    isSelected
}: {
    onClick: () => void
    isSelected: boolean
}) => {
    return (
        <Stack
            direction="row"
            spacing={2}
            p={0.5}
            sx={{
                cursor: 'pointer',
                borderRadius: 1,
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)'
                },
                ...(isSelected && {
                    outline: '4px solid #FED243',
                    backgroundColor: 'common.white',
                    '&:hover': {
                        backgroundColor: 'common.white'
                    }
                })
            }}
            onClick={onClick}
            alignItems="center"
            height={88}
            width={240}
        >
            <Stack
                width={80}
                height={80}
                sx={{
                    borderRadius: '50%',
                    backgroundColor: '#F5F5F5',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <MicOffIcon />
            </Stack>
            <Typography
                fontSize={14}
                letterSpacing={0.25}
                fontWeight={700}
                color={isSelected ? 'default' : 'rgba(9, 12, 16, 0.6)'}
            >
                None
            </Typography>
        </Stack>
    )
}
