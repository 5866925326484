import { useEffect, useLayoutEffect, useMemo, useState } from 'react'

const baseFrameHeight = 45
const baseFrameWidth = 80
const designFrameHeight = 58
const frameScale = designFrameHeight / baseFrameHeight

export const thumbnailFrameHeight = baseFrameHeight * frameScale
export const thumbnailFrameWidth = baseFrameWidth * frameScale

const getMiddleIndexes = (value: number, totalElements: number) => {
    const step = Math.floor((totalElements - 1) / (value - 1))

    const middleValues = Array.from({ length: value - 2 }, (_, i) => (i + 1) * step)

    return [0, ...middleValues, totalElements - 1]
}

const getDuplicatedIndexes = (value: number, totalElements: number) => {
    const step = totalElements / value

    const duplicatedIndexes = Array.from({ length: value }, (_, i) => {
        const value = i * step

        return Math.floor(value)
    })

    return duplicatedIndexes
}

const generateThumbnail = ({
    frameHeight,
    frameWidth,
    gridSize,
    image,
    canvasWidth
}: {
    frameWidth: number
    frameHeight: number
    gridSize: number
    image: HTMLImageElement
    canvasWidth: number
}) => {
    const canvas = document.createElement('canvas')
    canvas.width = canvasWidth
    canvas.height = frameHeight

    const ctx = canvas.getContext('2d')

    const amountOfFrames = Math.ceil(canvasWidth / frameWidth)
    const thumbnailElementsAmount = gridSize * gridSize
    const isIncludesDuplicates = amountOfFrames > thumbnailElementsAmount
    const arrayOfIndexes = isIncludesDuplicates
        ? getDuplicatedIndexes(amountOfFrames, thumbnailElementsAmount)
        : getMiddleIndexes(amountOfFrames, thumbnailElementsAmount)

    arrayOfIndexes.map((frameValue, index) => {
        const xIndex = frameValue % gridSize
        const yIndex = Math.floor(frameValue / gridSize)

        const frameX = baseFrameWidth * xIndex
        const frameY = baseFrameHeight * yIndex
        const destinationX = frameWidth * index
        const destinationY = 0

        ctx!.drawImage(
            image,
            frameX,
            frameY,
            baseFrameWidth,
            baseFrameHeight,
            destinationX,
            destinationY,
            frameWidth,
            frameHeight
        )
    })

    return canvas
}

type Props = {
    thumbnailUrl: string
    duration: number
    endPoint: number
    timelineWidth: number
}

export const useVideoThumbnailCanvas = ({
    thumbnailUrl,
    duration,
    endPoint,
    timelineWidth
}: Props) => {
    const [thumbnailImageElement, setThumbnailImageElement] = useState<HTMLImageElement | null>(
        null
    )

    const videoThumbnailCanvasWidth = useMemo(() => {
        const durationPercentage = duration / endPoint

        return Math.ceil(durationPercentage * timelineWidth)
    }, [duration, endPoint, timelineWidth])

    const [videoThumbnailCanvas, setVideoThumbnailCanvas] = useState<HTMLCanvasElement | null>(null)

    useEffect(() => {
        if (!thumbnailUrl) return

        const image = new Image()
        image.src = thumbnailUrl

        image.onload = () => setThumbnailImageElement(image)
    }, [thumbnailUrl])

    useLayoutEffect(() => {
        if (!thumbnailImageElement) return

        const canvas = generateThumbnail({
            image: thumbnailImageElement,
            frameWidth: thumbnailFrameWidth,
            frameHeight: thumbnailFrameHeight,
            gridSize: 5,
            canvasWidth: videoThumbnailCanvasWidth
        })

        setVideoThumbnailCanvas(canvas)
    }, [thumbnailImageElement, videoThumbnailCanvasWidth])

    return {
        videoThumbnailCanvasWidth,
        videoThumbnailCanvas
    }
}
