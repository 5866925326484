import { useContext, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Tooltip,
    Typography
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { DurationSelection } from './DurationSelection'

import { duplicateSteps, removeActiveStep } from 'ducks'
import { useBoolean } from 'hooks'
import { logToAnalytics } from 'modules'
import { Shape, type StepType } from 'app/types'

export const minimumOneStepRequiredMessage = 'A minimum of one step is required'

export const ActiveStepControls = () => {
    const dispatch = useDispatch()

    const anchorRef = useRef(null)

    const open = useBoolean()
    const editDuration = useBoolean()

    const {
        present: { activeStep, steps, selectedSteps }
    } = useSelector(state => state.qgEditor)

    const { kind, id, layers } = steps[activeStep] || {}

    const userWantsToDeleteAllSteps = steps.length === 1 || selectedSteps.length === steps.length

    const handleDuplicate = () => {
        let stepsForDuplication: Array<StepType> = []

        if (!selectedSteps.length) {
            stepsForDuplication = [steps[activeStep]]
        } else {
            const canDuplicatedActiveStep = kind === 'step'

            stepsForDuplication = steps.filter(step => {
                if (canDuplicatedActiveStep) {
                    return selectedSteps.includes(step.id) || step.id === id
                }

                return selectedSteps.includes(step.id)
            }, [])
        }

        if (!stepsForDuplication.length) {
            console.error('No steps selected for duplication', { steps, stepsForDuplication })
            return
        }
        const selectedStepsMaxIdx = steps.findLastIndex(step => selectedSteps.includes(step.id))

        // get last possible index for pasting steps
        const pasteIdx = Math.max(activeStep, selectedStepsMaxIdx)
        const newActiveStep = (!selectedSteps.length ? activeStep : selectedStepsMaxIdx) + 1

        dispatch(duplicateSteps({ steps: stepsForDuplication, pasteIdx, newActiveStep }))

        logToAnalytics('qg-duplicate-chapter', {
            activeChapter: newActiveStep
        })
    }
    const { processingVideoStepsId } = useContext(VoiceOverContext)

    const isStepDurationAvailable = useMemo(() => {
        if (['intro', 'outro'].includes(kind)) return false
        const isVideoStep = layers.some(l => l.type === Shape.Video)
        return !isVideoStep
    }, [kind, layers])

    const showDuplicateStepButton = useMemo(() => {
        const selectedStepsData = steps.filter(step => selectedSteps.includes(step.id))

        const allSelectedStepsKindIsStep = selectedStepsData.every(step => step.kind === 'step')
        const isRegularStep = kind === 'step'

        return allSelectedStepsKindIsStep && isRegularStep
    }, [kind, selectedSteps, steps])

    return (
        <Box mr="-10px">
            <IconButton
                data-test="editor-step-menu"
                ref={anchorRef}
                onMouseDown={e => {
                    e.stopPropagation()
                    open.setTrue()
                }}
                size="small"
                sx={{ padding: '2px' }}
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>

            <Popper open={open.isTrue} anchorEl={anchorRef?.current} style={{ zIndex: 2 }}>
                <ClickAwayListener onClickAway={open.setFalse} mouseEvent="onMouseDown">
                    <Paper>
                        <MenuList
                            onMouseDown={e => e.stopPropagation()}
                            onClick={open.setFalse}
                            style={{
                                padding: '0',
                                minWidth: '200px'
                            }}
                        >
                            {isStepDurationAvailable && (
                                <MenuItem
                                    style={{ padding: '12px 16px' }}
                                    onClick={() => {
                                        editDuration.setTrue()
                                        logToAnalytics('qg-set-duration', {
                                            activeChapter: activeStep + 1
                                        })
                                    }}
                                >
                                    <TimerOutlinedIcon />
                                    &nbsp;
                                    <Typography variant="button">Set timing</Typography>
                                </MenuItem>
                            )}

                            {showDuplicateStepButton && (
                                <div>
                                    <Divider />

                                    <MenuItem
                                        disabled={processingVideoStepsId.includes(id)}
                                        style={{ padding: '12px 16px' }}
                                        onClick={handleDuplicate}
                                    >
                                        <FileCopyOutlinedIcon />
                                        &nbsp;
                                        <Typography
                                            data-test="editor-duplicate-step"
                                            variant="button"
                                        >
                                            Duplicate step
                                        </Typography>
                                    </MenuItem>
                                </div>
                            )}

                            <div>
                                <Divider />
                            </div>

                            <Tooltip
                                title={
                                    userWantsToDeleteAllSteps ? minimumOneStepRequiredMessage : ''
                                }
                            >
                                <MenuItem
                                    style={{
                                        padding: '12px 16px',
                                        ...(userWantsToDeleteAllSteps && {
                                            opacity: 0.5,
                                            cursor: 'auto'
                                        })
                                    }}
                                    onClick={e => {
                                        if (userWantsToDeleteAllSteps) return e.stopPropagation()

                                        dispatch(removeActiveStep())
                                        logToAnalytics('qg-delete-chapter', {
                                            activeChapter: activeStep + 1,
                                            source: kind
                                        })
                                    }}
                                >
                                    <DeleteOutlineIcon />
                                    &nbsp;{' '}
                                    <Typography data-test="editor-delete-step" variant="button">
                                        Delete step
                                    </Typography>
                                </MenuItem>
                            </Tooltip>
                        </MenuList>
                    </Paper>
                </ClickAwayListener>
            </Popper>

            {editDuration.isTrue && (
                <DurationSelection
                    open
                    onClose={editDuration.setFalse}
                    anchorEl={anchorRef.current}
                />
            )}
        </Box>
    )
}
