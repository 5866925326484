import { useCallback, useMemo } from 'react'

import { type TimelineChunksType, type TimelineChunkType } from './types'
import { checkIsSnapshotChunk, getChunkDuration } from './splitAndTrimUtils'

type ChunkPositionOnTimeline = {
    start: number
    end: number
}

export type ActiveChunkDetailsType = {
    originalChunk: TimelineChunkType
    chunkPositionOnTimeline: ChunkPositionOnTimeline
    activeChunkIndex: number
}

type Props = {
    chunks: TimelineChunksType
    pointerTime: number
}

export const useMapTimelineToVideoTime = ({ chunks, pointerTime }: Props) => {
    const chunksPositionOnTimeline = useMemo(() => {
        return chunks.map((chunk, chunkIndex) => {
            const chunksBefore = chunks.slice(0, chunkIndex)

            const startPoint = chunksBefore.reduce((acc, chunk) => {
                const chunkDuration = getChunkDuration(chunk)
                return chunkDuration + acc
            }, 0)

            const duration = getChunkDuration(chunk)

            return {
                start: startPoint,
                end: startPoint + duration
            }
        })
    }, [chunks])

    const chunksTimelineDuration = useMemo(() => {
        const lastChunk = chunksPositionOnTimeline[chunksPositionOnTimeline.length - 1]

        return lastChunk.end
    }, [chunksPositionOnTimeline])

    const getActiveChunkDetails = useCallback(
        ({
            currentTime,
            canReachChapterBounds
        }: {
            currentTime: number
            canReachChapterBounds?: boolean
        }) => {
            const activeChunkIndex = chunksPositionOnTimeline.findIndex(chunk => {
                if (canReachChapterBounds) {
                    if (chunk.end === chunksTimelineDuration) return true
                    return currentTime >= chunk.start && currentTime < chunk.end
                }

                return currentTime > chunk.start && currentTime < chunk.end
            })

            if (activeChunkIndex < 0) return

            return {
                originalChunk: chunks[activeChunkIndex],
                chunkPositionOnTimeline: chunksPositionOnTimeline[activeChunkIndex],
                activeChunkIndex
            }
        },
        [chunks, chunksPositionOnTimeline, chunksTimelineDuration]
    )

    const activePointerChunkDetails = useMemo(
        () => getActiveChunkDetails({ currentTime: pointerTime }),
        [getActiveChunkDetails, pointerTime]
    )

    const getPointerVideoTime = useCallback(
        ({
            currentTime,
            canReachChapterBounds
        }: {
            currentTime: number
            canReachChapterBounds?: boolean
        }) => {
            const activeChunk = getActiveChunkDetails({
                currentTime,
                canReachChapterBounds
            })

            if (!activeChunk) return null

            if (checkIsSnapshotChunk(activeChunk.originalChunk)) return null

            const pointerTimeFromChunkStart =
                currentTime - activeChunk.chunkPositionOnTimeline.start

            const chunkStartTime = activeChunk.originalChunk.start

            return chunkStartTime + pointerTimeFromChunkStart
        },
        [getActiveChunkDetails]
    )

    const pointerVideoTime = useMemo(
        () =>
            getPointerVideoTime({
                currentTime: pointerTime,
                canReachChapterBounds: false
            }),
        [getPointerVideoTime, pointerTime]
    )

    return {
        activePointerChunkDetails,
        pointerVideoTime,
        chunksPositionOnTimeline,
        getActiveChunkDetails,
        chunksTimelineDuration,
        getPointerVideoTime
    }
}
