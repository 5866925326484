import { useEffect, useRef } from 'react'

import { Stack } from '@mui/material'

import { useBoolean } from 'hooks'

import { minMaxValue } from '../../TrimDialog/TrimSection'

type Props = {
    pointerTime: number
    setPointerTime: (value: number) => void
    timelineContainerElement: HTMLDivElement | null
    endPoint: number
}

export const TimelinePointer = ({
    pointerTime,
    setPointerTime,
    timelineContainerElement,
    endPoint
}: Props) => {
    const pointerRef = useRef<HTMLDivElement | null>(null)

    const pointerMove = useBoolean()
    const stopPointerMove = pointerMove.setFalse

    useEffect(() => {
        if (pointerMove.isFalse || !timelineContainerElement) return

        const onMouseMove = (event: MouseEvent) => {
            const containerRect = timelineContainerElement.getBoundingClientRect()
            const mouseX = event.clientX
            const positionPercentage = (mouseX - containerRect.left) / containerRect.width

            const limitedToBordersPositionPercentage = minMaxValue(positionPercentage, 0, 1)
            const positionTime = limitedToBordersPositionPercentage * endPoint

            setPointerTime(positionTime)
        }

        const onMouseUp = () => {
            stopPointerMove()
            document.body.style.cursor = 'auto'
        }

        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    }, [endPoint, pointerMove.isFalse, setPointerTime, stopPointerMove, timelineContainerElement])

    const position = (pointerTime / endPoint) * 100

    useEffect(() => {
        if (!pointerRef.current) return

        pointerRef.current?.scrollIntoView()
    }, [pointerTime, position])

    return (
        <Stack
            sx={{
                alignItems: 'center',
                position: 'absolute',
                top: -4,
                left: `${position}%`,
                transform: 'translateX(-50%)',
                zIndex: 1,
                height: 116,
                cursor: 'ew-resize'
            }}
            ref={pointerRef}
            onMouseDown={() => {
                pointerMove.setTrue()
                document.body.style.cursor = 'ew-resize'
            }}
        >
            <Stack
                sx={theme => ({
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: theme.palette.primary[600]
                })}
            />
            <Stack
                sx={theme => ({
                    height: 114,
                    width: '3px',
                    border: `1px solid ${theme.palette.common.white}`,
                    borderRadius: '18px',
                    background: theme.palette.primary[600],
                    top: 1,
                    position: 'absolute',
                    left: 4.5,
                    zIndex: -1
                })}
            />
        </Stack>
    )
}
