import { memo, useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { CircularProgress } from '@mui/material'

import { Button } from '@guidde/design-system'

import {
    type ImageShapeType,
    type QuickGuiddeType,
    Shape,
    type StepType,
    type VideoShapeType
} from 'app/types'
import {
    type TimelineChunksType,
    type TimelineSnapshotChunkType,
    type TimelineVideoChunkType
} from './types'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { getLayerCoordinates } from 'UI/Routes/quick-guidde/CanvasEditor/ControlPanel/AddNewStep'

import { checkIsSnapshotChunk, checkIsVideoChunk } from './splitAndTrimUtils'
import {
    getAudioDuration,
    getFirebaseUrl,
    logToAnalytics,
    round,
    uploadWithSignedUrl,
    uuid
} from 'modules'
import { setQuickGuiddeSteps } from 'ducks'
import { firebaseConfig } from 'env'

import { useAuth, useBoolean } from 'hooks'
import { useScaleFactor } from 'UI/Routes/quick-guidde/hooks'
import { QG_BROWSER_BAR_HEIGHT } from 'UI/Routes/quick-guidde/CanvasEditor/Workspace'

const uploadSnapshot = async (
    blobUrl: string,
    uid: string,
    playbookId: string
): Promise<string> => {
    const bucket = 'gs://' + `${firebaseConfig.projectId}-temporary`
    const storagePath = `quickguiddes/${uid}/${playbookId}/uploads`
    const storageUrl = `${storagePath}/${uuid()}.png`

    const response = await fetch(blobUrl)
    const blob = await response.blob()

    await uploadWithSignedUrl(bucket + '/' + storageUrl, blob)
    return getFirebaseUrl(storageUrl, true)
}

const generateImageLayer = async (
    chunk: TimelineSnapshotChunkType,
    uploadData: {
        playbookId: string
        uid: string
        windowDimensions: StepType['windowDimensions']
        scaleFactor: number
        hasBrowserBar?: boolean
    }
): Promise<ImageShapeType> => {
    const { width, height, imageUrl } = chunk
    const { playbookId, uid, windowDimensions, scaleFactor, hasBrowserBar } = uploadData

    const { x, y, scale } = getLayerCoordinates(width, height, windowDimensions, scaleFactor)

    const fileUrl = await uploadSnapshot(imageUrl, uid, playbookId)

    return {
        type: Shape.Image,
        x,
        y: y + (hasBrowserBar ? QG_BROWSER_BAR_HEIGHT / 2 : 0),
        scaleX: scale,
        scaleY: scale,
        width,
        height,
        isBackground: true,
        id: uuid(),
        url: fileUrl
    }
}

type Props = {
    language: QuickGuiddeType['language']
    chunks: TimelineChunksType
    onDone: () => void
}

export const SaveSplitsButton = memo(({ chunks, language, onDone }: Props) => {
    const { playbookId } = useParams<{ playbookId: string }>()
    const dispatch = useDispatch()

    const { uid } = useAuth()
    const { splitAudioFile } = useContext(VoiceOverContext)

    const loading = useBoolean()
    const setLoading = loading.set

    const scaleFactor = useScaleFactor()

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)
    const { audioNote, layers, windowDimensions } = steps[activeStep]

    const videoLayer = layers.find(layer => layer.type === Shape.Video) as VideoShapeType
    const hasBrowserBar = layers.some(layer => layer.type === Shape.BrowserBar)

    const audioUrl = useMemo(() => {
        if (!audioNote || audioNote.type === 'defaultSubtitles') return ''
        return audioNote.audioUrl
    }, [audioNote])

    const convertChunksToSteps = async () => {
        const audioDuration = audioUrl ? await getAudioDuration(audioUrl) : 0

        const audioChunks = audioUrl
            ? await splitAudioFile({
                  playbookId,
                  audioUrl,
                  langCode: language?.langCode || 'en-US',
                  segments: chunks.flatMap((chunk, index) => {
                      if (!checkIsVideoChunk(chunk)) return []

                      const isLast = index === chunks.length - 1
                      const endTime = isLast
                          ? round(audioDuration)
                          : Math.min(chunk.end, audioDuration)

                      const isNegativeTime = endTime - chunk.start < 0
                      if (isNegativeTime) return []

                      return {
                          stepId: chunk.id,
                          start: chunk.start,
                          end: endTime
                      }
                  })
              })
            : undefined

        return Promise.all(
            chunks.map(async (chunk, index) => {
                const isLast = index === chunks.length - 1

                const audioChunk = audioChunks?.segments.find(audioChunk => {
                    if (!checkIsVideoChunk(chunk)) return false
                    return audioChunk.stepId === chunk.id //&& audioChunk.end === chunk.end
                })

                const { audioNote, cta, subtitles, subGenerationId, ...bareStep } =
                    steps[activeStep]

                const videoDuration = checkIsVideoChunk(chunk)
                    ? round(chunk.end - chunk.start)
                    : chunk.duration

                const audioDuration = audioChunk ? round(audioChunk.end - audioChunk?.start) : 0

                const stepDuration = Math.max(videoDuration, audioDuration)

                return {
                    ...bareStep,
                    id: chunk.id,
                    ...(audioChunk?.url && {
                        audioNote: {
                            ...audioNote,
                            audioUrl: audioChunk.url,
                            audioDuration,
                            text: '',
                            markdown: ''
                        }
                    }),
                    ...(audioChunk && { subGenerationId: audioChunk.subGenerationId }),
                    ...(isLast && cta ? { cta } : {}),
                    ...(checkIsSnapshotChunk(chunk) && {
                        drawnScreenshot: '',
                        previewScreenshot: ''
                    }),
                    duration: stepDuration,
                    layers: await Promise.all(
                        layers.map(async layer => {
                            if (layer.type !== Shape.Video) return layer

                            switch (chunk.type) {
                                case 'snapshot':
                                    return await generateImageLayer(chunk, {
                                        playbookId,
                                        uid,
                                        windowDimensions,
                                        scaleFactor,
                                        hasBrowserBar
                                    })
                                case 'video':
                                    return Promise.resolve({
                                        ...layer,
                                        id: uuid(),
                                        start: chunk.start,
                                        end: chunk.end
                                    })
                                default:
                                    return layer
                            }
                        })
                    )
                } as StepType
            })
        )
    }

    const saveChunks = async () => {
        setLoading(true)

        const { start: chunkStart, end: chunkEnd } = chunks[0] as TimelineVideoChunkType
        const isOriginalTime = videoLayer.start === chunkStart && videoLayer.end === chunkEnd

        if (chunks.length === 1 && isOriginalTime) {
            onDone()
            setLoading(false)
            return
        }

        const newSteps = [
            // steps before
            ...steps.slice(0, activeStep),
            // insert new steps from chunks
            ...(await convertChunksToSteps()),
            // steps after
            ...steps.slice(activeStep + 1)
        ]

        dispatch(setQuickGuiddeSteps(newSteps, true))
        onDone()
        setLoading(false)
        logToAnalytics('editVideoStep_saveBtn_clicked', { chunks })
    }

    return (
        <Button
            fullWidth
            variant="contained"
            disabled={loading.isTrue}
            endIcon={loading.isTrue ? <CircularProgress size={16} /> : null}
            onClick={saveChunks}
        >
            Save
        </Button>
    )
})
