import { Fragment } from 'react'

import { Stack } from '@mui/material'

import { TimelineChunk } from './TimelineChunk'
import { GAP_WIDTH, TimelineConnector } from './TimelineConnector'

import { type TimelineChunksType } from '../types'
import { useVideoThumbnailCanvas } from './useVideoThumbnailCanvas'

type Props = {
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    endPoint: number
    thumbnailUrl: string
    timelineWidth: number
    duration: number
    timelineContainerElement: HTMLDivElement | null
    onTimeChange: (value: number) => void
}

export const TimelineChunks = ({
    chunks,
    endPoint,
    thumbnailUrl,
    timelineWidth,
    duration,
    setChunks,
    timelineContainerElement,
    onTimeChange
}: Props) => {
    const { videoThumbnailCanvas, videoThumbnailCanvasWidth } = useVideoThumbnailCanvas({
        thumbnailUrl,
        timelineWidth,
        duration,
        endPoint
    })

    return (
        <Stack direction="row" spacing={0}>
            {chunks.map((chunk, index) => {
                const isLastChunk = index === chunks.length - 1

                return (
                    <Fragment key={chunk.id}>
                        <TimelineChunk
                            endPoint={endPoint}
                            videoThumbnailCanvas={videoThumbnailCanvas}
                            videoThumbnailCanvasWidth={videoThumbnailCanvasWidth}
                            duration={duration}
                            chunkIndex={index}
                            chunks={chunks}
                            setChunks={setChunks}
                            timelineContainerElement={timelineContainerElement}
                            widthDelta={isLastChunk ? 0 : -GAP_WIDTH}
                            onTimeChange={onTimeChange}
                        />
                        {!isLastChunk && (
                            <TimelineConnector
                                chunkIndex={index}
                                chunks={chunks}
                                setChunks={setChunks}
                            />
                        )}
                    </Fragment>
                )
            })}
        </Stack>
    )
}
