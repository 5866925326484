export const links = {
    terms: 'https://www.guidde.com/eula',
    privacyPolicy: 'https://www.guidde.com/privacy-policy',
    contactSales: 'https://www.guidde.com/contact-sales',
    affiliateProgram: 'https://www.guidde.com/affiliate-program',
    guiddeWebsite: 'https://www.guidde.com',
    feedback: 'https://www.guidde.com/feedback',
    facebook: 'https://facebook.com/GuiddeCo',
    twitter: 'https://twitter.com/GuiddeCo',
    linkedin: 'https://www.linkedin.com/company/guidde',
    helpGuiddeAgent: 'https://help.guidde.com/en/articles/6364686-installing-the-guidde-agent',
    helpZoom: 'https://help.guidde.com/en/articles/6362264-guidde-for-zoom',
    helpExtension:
        'https://help.guidde.com/en/articles/6360996-installing-the-guidde-browser-extension',
    helpYoutube: 'https://help.guidde.com/en/articles/6393053-connect-to-youtube',
    helpSlack: 'https://help.guidde.com/en/articles/6362281-guidde-for-slack',
    helpMicrophone:
        'https://help.guidde.com/en/articles/6855636-unable-to-select-microphone-when-recording-voiceover-couldn-t-access-microphone',
    bookDemo: 'https://meetings.hubspot.com/ngiat/book-a-demo-with-guidde',
    downloadDesktopApp: 'https://help.guidde.com/en/articles/9760354-desktop-application'
}
