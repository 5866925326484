import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { BroadcastChannel } from 'broadcast-channel'

import { getAuth, getIdTokenResult } from 'firebase/auth'

import { GuiddeLoad } from 'UI/Components'
import { SelectRoles } from './SelectRoles'
import { SelectTeams } from './SelectTeams'

import { type UpdateAuthPayload, updateAppAuth } from 'ducks'
import { type UserAuthStateType } from 'ducks/reducers'

import { handleSessionRedirects } from 'UI/Routes/signIn/utils'
import { useAuth, useBoolean, useDataMutation, useQuery } from 'hooks'
import { DesktopCommunication, logToAnalytics } from 'modules'
import { useMemo } from 'react'

export enum OnboardingSteps {
    Teams = 'select-teams',
    Roles = 'select-roles',
    Done = 'done'
}

export type TopicType = {
    imageUrl: string
    title: string
    withExtraInput?: boolean
}

export type RolesResponseType = {
    roles: Array<{ id: string; name: string }>
    topics: Array<TopicType>
    initialCompanyName?: string
    companySizes: Array<string>
}

export type OnboardingStepPayload = {
    activeStep: OnboardingSteps.Roles
    data: {
        role: string
        companyName?: string
        topics: Array<string>
        companySize: string
        handledOnClient?: boolean
        other?: string
    }
}

export type PostOnboardingPayloadType =
    | OnboardingStepPayload
    | {
          activeStep: OnboardingSteps.Teams
          data: {
              // null - if user clicks "Create new organization"
              orgId: string | null
          }
      }

export enum OrgRequestStatus {
    Approved = 'approved',
    Declined = 'declined',
    Pending = 'pending'
}

export type TeamType = {
    orgLogo: string
    orgId: string
    orgName: string
    planName?: string
    members: Array<{
        photoURL: string
        name: string
    }>
    membersCount: number
    requestStatus?: null | OrgRequestStatus
}

export type WorkspacesResponseType = Array<TeamType>

export type OnboardingResponseType =
    | {
          activeStep: OnboardingSteps.Teams
          data: WorkspacesResponseType
          canCreateWorkspace: boolean
      }
    | {
          activeStep: OnboardingSteps.Roles
          data: RolesResponseType
      }
    | {
          activeStep: OnboardingSteps.Done
      }

const loadingMessage = 'Magically create video documentation with AI in seconds!'

export const OnboardingFinishedEvent = 'onboarding-finished'

export type OnboardingFinishedType = {
    roles: UserAuthStateType['roles']
}

export const PageOnboarding = () => {
    const { isLoading } = useAuth()

    const location = useLocation()

    const locationSearch = useMemo(() => new URLSearchParams(location.search), [location.search])

    const shouldPingDesktop = useMemo(() => {
        const isOpenedFromDesktop = locationSearch.get('src') === 'desktop'
        const isOnboardingSourceRequest = locationSearch.get('action') === 'finish-onboarding'
        return isOpenedFromDesktop && isOnboardingSourceRequest
    }, [locationSearch])

    const issueNotified = useBoolean()

    const history = useHistory()

    const dispatch = useDispatch()

    const $getOnboarding = useQuery<OnboardingResponseType>(
        isLoading ? '' : '/auth/v1/onboarding',
        { method: 'GET' },
        {
            revalidateOnFocus: false,
            refreshWhenHidden: false,
            onSuccess: data => {
                if (data?.activeStep === OnboardingSteps.Done) {
                    handleSessionRedirects({ historyPush: history.push })
                }
            }
        }
    )

    const $postOnboarding = useDataMutation<PostOnboardingPayloadType, {}, Error>(
        '/auth/v1/onboarding',
        'POST',
        {
            async onSuccess() {
                const currentUser = getAuth().currentUser
                if (!currentUser) return

                const tokenResult = await getIdTokenResult(currentUser, true)
                const roles = tokenResult?.claims?.roles as UpdateAuthPayload['roles']

                if (!roles) return
                dispatch(updateAppAuth({ roles }))

                const eventPayload: OnboardingFinishedType = { roles }
                // used to notify the app that onboarding is finished and redirect all tabs from login/onboarding pages to homepage
                new BroadcastChannel(OnboardingFinishedEvent).postMessage(eventPayload)

                const shouldRedirectToDesktopApp = !roles.n && shouldPingDesktop
                if (shouldRedirectToDesktopApp) DesktopCommunication.onBoardingDone()
            },
            throwOnFailure: true,

            async onFailure() {
                logToAnalytics('stuck_in_onboarding', $getOnboarding.data)
                issueNotified.setTrue()
            }
        }
    )

    if ($getOnboarding.isValidating && !$getOnboarding.data) {
        return <GuiddeLoad message={loadingMessage} />
    }

    switch ($getOnboarding.data?.activeStep) {
        case OnboardingSteps.Teams:
            return (
                <SelectTeams
                    joinTeam={data => {
                        return $postOnboarding.mutate(data).then(() => $getOnboarding.mutate())
                    }}
                    isLoading={$postOnboarding.isLoading}
                    canCreateWorkspace={$getOnboarding.data.canCreateWorkspace}
                    workspaces={$getOnboarding.data.data}
                />
            )

        case OnboardingSteps.Roles:
            return (
                <SelectRoles data={$getOnboarding.data?.data} $postOnboarding={$postOnboarding} />
            )
    }

    return <GuiddeLoad message={loadingMessage} />
}

export default PageOnboarding
