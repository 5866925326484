import { type SpeakerType } from 'app/types'
import { logToAnalytics, playbookToAnalyticsProps } from 'modules'

class AutoTranslateAnalytics {
    static getTranslatedCopyBtnClicked(props: GetTranslatedCopyBtnClickedProps) {
        logToAnalytics('autoTranslate_getTranslatedCopyBtn_clicked', props)
    }

    static translateBtnClicked(props: TranslateBtnClickedProps) {
        logToAnalytics('autoTranslate_translateBtn_clicked', props)
    }

    static reviewBtnClicked(props: ReviewBtnClickedProps) {
        logToAnalytics('autoTranslate_reviewBtn_clicked', props)
    }

    static tryAgainBtnClicked(props: TryAgainBtnClickedProps) {
        logToAnalytics('autoTranslate_tryAgainBtn_clicked', props)
    }

    static upgradeBtnClicked(props: UpgradeBtnClickedProps) {
        logToAnalytics('upgrade_button_auto_translate', props)
    }
}

type GetTranslatedCopyBtnClickedProps = ReturnType<typeof playbookToAnalyticsProps>
type TryAgainBtnClickedProps = ReturnType<typeof playbookToAnalyticsProps>
type UpgradeBtnClickedProps = ReturnType<typeof playbookToAnalyticsProps>

type TranslateBtnClickedProps = {
    speakers: SpeakerType[]
}

type ReviewBtnClickedProps = {
    playbookId: string
    language: string
}

export default AutoTranslateAnalytics
