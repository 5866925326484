import { type CSSProperties, type ReactNode, useMemo, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import {
    Box,
    ListItemIcon,
    ClickAwayListener,
    MenuItem,
    Popover,
    Typography,
    LinearProgress,
    IconButton,
    Tooltip
} from '@mui/material'

import { ReactComponent as DeleteIcon } from 'assets/icons/trash-outlined.svg'

import { SpacedGroup } from 'UI/Components'
import { Share, Delete } from 'UI/Routes/main/common'

import { type AnyPlaybookType } from 'app/types'
import { useRoles } from 'hooks'
import { QuickGuiddeTranslate } from 'UI/Routes/quick-guidde/Header/QuickGuiddeTranslate'
import { isQG } from 'modules'
import { ThemeProvider, Button, spacings } from '@guidde/design-system'

type Props = {
    canEdit: boolean
    playbook: AnyPlaybookType
    menuButtonBgColor?: CSSProperties['background']
    disableEdit?: boolean
    menuIconColor?: CSSProperties['color']
    revalidate?: () => void
    onEditClick?: () => void
    hideEdit?: boolean
    hideDelete?: boolean
    hideShare?: boolean
    controls: Array<{
        isLoading?: boolean
        icon: JSX.Element
        title: string
        onClick: () => void
        hidden?: boolean
        disabled?: boolean
        showAfterClick?: boolean
        component?: ReactNode
    }>
}

export const VideoPanelControls = ({
    controls,
    playbook,
    canEdit,
    menuButtonBgColor = 'none',
    menuIconColor,
    hideDelete,
    hideEdit = false,
    hideShare,
    disableEdit,
    revalidate = () => {},
    onEditClick = () => {}
}: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { isViewer } = useRoles()

    const filteredItems = useMemo(() => controls.filter(it => !it.hidden), [controls])

    const canViewEditOptions = useMemo(() => !hideEdit && canEdit, [canEdit, hideEdit])
    const disabledTextContent = useMemo(() => {
        if (disableEdit) {
            return 'Processing in progress'
        }
        return ''
    }, [disableEdit])

    if (isViewer) {
        return (
            <ThemeProvider>
                <Share
                    playbook={playbook}
                    canShare={canEdit}
                    isEditMode={hideEdit}
                    revalidatePlaybook={revalidate}
                />
            </ThemeProvider>
        )
    }

    return (
        <Box>
            <SpacedGroup>
                <ThemeProvider>
                    {canViewEditOptions && isQG(playbook) && (
                        <QuickGuiddeTranslate
                            playbook={playbook}
                            disabledText={disabledTextContent}
                            isDisabled={disableEdit}
                        />
                    )}

                    {!hideShare && (
                        <Share
                            playbook={playbook}
                            canShare={canEdit}
                            revalidatePlaybook={revalidate}
                            isEditMode={hideEdit}
                        />
                    )}
                    {canViewEditOptions && (
                        <Box
                            sx={theme => ({
                                minWidth: theme.spacing(spacings['10xl'])
                            })}
                        >
                            <Tooltip title={disabledTextContent}>
                                <Box>
                                    <Button
                                        onClick={onEditClick}
                                        fullWidth
                                        disabled={disableEdit}
                                        variant="contained"
                                        size="small"
                                    >
                                        Edit{' '}
                                        {'isPlaylist' in playbook && playbook?.isPlaylist
                                            ? 'Playlist'
                                            : 'Video'}
                                    </Button>
                                </Box>
                            </Tooltip>
                        </Box>
                    )}
                </ThemeProvider>

                {filteredItems.length > 0 && (
                    <IconButton
                        onClick={event => {
                            setAnchorEl(anchorEl ? null : event.currentTarget)
                        }}
                        style={{ marginLeft: -8, background: menuButtonBgColor }}
                        size="small"
                    >
                        <MoreVertIcon style={{ color: menuIconColor || 'inherit' }} />
                    </IconButton>
                )}

                <Popover
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    anchorOrigin={{
                        horizontal: -150,
                        vertical: 'bottom'
                    }}
                >
                    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                        <Box m={1}>
                            {filteredItems.map(({ icon, isLoading, ...control }, i) => (
                                <MenuItem
                                    key={i}
                                    onClick={() => {
                                        control.onClick()
                                        if (!control.showAfterClick) setAnchorEl(null)
                                    }}
                                    disabled={control.disabled || isLoading}
                                    divider
                                    style={{ padding: 0, position: 'relative' }}
                                >
                                    <Box position="absolute" top={0} left={0} right={0}>
                                        {isLoading && <LinearProgress style={{ height: 2 }} />}
                                    </Box>
                                    <Box
                                        style={{ padding: '8px 12px' }}
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <ListItemIcon
                                            sx={{
                                                marginRight: '-4px',
                                                '& svg': {
                                                    color: '#000000DE !important',
                                                    height: '20px'
                                                }
                                            }}
                                        >
                                            {icon}
                                        </ListItemIcon>
                                        <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                                            {control.title}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            ))}
                            {!hideDelete && (
                                <Delete
                                    label={
                                        <MenuItem
                                            style={{ padding: 0, position: 'relative' }}
                                            divider
                                        >
                                            <SpacedGroup
                                                spacing={1}
                                                style={{ padding: '8px 12px' }}
                                            >
                                                <DeleteIcon
                                                    style={{ width: '20px', color: 'inherit' }}
                                                />
                                                <Box fontSize={14} fontWeight={500}>
                                                    Move to Trash
                                                </Box>
                                            </SpacedGroup>
                                        </MenuItem>
                                    }
                                    playbook={playbook}
                                />
                            )}
                        </Box>
                    </ClickAwayListener>
                </Popover>
            </SpacedGroup>

            {filteredItems
                .filter(it => it.component)
                .map(item => (
                    <Box key={item.title} position="absolute">
                        {item.component}
                    </Box>
                ))}
        </Box>
    )
}
