import { memo, type ReactNode } from 'react'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'

import { List, Box, IconButton } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import GuiddeLogoRed from 'assets/icons/guidde_red.svg'

import { useMenuStatus } from 'UI/Components'
import { DrawerBody, DrawerLink, MenuProfile } from './common'

import {
    type GlobalFeatureFlagType,
    type OrgFeatureFlagType,
    useOrgFeatureFlags,
    useGlobalFeatureFlags,
    useRoles,
    useServiceUsage
} from 'hooks'

import { type MenuListType, MENU } from 'app/menu'
import { paths } from 'app/paths'

import { SpaceMenuItem } from './SpaceMenuItem'
import { DesktopWorkspacePanel } from './WorkspaceControlPanel'
import { CollapsedMenuTooltip } from './common/CollapsedMenuTooltip'
import { MenuReferralButton } from './common/MenuReferralButton'

type LinkDrawerProps = {
    menu: MenuListType
}

const MAP_LOCKS = {
    [paths.activity]: 'activityFeedLocked',
    [paths.insights]: 'insightsLocked'
} as const

const LinkDrawer = ({ menu }: LinkDrawerProps) => {
    const location = useLocation()

    const orgFeatureFlags = useOrgFeatureFlags()
    const globalFeatureFlags = useGlobalFeatureFlags()
    const serviceUsage = useServiceUsage()

    const { userRoles, isSuperAdmin } = useRoles()
    const { isFreePlan } = useServiceUsage()

    const showBrandKitEndIcon = isFreePlan && !isSuperAdmin

    return menu.map((link, i) => {
        const currentLink = location?.pathname

        const isActive =
            currentLink === link.path || link.path.split('/')[1] === currentLink.split('/')[1]

        if (link.role && !Array.isArray(link.role) && !userRoles[link.role]) {
            return null
        }

        if (Array.isArray(link.role) && link.role.every(r => !userRoles[r])) {
            return null
        }

        // Check for valid feature flag
        if (
            link.feature &&
            !orgFeatureFlags?.[link.feature as OrgFeatureFlagType] &&
            !globalFeatureFlags?.[link.feature as GlobalFeatureFlagType]
        ) {
            return null
        }

        const StartIcon = link.startIcon
        // hardcoded rule for showing second icon specially only for a brand kit menu item based on plan type
        let EndIcon = showBrandKitEndIcon && link.path === paths.brandKit ? link.endIcon : null

        // check if icon is custom or imported from material-ui and select active color
        // @ts-ignore @TODO: TS-FIX
        const activeColor = StartIcon.type ? 'primary' : '#cd0000'

        if (serviceUsage[MAP_LOCKS[link.path as keyof typeof MAP_LOCKS]]) {
            return null
        }

        if (link.path === paths.spaces) {
            return <SpaceMenuItem key={link.path + i} />
        }

        return (
            <DrawerLink
                key={link.path + i}
                divider={link.showDivider}
                to={{
                    pathname: link.path,
                    state: { prevPath: location.pathname }
                }}
                isActive={isActive}
                endIcon={
                    !!EndIcon && (
                        <EndIcon
                            style={{
                                color: '#cb0000'
                            }}
                        />
                    )
                }
                startIcon={<StartIcon style={{ color: isActive ? activeColor : 'inherit' }} />}
                title={link.title}
            />
        )
    })
}

type LaptopDrawerProps = {
    toolbar?: ReactNode
}
export const LaptopDrawer = memo(({ toolbar }: LaptopDrawerProps) => {
    const isHomePage = useRouteMatch(paths.home)?.isExact

    const { isMenuClosed, setIsMenuClosed } = useMenuStatus()

    return (
        <DrawerBody toolbar={toolbar}>
            <List disablePadding>
                <Box display="flex">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        minHeight={70}
                        minWidth={70}
                    >
                        <CollapsedMenuTooltip title="Expand">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                width="100%"
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => setIsMenuClosed(!isMenuClosed)}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Box>
                        </CollapsedMenuTooltip>
                    </Box>

                    {!isMenuClosed && (
                        <Box
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            px={1}
                        >
                            <Link
                                to={{ pathname: paths.index }}
                                onClick={e => {
                                    if (isHomePage) {
                                        e.preventDefault()
                                        return
                                    }
                                }}
                            >
                                <img width={100} src={GuiddeLogoRed} alt="red logo" />
                            </Link>
                        </Box>
                    )}
                </Box>

                <Box px={isMenuClosed ? 0 : 2}>
                    <DesktopWorkspacePanel isMenuClosed={isMenuClosed} />
                </Box>
            </List>

            <List
                style={{
                    paddingTop: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    height: '100%',
                    flexGrow: 1,
                    overflowY: 'auto'
                }}
            >
                <Box>
                    <LinkDrawer menu={MENU} />
                </Box>

                <MenuReferralButton />
            </List>

            <MenuProfile />
        </DrawerBody>
    )
})
