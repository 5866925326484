import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { getArrowDataKey, getArrowType, svgArrowSize } from './Workspace/Shapes/svg-arrow-data'
import { useAddStepBackground } from './ControlPanel'

import {
    type ArrowShapeType,
    type AudioCircleType,
    type BlurShapeType,
    type CircleShapeType,
    type CircleWithTextShapeType,
    type ImageCircleType,
    type ImageShapeType,
    type OverlayShapeType,
    type RectangleShapeType,
    type TextShapeType,
    Shape
} from 'app/types'

import { uuid } from 'modules'
import { useAuth, useBrandKit, useOrgFeatureFlags } from 'hooks'
import { generateStepIndex } from './StepsPreview'

const DEFAULT_LAYER_POSITION = {
    x: 30,
    y: 30
}

// Text layer
export const DEFAULT_TEXT_LAYER = {
    type: Shape.Text,
    ...DEFAULT_LAYER_POSITION,
    id: '',
    title: 'Some text',
    fontFamily: '',
    color: '#fff',
    backgroundColor: '',
    fontSize: 90,
    fontStyle: 'normal',
    width: 550,
    height: 130, // fontSize + padding * 2 (top and bottom). Needs for step numbering calculation
    padding: 20,
    scaleX: 1,
    scaleY: 1,
    cornerRadius: 8
} as const

// Circle layer
const DEFAULT_CIRCLE_LAYER = {
    type: Shape.Circle,
    id: '',
    strokeColor: '#FED243',
    strokeWidth: 10,
    x: DEFAULT_LAYER_POSITION.x * 3,
    y: DEFAULT_LAYER_POSITION.y * 3,
    scaleX: 1,
    scaleY: 1,
    radius: DEFAULT_LAYER_POSITION.x * 2,
    fill: '',
    isSpotlight: false,
    rotate: 1
} as const

// Profile image layer
const DEFAULT_PROFILE_IMAGE_LAYER = {
    type: Shape.ImageCircle,
    id: '',
    x: DEFAULT_LAYER_POSITION.x * 5,
    y: DEFAULT_LAYER_POSITION.y * 5,
    scaleX: 1,
    scaleY: 1,
    url: 'https://storage.googleapis.com/guidde-production.appspot.com/public/images/defaultProfile.png',
    radius: 120,
    isProfile: true
} as const

// Step numbering layer
export const DEFAULT_CIRCLE_WITH_TEXT_LAYER = {
    type: Shape.CircleWithText,
    ...DEFAULT_LAYER_POSITION,
    id: uuid(),
    scaleX: 1,
    scaleY: 1,
    circle: {
        ...DEFAULT_CIRCLE_LAYER,
        x: DEFAULT_CIRCLE_LAYER.radius,
        y: DEFAULT_CIRCLE_LAYER.radius,
        isSpotlight: false,
        id: uuid(),
        fill: DEFAULT_CIRCLE_LAYER.strokeColor,
        strokeColor: DEFAULT_CIRCLE_LAYER.strokeColor
    },
    text: {
        ...DEFAULT_TEXT_LAYER,
        x: 0,
        y: DEFAULT_LAYER_POSITION.y,
        id: uuid(),
        color: 'black',
        align: 'center',
        title: '01',
        padding: 0,
        fontSize: 75,
        width: DEFAULT_CIRCLE_LAYER.radius * 2,
        height: 75
    }
} as const

// Audio layer with image
const DEFAULT_AUDIO_IMAGE_CIRCLE_LAYER = {
    type: Shape.AudioCircle,
    ...DEFAULT_LAYER_POSITION,
    id: '',
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    radius: 120,
    strokeColor: 'red' as string,
    strokeWidth: 12,
    render: {
        type: 'imageRender',
        url: ''
    }
} as const

// Audio layer with svg icon
const DEFAULT_AUDIO_SVG_CIRCLE_LAYER = {
    ...DEFAULT_AUDIO_IMAGE_CIRCLE_LAYER,
    render: {
        type: 'svgRender',
        url: 'https://storage.googleapis.com/guidde-production.appspot.com/public/images/defaultWave.png',
        logo: {
            x: 80,
            y: 80,
            fill: 'red',
            fillRadius: 40,
            icon: {
                x: 21.4500095,
                y: 21.4500095,
                fill: 'black',
                dataKey: 'qgMicrophoneIcon'
            }
        }
    }
} as const

// Blur layer
const DEFAULT_BLUR_LAYER = {
    type: Shape.Blur,
    ...DEFAULT_LAYER_POSITION,
    id: '',
    width: 200,
    height: 200,
    scaleX: 1,
    scaleY: 1
} as const

// Watermark layer
const DEFAULT_WATERMARK_LAYER = {
    type: Shape.Image,
    x: DEFAULT_LAYER_POSITION.x,
    y: DEFAULT_LAYER_POSITION.y,
    url: 'https://storage.app.guidde.com/v0/b/guidde-production.appspot.com/o/qgSlides%2Fdefault%2Fqg_watermark.png?alt=media&token=3348d1f0-bcfd-4237-ab79-2f904a75116b',
    width: 224,
    height: 60,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
    id: uuid(),
    isWatermark: true
} as const

// Overlay layer
const DEFAULT_OVERLAY_LAYER = {
    type: Shape.Overlay,
    id: '',
    x: 0 as number,
    y: 0 as number,
    width: 3000 as number,
    height: 3000 as number,
    scaleX: 1,
    scaleY: 1,
    fill: 'rgba(0,0,0,0.5)',
    strokeWidth: 0,
    strokeColor: ''
} as const

// Rectangle layer
const DEFAULT_RECT_LAYER = {
    type: Shape.Rectangle,
    ...DEFAULT_LAYER_POSITION,
    id: '',
    cornerRadius: 0,
    width: 200,
    height: 100,
    scaleX: 1,
    scaleY: 1,
    strokeWidth: 10,
    strokeColor: '#FED243',
    fill: '',
    isSpotlight: false
} as const

// Arrow layer
const DEFAULT_ARROW_LAYER = {
    type: Shape.Arrow,
    ...DEFAULT_LAYER_POSITION,
    dataKey: 'qgArrowBottomLeft',
    id: '',
    fill: '#FED243',
    position: 'custom',
    rotation: 0,
    scaleX: 1,
    scaleY: 1,
    strokeColor: '',
    strokeWidth: 0,
    width: svgArrowSize.width,
    height: svgArrowSize.height
} as const

// Image layer
const DEFAULT_IMAGE_LAYER = {
    type: Shape.Image,
    ...DEFAULT_LAYER_POSITION,
    id: '',
    width: 0,
    height: 0,
    url: '',
    scaleX: 1,
    scaleY: 1
} as const

// Background rectangle layer
export const DEFAULT_BACKGROUND_COLOR_LAYER = {
    type: Shape.Rectangle,
    id: uuid(),
    cornerRadius: 0,
    width: 3000,
    height: 3000,
    scaleX: 1,
    scaleY: 1,
    strokeWidth: 0,
    strokeColor: '',
    x: 0,
    y: 0,
    isBackground: true
} as const

export const useLayers = () => {
    const { photoURL } = useAuth()
    const {
        brandKitHighlightColor: brandKitColor,
        brandKitFont,
        brandKitFontSize,
        brandKitFontColor,
        brandKitFontFillColor,
        brandKitStepNumberingColor
    } = useBrandKit()
    const { enableSketchedArrow, enableWixArrow } = useOrgFeatureFlags()
    const arrowType = getArrowType({ enableSketchedArrow, enableWixArrow })

    const newStepBackground = useAddStepBackground()

    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote, windowDimensions, layers, title } = steps[activeStep] || {}

    const bgImageLayer = layers?.find(layer => layer.type === Shape.Image && layer.isBackground) as
        | ImageShapeType
        | undefined

    const text = useMemo((): TextShapeType => {
        const hasTitleLayer = layers?.some(layer => layer.type === Shape.Text && layer.isTitle)

        const { width, ...otherTextProps } = DEFAULT_TEXT_LAYER

        const fontSize = brandKitFontSize || DEFAULT_TEXT_LAYER.fontSize
        const color = brandKitFontColor || DEFAULT_TEXT_LAYER.color
        const fontFamily = brandKitFont?.title || DEFAULT_TEXT_LAYER.fontFamily

        return {
            ...otherTextProps,
            ...(!hasTitleLayer && {
                isTitle: true,
                title: title || DEFAULT_TEXT_LAYER.title
            }),
            ...((hasTitleLayer || !title) && { width }),
            color,
            fontFamily,
            fontSize,
            height: fontSize + DEFAULT_TEXT_LAYER.padding * 2,
            backgroundColor: brandKitFontFillColor
        }
    }, [
        brandKitFont?.title,
        brandKitFontColor,
        brandKitFontFillColor,
        brandKitFontSize,
        layers,
        title
    ])

    const stepNumbering = useMemo((): CircleWithTextShapeType => {
        const stepNumberingTitle = generateStepIndex(steps, activeStep + 1)

        const { text, circle } = DEFAULT_CIRCLE_WITH_TEXT_LAYER

        return {
            ...DEFAULT_CIRCLE_WITH_TEXT_LAYER,
            id: uuid(),
            text: {
                ...text,
                title: stepNumberingTitle,
                color: brandKitStepNumberingColor || text.color
            },
            circle: {
                ...circle,
                fill: brandKitColor || circle.fill,
                strokeColor: brandKitColor || circle.strokeColor
            }
        }
    }, [activeStep, brandKitColor, brandKitStepNumberingColor, steps])

    const watermark = useMemo((): ImageShapeType => {
        const padding = 20

        return {
            ...DEFAULT_WATERMARK_LAYER,
            x: windowDimensions?.innerWidth - DEFAULT_WATERMARK_LAYER.width - padding,
            y: windowDimensions?.innerHeight - DEFAULT_WATERMARK_LAYER.height - padding
        }
    }, [windowDimensions?.innerHeight, windowDimensions?.innerWidth])

    const audioSVG = useMemo((): AudioCircleType => {
        const audioLayer = JSON.parse(JSON.stringify(DEFAULT_AUDIO_SVG_CIRCLE_LAYER))

        const bottomPoint = bgImageLayer
            ? bgImageLayer?.y + bgImageLayer?.height * bgImageLayer.scaleX
            : windowDimensions?.innerHeight

        audioLayer.y = bottomPoint - audioLayer.radius * 2 - audioLayer.y
        audioLayer.x = (bgImageLayer?.x || 0) + audioLayer.x

        if (brandKitColor) {
            audioLayer.strokeColor = brandKitColor
            if (audioLayer.render.type === 'svgRender') {
                audioLayer.render.logo.fill = brandKitColor
            }
        }

        return audioLayer
    }, [bgImageLayer, brandKitColor, windowDimensions?.innerHeight])

    const audioImage = useMemo((): AudioCircleType => {
        const audioLayer = structuredClone({
            ...DEFAULT_AUDIO_IMAGE_CIRCLE_LAYER,
            render: { ...DEFAULT_AUDIO_IMAGE_CIRCLE_LAYER.render, url: photoURL }
        })

        const bottomPoint = bgImageLayer
            ? bgImageLayer?.y + bgImageLayer?.height * bgImageLayer.scaleX
            : windowDimensions?.innerHeight

        audioLayer.y = bottomPoint - audioLayer.radius - audioLayer.y
        audioLayer.x = (bgImageLayer?.x || 0) + audioLayer.radius + audioLayer.x

        if (brandKitColor) {
            audioLayer.strokeColor = brandKitColor
        }

        return audioLayer
    }, [bgImageLayer, brandKitColor, photoURL, windowDimensions?.innerHeight])

    const speaker = useMemo((): AudioCircleType => {
        if (audioNote?.type !== 'textToSpeech') return audioImage

        return {
            ...audioImage,
            render: {
                ...audioImage.render,
                url: audioNote.speakerConfig.avatarUrl
            }
        }
    }, [audioNote, audioImage])

    const circle = useMemo((): CircleShapeType => {
        return {
            ...DEFAULT_CIRCLE_LAYER,
            strokeColor: brandKitColor || DEFAULT_CIRCLE_LAYER.strokeColor
        }
    }, [brandKitColor])

    const profileImage = useMemo((): ImageCircleType => {
        return {
            ...DEFAULT_PROFILE_IMAGE_LAYER,
            url: photoURL || DEFAULT_PROFILE_IMAGE_LAYER.url
        }
    }, [photoURL])

    const blur = useMemo((): BlurShapeType => DEFAULT_BLUR_LAYER, [])

    const overlay = useMemo((): OverlayShapeType => {
        const copiedOverlay = { ...DEFAULT_OVERLAY_LAYER }

        if (bgImageLayer) {
            copiedOverlay.width = bgImageLayer.width * bgImageLayer.scaleX
            copiedOverlay.height = bgImageLayer.height * bgImageLayer.scaleY
            copiedOverlay.x = bgImageLayer.x
            copiedOverlay.y = bgImageLayer.y
        } else {
            copiedOverlay.width = windowDimensions?.innerWidth
            copiedOverlay.height = windowDimensions?.innerHeight
        }

        return copiedOverlay
    }, [bgImageLayer, windowDimensions?.innerHeight, windowDimensions?.innerWidth])

    const rectangle = useMemo(
        (): RectangleShapeType => ({
            ...DEFAULT_RECT_LAYER,
            strokeColor: brandKitColor || DEFAULT_RECT_LAYER.strokeColor
        }),
        [brandKitColor]
    )

    const arrow = useMemo(
        (): ArrowShapeType => ({
            ...DEFAULT_ARROW_LAYER,
            dataKey: getArrowDataKey('bottomLeft', arrowType),
            fill: brandKitColor || DEFAULT_ARROW_LAYER.fill
        }),
        [brandKitColor, arrowType]
    )

    const image = useMemo((): ImageShapeType => DEFAULT_IMAGE_LAYER, [])

    const backgroundRectangle = useMemo(
        (): RectangleShapeType => ({
            ...DEFAULT_BACKGROUND_COLOR_LAYER,
            width: windowDimensions?.innerWidth,
            height: windowDimensions?.innerHeight,
            id: uuid(),
            ...newStepBackground
        }),
        [newStepBackground, windowDimensions]
    )

    const layersList = {
        text,
        audioSVG,
        audioImage,
        speaker,
        blur,
        circle,
        profileImage,
        overlay,
        watermark,
        stepNumbering,
        rectangle,
        arrow,
        image,
        backgroundRectangle
    }

    // change layers position to avoid inserting them outside the bg image,
    // when BE generates image with less than 100% width/height
    return Object.entries(layersList).reduce(
        (acc, [key, value]) => {
            const exceptions = [Shape.AudioCircle, Shape.Overlay]
            // if we don't have bg image
            // OR
            // if current layer is rectangle with isBackground (fill/gradient)
            // OR
            // if current layer is inside exceptions - no need to change initial position
            if (
                !bgImageLayer ||
                (value.type === Shape.Rectangle && value.isBackground) ||
                exceptions.includes(value.type)
            ) {
                return {
                    ...acc,
                    [key]: { ...value, id: uuid() }
                }
            }

            // Otherwise, we need to update x and y points
            const x = value.x + bgImageLayer.x
            const y = value.y + bgImageLayer.y

            return {
                ...acc,
                [key]: { ...value, id: uuid(), x, y }
            }
        },
        layersList // as initial value
    )
}
