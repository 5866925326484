import { Box } from '@mui/material'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { IconButton, Typography, Icon } from '@guidde/design-system'

type Props = {
    onClose: () => void
}

export const Header = ({ onClose }: Props) => (
    <>
        <Typography variant="text" size="lg" fontWeight="semibold">
            Add to Space
        </Typography>

        <Typography variant="text" size="sm" color="textSecondary">
            Create a Space to organize your Guiddes
        </Typography>

        <Box position="absolute" top="15px" right="15px">
            <IconButton
                onClick={onClose}
                label="close"
                color="secondary"
                variant="text"
                size="small"
            >
                <Icon icon={faXmark} />
            </IconButton>
        </Box>
    </>
)
