import { useEffect } from 'react'

import { addReferralScripts, initReferralProgram, logoutReferral } from 'modules'

import { useAuth } from './use-auth'
import { useOrgFeatureFlags } from './use-feature-flags'
import { useServiceUsage } from './use-service-usage'
import { useRoles } from './use-roles'

export const useCanShowReferralProgram = () => {
    const { isViewer } = useRoles()
    const { isEnterprisePlan, isTrialPlan, isLoading: isServiceUsageLoading } = useServiceUsage()
    const { showReferralLink, isLoading } = useOrgFeatureFlags()

    if (isLoading || isServiceUsageLoading) return false

    if (!showReferralLink || isEnterprisePlan || isTrialPlan || isViewer) return false

    return true
}

export const useReferralProgram = () => {
    const { email, displayName, token } = useAuth()

    const isInitReferralProgram = useCanShowReferralProgram()

    useEffect(() => {
        addReferralScripts()
    }, [])

    useEffect(() => {
        if (!token) {
            logoutReferral()
            return
        }

        if (isInitReferralProgram) {
            initReferralProgram({ email, displayName })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitReferralProgram, token])
}
