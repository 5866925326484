import { memo, useEffect, useMemo, useState, type MouseEvent, type SyntheticEvent } from 'react'
import { useParams } from 'react-router-dom'

import {
    Box,
    Dialog,
    DialogActions,
    Divider,
    FormControlLabel,
    Checkbox,
    DialogTitle,
    DialogContent
} from '@mui/material'

import { useBoolean } from '@guidde-co/shared.hooks.use-boolean'

import { faXmark, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons'

import { LanguageSpeakers, useLangaugeSpeakers } from 'UI/Routes/quick-guidde/LeftPanel'
import { useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'

import { useServerUserPreferences, useConfirmation, useSessionStorage } from 'hooks'
import { type OptionType, option } from 'modules'

import { type SpeakerType } from 'app/types'
import VoiceoverAnalytics, { type VoiceoverSoure } from 'analytics/voiceover'
import {
    ConfirmationDialog,
    Icon,
    Button,
    IconButton,
    ThemeProvider,
    Toggle,
    Typography
} from '@guidde/design-system'
import { LanguageList } from 'UI/Routes/quick-guidde/Header/LanguageList'

export type SpeakerSelectionDialogProps = {
    isOpen: boolean
    currentSpeaker: SpeakerType
    onClose: () => void
    onSave: (
        e: MouseEvent<HTMLElement>,
        speaker: SpeakerType,
        config?: { applyToAll: boolean }
    ) => void
    source?: VoiceoverSoure
    onAudioPlay: (speaker: SpeakerType) => void
}

export const SpeakerSelectionDialog = memo(
    ({
        isOpen,
        onClose,
        onSave,
        currentSpeaker,
        source,
        onAudioPlay
    }: SpeakerSelectionDialogProps) => {
        const { playbookId } = useParams<{ playbookId?: string }>()

        const {
            languagesList,
            hasStudioVoices,
            getSpeakersList,
            getInitialSpeaker,
            getDefaultSpeakerId,
            getBrandkitSpeakerId,
            isLoading
        } = useSpeakerOptions()

        const { updateSpeakerSelection, setDefaultSpeaker } = useServerUserPreferences()
        const {
            isOpen: isApplyAllConfirmationDialogOpen,
            handleConfirm,
            handleCancel,
            confirm: applyAllConfirmation
        } = useConfirmation()

        const setAsDefault = useBoolean()
        const [isApplyAllChecked, setApplyAllState] = useSessionStorage(
            'qg_editor_voiceover_applyAll',
            source === 'voiceovert2vTab'
        )

        const [selectedLang, setSelectedLang] = useState(
            option(currentSpeaker.langCode, currentSpeaker.langName)
        )

        const speakers = useMemo(() => {
            return getSpeakersList(selectedLang.value)
        }, [getSpeakersList, selectedLang])

        const { defaultSpeakerId, brandkitSpeakerId, recentlyUsedSpeakers, restSpeakers } =
            useLangaugeSpeakers(speakers, selectedLang.value)

        const [selectedSpeaker, setSelectedSpeaker] = useState<SpeakerType | null>(currentSpeaker)

        const selectedSpeakerIsDefaultSpeaker = useMemo(
            () => selectedSpeaker?.id === getDefaultSpeakerId(selectedLang.value),
            [getDefaultSpeakerId, selectedSpeaker, selectedLang.value]
        )

        useEffect(() => {
            if (!isLoading && (!currentSpeaker || currentSpeaker.langCode !== selectedLang.value)) {
                setSelectedSpeaker(getInitialSpeaker(selectedLang.value))
            } else setSelectedSpeaker(currentSpeaker)
        }, [isLoading, selectedLang, getInitialSpeaker, currentSpeaker])

        useEffect(() => {
            setAsDefault.setFalse()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [selectedSpeaker])

        const assertApplyToAll = async () => {
            if (source === 'voiceovert2vTab' && isApplyAllChecked) return applyAllConfirmation()
            return true
        }

        const handleSave = async (e: MouseEvent<HTMLElement>) => {
            if (!selectedSpeaker) return
            const isValidSave = await assertApplyToAll()

            if (!isValidSave) return

            if (selectedSpeaker?.isStudio && !hasStudioVoices) return

            const notBrankKitSpeaker =
                selectedSpeaker.id !== getBrandkitSpeakerId(selectedSpeaker.langCode)
            const shouldUpdateSpeakerSelection =
                notBrankKitSpeaker && (source === 'voiceovert2vTab' || source === 'magicCapture')

            if (shouldUpdateSpeakerSelection) updateSpeakerSelection(selectedSpeaker)
            if (setAsDefault.isTrue) setDefaultSpeaker(selectedSpeaker)

            onSave(e, selectedSpeaker, { applyToAll: isApplyAllChecked })

            VoiceoverAnalytics.voiceoverSaveBtnClicked({
                selectedSpeaker,
                isBrandKitSpeaker: selectedSpeaker.id === brandkitSpeakerId,
                isRecentlyUsedSpeaker: !!recentlyUsedSpeakers.find(
                    speaker => speaker.id === selectedSpeaker.id
                ),
                defaultSpeakerToggleState: source && setAsDefault.isTrue,
                applyToAllSteps: isApplyAllChecked,
                source
            })

            onClose()
        }

        const handleSelectSpeaker = (_: MouseEvent<HTMLElement>, speaker: SpeakerType) => {
            setSelectedSpeaker(speaker)
        }

        const handleClickOnApplyAll = (e: SyntheticEvent<HTMLInputElement>) => {
            if (playbookId)
                VoiceoverAnalytics.voiceoverT2VTabSpeakerApplyAllChkClicked({
                    playbookId
                })

            setApplyAllState(e.currentTarget.checked)
        }

        const handleLanguageSelect = (lang: OptionType) => {
            setSelectedLang(lang)
        }

        return (
            <ThemeProvider>
                <Dialog
                    open={isOpen}
                    fullWidth
                    maxWidth="md"
                    PaperProps={{
                        style: {
                            maxWidth: 820
                        }
                    }}
                >
                    <DialogTitle borderBottom="1px solid" borderColor="grey.200">
                        <Typography variant="text" size="lg" fontWeight="semibold">
                            Select a language
                        </Typography>
                        <Box
                            sx={{
                                textAlign: 'right',
                                position: 'absolute',
                                right: 5,
                                top: 5
                            }}
                        >
                            <IconButton
                                label="close"
                                color="secondary"
                                variant="text"
                                size="small"
                                onClick={onClose}
                            >
                                <Icon icon={faXmark} size="1x" />
                            </IconButton>
                        </Box>
                    </DialogTitle>

                    <DialogContent
                        sx={{ display: 'flex', maxHeight: 500, mb: '40px', padding: '20px 0' }}
                    >
                        <LanguageList
                            languages={languagesList}
                            activeLanguage={selectedLang}
                            onActive={handleLanguageSelect}
                        />
                        <Divider orientation="vertical" flexItem />
                        <Box overflow="auto" width="100%" px={1} py={1}>
                            <LanguageSpeakers
                                onAudioPlay={onAudioPlay}
                                onSelect={handleSelectSpeaker}
                                currentSpeaker={selectedSpeaker ?? null}
                                hasStudioVoices={hasStudioVoices}
                                brandkitSpeakerId={brandkitSpeakerId}
                                defaultSpeakerId={defaultSpeakerId}
                                recentlyUsedSpeakers={recentlyUsedSpeakers}
                                restSpeakers={restSpeakers}
                                gridGap="10px 6px"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            height: '60px',
                            width: '100%',
                            justifyContent: 'flex-end',
                            padding: '16px',
                            backgroundColor: 'common.white',
                            borderTop: '1px solid',
                            borderColor: 'grey.200'
                        }}
                    >
                        {!!source && (
                            <Box marginRight="auto">
                                <Toggle
                                    label="Set as default speaker"
                                    size="small"
                                    checked={selectedSpeakerIsDefaultSpeaker || setAsDefault.isTrue}
                                    onChange={setAsDefault.toggle}
                                    disabled={selectedSpeakerIsDefaultSpeaker}
                                    disabledReason="Speaker is set as default"
                                />
                            </Box>
                        )}

                        {source === 'voiceovert2vTab' && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-test="t2s-doc-checkbox"
                                            color="primary"
                                            checked={isApplyAllChecked}
                                            id="apply-all-checkbox"
                                            size="medium"
                                            onChange={handleClickOnApplyAll}
                                            sx={{
                                                padding: '0px 4px 0px 0px'
                                            }}
                                        />
                                    }
                                    label={
                                        <Typography variant="text" size="sm">
                                            Apply Speaker to all steps
                                        </Typography>
                                    }
                                />
                                <ConfirmationDialog
                                    variant="warning"
                                    isOpen={isApplyAllChecked && isApplyAllConfirmationDialogOpen}
                                    title="Apply Speaker to all steps"
                                    onCancel={handleCancel}
                                    onConfirm={handleConfirm}
                                    text={
                                        (
                                            <>
                                                <Typography variant="text" color="grey.600">
                                                    This will apply the Speaker{' '}
                                                    {selectedSpeaker?.name} to all the steps in this
                                                    Guidde.
                                                </Typography>
                                                <Box
                                                    mt={3}
                                                    p={2}
                                                    sx={theme => ({
                                                        background: theme.palette.error[100],
                                                        border: `1px solid ${theme.palette.grey[300]}`,
                                                        borderRadius: '6px'
                                                    })}
                                                >
                                                    <Typography
                                                        variant="text"
                                                        size="sm"
                                                        fontWeight="bold"
                                                        color="grey.700"
                                                    >
                                                        Note: Click the Generate Voiceover button in
                                                        the voiceover panel to apply this setting.
                                                    </Typography>
                                                </Box>
                                            </>
                                        ) as unknown as string
                                    }
                                    confirmText="Confirm"
                                    cancelText="Cancel"
                                    icon={faExclamationTriangle}
                                />
                            </>
                        )}

                        <Box flex="0 0 15%">
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!selectedSpeaker}
                                fullWidth
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }
)
