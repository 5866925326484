import { Box, Divider, Stack } from '@mui/material'
import { Icon, Button } from '@guidde/design-system'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons'

type Props = {
    onCreateSpace: () => void
    onCancel: () => void
    onSave: () => void
    disableCreateSpace: boolean
    disableSave: boolean
}

export const FooterControlButtons = ({
    onCreateSpace,
    onCancel,
    onSave,
    disableCreateSpace,
    disableSave
}: Props) => (
    <Box width="100%" pt="4px" bgcolor="#FFFFFF">
        <Divider sx={theme => ({ borderColor: theme.palette.grey[200] })} />
        <Box mt={2} height={40} display="flex" alignItems="center" justifyContent="space-between">
            <Box ml={-1}>
                <Button
                    variant="text"
                    color="primary"
                    size="medium"
                    startIcon={<Icon icon={faCirclePlus} />}
                    onClick={onCreateSpace}
                    disabled={disableCreateSpace}
                >
                    Create a new Space
                </Button>
            </Box>
            <Stack spacing={1.5} direction="row" width={196}>
                <Button
                    variant="outlined"
                    color="secondary"
                    size="medium"
                    fullWidth
                    onClick={onCancel}
                >
                    Cancel
                </Button>
                <Button size="medium" fullWidth onClick={onSave} disabled={disableSave}>
                    Save
                </Button>
            </Stack>
        </Box>
    </Box>
)
