// Link to documentation customization example -
// https://mui.com/x/react-tree-view/simple-tree-view/customization/

import {
    forwardRef,
    memo,
    type CSSProperties,
    type HTMLAttributes,
    type ReactNode,
    type Ref
} from 'react'

import { Icon } from '@guidde/design-system'
import { Box } from '@mui/material'

import {
    unstable_useTreeItem2 as useTreeItem2,
    type UseTreeItem2Parameters
} from '@mui/x-tree-view/useTreeItem2'
import {
    TreeItem2Content,
    TreeItem2IconContainer,
    TreeItem2GroupTransition,
    TreeItem2Label,
    TreeItem2Root
} from '@mui/x-tree-view/TreeItem2'
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider'

import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons'

import { useBoolean } from 'hooks'

interface CustomTreeItemProps
    extends Omit<UseTreeItem2Parameters, 'rootRef'>,
        Omit<HTMLAttributes<HTMLLIElement>, 'onFocus'> {
    collapse?: boolean
    labelStyles?: CSSProperties
    padding?: CSSProperties['padding']
    hideActionControls?: boolean
    actionControls?: ReactNode
    isRoot?: boolean
}

export const actionId = 'block-action'
export const openOnlyId = 'open-only'

const shouldPreventClick = (e: any) => {
    if (e.target.localName === 'input') return true

    const blockById = (e.target as HTMLElement).closest(`#${actionId}`) !== null

    return blockById
}

export const CustomTreeItem = memo(
    forwardRef(function CustomTreeItem(
        {
            id,
            itemId,
            label,
            isRoot = false,
            padding = '6px 12px',
            labelStyles = {},
            hideActionControls,
            actionControls = null,
            disabled,
            children,
            collapse,
            ...props
        }: CustomTreeItemProps,
        ref: Ref<HTMLLIElement>
    ) {
        const hovered = useBoolean()

        const {
            getRootProps,
            getContentProps,
            getIconContainerProps,
            getLabelProps,
            getGroupTransitionProps,
            status,
            publicAPI
        } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref })

        const contentProps = getContentProps()

        return (
            <TreeItem2Provider itemId={itemId} onEx>
                <TreeItem2Root
                    {...getRootProps(props)}
                    sx={{
                        p: 0,
                        m: 0,
                        my: '4px'
                    }}
                >
                    <TreeItem2Content
                        {...contentProps}
                        onMouseEnter={hovered.setTrue}
                        onMouseLeave={hovered.setFalse}
                        onClick={event => {
                            const keepExistingSelection =
                                (event.target as HTMLElement).closest(`#${openOnlyId}`) !== null

                            const preventClick = shouldPreventClick(event)

                            if (keepExistingSelection && status.expanded) {
                                publicAPI.selectItem({
                                    event,
                                    itemId,
                                    keepExistingSelection: true
                                })
                                return
                            }

                            if (!preventClick) contentProps.onClick(event)
                        }}
                        sx={theme => ({
                            bgcolor: 'transparent',
                            p: 0,
                            paddingLeft: isRoot ? 0 : '13px',
                            '&:hover': {
                                background: isRoot ? 'transparent' : theme.palette.grey[50]
                            }
                        })}
                    >
                        <Box sx={{ flexGrow: 1, display: 'flex', gap: 1 }}>
                            <TreeItem2Label
                                {...getLabelProps()}
                                sx={{
                                    '& p': {
                                        ...labelStyles,
                                        wordBreak: 'break-word',
                                        color: theme => theme.palette.grey[950]
                                    },
                                    padding,
                                    '& .MuiStack-root > :not(:first-child)': {
                                        marginLeft: '12px'
                                    }
                                }}
                            />
                            {actionControls &&
                                hovered.isTrue &&
                                !hideActionControls &&
                                actionControls}
                            {!collapse && (
                                <TreeItem2IconContainer
                                    {...getIconContainerProps()}
                                    sx={theme => ({
                                        alignItems: 'center',
                                        '& svg': {
                                            width: 16,
                                            height: 16,
                                            color: theme.palette.grey[500]
                                        }
                                    })}
                                >
                                    <Icon icon={status.expanded ? faChevronUp : faChevronDown} />
                                </TreeItem2IconContainer>
                            )}
                        </Box>
                    </TreeItem2Content>
                    {children && <TreeItem2GroupTransition {...getGroupTransitionProps()} />}
                </TreeItem2Root>
            </TreeItem2Provider>
        )
    })
)
