import { useCallback, useMemo } from 'react'

import { useQuery, useDataMutation } from 'hooks'
import { type SpeakerType } from 'app/types'

const requestURL = '/c/v1/user-preferences'
const updateDefaultSpeakerURL = '/c/v1/user-preferences/default-speaker'
const postSpeakerSelectionURL = '/c/v1/user-preferences/speaker-selection'

type TUserPreferences = {
    // #TODO: Delete this type as soon as the API is ready https://guidde.atlassian.net/browse/GD-10421
    lastSpeakerIdUsed?: string
    speaker?: TSpeaker
}

type TUserLangaugeSpeaker = {
    defaultSpeakerId: string
    recentlyUsedSpeakerIds: string[]
}

type TSpeaker = {
    lastLanguageUsed: string
    languages: Record<string, TUserLangaugeSpeaker>
}

type TUpdateDefaultSpeakerPayload = {
    langCode: string
    speakerId: string
}

type TUpdateSpeakerSelectionPayload = {
    selectedSpeaker: SpeakerType
}

export const useServerUserPreferences = () => {
    const $serverUserPreferences = useQuery<TUserPreferences>(requestURL, { method: 'GET' })

    const $setDefaultSpeaker = useDataMutation<
        TUpdateDefaultSpeakerPayload,
        TUserPreferences,
        Error
    >(updateDefaultSpeakerURL, 'POST', {
        onSuccess: () => $serverUserPreferences.mutate()
    })

    const $updateSpeakerSelection = useDataMutation<
        TUpdateSpeakerSelectionPayload,
        TUserPreferences,
        Error
    >(postSpeakerSelectionURL, 'POST', {
        onSuccess: () => $serverUserPreferences.mutate()
    })

    const setDefaultSpeaker = useCallback(
        (speaker: SpeakerType) => {
            return $setDefaultSpeaker.mutate({
                speakerId: speaker.id,
                langCode: speaker.langCode
            })
        },
        [$setDefaultSpeaker]
    )

    const updateSpeakerSelection = useCallback(
        (speaker: SpeakerType) => {
            return $updateSpeakerSelection.mutate({
                selectedSpeaker: speaker
            })
        },
        [$updateSpeakerSelection]
    )

    return useMemo(
        () => ({
            isServerUserPreferencesLoading: $serverUserPreferences.isValidating,
            serverUserPreferences: $serverUserPreferences.data || ({} as TUserPreferences),
            setDefaultSpeaker,
            updateSpeakerSelection
        }),
        [
            $serverUserPreferences.isValidating,
            $serverUserPreferences.data,
            setDefaultSpeaker,
            updateSpeakerSelection
        ]
    )
}
