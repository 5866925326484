import { memo, useContext } from 'react'

import { ClickAwayListener, Stack } from '@mui/material'

import { Tooltip, Icon, IconButton } from '@guidde/design-system'
import { faCirclePlus } from '@fortawesome/pro-regular-svg-icons/faCirclePlus'
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan'

import Highlighter from 'react-highlight-words'

import { CaptionText } from './CaptionText'
import { CaptionTime } from './CaptionTime'
import { CaptionsEditorContext } from './CaptionsEditorProvider'

import { type SubtitleType } from 'app/types'
import { useBoolean } from 'hooks'

type Props = {
    blockId: string
    timeDelta: number
    caption?: SubtitleType
    isInitiallyActive: boolean
    onClick: () => void
    onUpdate: (newProperties: Partial<SubtitleType>) => void
    onAdd: () => void
    onDelete: () => void
}

export const CaptionBlock = memo(
    ({
        blockId,
        isInitiallyActive,
        caption,
        timeDelta,
        onClick,
        onUpdate,
        onAdd,
        onDelete
    }: Props) => {
        const hover = useBoolean()

        const active = useBoolean(isInitiallyActive)

        const { debouncedQuery, currentHighlight, matchSelectors } =
            useContext(CaptionsEditorContext)

        return (
            <ClickAwayListener
                onClickAway={() => {
                    if (active.isFalse) return
                    active.setFalse()
                }}
            >
                <Stack
                    id={blockId}
                    className={caption?.textError || caption?.timeError ? 'caption-error' : ''}
                    spacing={2}
                    p={2}
                    pl={2.5}
                    pr={1.5}
                    sx={theme => ({
                        borderBottom: `1px solid ${theme.palette.grey['200']}`,
                        cursor: 'pointer',
                        fontSize: 14,
                        color: theme.palette.grey['600'],
                        '& *': {
                            lineHeight: '20px',
                            fontFamily: 'Inter, sans-serif !important'
                        }
                    })}
                    position="relative"
                    onClick={onClick}
                    onMouseEnter={hover.setTrue}
                    onMouseLeave={hover.setFalse}
                >
                    <CaptionText
                        isActive={active.isTrue}
                        value={caption?.text || ''}
                        error={caption?.textError}
                        onUpdate={text => onUpdate({ text })}
                        onFocus={active.setTrue}
                        placeholder={
                            debouncedQuery ? (
                                <Highlighter
                                    searchWords={[debouncedQuery]}
                                    autoEscape={true}
                                    textToHighlight={caption?.text || ''}
                                    highlightTag={({ highlightIndex, children }) => {
                                        const id = `match-${blockId}-${highlightIndex}`
                                        const isActive = matchSelectors[currentHighlight] === id

                                        return (
                                            <mark
                                                id={id}
                                                style={{
                                                    background: isActive ? 'orange' : 'yellow'
                                                }}
                                            >
                                                {children}
                                            </mark>
                                        )
                                    }}
                                />
                            ) : (
                                caption?.text
                            )
                        }
                    />

                    {caption && (
                        <>
                            <CaptionTime
                                isEditable={hover.isTrue || active.isTrue}
                                isActive={active.isTrue}
                                start={timeDelta + caption.start}
                                end={timeDelta + caption.end}
                                error={caption.timeError}
                                onClick={active.setTrue}
                                onTimeUpdate={({ start, end }) => {
                                    const newStart =
                                        start !== undefined ? { start: start - timeDelta } : {}
                                    const newEnd = end !== undefined ? { end: end - timeDelta } : {}

                                    onUpdate({ ...newStart, ...newEnd })
                                }}
                            />
                            {hover.isTrue && (
                                <Stack
                                    direction="row"
                                    spacing={0}
                                    alignItems="center"
                                    position="absolute"
                                    bottom="100%"
                                    right={16}
                                    zIndex={10}
                                    bgcolor="white"
                                    p={0.5}
                                    sx={theme => ({
                                        cursor: 'default',
                                        color: 'black',
                                        transform: 'translateY(10px)',
                                        boxShadow: theme.shadows[1]
                                    })}
                                    borderRadius={1}
                                    onClick={() => {
                                        active.setFalse()
                                        hover.setFalse()
                                    }}
                                >
                                    <Tooltip title="Add Captions" placement="top">
                                        <IconButton
                                            variant="text"
                                            label="add"
                                            color="secondary"
                                            size="small"
                                            onClick={onAdd}
                                        >
                                            <Icon icon={faCirclePlus} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete Captions" placement="top">
                                        <IconButton
                                            variant="text"
                                            label="delete"
                                            color="secondary"
                                            size="small"
                                            onClick={onDelete}
                                        >
                                            <Icon icon={faTrashCan} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                        </>
                    )}
                </Stack>
            </ClickAwayListener>
        )
    }
)
