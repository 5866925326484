import { useMemo } from 'react'
import { Stack } from '@mui/material'

import { Button, Icon, Modal, spacings, ThemeProvider, Typography } from '@guidde/design-system'
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'

import { links } from 'app/links'
import { getRoleName, useAuth, useServiceUsage } from 'hooks'
import { logToAnalytics, openLink } from 'modules'

export enum DialogType {
    enterprise = 'enterprise',
    paidAdmin = 'paidAdmin',
    paidOther = 'paidOther',
    trialEnded = 'trialEnded'
}

const desktopAppDescriptionList = [
    'Record your desktop applications',
    'Available on macOS and Windows',
    'Seamless experience with the existing Guidde platform'
]

type Props = {
    variant: DialogType
    closeDialog: () => void
    source: string
    startTrial: () => void
}

export const DialogEnableDesktopFeature = ({ variant, closeDialog, source, startTrial }: Props) => {
    const { planName } = useServiceUsage()
    const { roles } = useAuth()

    const dialogVariants = useMemo(() => {
        return {
            [DialogType.enterprise]: {
                description:
                    'Please contact your Customer Success Manager to enable desktop capture for your account',
                cta: 'Close',
                action: closeDialog
            },
            [DialogType.paidAdmin]: {
                description: 'Desktop capture is now available as an add-on to our Enterprise plan',
                cta: 'Try it now',
                action: () => {
                    startTrial()
                    closeDialog()
                }
            },
            [DialogType.paidOther]: {
                description: 'Please contact your admin to enable Desktop capture in your account',
                cta: 'Close',
                action: closeDialog
            },
            [DialogType.trialEnded]: {
                description:
                    'Please contact our Sales team to enable desktop capture for your account',
                cta: 'Contact Sales',
                action: () => {
                    openLink(links.contactSales)
                    closeDialog()
                }
            }
        }
    }, [closeDialog, startTrial])

    const dialogProps = dialogVariants[variant]

    return (
        <ThemeProvider>
            <Modal isOpen onClose={closeDialog} useDefaultCloseButton={false} size="sm">
                <Stack spacing={spacings.xl} sx={{ p: 2, alignItems: 'start' }}>
                    <Stack spacing={spacings.md}>
                        <Typography size="xl" variant="text" fontWeight="semibold">
                            Desktop capture
                        </Typography>
                        <Stack maxWidth={312}>
                            <Typography size="xs" variant="text" color="rgba(9, 12, 16, 0.60)">
                                {dialogProps.description}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack spacing={1.75}>
                        {desktopAppDescriptionList.map(item => (
                            <Stack
                                direction="row"
                                key={item}
                                spacing={spacings.md}
                                alignItems="center"
                            >
                                <Icon icon={faCircleCheck} color="#194185" />
                                <Typography size="xs" variant="text">
                                    {item}
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                    <Button
                        size="sm"
                        onClick={() => {
                            dialogProps.action()
                            logToAnalytics('desktopApp_ctaBtn_clicked', {
                                source,
                                plan: planName,
                                role: getRoleName(roles.a)
                            })
                        }}
                    >
                        {dialogProps.cta}
                    </Button>
                </Stack>
            </Modal>
        </ThemeProvider>
    )
}
