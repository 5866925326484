import { ButtonBase, Stack } from '@mui/material'

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'

import { useCanShowReferralProgram } from 'hooks'
import { logToAnalytics, toggleReferralState } from 'modules'

import { DrawerItem } from './DrawerItem'

// Needed for dynamic placement in admin console
const REFERRAL_BUTTON_ID = 'celloMenuCustomLauncherButton'

export const MenuReferralButton = () => {
    const isShowReferralButton = useCanShowReferralProgram()

    if (!isShowReferralButton) return null

    return (
        <Stack position="relative">
            <ButtonBase
                onClick={() => {
                    toggleReferralState('open')
                    logToAnalytics('cello_widget_clicked', { source: 'inviteEarn' })
                }}
                id={REFERRAL_BUTTON_ID}
            >
                <DrawerItem
                    isActive={false}
                    title="Refer & Earn"
                    startIcon={<CardGiftcardIcon color="primary" />}
                    color="common.black"
                />
            </ButtonBase>
        </Stack>
    )
}
