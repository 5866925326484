import { captureException } from '@sentry/react'

import { entailApiKey } from 'env'
import { isDev } from 'modules'

export enum EntailEvent {
    signUp = 'signup',
    installedExtension = 'installed-extension'
}

export const sendEntailEvent = (eventName: EntailEvent) => {
    if (isDev) return

    try {
        // @ts-ignore
        !(function (t, e) {
            var o, n, p, r
            e.__SV ||
                ((window.entailInsights = e),
                (e._i = []),
                // @ts-ignore
                (e.init = function (i, s, a) {
                    // @ts-ignore
                    function g(t, e) {
                        var o = e.split('.')
                        2 == o.length && ((t = t[o[0]]), (e = o[1])),
                            (t[e] = function () {
                                t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
                            })
                    }
                    ;((p = t.createElement('script')).type = 'text/javascript'),
                        (p.async = !0),
                        (p.src = 'https://cdn.entail-insights.com/js/entail.js'),
                        // @ts-ignore
                        (r = t.getElementsByTagName('script')[0]).parentNode.insertBefore(p, r)
                    var u = e
                    for (
                        void 0 !== a ? (u = e[a] = []) : (a = 'entailInsights'),
                            // @ts-ignore
                            u.toString = function (t) {
                                var e = 'entailInsights'
                                return (
                                    'entailInsights' !== a && (e += '.' + a),
                                    t || (e += ' (stub)'),
                                    e
                                )
                            },
                            o =
                                'trackEvent capture opt_out_capturing has_opted_out_capturing opt_in_capturing reset'.split(
                                    ' '
                                ),
                            n = 0;
                        n < o.length;
                        n++
                    )
                        g(u, o[n])
                    e._i.push([i, s, a])
                }),
                (e.__SV = 1))
        })(document, window.entailInsights || [])

        window.entailInsights.init(entailApiKey, {
            disable_decide: true,
            autocapture: false,
            widgets: false,
            popups: false,
            capture_pageview: false,
            campaigns: false
        })
        window.entailInsights.trackEvent(eventName, {})
    } catch (error) {
        captureException(new Error('[Entail] failed to send event'), { data: { error } })
    }
}
