import { type MutableRefObject } from 'react'
import { ActionCreators } from 'redux-undo'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import type Konva from 'konva'

import { Box, Button, Divider, IconButton, Tooltip } from '@mui/material'
import ClosedCaptionOffIcon from '@mui/icons-material/ClosedCaptionOff'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'

import { ReactComponent as MotionIcon } from 'assets/icons/motion.svg'
import { ReactComponent as VoiceoverIcon } from 'assets/icons/voiceover.svg'

import { SpacedGroup } from 'UI/Components'

import { AddNewLayer } from './AddNewLayer'
import { BackgroundSelection } from './BackgroundSelection'

import { FloatingActions } from './FloatingActions'
import { RemoveWaterMark } from './RemoveWaterMark'
import { ZoomBackgroundImage } from './ZoomBackgroundImage'
import { ContextMenu } from './ContextMenu'
import { EditVideo } from './EditVideo'
import { AddNewStep } from './AddNewStep'

import { logToAnalytics } from 'modules'
import { setSidePanelMode } from 'ducks'
import { useGlobalFeatureFlags } from 'hooks'

import { paths } from 'app/paths'
import { type QuickGuiddeType, Shape } from 'app/types'

type Props = {
    stageRef: MutableRefObject<Konva.Stage | null>
    quickGuidde: QuickGuiddeType
}

export const ControlPanel = ({ stageRef, quickGuidde }: Props) => {
    const dispatch = useDispatch()

    const isBrandKitPage = useRouteMatch([paths.brandKitDetails, paths.brandKit])?.isExact

    const {
        past,
        future,
        present: { steps, activeStep },
        index = 0
    } = useSelector(state => state.qgEditor)

    const isVideoStep = steps[activeStep].layers.some(layer => layer.type === Shape.Video)

    const { enableVideoTrim, enableCaptionsEditor, enableManualSplit } = useGlobalFeatureFlags()

    const showCaptionsButton = enableCaptionsEditor && !isBrandKitPage
    const showVideoEdit = (enableVideoTrim || enableManualSplit) && !isBrandKitPage && isVideoStep

    if (!steps[activeStep]) return null

    return (
        <>
            <Box
                px={2}
                height="50px"
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ background: '#fafafa' }}
            >
                <SpacedGroup spacing={1}>
                    <IconButton
                        onClick={() => {
                            dispatch(ActionCreators.undo())
                            logToAnalytics('qg-undo-clicked')
                        }}
                        disabled={!past[index - 1] || past[index - 1]?.steps?.length === 0} // prev step is empty
                        size="small"
                    >
                        <Tooltip title="Undo">
                            <UndoIcon />
                        </Tooltip>
                    </IconButton>

                    <IconButton
                        onClick={() => {
                            dispatch(ActionCreators.redo())
                            logToAnalytics('qg-redo-clicked')
                        }}
                        disabled={future.length === 0}
                        size="small"
                    >
                        <Tooltip title="Redo">
                            <RedoIcon />
                        </Tooltip>
                    </IconButton>

                    <AddNewLayer />
                    <BackgroundSelection quickGuidde={quickGuidde} />

                    {!isBrandKitPage && (
                        <Button
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                dispatch(setSidePanelMode('voiceOver'))
                                logToAnalytics('editor_voiceOverBtn_clicked', {
                                    playbookId: quickGuidde.id
                                })
                            }}
                            style={{ minWidth: 30 }}
                            startIcon={<VoiceoverIcon width={24} height={24} />}
                        >
                            Voiceover
                        </Button>
                    )}

                    {!isBrandKitPage && (
                        <Button
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                dispatch(setSidePanelMode('motion'))
                                logToAnalytics('editor_motionBtn_clicked', {
                                    playbookId: quickGuidde.id
                                })
                            }}
                            style={{ minWidth: 30 }}
                            startIcon={<MotionIcon width={24} height={24} />}
                        >
                            Motion
                        </Button>
                    )}
                    <ZoomBackgroundImage step={steps[activeStep]} />

                    {showVideoEdit && <EditVideo language={quickGuidde.language} />}

                    {showCaptionsButton && (
                        <Button
                            variant="text"
                            color="inherit"
                            onClick={() => {
                                dispatch(setSidePanelMode('captions'))
                                logToAnalytics('editor_subtitlesBtn_clicked', {
                                    playbookId: quickGuidde.id
                                })
                            }}
                            style={{ minWidth: 30 }}
                            startIcon={<ClosedCaptionOffIcon width={24} height={24} />}
                        >
                            Captions
                        </Button>
                    )}
                </SpacedGroup>

                <FloatingActions stageRef={stageRef} />
                <ContextMenu stageRef={stageRef} />

                <SpacedGroup spacing={1}>
                    <RemoveWaterMark />
                    <AddNewStep quickGuidde={quickGuidde} />
                </SpacedGroup>
            </Box>

            <Divider />
        </>
    )
}
