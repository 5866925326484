import { useEffect, useMemo, useState } from 'react'

import * as yup from 'yup'
import { Form, Formik } from 'formik'

import {
    Avatar,
    Box,
    FormHelperText,
    Typography,
    Tooltip as MuiTooltip,
    Divider,
    styled,
    Stack
} from '@mui/material'

import PeopleAltIcon from '@mui/icons-material/LockOutlined'
import PublicIcon from '@mui/icons-material/PublicOutlined'

import { ReactComponent as TeamIcon } from 'assets/icons/team-icon.svg'
import { ReactComponent as PrivacyEyeIcon } from 'assets/icons/privacy-eye.svg'
import { ReactComponent as PrivacyEyeWithLockIcon } from 'assets/icons/privacy-eye-with-lock.svg'

import { ConnectedAutocomplete, ConnectedFreeSoloInput, ConnectedInput } from 'UI/Components'
import { FancyDropdown } from './FancyDropdown'

import {
    useDataMutation,
    useAuth,
    useOrganization,
    ORG_SHARE_OPTIONS,
    useServiceUsage,
    useOrgFeatureFlags,
    useSpaces
} from 'hooks'
import { type OptionType, privateDomains, option, logToAnalytics } from 'modules'

import { PRIVACY_SETTINGS_OPTIONS } from 'app/types'
import { ThemeProvider, Toggle, Tooltip, Button, Icon } from '@guidde/design-system'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons/faArrowsRotate'

const SpaceLogo = styled(Avatar)(({ theme }) => ({
    background: '#1976d2',
    width: '32px',
    height: '32px',
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(13)
}))

const SectionTitle = styled(Typography)({
    fontSize: '14px',
    fontWeight: 500,
    color: '#090C10',
    marginBottom: '8px'
})

const Caption = styled(Typography)({
    fontSize: '14px',
    fontWeight: 400,
    color: '#090C10'
})

type FormValuesType = {
    name: string
    isPosterInEmbedON: boolean
    domains: Array<string>
    defaultSpaces: Array<OptionType>
    defaultSharingSettings: keyof typeof ORG_SHARE_OPTIONS
    allowSsoOnly?: boolean
    privacySettings?: keyof typeof PRIVACY_SETTINGS_OPTIONS
}

type SubmitValuesType = Omit<FormValuesType, 'defaultSpaces'> & {
    defaultSpaces: Array<string>
}

type Props = {
    onSubmit: (fieldsData: SubmitValuesType) => void
    disabled?: boolean
    orgId: string
}

const privacySettingOptions = [
    {
        icon: (
            <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                <PrivacyEyeIcon />
            </Avatar>
        ),
        value: PRIVACY_SETTINGS_OPTIONS.internal,
        label: 'Internal',
        caption: 'Anyone with your company domain can join'
    },
    {
        icon: (
            <Avatar sx={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                <PrivacyEyeWithLockIcon />
            </Avatar>
        ),
        value: PRIVACY_SETTINGS_OPTIONS.restricted,
        label: 'Restricted',
        caption: 'Only invited members can join the team'
    }
]

const getPbPublicityOptions = ({
    orgName,
    privateAccount,
    disableExternalSharing
}: {
    orgName: string
    privateAccount: boolean
    disableExternalSharing?: boolean
}) => {
    return [
        {
            icon: (
                <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                    <PublicIcon />
                </Avatar>
            ),
            value: ORG_SHARE_OPTIONS.public,
            label: 'Public',
            caption: 'Anyone with the link can view',
            disabled: disableExternalSharing,
            disabledTooltipMessage: 'Please contact your admin for more info'
        },
        {
            icon: (
                <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                    <PeopleAltIcon />
                </Avatar>
            ),
            value: ORG_SHARE_OPTIONS.private,
            label: 'Private',
            caption: 'Only members and spaces added can open this link'
        },
        ...(privateAccount
            ? []
            : [
                  {
                      icon: (
                          <Avatar style={{ backgroundColor: '#F5F5F5', color: '#090C10' }}>
                              <TeamIcon />
                          </Avatar>
                      ),
                      value: ORG_SHARE_OPTIONS.anyoneInOrg,
                      label: `Anyone in ${orgName}`,
                      caption: 'All members in this account can view'
                  }
              ])
    ]
}

const generateSsoError = (domains: Array<string>) => {
    if (!domains.length) return ''

    return `Domain${domains.length > 1 ? 's' : ''} ${domains.join(', ')} do not support SSO login`
}

export const OrganizationForm = ({ orgId, onSubmit, disabled }: Props) => {
    const { isPrivateAccount, uid } = useAuth()
    const { hasPrivacyControl, allowSsoOnly: serviceUsageAllowSSO } = useServiceUsage()

    const [existingDomains, setExistingDomains] = useState<FormValuesType['domains']>([])
    const { orgSpaces, isLoading } = useSpaces()
    const { disableExternalSharing } = useOrgFeatureFlags()

    const {
        name,
        domains,
        defaultSpaces = [],
        defaultSharingSettings,
        allowSsoOnly,
        isPosterInEmbedON,
        isDomainEditNotAllowed,
        privacySettings
    } = useOrganization(orgId)

    const $validateSsoDomains = useDataMutation<
        { domains: Array<string> },
        { notConnectedSsoDomains: Array<string>; forbiddenSsoDomains: Array<string> },
        Error
    >('/c/v1/check-org-domains', 'POST')

    const validateSsoDomains = $validateSsoDomains.mutate

    const doNotValidateSSoDomains =
        !serviceUsageAllowSSO ||
        !allowSsoOnly ||
        isPrivateAccount ||
        domains.length < 1 ||
        $validateSsoDomains.data

    useEffect(() => {
        if (doNotValidateSSoDomains) return

        validateSsoDomains({ domains })
    }, [domains, doNotValidateSSoDomains, validateSsoDomains])

    const onDone = async (values: FormValuesType) => {
        onSubmit({
            ...values,
            domains: values.domains.map(it => it.toLowerCase()),
            defaultSpaces: values.defaultSpaces?.map(({ value }) => value) || []
        })
    }

    const spaceOptions = useMemo(() => {
        return orgSpaces
            .filter(({ isPrivate }) => !isPrivate)
            .map(({ name, id }) => option(id, name))
            .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
    }, [orgSpaces])

    const invalidDomains = [
        ...($validateSsoDomains.data?.notConnectedSsoDomains || []),
        ...($validateSsoDomains.data?.forbiddenSsoDomains || [])
    ].filter(Boolean)

    const ssoToggleTooltip = generateSsoError(invalidDomains)

    return (
        <Box mr={3} mt={3}>
            <Formik
                enableReinitialize={true}
                onSubmit={values => {
                    if (!values) return

                    const { allowSsoOnly, ...restValues } = values

                    onDone({
                        ...(serviceUsageAllowSSO && { allowSsoOnly }),
                        ...restValues
                    })
                }}
                initialValues={{
                    name,
                    domains,
                    isPosterInEmbedON,
                    defaultSharingSettings,
                    allowSsoOnly,
                    privacySettings,
                    defaultSpaces: isLoading
                        ? []
                        : defaultSpaces.map(spaceId => {
                              const space = orgSpaces.find(({ id }) => spaceId === id)

                              return option(spaceId, space?.name || '')
                          })
                }}
                validationSchema={yup.object().shape({
                    name: yup.string().trim().nullable().required('Field is required'),
                    ...(!isPrivateAccount && {
                        domains: yup
                            .array()
                            .min(1, 'Field is required')
                            .of(
                                yup
                                    .string()
                                    .trim()
                                    .test('isAllowedDomain', '', (value, { createError }) => {
                                        const invalid = privateDomains.includes(
                                            value?.toLowerCase() || ''
                                        )

                                        if (invalid) {
                                            return createError({
                                                message: `${value} can't be added`,
                                                path: 'domains'
                                            })
                                        }

                                        return true
                                    })
                            )
                    })
                })}
            >
                {({ values, dirty, setFieldValue }) => (
                    <Form>
                        <Stack direction="column" spacing={2}>
                            <ConnectedInput
                                fullWidth
                                label="Account Name"
                                name="name"
                                placeholder="Enter account name"
                                disableUnderline
                                InputLabelProps={{
                                    style: {
                                        fontSize: '18px',
                                        fontWeight: 500,
                                        color: '#090C10'
                                    }
                                }}
                            />

                            <Divider light={true} />

                            {!isPrivateAccount && (
                                <Box>
                                    <ConnectedFreeSoloInput
                                        fullWidth
                                        variant="standard"
                                        label="Account Email Domains"
                                        name="domains"
                                        placeholder={
                                            !values?.domains?.length ? 'Enter domains' : ''
                                        }
                                        onChange={(_, newDomains) => {
                                            setExistingDomains([])
                                            setFieldValue('domains', newDomains)

                                            if (!values.allowSsoOnly) return

                                            const domainValues = newDomains.map(it => {
                                                return typeof it === 'string' ? it : it.value
                                            })

                                            validateSsoDomains({
                                                domains: domainValues
                                            })
                                        }}
                                        disabled={isDomainEditNotAllowed}
                                        disableUnderline
                                        InputLabelProps={{
                                            style: {
                                                fontSize: '18px',
                                                fontWeight: 500,
                                                color: '#090C10'
                                            }
                                        }}
                                    />

                                    {ssoToggleTooltip && values.allowSsoOnly && (
                                        <FormHelperText error>{ssoToggleTooltip}</FormHelperText>
                                    )}

                                    {existingDomains.length > 0 && (
                                        <FormHelperText error>
                                            {`${existingDomains.join(', ')} ${
                                                existingDomains.length > 1 ? 'are' : 'is'
                                            } already in use`}
                                        </FormHelperText>
                                    )}
                                    <Divider light={true} />
                                </Box>
                            )}

                            <Box>
                                <SectionTitle>Account Default Spaces</SectionTitle>

                                <Caption>Spaces that members will automatically join</Caption>
                                <Box
                                    mt={1.2}
                                    sx={{
                                        '&::placeholder': {
                                            fontSize: 12
                                        }
                                    }}
                                >
                                    <ConnectedAutocomplete
                                        fullWidth
                                        multiple
                                        placeholder="Select spaces"
                                        variant="standard"
                                        name="defaultSpaces"
                                        filterSelectedOptions={false}
                                        disableUnderline
                                        options={spaceOptions}
                                        renderOption={(props, { label, value: spaceId }) => {
                                            const space = orgSpaces.find(({ id }) => id === spaceId)
                                            if (!space) return

                                            return (
                                                // @ts-ignore
                                                <Box {...props}>
                                                    <SpaceLogo src="" variant="circular">
                                                        {label.slice(0, 2).toUpperCase()}
                                                    </SpaceLogo>
                                                    <Typography variant="body2">
                                                        <b>{label}</b>
                                                    </Typography>
                                                </Box>
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>

                            <Divider light={true} />

                            <Box style={{ opacity: hasPrivacyControl ? 1 : 0.6 }} mb={2}>
                                <SectionTitle>Sharing settings</SectionTitle>
                                <Caption>
                                    Default sharing settings for all guides in your account.
                                </Caption>

                                <Box mt={1.2} mb={3}>
                                    <MuiTooltip
                                        arrow
                                        title={
                                            hasPrivacyControl
                                                ? ''
                                                : 'Available on the Business Plan'
                                        }
                                        placement="right"
                                    >
                                        <Box mt={1}>
                                            <FancyDropdown
                                                options={getPbPublicityOptions({
                                                    orgName: name,
                                                    privateAccount: isPrivateAccount,
                                                    disableExternalSharing
                                                })}
                                                name="defaultSharingSettings"
                                                disabled={!hasPrivacyControl}
                                            />
                                        </Box>
                                    </MuiTooltip>
                                </Box>
                            </Box>

                            <Divider light={true} />

                            <Box my={2}>
                                <SectionTitle>Privacy settings</SectionTitle>
                                <Caption>Control who can join your account</Caption>
                                <Box my={1}>
                                    <FancyDropdown
                                        options={privacySettingOptions}
                                        name="privacySettings"
                                        onChange={value => {
                                            logToAnalytics(
                                                'teamDiscovery_privacySettings_selected',
                                                {
                                                    teamName: name,
                                                    teamId: orgId,
                                                    uid,
                                                    privacyOptionSelected: value
                                                }
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Stack>

                        <ThemeProvider>
                            {serviceUsageAllowSSO && (
                                <>
                                    <Box my={2}>
                                        <Divider light={true} />
                                    </Box>
                                    <Box
                                        sx={{
                                            '& > div': {
                                                justifyContent: 'flex-start'
                                            }
                                        }}
                                    >
                                        <Tooltip
                                            title={invalidDomains.length ? ssoToggleTooltip : ''}
                                        >
                                            <Toggle
                                                size={'small' as const}
                                                checked={values.allowSsoOnly}
                                                onChange={(_: any, value: boolean) => {
                                                    setFieldValue('allowSsoOnly', value)

                                                    if (value) {
                                                        validateSsoDomains({
                                                            domains: values.domains
                                                        })
                                                    }
                                                }}
                                                label="Security Settings"
                                                description="Allow Single Sign-On (SSO) only"
                                            />
                                        </Tooltip>
                                    </Box>
                                </>
                            )}

                            <Box my={2}>
                                <Divider light={true} />
                            </Box>

                            <Toggle
                                size={'small' as const}
                                checked={values.isPosterInEmbedON}
                                onChange={(e: any, value: boolean) =>
                                    setFieldValue('isPosterInEmbedON', value)
                                }
                                label="Embed Settings"
                                description="Display video thumbnail GIF"
                            />
                            <Box mt={6}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={<Icon icon={faArrowsRotate} />}
                                    disabled={
                                        disabled ||
                                        !dirty ||
                                        (values.allowSsoOnly &&
                                            (invalidDomains.length > 0 ||
                                                existingDomains.length > 0))
                                    }
                                >
                                    Update
                                </Button>
                            </Box>
                        </ThemeProvider>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}
