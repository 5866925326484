import { useCallback, useState, type ReactNode } from 'react'

import { links } from 'app/links'
import {
    getRoleName,
    useAuth,
    useDataMutation,
    useOrganization,
    useOrgFeatureFlags,
    useRoles,
    useServiceUsage
} from 'hooks'
import { logToAnalytics, openLink } from 'modules'

import { DialogEnableDesktopFeature, DialogType } from './DialogEnableDesktopFeature'

const MIN_COMPANY_SIZE = 100

const parseCompanySize = (companySize: string) => {
    const companySizeRemovedCommas = companySize.replaceAll(',', '')
    const companySizeStartsFrom = parseFloat(companySizeRemovedCommas)

    return companySizeStartsFrom
}

type Props = {
    source: string
    children: (handleDownloadDesktopAppClick: () => void) => ReactNode
}

export const DownloadDesktopAppProvider = ({ children, source }: Props) => {
    const { isLoading: areFeatureFlagsLoading, hideDesktopAppDownload } = useOrgFeatureFlags()
    const {
        hasDesktopApp,
        isLoading: isServiceUsageLoading,
        isFreePlan,
        isEnterprisePlan,
        desktopAppTrialStartedAt,
        planName,
        isTrialPlan
    } = useServiceUsage()
    const { isAdmin, isViewer } = useRoles()
    const { roles } = useAuth()

    const { companySize } = useOrganization()

    const [dialogEnableFeatureVariant, setDialogEnableFeatureVariant] = useState<DialogType | null>(
        null
    )

    const $startTrial = useDataMutation('/c/v1/start-desktop-app-trial', 'POST')
    const mutateStartTrial = $startTrial.mutate
    const startTrial = useCallback(() => {
        logToAnalytics('desktopApp_trial_triggered', {
            source,
            plan: planName
        })
        mutateStartTrial(undefined)
        openLink(links.downloadDesktopApp)
    }, [mutateStartTrial, planName, source])

    const handleDownloadDesktopAppClick = useCallback(() => {
        logToAnalytics('desktopApp_downloadBtn_clicked', {
            source,
            plan: planName,
            role: getRoleName(roles.a)
        })

        if (hasDesktopApp) return openLink(links.downloadDesktopApp)

        if (isEnterprisePlan) return setDialogEnableFeatureVariant(DialogType.enterprise)

        const isTrialEnded = !hasDesktopApp && desktopAppTrialStartedAt
        if (isTrialEnded) return setDialogEnableFeatureVariant(DialogType.trialEnded)

        if (isTrialPlan) return startTrial()

        if (isAdmin) return setDialogEnableFeatureVariant(DialogType.paidAdmin)
        setDialogEnableFeatureVariant(DialogType.paidOther)
    }, [
        desktopAppTrialStartedAt,
        hasDesktopApp,
        isAdmin,
        isEnterprisePlan,
        isTrialPlan,
        planName,
        roles.a,
        source,
        startTrial
    ])

    const isLoading = isServiceUsageLoading || areFeatureFlagsLoading
    const isDesktopDownloadRestricted = hideDesktopAppDownload || isViewer
    if (isLoading || isDesktopDownloadRestricted) return null

    const isRequiredCompanySize = companySize && parseCompanySize(companySize) >= MIN_COMPANY_SIZE
    const isRequiredSelfServePlan = !isFreePlan && isRequiredCompanySize
    const isShowDownloadButton = hasDesktopApp || isEnterprisePlan || isRequiredSelfServePlan
    if (!isShowDownloadButton) return null

    return (
        <>
            {dialogEnableFeatureVariant !== null && (
                <DialogEnableDesktopFeature
                    variant={dialogEnableFeatureVariant}
                    closeDialog={() => setDialogEnableFeatureVariant(null)}
                    source={source}
                    startTrial={startTrial}
                />
            )}
            {children(handleDownloadDesktopAppClick)}
        </>
    )
}
