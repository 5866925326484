import { useSelector } from 'react-redux'

import { Box, Stack, Typography } from '@mui/material'

import noVideo from 'assets/images/noVideo.png'

import { DesktopAppBanner, NewPlaybook, SpacedGroup } from 'UI/Components'
import { useWindowView } from 'hooks'

const styles = {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: 'rgba(9, 12, 16, 0.6)'
}

type NoVideoEmptyStateProps = {
    title: string
    buttonTitle: string
    isShowDesktopBanner?: boolean
}

export const NoVideoEmptyState = ({
    title,
    buttonTitle,
    isShowDesktopBanner
}: NoVideoEmptyStateProps) => {
    const { isMobileView } = useWindowView()
    const isExtensionInstalled = useSelector(state => state.extensionStatus.isInstalled)

    return (
        <Stack>
            {isShowDesktopBanner && <DesktopAppBanner />}
            <SpacedGroup flexDirection="column" style={styles}>
                <Box mt={2} py={4} px={5} display="flex" alignItems="center" flexDirection="column">
                    <img src={noVideo} width={270} />
                    <Box my={5}>
                        <Typography style={styles} align="center">
                            {title}
                        </Typography>
                    </Box>
                    {!isMobileView && (
                        <NewPlaybook
                            label={buttonTitle}
                            isExtensionInstalled={isExtensionInstalled}
                        />
                    )}
                </Box>
            </SpacedGroup>
        </Stack>
    )
}
