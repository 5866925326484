import { useMemo, useRef } from 'react'

import { Box, Stack } from '@mui/material'

import { useBoolean, useServiceUsage } from 'hooks'
import { logToAnalytics } from 'modules'

import {
    type InsightsPeriodSelectValue,
    InsightsPeriod,
    insightsPeriodSchema
} from 'app/types/insights'

import { insightsDefaultPeriod, insightsPeriodLabel } from 'UI/Components'

import { DialogSelectCustomRange } from './DialogSelectCustomRange'
import { Button, ButtonGroup, spacings, ThemeProvider, Icon } from '@guidde/design-system'
import { faCalendar } from '@fortawesome/pro-light-svg-icons'

type Props = {
    value: InsightsPeriodSelectValue
    onSelectPeriod: (value: InsightsPeriodSelectValue) => void
    analyticsEventSource: string
}

export const InsightsPeriodSelect = ({ value, onSelectPeriod, analyticsEventSource }: Props) => {
    const { insightsDays } = useServiceUsage()

    const insightsDaysQuota = insightsDays || insightsPeriodSchema[insightsDefaultPeriod]

    const dialogSelectDateRange = useBoolean()

    const selectDateRangeAnchorRef = useRef<HTMLDivElement | null>(null)

    const insightsPeriodOptions = useMemo(() => {
        const allowedPeriods = (
            [
                InsightsPeriod.Week,
                InsightsPeriod.Month,
                InsightsPeriod.Quarter,
                InsightsPeriod.HalfYear
            ] as const
        ).filter(period => insightsPeriodSchema[period] <= insightsDaysQuota)

        return allowedPeriods
    }, [insightsDaysQuota])

    const { period, dateRange } = value

    return (
        <>
            {dialogSelectDateRange.isTrue && (
                <DialogSelectCustomRange
                    onClose={dialogSelectDateRange.setFalse}
                    onApply={({ start, end }) => {
                        onSelectPeriod({ period: InsightsPeriod.Custom, dateRange: { start, end } })

                        logToAnalytics('insights_dateRange_submitBtn_clicked', {
                            start: start.format('MMM D, YYYY'),
                            end: end.format('MMM D, YYYY'),
                            source: analyticsEventSource
                        })
                    }}
                    initialStartDate={dateRange?.start ?? null}
                    initialEndDate={dateRange?.end ?? null}
                    anchorElement={selectDateRangeAnchorRef.current}
                    maxPeriodLengthInDays={insightsDaysQuota}
                    analyticsEventSource={analyticsEventSource}
                />
            )}

            <ThemeProvider>
                <Stack
                    spacing={spacings['4xl']}
                    sx={theme => ({
                        [`& [aria-label="${period}"]`]: {
                            background: `${theme.palette.grey[50]} !important`
                        }
                    })}
                >
                    <ButtonGroup>
                        {insightsPeriodOptions.map(option => (
                            <Button
                                size="small"
                                key={option}
                                label={option}
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    logToAnalytics('insights_dateSelect_clicked', {
                                        state: option,
                                        source: analyticsEventSource
                                    })

                                    onSelectPeriod({ period: option, dateRange: null })
                                }}
                            >
                                {insightsPeriodLabel[option]}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Stack>
                <Box width="150px" ref={selectDateRangeAnchorRef}>
                    <Button
                        fullWidth
                        onClick={dialogSelectDateRange.setTrue}
                        variant="outlined"
                        startIcon={<Icon icon={faCalendar} style={{ width: 16, height: 16 }} />}
                        color="secondary"
                    >
                        Select dates
                    </Button>
                </Box>
            </ThemeProvider>
        </>
    )
}
