import { generatePath } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { parseLocationParams } from 'UI/Components'

import { type LocationDescriptor } from 'history'
import { type UserAuthStateType } from 'ducks/reducers'

import { paths } from 'app/paths'

import { logToAnalytics, DesktopCommunication } from 'modules'

import { log } from '@fullstory/browser'

export const identifyHubspotContact = (email: string, isNewUser?: boolean) => {
    try {
        const hubspotutk = document.cookie
            .split(' ')
            .find(cookie => cookie.includes('hubspotutk'))
            ?.split('=')[1]
            .replace(/;$/, '')

        logToAnalytics(isNewUser ? 'identify_hubspot_contact' : 'identify_hubspot_login', {
            // do NOT change/delete fields below, they are required by Hubspot
            dataToForward: {
                submittedAt: new Date().getTime(),
                fields: [
                    {
                        objectTypeId: '0-1',
                        name: 'email',
                        value: email
                    }
                ],
                context: {
                    hutk: hubspotutk || null,
                    pageUri: window.location.href,
                    pageName: document.title
                }
            }
        })

        if ('_hsq' in window) {
            // _hsq it's HS instance injected to window by GTM
            // Use code below as a fallback to identify user on FE side
            // in case something goes wrong on BE side
            // @ts-ignore
            window._hsq.push(['identify', { email }])
            // @ts-ignore
            window._hsq.push(['trackPageView'])
        }
    } catch (e: any) {
        log(`failed_to_identify_hubspot_contact: ${e.message}`)
        Sentry.captureException(e)
        logToAnalytics('failed_to_identify_hubspot_contact', {
            message: e,
            pathname: window.location.pathname
        })
    }
}

export const handleSessionRedirects = ({
    token,
    roles,
    historyPush,
    spaceId: spaceIdArg,
    isExtensionInstalled,
    fromWelcome
}: {
    token?: string
    roles?: UserAuthStateType['roles']
    historyPush: <T = unknown>(location: LocationDescriptor<T>, state?: T) => void
    spaceId?: string
    isExtensionInstalled?: boolean
    fromWelcome?: boolean
}) => {
    const params = new URLSearchParams(window.location.search)
    const { fromPathname, fromSearch } = parseLocationParams(params)

    const source = params.get('src')
    const sourceAction = params.get('action')

    const continueUrl = params.get('continueUrl')

    const spaceId = spaceIdArg || params.get('spaceId')

    // forward all params to the next route except private ones
    ;['secretKey', 'invitationId'].forEach(query => params.delete(query))

    const shouldAuthCallbackDesktopApp =
        source === 'desktop' &&
        (sourceAction === 'auth' || sourceAction === null || sourceAction === undefined)
    if (shouldAuthCallbackDesktopApp && token) DesktopCommunication.authCallback(token)

    const shouldPingDesktopOnboardingDone =
        source === 'desktop' && sourceAction === 'finish-onboarding' && !roles?.n

    if (shouldPingDesktopOnboardingDone) DesktopCommunication.onBoardingDone()

    if (window.location.pathname === paths.welcome && !fromWelcome) {
        return
    }

    if (roles?.n && (roles.a > 10 || !roles.o)) {
        return historyPush({
            pathname: paths.onboarding,
            search: params.toString()
        })
    }

    params.delete('fromSearch')
    params.delete('fromPathname')

    const isFromExtension = source === 'extension'
    if (isFromExtension && isExtensionInstalled) {
        params.delete('src')

        return historyPush({
            pathname: paths.welcome,
            search: params.toString()
        })
    }

    if (spaceId) {
        // used when user accepts space invite
        params.delete('spaceId')

        return historyPush({
            pathname: generatePath(paths.spaceLibrary, {
                spaceId
            }),
            search: params.toString()
        })
    }

    if (continueUrl) {
        params.delete('continueUrl')

        return historyPush({
            pathname: continueUrl,
            search: params.toString()
        })
    }

    if (fromPathname || fromSearch) {
        // this condition works when we have location in url from previous attempt to open private route
        // on this state user already logged in and it's safe to redirect him to the private route
        return historyPush({ pathname: fromPathname, search: fromSearch })
    }

    if (
        (
            [
                paths.login,
                paths.onboarding,
                paths.emailLink,
                paths.welcome,
                paths.signup
            ] as string[]
        ).includes(window.location.pathname) ||
        window.location.pathname.startsWith(paths.ssoLogin) ||
        (document.hidden && roles?.n)
    ) {
        // activated when user uses usual login without any extra redirects logic
        return historyPush({ pathname: paths.index, search: params.toString() })
    }
}

export const aliasUser = (userId: string) => {
    try {
        const cookieString = document.cookie.split(';').filter(c => c.includes('mp_'))[0]

        if (cookieString) {
            const cookieValue = cookieString.split('=')[1]
            const mixpanelDistinctId = JSON.parse(decodeURIComponent(cookieValue)).distinct_id

            logToAnalytics('alias_user', {
                userId,
                previousId: mixpanelDistinctId
            })
        }
    } catch (e) {
        Sentry.captureException(e)
    }
}
