import { type MouseEvent, memo } from 'react'

import { type SxProps } from '@mui/material/styles'

import {
    faPenRuler,
    faLockKeyhole,
    faBadgeCheck,
    faPlay,
    faPause
} from '@fortawesome/pro-solid-svg-icons'

import { Box, Stack } from '@mui/material'

import { Icon, IconButton, Tooltip, Typography } from '@guidde/design-system'

import { ReactComponent as PremiumIcon } from 'assets/icons/premium.svg'

import { SpacedGroup } from 'UI/Components'

import { type SpeakerType } from 'app/types'

import { getTimeString } from 'modules'

export type SpeakerItemProps = {
    speaker: SpeakerType
    isActive: boolean
    isPremium?: boolean
    isPlaying: boolean
    isDefaultSpeaker?: boolean
    isBrandkitSpeaker?: boolean
    currentTime: number
    dataCy?: string
    onContainerClick: (event: MouseEvent<HTMLElement>, speaker: SpeakerType) => void
    onActionButtonClick: (speaker: SpeakerType) => void
    sx?: SxProps
    premiumDisplayMode?: 'lock' | 'upgrade'
}

export const SpeakerItem = memo(
    ({
        speaker,
        dataCy,
        isPlaying,
        currentTime,
        isPremium,
        isActive,
        isDefaultSpeaker,
        isBrandkitSpeaker,
        onActionButtonClick,
        onContainerClick,
        sx,
        premiumDisplayMode = 'upgrade'
    }: SpeakerItemProps) => {
        const handleSpeakerItemClick = (event: MouseEvent<HTMLElement>) => {
            onContainerClick(event, speaker)
        }

        const handlePlayPauseAction = (event: MouseEvent<HTMLElement>) => {
            event.stopPropagation()
            onActionButtonClick(speaker)
        }

        return (
            <Stack
                id={speaker.id}
                data-test={dataCy}
                onClick={handleSpeakerItemClick}
                direction="row"
                sx={{
                    minHeight: '88px',
                    borderRadius: '4px',
                    padding: '2px',
                    marginRight: `0 !important`,
                    border: 'solid 1px transparent',
                    borderColor: isActive ? 'primary.700' : 'rgba(9, 12, 16, 0.08)',
                    backgroundColor: isActive ? 'primary.100' : 'transparent',
                    cursor: 'pointer',
                    ...sx
                }}
                spacing={2}
            >
                <Box
                    sx={{
                        ...(speaker.isStudio && {
                            position: 'relative',
                            '&::after': {
                                content: '"HQ"',
                                width: 23,
                                position: 'absolute',
                                right: -8,
                                top: 16,
                                color: 'white',
                                backgroundColor: 'primary.700',
                                textAlign: 'center',
                                fontSize: 10,
                                fontWeight: 700,
                                borderRadius: 8
                            },
                            '& img': {
                                border: 'solid 2px',
                                borderRadius: '50%',
                                borderColor: speaker.isStudio ? 'primary.700' : 'transparent'
                            }
                        })
                    }}
                >
                    {speaker.isStudio && premiumDisplayMode === 'lock' && isPremium && (
                        <Box
                            sx={{
                                display: 'flex',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                borderRadius: '50%',
                                border: `2px solid`,
                                borderColor: 'primary.700',
                                color: 'warning.300'
                            }}
                        >
                            <Icon icon={faLockKeyhole} size="2x" />
                        </Box>
                    )}
                    <img src={speaker.avatarUrlCompressed} width={80} height={80} alt="avatar" />
                </Box>

                <SpacedGroup
                    flexDirection="column"
                    position="relative"
                    flexGrow={1}
                    justifyContent="center"
                >
                    <SpacedGroup
                        justifyContent="space-between"
                        sx={{
                            marginRight: 0
                        }}
                    >
                        <Typography variant="text" fontWeight="semibold" size="sm">
                            {speaker.name}
                        </Typography>
                        <SpacedGroup spacing={0.25} data-test="ok" marginRight={0.5} gap={0.5}>
                            {isBrandkitSpeaker && (
                                <Tooltip title="Brand Kit Speaker">
                                    <Box
                                        sx={{
                                            color: 'pink.700'
                                        }}
                                    >
                                        <Icon icon={faPenRuler} size="1x" />
                                    </Box>
                                </Tooltip>
                            )}
                            {isDefaultSpeaker && (
                                <Tooltip title="Default Speaker">
                                    <Box
                                        sx={{
                                            color: 'primary.700'
                                        }}
                                    >
                                        <Icon icon={faBadgeCheck} size="1x" />
                                    </Box>
                                </Tooltip>
                            )}
                            {speaker.isStudio && premiumDisplayMode === 'upgrade' && isPremium && (
                                <PremiumIcon />
                            )}
                        </SpacedGroup>
                    </SpacedGroup>

                    <SpacedGroup
                        sx={{
                            '& button': {
                                borderRadius: '100%'
                            }
                        }}
                        gap={0.75}
                    >
                        <IconButton
                            color="primary"
                            size="small"
                            label="Play"
                            onClick={handlePlayPauseAction}
                        >
                            <Icon icon={isPlaying ? faPause : faPlay} size="1x" />
                        </IconButton>
                        <Typography variant="text" size="sm">
                            {isPlaying && getTimeString(currentTime)}
                        </Typography>
                    </SpacedGroup>
                </SpacedGroup>
            </Stack>
        )
    }
)
