import { type MutableRefObject, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { round } from 'modules'

type Props = {
    videoElement: HTMLVideoElement | null
    audioPlayerRef: MutableRefObject<HTMLAudioElement | null>
}

export const AudioPlayer = ({ audioPlayerRef, videoElement }: Props) => {
    const { activeStep, steps } = useSelector(state => state.qgEditor.present)
    const { audioNote } = steps[activeStep]

    const audioUrl = useMemo(() => {
        if (!audioNote || audioNote.type === 'defaultSubtitles') return ''
        return audioNote.audioUrl
    }, [audioNote])

    useEffect(() => {
        if (!videoElement) return

        const audioPlayer = audioPlayerRef.current

        const handleTimeChange = (e: Event) => {
            if (!audioPlayer) return

            const videoTime = (e.currentTarget as HTMLVideoElement).currentTime
            const audioTime = audioPlayer.currentTime

            const isStopped = videoElement.paused

            if (isStopped) {
                if (round(videoTime) !== round(audioTime)) {
                    audioPlayer.currentTime = videoTime
                }
            } else {
                // @TODO check if it's safe to remove this condition completely
                if (Math.abs(videoTime - audioTime) > 0.2) {
                    audioPlayer.currentTime = videoTime
                }
            }
        }

        videoElement.addEventListener('timeupdate', handleTimeChange)

        return () => {
            videoElement.removeEventListener('timeupdate', handleTimeChange)
        }
    }, [audioPlayerRef, videoElement])

    if (!audioUrl) return null

    return (
        <audio
            ref={audioPlayerRef}
            src={audioUrl}
            preload="auto"
            controls
            style={{ display: 'none' }}
        />
    )
}
