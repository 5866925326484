import { type SpeakerType } from 'app/types'
import { logToAnalytics } from 'modules'

class VoiceoverAnalytics {
    static voiceoverPlaySpeakerPreview(props: VoiceoverPlaySpeakerPreviewProps) {
        logToAnalytics('voiceover_play_speaker_preview', props)
    }

    static voiceoverAdd(props: VoiceoverAddProps) {
        logToAnalytics('voiceover_add', props)
    }

    static voiceoverT2VTabSpeakerApplyAllChkClicked(
        props: VoiceoverT2VTabSpeakerApplyAllChkClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_speaker_applyAllChk_clicked', props)
    }

    static voiceoverT2VTabSpeakerClicked(props: VoiceoverT2VTabSpeakerClickedProps) {
        logToAnalytics('voiceover_t2vTab_speaker_clicked', props)
    }

    static voiceoverAddVoiceoverBtnClicked(props: VoiceoverAddVoiceoverBtnClickedProps) {
        logToAnalytics('voiceover_addVoiceoverBtn_clicked', props)
    }

    static voiceoverSaveBtnClicked(props: VoiceoverSaveBtnClickedProps) {
        logToAnalytics('voiceover_saveBtn_clicked', props)
    }

    static voiceoverT2VTabDeleteVoiceoverFromAllStepsClicked(
        props: VoiceoverT2VTabDeleteVoiceoverFromAllStepsClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_deleteVoiceoverFromAllSteps_clicked', props)
    }

    static voiceoverT2VTabDeleteVoiceoverCancelBtnClicked(
        props: VoiceoverT2VTabDeleteVoiceoverCancelBtnClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_deleteVoiceover_cancelBtn_clicked', props)
    }

    static voiceoverT2VTabDeleteVoiceoverDeleteBtnClicked(
        props: VoiceoverT2VTabDeleteVoiceoverDeleteBtnClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_deleteVoiceover_deleteBtn_clicked', props)
    }

    static voiceoverT2VTabEditVoiceoverBtnClicked(
        props: VoiceoverT2VTabEditVoiceoverBtnClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_editVoiceoverBtn_clicked', props)
    }

    static voiceoverPlay(props: VoiceoverPlayProps) {
        logToAnalytics('voiceover_play', props)
    }

    static voiceoverT2VTabPlayerContainerPointerClicked(
        props: VoiceoverT2VTabPlayerContainerPointerClickedProps
    ) {
        logToAnalytics('voiceover_t2vTab_playerContainer_pointer_clicked', props)
    }

    static voiceoverRestartFromPreview(props: VoiceoverRestartFromPreviewProps) {
        logToAnalytics('voiceover_restart_from_preview', props)
    }
}

export type VoiceoverSoure = 'magicCapture' | 'voiceovert2vTab'
export type VoiceoverType = 'textToSpeech' | 'speechToText'

export type VoiceoverAddProps = {
    voiceOverType: VoiceoverType
    playbookId: string
    activeStep: number
    multipleSteps: boolean
    languageCode: string
    speaker: SpeakerType
    data: any
}

export type VoiceoverT2VTabSpeakerSelectedProps = {
    source: VoiceoverSoure
    playbookId: string
}

export type VoiceoverPlaySpeakerPreviewProps = {
    playbookId: string
    activeStep: number
    speaker: SpeakerType
    source: VoiceoverSoure
}

export type VoiceoverT2VTabSpeakerApplyAllChkClickedProps = {
    playbookId: string
}

export type VoiceoverT2VTabSpeakerClickedProps = {
    playbookId: string
}

export type VoiceoverAddVoiceoverBtnClickedProps = {
    playbookId: string
    textInput: string
}

export type VoiceoverSaveBtnClickedProps = {
    selectedSpeaker: SpeakerType
    isBrandKitSpeaker: boolean
    isRecentlyUsedSpeaker: boolean
    defaultSpeakerToggleState?: boolean
    applyToAllSteps?: boolean
    source?: VoiceoverSoure
}

export type VoiceoverT2VTabDeleteVoiceoverFromAllStepsClickedProps = {
    playbookId: string
}

export type VoiceoverT2VTabDeleteVoiceoverCancelBtnClickedProps = {
    playbookId: string
}

export type VoiceoverT2VTabDeleteVoiceoverDeleteBtnClickedProps = {
    playbookId: string
}

export type VoiceoverT2VTabEditVoiceoverBtnClickedProps = {
    playbookId: string
}

export type VoiceoverPlayProps = {
    voiceOverType: VoiceoverType
    playbookId: string
    activeStep: number
}

export type VoiceoverT2VTabPlayerContainerPointerClickedProps = {
    playbookId: string
    timestamp: number
}

export type VoiceoverRestartFromPreviewProps = {
    playbookId: string
    activeStep: number
}

export default VoiceoverAnalytics
