import { useEffect, useMemo, useRef, useState, type SyntheticEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, ClickAwayListener } from '@mui/material'
import { Typography, Button } from '@guidde/design-system'

import { SpacedGroup } from 'UI/Components'
import { enGBcode, enUScode } from 'UI/Routes/quick-guidde/hooks'

import { ReactComponent as Premium } from 'assets/icons/workspace_premium.svg'

import { delay, logToAnalytics } from 'modules'
import { showPlanDialog } from 'ducks'

import { type SortedAudioConfigsType, type SpeakerType } from 'app/types'
import { useBoolean, useServiceUsage } from 'hooks'
import { SpeakerItem } from 'UI/Routes/quick-guidde/LeftPanel'

export const getFirstSpeaker = (
    speakers: SortedAudioConfigsType['speakers'],
    gender: string,
    langCode: string
) => {
    return speakers[langCode]?.options?.find(speaker => {
        return (
            speaker.gender.toLowerCase() === gender.toLowerCase() &&
            speaker.langCode.toLowerCase() === langCode.toLowerCase()
        )
    })
}

type Props = {
    onClose: () => void
}

export const T2sBlockedMessage = ({ onClose }: Props) => {
    const dispatch = useDispatch()
    const { planName } = useServiceUsage()
    const audioRef = useRef<HTMLMediaElement | null>(null)

    const [currentTime, setCurrentTime] = useState(0)

    const playStatus = useBoolean()
    const setPlayStatus = playStatus.set

    const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)

    // on unmount
    useEffect(() => {
        return () => {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { speakers } = useSelector(state => state.configs.audioConfigs)

    const figures = useMemo(() => {
        if (!Object.values(speakers || {}).length) return null

        const male = getFirstSpeaker(speakers, 'male', enGBcode)
        const female = getFirstSpeaker(speakers, 'female', enUScode)

        if (!female || !male) return null

        return {
            female,
            male
        }
    }, [speakers])

    const handlePlayAction = () => {
        playStatus.setTrue()
    }

    const handlePauseAction = () => {
        playStatus.setFalse()
    }

    const handleTimeUpdateAction = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
        const { currentTime } = event.target as HTMLAudioElement
        setCurrentTime(currentTime)
    }

    const handleAudioEndedAction = () => {
        playStatus.setFalse()
        setCurrentTime(0)
    }

    const handleUpgradeButtonClick = () => {
        logToAnalytics('voiceover_upgradeBtn_clicked', {
            source: 'magicCapturePopup',
            currentPlan: planName
        })
        dispatch(showPlanDialog('upgradePlanDialog'))
    }

    const handleIgnoreButtonClick = () => {
        logToAnalytics('voiceover_ignoreUpgradeBtn_clicked', {
            currentPlan: planName
        })

        onClose()
    }

    const handleSpeakerPlayClickAction = (speaker: SpeakerType) => {
        const isPlaying = playStatus.isTrue && speaker.id === playSpeaker?.id

        if (playSpeaker?.id !== speaker.id) {
            setPlaySpeaker(speaker)
        }

        const audioElement = audioRef.current
        if (!audioElement) return

        delay(0).then(() => {
            if (isPlaying) audioElement.pause()
            else audioElement.play()
        })
    }
    const handleSpeakerContainer = () => undefined

    const reset = () => {
        setPlaySpeaker(null)
        setPlayStatus(false)
        setCurrentTime(0)
    }

    return (
        <ClickAwayListener onClickAway={reset}>
            <Box
                data-test="editor-T2sBanner"
                data-intercom="editor-T2sBanner"
                p="10px"
                bgcolor="#F5F5F5"
                borderRadius="4px"
                textAlign="center"
                position="relative"
            >
                <audio
                    src={playSpeaker?.sampleAudioUrl ?? ''}
                    ref={audioRef}
                    onPlay={handlePlayAction}
                    onPause={handlePauseAction}
                    onTimeUpdate={handleTimeUpdateAction}
                    onEnded={handleAudioEndedAction}
                />
                <Box mb={1}>
                    <Typography variant="text" size="xl">
                        Type it in
                    </Typography>
                    <Typography variant="text">
                        Get a natural sounding voiceover using text.
                    </Typography>
                </Box>
                <SpacedGroup justifyContent="center" flexDirection="column">
                    {figures &&
                        Object.values(figures).map(figure => {
                            const isPlaying = figure.id === playSpeaker?.id && playStatus.isTrue

                            return (
                                <SpeakerItem
                                    key={figure.id}
                                    speaker={figure}
                                    isActive={false}
                                    isPlaying={isPlaying}
                                    currentTime={currentTime}
                                    onContainerClick={handleSpeakerContainer}
                                    onActionButtonClick={handleSpeakerPlayClickAction}
                                />
                            )
                        })}
                </SpacedGroup>
                <SpacedGroup alignItems="baseline" mt={2}>
                    <Button variant="text" color="secondary" onClick={handleIgnoreButtonClick}>
                        Ignore
                    </Button>
                    <Button
                        fullWidth
                        onClick={handleUpgradeButtonClick}
                        variant="contained"
                        size="large"
                        startIcon={<Premium />}
                    >
                        <Box ml={1}>Upgrade</Box>
                    </Button>
                </SpacedGroup>
            </Box>
        </ClickAwayListener>
    )
}
