import { useMemo, useState } from 'react'

import { useHistory, useParams, generatePath } from 'react-router-dom'

import { styled, Button as MuiButton, Box } from '@mui/material'
import { Icon, Button, DropdownMenu } from '@guidde/design-system'

import { faPenRuler, faPen, faTrashCan } from '@fortawesome/pro-regular-svg-icons'

import { ReactComponent as PremiumIcon } from 'assets/icons/medal.svg'

import { brandKitRequestPath } from './PageBrandKit'
import { UpgradePlanPopup, ConfirmationDialog, PageHeader } from 'UI/Components'
import { BrandKitCardDialog } from './sections/dialogs'

import { planTypes, useBoolean, useBrandKit, useNotification, useServiceUsage } from 'hooks'
import { openLink, request } from 'modules'

import { links } from 'app/links'
import { type MultiBrandKitItem } from 'app/types'
import { paths } from 'app/paths'

const ContactUsButton = styled(MuiButton)({
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 1.25
})

export const BrandKitHeader = () => {
    const { brandKitsLimit } = useServiceUsage()

    const history = useHistory()

    const { id: brandKitId } = useParams<{ id: string }>()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const showAddDialog = useBoolean()
    const showDeleteDialog = useBoolean()
    const showRenameDialog = useBoolean()

    const { revalidateMultiBrandKit, multiBrandKitData } = useBrandKit()

    const multiBrandKitItem = multiBrandKitData?.find(({ id }) => id === brandKitId) as
        | MultiBrandKitItem
        | undefined

    const multiBrandKitCounter = multiBrandKitData?.length || 1

    const { showSuccessNotification } = useNotification()

    const isReachedLimit = multiBrandKitCounter >= brandKitsLimit

    const { activePlanId } = useServiceUsage()

    const isSingleBrandKit = !multiBrandKitItem || multiBrandKitCounter === 1

    const breadcrumbs = useMemo(() => {
        if (!multiBrandKitItem || isSingleBrandKit) {
            return [{ label: 'All Kits', to: paths.brandKit }]
        }

        return [
            { label: 'All Kits', to: paths.brandKit },
            {
                label: multiBrandKitItem?.name || '',
                to: generatePath(paths.brandKitDetails, {
                    id: multiBrandKitItem.id
                })
            }
        ]
    }, [multiBrandKitItem, isSingleBrandKit])

    return (
        <>
            <PageHeader
                title={multiBrandKitItem ? multiBrandKitItem.name : 'Brand kits'}
                subtitle="Create on-brand videos with ease. Customize fonts, colors, and more using our Brand Kit."
                topRowStartSlot={<PageHeader.Breadcrumbs breadcrumbs={breadcrumbs} />}
                actionsSlot={
                    isSingleBrandKit ? (
                        <Button
                            variant="contained"
                            size="medium"
                            startIcon={
                                brandKitsLimit === 1 ? (
                                    <PremiumIcon />
                                ) : (
                                    <Icon icon={faPenRuler} style={{ width: 16 }} />
                                )
                            }
                            onClick={event => {
                                if (brandKitsLimit === 1 || isReachedLimit) {
                                    return setAnchorEl(event.currentTarget)
                                }

                                showAddDialog.setTrue()
                            }}
                        >
                            Add brand kit
                        </Button>
                    ) : (
                        <DropdownMenu
                            options={[
                                {
                                    label: 'Edit name',
                                    value: 'edit',
                                    icon: faPen,
                                    hasDivider: true
                                },
                                { label: 'Delete', value: 'delete', icon: faTrashCan }
                            ]}
                            onMenuItemSelect={option => {
                                if (option.value === 'edit') {
                                    showRenameDialog.setTrue()
                                }
                                if (option.value === 'delete') {
                                    showDeleteDialog.setTrue()
                                }
                            }}
                        >
                            <Box width={140}>
                                <Button
                                    color="secondary"
                                    variant="outlined"
                                    size="medium"
                                    fullWidth
                                >
                                    More Actions
                                </Button>
                            </Box>
                        </DropdownMenu>
                    )
                }
            />

            {anchorEl && (
                <UpgradePlanPopup
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    hideSeePlanFeatures={isReachedLimit}
                    hidePrice={true}
                    points={
                        activePlanId === planTypes.enterprise
                            ? []
                            : ['Multiple brand kits', 'Access to studio voices', 'Auto Translation']
                    }
                    title="Upgrade your account"
                    planId={planTypes.enterprise}
                    text={
                        activePlanId === planTypes.enterprise ? (
                            <>
                                Hi there! Managing more than{' '}
                                {brandKitsLimit > 1 ? `${brandKitsLimit} brands` : '1 brand'}?{' '}
                                <br />
                                just contact our team and we'll be happy to assist you.
                            </>
                        ) : (
                            <>
                                Hi there! Managing more than{' '}
                                {brandKitsLimit > 1 ? `${brandKitsLimit} brands` : '1 brand'}? You
                                can create and manage up to 5 separate brands, all in one place.
                            </>
                        )
                    }
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    customActionButton={
                        <ContactUsButton
                            onClick={() => openLink(links.contactSales)}
                            variant="contained"
                        >
                            CONTACT US
                        </ContactUsButton>
                    }
                />
            )}

            {showDeleteDialog.isTrue && (
                <ConfirmationDialog
                    isOpen={true}
                    onClose={showDeleteDialog.setFalse}
                    onConfirm={() => {
                        if (!multiBrandKitItem) return

                        request(`${brandKitRequestPath}/${multiBrandKitItem.id}`, 'DELETE').then(
                            () => {
                                revalidateMultiBrandKit()
                                history.push(paths.brandKit)
                                showDeleteDialog.setFalse()
                                showSuccessNotification('Brand Kit successfully removed')
                            }
                        )
                    }}
                    title={`Delete ${multiBrandKitItem?.name}`}
                    text={`Are you sure you want to delete the ${multiBrandKitItem?.name} ?`}
                />
            )}

            {(showRenameDialog.isTrue || showAddDialog.isTrue) && (
                <BrandKitCardDialog
                    onClose={() => {
                        showAddDialog.setFalse()
                        showRenameDialog.setFalse()
                    }}
                    currentName={showRenameDialog.isTrue ? multiBrandKitItem?.name : ''}
                    id={multiBrandKitItem?.id}
                    isDefault={multiBrandKitItem?.isDefault}
                />
            )}
        </>
    )
}
