import { useMemo } from 'react'

import { type ShareableSpacesType } from 'app/types'

import { useNotification, useQuery } from 'hooks'
import { generalErrorMessage } from 'modules'

const initialData = {
    spaces: [],
    internalUsers: [],
    externalUsers: [],
    pendingEmails: []
}

const initialParams = {
    internalUsers: true,
    externalUsers: true,
    pendingEmails: true
}

type Props = {
    playbooksIds: Array<string>
    blockRequest?: boolean
    params?: Partial<{
        spaces: boolean
        spaceSearch: string
        internalUsers: boolean
        externalUsers: boolean
        pendingEmails: boolean
    }>
    onSuccess?: (data: ShareableSpacesType) => void
    onError?: () => void
}

export const getShareableOptionsUrl = '/c/v1/get-shareable-options'

export const useShareableOptions = ({
    playbooksIds,
    blockRequest = false,
    params = initialParams,
    onError,
    onSuccess
}: Props) => {
    const { showErrorNotification } = useNotification()

    const $options = useQuery<ShareableSpacesType>(
        playbooksIds.length > 0 && !blockRequest ? getShareableOptionsUrl : '',
        {
            method: 'POST',
            body: {
                playbooksIds,
                ...params
            }
        },
        {
            onError: ({ message }) => {
                onError?.()
                console.error(message)
                showErrorNotification(generalErrorMessage)
            },
            onSuccess: data => onSuccess?.(data),
            dedupingInterval: 0
        }
    )

    return useMemo(
        () => ({
            ...$options,
            data: $options.data || initialData
        }),
        [$options]
    )
}
