import { useCallback, useMemo } from 'react'
import { renderToString } from 'react-dom/server'
import { generatePath } from 'react-router-dom'

import TurndownService from 'turndown'

import { type QuickGuiddeType } from 'app/types'

import { copyToClipboard, isQG, host } from 'modules'
import { useGlobalFeatureFlags, useServiceUsage } from 'hooks'

import { GifImageWithPlayButton, filterIntoOutroSteps } from 'UI/Components'

import {
    createNotResponsiveEmbedCode,
    createResponsiveEmbedCode,
    createSteps,
    generateAndCopyStyledHTML,
    generateHTML
} from './common'

import {
    type PlaybookImageWidthType,
    type CopyHTMLWithEmbedCodeType,
    type CopyResponsiveEmbedCodeType
} from './types'

import { paths } from 'app/paths'

export const useCopyHtml = () => {
    const { enableNgnixLinks } = useGlobalFeatureFlags()
    const { canHideWatermarkEmbed } = useServiceUsage()

    const hidePoweredByGuidde = Boolean(canHideWatermarkEmbed)

    const createMarkdown = useCallback(
        (playbook: QuickGuiddeType, content: string) => {
            const html = generateHTML({
                playbook,
                content,
                enableNgnixLinks,
                hidePoweredByGuidde
            })

            const turndownService = new TurndownService()
            const markdown = turndownService.turndown(html)

            return markdown
        },
        [enableNgnixLinks, hidePoweredByGuidde]
    )

    const copyGitbookMarkdown = useCallback(
        (playbook: QuickGuiddeType) => {
            const pbLink = generatePath(paths.publicPlaybook, {
                playbookId: playbook.id
            })

            const content = `{% embed url="${host + pbLink}" %}`

            const markdown = createMarkdown(playbook, content)

            return copyToClipboard(markdown)
        },
        [createMarkdown]
    )

    const copyMarkdown = useCallback(
        (playbook: QuickGuiddeType) => {
            const content = renderToString(
                <GifImageWithPlayButton playbook={playbook} enableNgnixLinks={enableNgnixLinks} />
            )

            const markdown = createMarkdown(playbook, content)

            return copyToClipboard(markdown)
        },
        [enableNgnixLinks, createMarkdown]
    )

    const copyHTMLWithPlayButton = useCallback(
        async (playbook: QuickGuiddeType, width?: PlaybookImageWidthType) => {
            const content = renderToString(
                <GifImageWithPlayButton playbook={playbook} width={width} />
            )

            await generateAndCopyStyledHTML({
                playbook,
                content,
                enableNgnixLinks,
                width,
                hidePoweredByGuidde
            })
        },
        [enableNgnixLinks, hidePoweredByGuidde]
    )

    const copyIframeWithSteps = useCallback(
        (playbook: QuickGuiddeType) => {
            const embedCode = createResponsiveEmbedCode(playbook)

            const html = generateHTML({
                content: embedCode,
                playbook,
                enableNgnixLinks,
                width: 'auto',
                hidePoweredByGuidde
            })

            return copyToClipboard(html)
        },
        [hidePoweredByGuidde, enableNgnixLinks]
    )

    const copyHTMLWithEmbedCode = useCallback(
        ({
            playbook,
            width,
            headerSize,
            iframeWidth = 700,
            responsive,
            headerAlignment,
            withHyperLinkTitle
        }: CopyHTMLWithEmbedCodeType) => {
            const embedCode = responsive
                ? createResponsiveEmbedCode(playbook)
                : createNotResponsiveEmbedCode(playbook, { width: iframeWidth, height: 400 })

            const content = generateHTML({
                withHyperLinkTitle,
                playbook,
                content: embedCode,
                enableNgnixLinks,
                width,
                headerSize,
                headerAlignment,
                hidePoweredByGuidde
            })

            return copyToClipboard(content)
        },
        [hidePoweredByGuidde, enableNgnixLinks]
    )

    const copyResponsiveEmbedCode = useCallback(
        ({ playbook, renderSteps }: CopyResponsiveEmbedCodeType) => {
            const embedCode = createResponsiveEmbedCode(playbook)

            if (!renderSteps) return copyToClipboard(embedCode)

            const content = `
                ${embedCode}
                ${createSteps({
                    steps: isQG(playbook) ? filterIntoOutroSteps(playbook.steps) : [],
                    enableNgnixLinks,
                    goToLabel: isQG(playbook) ? playbook.goToLabel : '',
                    id: playbook.id
                })}

            `

            return copyToClipboard(content)
        },
        [enableNgnixLinks]
    )

    return useMemo(
        () => ({
            copyResponsiveEmbedCode,
            copyHTMLWithPlayButton,
            copyHTMLWithEmbedCode,
            copyIframeWithSteps,
            copyMarkdown,
            copyGitbookMarkdown
        }),
        [
            copyHTMLWithPlayButton,
            copyIframeWithSteps,
            copyResponsiveEmbedCode,
            copyHTMLWithEmbedCode,
            copyMarkdown,
            copyGitbookMarkdown
        ]
    )
}
