import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useDataMutation, useServiceUsage } from 'hooks'
import {
    type MagicCaptureType,
    type QuickGuiddeType,
    type SpeakerType,
    type StepType
} from 'app/types'
import { type OptionType, option, playbookToAnalyticsProps, isQG } from 'modules'
import { useSpeakerOptions, enUScode } from 'UI/Routes/quick-guidde/hooks'

import { type ProgressData, type TranslateProgressDialogProps } from '../TranslateProgressDialog'
import AutoTranslateAnalytics from 'analytics/autoTranslate'

const updateDialogPoints = [
    'Translate your video',
    'Add natural voiceovers',
    'Video engagement insights',
    'Remove Guidde watermark',
    'Custom Branding'
]

type GetInitialLanguageProps = {
    languagesList: Array<OptionType>
    audioNote: StepType['audioNote']
    magicCapture?: MagicCaptureType
    language?: {
        langCode: string
        langName: string
    }
}

const getInitialLanguage = ({
    languagesList,
    audioNote,
    magicCapture,
    language
}: GetInitialLanguageProps) => {
    if (language) {
        return option(language.langCode, language.langName)
    }

    switch (audioNote?.type) {
        case 'textToSpeech': {
            const config = audioNote?.speakerConfig
            return option(config.langCode, config.langName)
        }
        case 'speechToText': {
            return option(audioNote.languageCode, audioNote.languageCode)
        }
        default: {
            if (magicCapture?.language) {
                return option(magicCapture.language.langCode, magicCapture.language.langName)
            }
            return languagesList?.find(it => it.value === enUScode)
        }
    }
}

type Payload = {
    playbookId: string
    speaker: SpeakerType
}

type Response = {
    playbookId: string
    playbook: QuickGuiddeType
}

export const useQuickGuiddeTranslate = (playbook: QuickGuiddeType) => {
    const buttonRef = useRef<HTMLElement | null>(null)
    const { languagesList } = useSpeakerOptions()
    const { canTranslateQuickGuidde } = useServiceUsage()

    const [progressData, setProgressData] = useState<TranslateProgressDialogProps['data']>([])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [popperAnchorEl, setPopperAnchorEl] = useState<null | HTMLElement>(null)

    const upgradePopup = useBoolean()
    const translationInProgress = useBoolean()
    const progressDialog = useBoolean()
    const speakersDialog = useBoolean()

    const initialLanguage = useMemo(
        () =>
            getInitialLanguage({
                languagesList,
                audioNote: playbook.steps.find(it => it.audioNote)?.audioNote,
                magicCapture: playbook.magicCapture,
                language: playbook.language
            }),
        [languagesList, playbook.language, playbook.steps, playbook.magicCapture]
    )

    const $translate = useDataMutation<Payload, Response, Error>(
        '/c/v1/quickguidde/translate',
        'POST'
    )

    useEffect(() => {
        if (translationInProgress.isTrue && progressDialog.isFalse)
            setPopperAnchorEl(buttonRef.current)
        else setPopperAnchorEl(null)
    }, [progressDialog.isFalse, translationInProgress])

    useEffect(() => {
        if (progressData.length > 0) return
        if (translationInProgress.isFalse) return

        translationInProgress.setFalse()
    }, [progressData, translationInProgress])

    const postTranslate = useCallback(
        (playbookId: string, speaker: SpeakerType) => {
            return $translate.mutate({ playbookId, speaker })
        },
        [$translate]
    )

    const updateTranslationProgress = useCallback(
        (speaker: SpeakerType, data: Partial<ProgressData>) => {
            setProgressData(prev => {
                const updatedIndex = prev.findIndex(it => it.speaker.id === speaker.id)
                if (updatedIndex === -1) return prev

                return [
                    ...prev.slice(0, updatedIndex),
                    { ...prev[updatedIndex], ...data },
                    ...prev.slice(updatedIndex + 1)
                ]
            })
        },
        []
    )

    const onTranslateClick = useCallback(
        async (speakers: SpeakerType[]) => {
            speakersDialog.setFalse()
            translationInProgress.setTrue()
            progressDialog.setTrue()

            AutoTranslateAnalytics.translateBtnClicked({ speakers })

            setProgressData(speakers.map(speaker => ({ speaker })))

            const results = await Promise.allSettled(
                speakers.map(speaker =>
                    postTranslate(playbook.id, speaker)
                        .then(response => ({ speaker, response }))
                        .catch(error => {
                            return Promise.reject({ speaker, error })
                        })
                )
            )

            for (const result of results) {
                if (result.status === 'fulfilled') {
                    const { speaker, response } = result.value as {
                        speaker: SpeakerType
                        response: Response
                    }
                    if (response && isQG(response.playbook)) {
                        updateTranslationProgress(speaker, { playbook: response.playbook })
                    } else {
                        updateTranslationProgress(speaker, { isFailed: true })
                    }
                } else {
                    const { speaker, error } = result.reason as {
                        speaker: SpeakerType
                        error: Error
                    }
                    console.error(error)
                    updateTranslationProgress(speaker, { isFailed: true })
                }
            }
        },
        [
            playbook,
            postTranslate,
            progressDialog,
            speakersDialog,
            translationInProgress,
            updateTranslationProgress
        ]
    )

    const handleDeleteFailedTranslation = useCallback((speaker: SpeakerType) => {
        setProgressData(prev => prev.filter(it => it.speaker.id !== speaker.id))
    }, [])

    const handleFinishedTranslation = useCallback(
        (speaker: SpeakerType) => {
            updateTranslationProgress(speaker, { isFinished: true })
        },
        [updateTranslationProgress]
    )

    const handleCloseSpeakersTranslationDialog = useCallback(() => {
        if (speakersDialog.isFalse) return

        speakersDialog.setFalse()
    }, [speakersDialog])

    const handleTryAgainFailedTranslation = useCallback(
        async (speaker: SpeakerType) => {
            AutoTranslateAnalytics.tryAgainBtnClicked(playbookToAnalyticsProps(playbook))
            updateTranslationProgress(speaker, { isFailed: false })

            try {
                const response = await postTranslate(playbook.id, speaker)
                if (!response || !isQG(response.playbook)) {
                    updateTranslationProgress(speaker, { isFailed: true })
                } else {
                    updateTranslationProgress(speaker, { playbook: response.playbook })
                }
            } catch (e) {
                console.error(e)
                updateTranslationProgress(speaker, { isFailed: true })
            }
        },
        [playbook, postTranslate, updateTranslationProgress]
    )

    const handleOpenTranslationDialog = () => {
        if (translationInProgress.isTrue) {
            progressDialog.setTrue()
            return
        }
        if (canTranslateQuickGuidde) {
            AutoTranslateAnalytics.getTranslatedCopyBtnClicked(playbookToAnalyticsProps(playbook))
            speakersDialog.toggle()
            setAnchorEl(null)
        } else {
            AutoTranslateAnalytics.upgradeBtnClicked(playbookToAnalyticsProps(playbook))
            upgradePopup.toggle()
            setAnchorEl(buttonRef.current)
        }
    }

    const handleCloseProgressDialog = () => {
        progressDialog.setFalse()
        if (progressData.every(it => !it.isFailed && it.isFinished)) {
            translationInProgress.setFalse()
        }
    }

    return {
        buttonRef,
        initialLanguage,
        languagesList,
        translationInProgress,
        handleOpenTranslationDialog,
        progressDialog,
        progressData,
        handleCloseProgressDialog,
        handleTryAgainFailedTranslation,
        handleDeleteFailedTranslation,
        handleFinishedTranslation,
        handleCloseSpeakersTranslationDialog,
        speakersDialog,
        onTranslateClick,
        upgradePopup,
        anchorEl,
        setAnchorEl,
        updateDialogPoints,
        popperAnchorEl,
        setPopperAnchorEl,
        playbook,
        canTranslateQuickGuidde
    }
}
