import { memo } from 'react'

import { useGlobalFeatureFlags } from 'hooks'
import { type PlaybookModeType } from 'app/types'

import { NewShareToSpaceDialog } from './NewShareToSpaceDialog'
import { OldShareToSpaceDialog } from './OldShareToSpaceDialog'

type Props = {
    isOpen: boolean
    onClose: () => void
    playbooksIds: Array<string>
    playbookMode?: PlaybookModeType
}

export const ShareToSpaceDialog = memo(({ playbookMode, ...props }: Props) => {
    const { enableNewShareToSpaceDialog, isLoading } = useGlobalFeatureFlags()

    if (isLoading || !props.isOpen) return null

    if (enableNewShareToSpaceDialog) {
        return <NewShareToSpaceDialog {...props} playbookMode={playbookMode} />
    }

    return <OldShareToSpaceDialog {...props} />
})
