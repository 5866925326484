import { type MouseEvent, useCallback, useMemo, useRef, useState } from 'react'

import { Stack } from '@mui/material'

import {
    getEndPoint,
    getTimestampDelta,
    minInitialTimelineWidth,
    maxZoomDistanceBetweenLabels,
    TimelineLabels,
    minDistanceBetweenLabels,
    getLabelsAmount,
    getTimelineWidth
} from './TimelineLabels'
import { TimelineChunks } from './TimelineChunks'
import { type TimelineChunksType } from '../types'
import { TimelinePointer } from './TimelinePointer'
import { checkIsSnapshotChunk } from '../splitAndTrimUtils'

const maxTimelineWidth = 65000 // max allowed width of the canvas by browser

const getZoomLevelPercentage = ({
    initialTimelineWidth,
    maxZoomTimelineWidth
}: {
    initialTimelineWidth: number
    maxZoomTimelineWidth: number
}) => {
    const zoomFactor = Math.min(maxZoomTimelineWidth, maxTimelineWidth) / initialTimelineWidth
    return Math.exp(Math.log(zoomFactor) / 100)
}

type Props = {
    zoomPercentage: number
    duration: number
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    thumbnailUrl: string
    pointerTime: number
    onTimeChange: (value: number) => void
}

export const Timeline = ({
    duration,
    zoomPercentage,
    chunks,
    thumbnailUrl,
    pointerTime,
    onTimeChange,
    setChunks
}: Props) => {
    const initialLayout = useMemo(() => {
        const snapshotsDuration = chunks.reduce((acc, chunk) => {
            if (checkIsSnapshotChunk(chunk)) return acc + chunk.duration

            return acc
        }, 0)

        const totalDuration = duration + snapshotsDuration

        const delta = getTimestampDelta(totalDuration)

        return {
            delta,
            endPoint: getEndPoint({ duration: totalDuration, delta })
        }
    }, [chunks, duration])

    const initialTimelineWidth = useMemo(() => {
        const labelsAmount = getLabelsAmount({
            endPoint: initialLayout.endPoint,
            delta: initialLayout.delta
        })

        const calculatedInitialTimelineWidth = getTimelineWidth({
            distanceBetweenLabels: minDistanceBetweenLabels,
            labelsAmount
        })

        return Math.max(calculatedInitialTimelineWidth, minInitialTimelineWidth)
    }, [initialLayout])

    const exponentialZoomLevelPercentage = useMemo(() => {
        const labelsAmount = getLabelsAmount({
            endPoint: initialLayout.endPoint,
            delta: 1
        })

        const maxZoomTimelineWidth = getTimelineWidth({
            distanceBetweenLabels: maxZoomDistanceBetweenLabels,
            labelsAmount
        })

        return getZoomLevelPercentage({
            initialTimelineWidth,
            maxZoomTimelineWidth
        })
    }, [initialLayout, initialTimelineWidth])

    const timelineWidth = useMemo(
        () => initialTimelineWidth * Math.pow(exponentialZoomLevelPercentage, zoomPercentage),
        [exponentialZoomLevelPercentage, initialTimelineWidth, zoomPercentage]
    )

    const [scrollElement, setScrollElement] = useState<HTMLDivElement | null>(null)

    const scrollRefCallback = useCallback((target: HTMLDivElement) => {
        setScrollElement(target)
    }, [])

    const timelineContainerRef = useRef<HTMLDivElement | null>(null)
    const timelineContainerElement = timelineContainerRef.current

    const handleTimelineClick = useCallback(
        (event: MouseEvent) => {
            if (!timelineContainerElement) return

            const rect = timelineContainerElement.getBoundingClientRect()
            const clickX = event.clientX - rect.left
            const clickPercentage = clickX / rect.width
            const newTime = Math.min(clickPercentage * initialLayout.endPoint, duration)

            onTimeChange(newTime)
        },
        [timelineContainerElement, initialLayout.endPoint, duration, onTimeChange]
    )

    return (
        <Stack
            ref={scrollRefCallback}
            sx={{
                overflowX: 'auto',
                px: 1,
                mx: -1,
                pt: 1,
                pb: 4.25,
                position: 'relative',
                cursor: 'pointer'
            }}
            onClick={handleTimelineClick}
        >
            <Stack width={timelineWidth} sx={{ position: 'relative' }} ref={timelineContainerRef}>
                <TimelinePointer
                    pointerTime={pointerTime}
                    setPointerTime={onTimeChange}
                    timelineContainerElement={timelineContainerElement}
                    endPoint={initialLayout.endPoint}
                />
                <Stack spacing={2.5}>
                    <TimelineLabels
                        timelineWidth={timelineWidth}
                        initialDelta={initialLayout.delta}
                        endPoint={initialLayout.endPoint}
                        zoomPercentage={zoomPercentage}
                        scrollElement={scrollElement}
                    />
                    <TimelineChunks
                        chunks={chunks}
                        endPoint={initialLayout.endPoint}
                        thumbnailUrl={thumbnailUrl}
                        timelineWidth={timelineWidth}
                        duration={duration}
                        timelineContainerElement={timelineContainerElement}
                        setChunks={setChunks}
                        onTimeChange={onTimeChange}
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}
