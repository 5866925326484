import { firebaseConfig } from 'env'

export class DesktopCommunication {
    public static authCallback(token: string) {
        const currentRegion = firebaseConfig.authDomain === 'eu.guidde.com' ? 'EU' : 'US'

        window.open(`guidde://auth-callback?token=${token}&region=${currentRegion}`, '_self')
    }

    public static onBoardingDone() {
        window.open(`guidde://onboarding-done`, '_self')
    }
}
