export enum TimelineChunkVariant {
    video = 'video',
    snapshot = 'snapshot'
}

export type TimelineVideoChunkType = {
    type: TimelineChunkVariant.video
    start: number
    end: number
    id: string
}

export type TimelineSnapshotChunkType = {
    type: TimelineChunkVariant.snapshot
    duration: number
    imageUrl: string
    width: number
    height: number
    id: string
}

export type TimelineChunkType = TimelineVideoChunkType | TimelineSnapshotChunkType

export type TimelineChunksType = Array<TimelineChunkType>

export type TrimmedChunk = {
    start: number
    end: number
} | null
