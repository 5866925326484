import { Stack } from '@mui/material'

import { Icon, IconButton, spacings } from '@guidde/design-system'
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons/faRotateLeft'
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons/faRotateRight'

import { logToAnalytics } from 'modules'

type Props = {
    undo: () => void
    redo: () => void
    canUndo: boolean
    canRedo: boolean
}

export const UndoRedoControls = ({ undo, redo, canUndo, canRedo }: Props) => {
    return (
        <Stack direction="row" spacing={spacings.xl} width={160} justifyContent="flex-end">
            <IconButton
                label="undo"
                variant="text"
                color="secondary"
                size="sm"
                disabled={!canUndo}
                onClick={() => {
                    undo()
                    logToAnalytics('editVideoStep_undoBtn_clicked')
                }}
            >
                <Icon icon={faRotateLeft} />
            </IconButton>
            <IconButton
                label="undo"
                variant="text"
                color="secondary"
                size="sm"
                disabled={!canRedo}
                onClick={() => {
                    redo()
                    logToAnalytics('editVideoStep_redoBtn_clicked')
                }}
            >
                <Icon icon={faRotateRight} />
            </IconButton>
        </Stack>
    )
}
