import { useState, useCallback, useMemo } from 'react'

type State<T> = {
    past: Array<T>
    present: T
    future: Array<T>
}

export const useUndoableState = <T>(
    initialValue: T
): [T, (newPresent: T) => void, () => void, () => void, boolean, boolean] => {
    const [state, setState] = useState<State<T>>({
        past: [],
        present: initialValue as T,
        future: []
    })

    const set = useCallback(
        (newValue: T) => {
            setState(currentState => {
                const { past, present } = currentState
                return {
                    past: [...past, present],
                    present: newValue,
                    future: []
                }
            })
        },
        [setState]
    )

    const undo = useCallback(() => {
        setState(currentState => {
            const { past, present, future } = currentState
            if (past.length === 0) return currentState

            const previous = past[past.length - 1]
            const newPast = past.slice(0, past.length - 1)

            return {
                past: newPast,
                present: previous,
                future: [present, ...future]
            }
        })
    }, [setState])

    const redo = useCallback(() => {
        setState(currentState => {
            const { past, present, future } = currentState
            if (future.length === 0) return currentState

            const next = future[0]
            const newFuture = future.slice(1)

            return {
                past: [...past, present],
                present: next,
                future: newFuture
            }
        })
    }, [setState])

    const canUndo = state.past.length > 0
    const canRedo = state.future.length > 0

    return useMemo(() => {
        return [state.present, set, undo, redo, canUndo, canRedo]
    }, [state.present, set, undo, redo, canUndo, canRedo])
}
