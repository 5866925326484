import { type ButtonProps, Button, Typography } from '@mui/material'

import { getUserBrowser } from 'modules'

import { ReactComponent as ChromeIcon } from 'assets/icons/chrome.svg'
import { ReactComponent as EdgeIcon } from 'assets/icons/edge.svg'

const isEdge = getUserBrowser() === 'edge'

type Props = {
    onClick: (browserType: string) => void
} & Omit<ButtonProps, 'onClick'>

export const ExtensionButton = ({ onClick, style = {}, ...rest }: Props) => (
    <Button
        size="large"
        variant="contained"
        data-test="extension-button"
        startIcon={isEdge ? <EdgeIcon /> : <ChromeIcon />}
        onClick={() => onClick(isEdge ? 'edge' : 'chromium')}
        style={{
            ...style,
            height: '36.5px',
            minWidth: 230,
            textTransform: 'uppercase',
            letterSpacing: '1.25 px'
        }}
        {...rest}
    >
        <Typography>Add guidde to {isEdge ? 'Edge' : 'Chrome'}</Typography>
    </Button>
)
