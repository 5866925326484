import { Input, Icon } from '@guidde/design-system'
import { InputAdornment } from '@mui/material'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'

type Props = {
    value: string
    disabled?: boolean
    onChange: (newValue: string) => void
}

export const SearchInput = ({ value, disabled, onChange }: Props) => (
    <Input
        value={value}
        placeholder="Search"
        isDisabled={disabled}
        onChange={(e: any) => onChange(e.target.value)}
        endAdornment={
            <InputAdornment position="end">
                <Icon icon={faMagnifyingGlass} />
            </InputAdornment>
        }
    />
)
