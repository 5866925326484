// appAuth
export const UPDATE_APP_AUTH = '@APPLICATION_AUTH/UPDATE'
export const RESET_APP_AUTH = '@APPLICATION_AUTH/RESET'
export const REGISTER_SET_AUTH_STATUS = '@APPLICATION_AUTH/NEW_AUTH'

// extensionStatus
export const SET_EXTENSION_STATUS = '@EXTENSION_STATUS/SET'
export const SET_QUICK_GUIDDE_RECORDING_STATUS = '@EXTENSION_STATUS/SET_RECORDING_STATUS'

// playbookFilters
export const SET_MY_GUIDDES_FILTERS = '@MY_GUIDDES_FILTERS/SET'
export const SET_LIBRARY_FACET_FILTERS = '@LIBRARY_FACET_FILTERS/SET'
export const SET_LIBRARY_PLAYBOOK_FILTERS = '@LIBRARY_PLAYBOOK_FILTERS/SET'
export const RESET_LIBRARY_PLAYBOOK_MODE = '@RESET_LIBRARY_PLAYBOOK_MODE'
export const RESET_LIBRARY_PLAYBOOK_FILTERS = '@RESET_LIBRARY_PLAYBOOK_FILTERS'
export const RESET_MY_PLAYBOOKS_FILTERS = '@RESET_MY_PLAYBOOKS_FILTERS'

// uploadProgressPopup
export const OPEN_UPLOAD_PROGRESS_POPUP = '@UPLOAD_PROGRESS_POPUP/OPEN'
export const CLOSE_UPLOAD_PROGRESS_POPUP = '@UPLOAD_PROGRESS_POPUP/CLOSE'
export const UPDATE_FILE_PROGRESS = '@UPLOAD_PROGRESS_POPUP/UPDATE_PROGRESS'

// videoEditor
export const SET_SECTIONS = '@VIDEO_EDITOR/SET-SECTIONS'
export const SET_INITIAL_SECTIONS = '@VIDEO_EDITOR/SET-INITIAL-SECTIONS'
export const SET_SECTIONS_RESET = '@VIDEO_EDITOR/SET-SECTIONS-RESET'
export const SET_CURRENT_TIME = '@VIDEO_EDITOR/SET-CURRENT-TIME'
export const SET_DURATION = '@VIDEO_EDITOR/SET-DURATION'
export const SET_ACTIVE_SECTION = '@VIDEO_EDITOR/SET-ACTIVE-SECTION'
export const SET_CHAPTER_TITLE = '@VIDEO_EDITOR/SET-CHAPTER-TITLE'
export const TOGGLE_PLAYBOOK_SECTION = '@VIDEO_EDITOR/TOGGLE-PLAYBOOK-SECTION'
export const CLOSE_VIDEO_EDITOR = '@VIDEO_EDITOR/CLOSE'

// videoProgress
export const SET_VIDEO_PROGRESS = '@VIDEO_PROGRESS/SET_VIDEO_PROGRESS'
export const RESET_VIDEO_PROGRESS = '@VIDEO_PROGRESS/RESET_VIDEO_PROGRESS'

// qgEditor
export const SET_STEP_TRANSITION = '@QUICK_GUIDDE/SET_STEP_TRANSITION'
export const TOGGLE_MULTIPLE_SELECTED_STEPS = '@QUICK_GUIDDE/TOGGLE_MULTIPLE_SELECTED_STEPS'
export const SET_ACTIVE_STEP = '@QUICK_GUIDDE/SET_ACTIVE_STEP'
export const SET_LAYER_POSITION = '@QUICK_GUIDDE/SET_LAYER_POSITION'
export const SET_LAYER_TRANSFORM = '@QUICK_GUIDDE/SET_LAYER_TRANSFORM'
export const SET_LAYER_TEXT = '@QUICK_GUIDDE/SET_LAYER_TEXT'
export const SET_LAYER_ORDER = '@QUICK_GUIDDE/SET_LAYER_ORDER'
export const TOGGLE_SPOTLIGHT = '@QUICK_GUIDDE/TOGGLE_SPOTLIGHT'
export const SET_BACKGROUND_LAYER = '@QUICK_GUIDDE/SET_BACKGROUND_LAYER'
export const ADD_NEW_LAYER = '@QUICK_GUIDDE/ADD_NEW_LAYER'
export const SET_COMPANY_LOGO = '@QUICK_GUIDDE/SET_COMPANY_LOGO'
export const SET_FONT_STYLES = '@QUICK_GUIDDE/SET_FONT_STYLES'
export const SET_TEXT_SHAPE_SIZE = '@QUICK_GUIDDE/SET_TEXT_SHAPE_SIZE'
export const SET_TEXT_COLORS = '@QUICK_GUIDDE/SET_TEXT_COLORS'
export const ADD_STEP_NUMBERING = '@QUICK_GUIDDE/ADD_STEP_NUMBERING'
export const SET_STEP_NUMBERING_TEXT = '@QUICK_GUIDDE/SET_STEP_NUMBERING_TEXT'
export const REMOVE_STEP_NUMBERING = '@QUICK_GUIDDE/REMOVE_STEP_NUMBERING'
export const OVERWRITE_STEP = '@QUICK_GUIDDE/SET_QG_STEP'
export const SET_VIDEO_PREVIEW = '@QUICK_GUIDDE/SET_VIDEO_PREVIEW'
export const ADD_NEW_STEP = '@QUICK_GUIDDE/ADD_NEW_STEP'
export const DUPLICATE_STEPS = '@QUICK_GUIDDE/DUPLICATE_STEPS'
export const REMOVE_ACTIVE_STEP = '@QUICK_GUIDDE/REMOVE_ACTIVE_STEP'
export const SET_QUICK_GUIDDE_STEPS = '@QUICK_GUIDDE/SET_QUICK_GUIDDE_STEPS'
export const PASTE_COPIED_STEPS = '@QUICK_GUIDDE/PASTE_COPIED_STEPS'
export const SET_QUICK_GUIDDE_DURATION = '@QUICK_GUIDDE/SET_QUICK_GUIDDE_DURATION'
export const SET_QUICK_GUIDDE_STEP_CTA = '@QUICK_GUIDDE/SET_QUICK_GUIDDE_STEP_CTA'
export const SELECT_LAYER = '@QUICK_GUIDDE/SELECT_LAYER'
export const DUPLICATE_SELECTED_LAYER = '@QUICK_GUIDDE/DUPLICATE_SELECTED_LAYER'
export const MOVE_SELECTED_LAYER = '@QUICK_GUIDDE/MOVE_SELECTED_LAYER'
export const COPY_SELECTED_LAYER = '@QUICK_GUIDDE/COPY_SELECTED_LAYER'
export const RESET_COPIED_LAYER = '@QUICK_GUIDDE/RESET_COPIED_LAYER'
export const PASTE_SELECTED_LAYER = '@QUICK_GUIDDE/PASTE_SELECTED_LAYER'
export const REMOVE_SELECTED_LAYER = '@QUICK_GUIDDE/REMOVE_SELECTED_LAYER'
export const DISABLE_QG_SHORTCUTS = '@QUICK_GUIDDE/DISABLE_QG_SHORTCUTS'
export const ENABLE_QG_SHORTCUTS = '@QUICK_GUIDDE/ENABLE_QG_SHORTCUTS'
export const SET_TEMP_QUICK_GUIDDE_AUDIO_NOTE = '@QUICK_GUIDDE/SET_TEMP_QUICK_GUIDDE_AUDIO_NOTE'
export const SET_MULTIPLE_QUICK_GUIDDE_AUDIO_NOTES = '@QUICK_GUIDDE/SET_MULTIPLE_AUDIO_NOTES'
export const REMOVE_MULTIPLE_QUICK_GUIDDE_AUDIO_NOTES = '@QUICK_GUIDDE/REMOVE_MULTIPLE_AUDIO_NOTES'
export const SET_QUICK_GUIDDE_AUDIO_NOTE = '@QUICK_GUIDDE/SET_AUDIO_NOTE'
export const SET_QUICK_GUIDDE_AUDIO_NOTE_TEMP_MARKDOWN =
    '@QUICK_GUIDDE/SET_AUDIO_NOTE_TEMP_MARKDOWN'
export const SET_ARROW_DIRECTION = '@QUICK_GUIDDE/SET_ARROW_DIRECTION'
export const SET_IMAGE_STEP_AREA = '@QUICK_GUIDDE/SET_IMAGE_STEP_AREA'
export const SET_QUICK_GUIDDE_STEP_LAYER = '@QUICK_GUIDDE/SET_STEP_LAYER'
export const SET_QUICK_GUIDDE_STEP_TITLE = '@QUICK_GUIDDE/SET_STEP_TITLE'
export const SET_QUICK_GUIDDE_VIDEO_STEP_RANGES = '@QUICK_GUIDDE/VIDEO_STEP_RANGES'
export const RESET_STEPS = '@QUICK_GUIDDE/RESET_STEPS'
export const SET_LAYER_FILL = '@QUICK_GUIDDE/SET_LAYER_FILL'
export const MULTI_ALIGN_SELECTED_LAYERS = '@QUICK_GUIDDE/MULTI_ALIGN_SELECTED_LAYERS'
export const APPLY_LAYER_TO_ALL_STEPS = '@QUICK_GUIDDE/APPLY_LAYER_TO_ALL_STEPS'
export const SET_STEPS_SUBTITLES = '@QUICK_GUIDDE/SET_STEPS_SUBTITLES'
export const SET_STEP_SUBTITLES_BLOCK = '@QUICK_GUIDDE/SET_STEP_SUBTITLES_BLOCK'

// qgMetadata
export const SET_SIDE_PANEL_MODE = '@QG_METADATA/SET_SIDE_PANEL_MODE'
export const SET_QUICK_GUIDDE_METADATA = '@QG_METADATA/SET_QUICK_GUIDDE_METADATA'
export const SET_QUICK_GUIDDE_TITLE = '@QG_METADATA/SET_QUICK_GUIDDE_TITLE'
export const SET_QUICK_GUIDDE_DESCRIPTION = '@QG_METADATA/SET_QUICK_GUIDDE_DESCRIPTION'
export const SET_QUICK_GUIDDE_APPLICATIONS = '@QG_METADATA/SET_QUICK_GUIDDE_APPLICATIONS'
export const SET_QUICK_GUIDDE_TAGS = '@QG_METADATA/SET_QUICK_GUIDDE_TAGS'
export const SET_QUICK_GUIDDE_AUDIO = '@QG_METADATA/SET_QUICK_GUIDDE_AUDIO'
export const SET_QUICK_GUIDDE_AUDIO_VOLUME = '@QG_METADATA/SET_QUICK_GUIDDE_AUDIO_VOLUME'

// cta
export const SET_QUICK_GUIDDE_MULTI_CTA_VISIBILITY = '@QG_CTA/SET_QUICK_MULTI_CTA_MODE'
export const SET_QUICK_GUIDDE_STEP_CTA_VISIBILITY = '@QG_CTA/SET_QUICK_GUIDDE_STEP_CTA_VISIBILITY'
export const SET_QUICK_GUIDDE_MULTI_APPLY_VISIBILITY =
    '@QG_CTA/SET_QUICK_GUIDDE_MULTI_APPLY_VISIBILITY'
export const SET_QUICK_GUIDDE_STEP_CTA_ACTION = '@QG_CTA/SET_QUICK_GUIDDE_STEP_CTA_ACTION'
export const SET_QUICK_GUIDDE_CURRENT_CTA = '@QG_CTA/SET_QUICK_GUIDDE_CURRENT_CTA'

// serviceUsage
export const SHOW_SERVICE_USAGE_DIALOG = '@SERVICE_USAGE/SHOW_DIALOG'
export const CLOSE_SERVICE_USAGE_DIALOGS = '@SERVICE_USAGE/CLOSE_DIALOGS'
export const SHOW_SERVICE_USAGE_NOTIFICATIONS = '@SERVICE_USAGE/SHOW_NOTIFICATIONS'

// configs
export const SET_AUDIO_CONFIGS = '@CONFIGS/SET_AUDIO_CONFIGS'
export const SET_USER_PREFERENCES = '@CONFIGS/SET_USER_PREFERENCES'

// Paste steps loading indications
export const SET_QUICK_GUIDDE_STEPS_LOADING = '@PASTE_STEPS/SET_QUICK_GUIDDE_STEPS_LOADING'
