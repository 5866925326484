import { useCallback, useEffect, useRef, useState, useContext, useMemo } from 'react'

import { Dialog, Stack, Slider } from '@mui/material'

import { faMinus } from '@fortawesome/pro-regular-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'

import { Button, Icon, IconButton, ThemeProvider, Typography } from '@guidde/design-system'

import { Timeline } from './Timeline'
import { PointerButtons } from './PointerButtons'
import { PlayerControls, StepsPlayer, UndoRedoControls } from './StepsPlayer'
import { SaveSplitsButton } from './SaveSplitsButton'

import { useMapTimelineToVideoTime } from './useMapTimelineToVideoTime'

import { type QuickGuiddeType } from 'app/types'
import { TimelineChunkVariant, type TimelineChunksType } from './types'

import { logToAnalytics, uuid } from 'modules'
import { useBoolean, useUndoableState } from 'hooks'
import { ManualSplitProvider } from './ManualSplitProvider'
import { getChunkDuration } from './splitAndTrimUtils'

type Props = {
    videoInfo: { url: string; duration: number; thumbnailUrl: string; start: number; end: number }
    language: QuickGuiddeType['language']
    onClose: () => void
}

export const SplitAndTrimDialog = ({ videoInfo, language, onClose }: Props) => {
    const [chunks, setChunks, undo, redo, canUndo, canRedo] = useUndoableState<TimelineChunksType>([
        { type: TimelineChunkVariant.video, start: videoInfo.start, end: videoInfo.end, id: uuid() }
    ])

    const { tempChunks, setTempChunks } = useContext(ManualSplitProvider)

    useEffect(() => {
        setTempChunks(chunks)
    }, [chunks, setTempChunks])

    const [zoomPercentage, setZoomPercentage] = useState(0)

    const videoPlayerRef = useRef<HTMLVideoElement | null>(null)

    const playing = useBoolean()
    const [playerTime, setPlayerTime] = useState(0)

    const chunksWithLongerDuration = useMemo(() => {
        const chunksDuration = chunks.reduce((acc, chunk) => {
            const chunkDuration = getChunkDuration(chunk)
            return chunkDuration + acc
        }, 0)

        const tempChunksDuration = tempChunks.reduce((acc, chunk) => {
            const chunkDuration = getChunkDuration(chunk)
            return chunkDuration + acc
        }, 0)

        return chunksDuration > tempChunksDuration ? chunks : tempChunks
    }, [chunks, tempChunks])

    const { getPointerVideoTime, chunksTimelineDuration } = useMapTimelineToVideoTime({
        chunks: chunksWithLongerDuration,
        pointerTime: playerTime
    })

    const handleTimeChange = useCallback(
        (time: number) => {
            const limitedTime = Math.max(0, Math.min(time, chunksTimelineDuration))
            setPlayerTime(limitedTime)

            const videoTime = getPointerVideoTime({
                currentTime: limitedTime,
                canReachChapterBounds: true
            })

            if (!videoPlayerRef.current) return
            if (videoTime === null) return

            videoPlayerRef.current.currentTime = videoTime
        },
        [chunksTimelineDuration, getPointerVideoTime]
    )

    useEffect(() => {
        const limitValue = (value: number) => Math.min(Math.max(value, 0), chunksTimelineDuration)

        const handleKeyPress = (event: KeyboardEvent) => {
            switch (event.key) {
                case 'ArrowLeft':
                    handleTimeChange(limitValue(playerTime - 1))
                    break
                case 'ArrowRight':
                    handleTimeChange(limitValue(playerTime + 1))
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', handleKeyPress)

        return () => {
            window.removeEventListener('keydown', handleKeyPress)
        }
    }, [chunksTimelineDuration, handleTimeChange, playerTime])

    return (
        <ThemeProvider>
            <Dialog
                open
                fullWidth
                PaperProps={{ sx: { maxWidth: 732 } }}
                sx={{
                    fontFamily: '"Inter", sans-serif',
                    '*': {
                        userSelect: 'none'
                    }
                }}
            >
                <Stack>
                    <Stack sx={{ p: '16px 24px 0px' }}>
                        <Stack spacing={3}>
                            <Stack spacing={1.25}>
                                <Typography variant="text" size="lg" fontWeight="semibold">
                                    Edit video
                                </Typography>
                                <Typography variant="text" size="sm" color="grey.600">
                                    Place the marker on the timeline, then hit ‘Split’ to cut or
                                    ‘Snapshot’ to freeze a frame. <br /> To merge cuts, hover with
                                    your cursor between the parts.
                                </Typography>
                            </Stack>
                            <StepsPlayer
                                videoUrl={videoInfo.url}
                                videoPlayerRef={videoPlayerRef}
                                pointerTime={playerTime}
                                chunks={chunks}
                                playerTime={playerTime}
                                setPlayerTime={setPlayerTime}
                                playing={playing}
                            />
                        </Stack>
                        <Stack
                            py={1.25}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            sx={theme => ({
                                borderBottom: `1px solid ${theme.palette.grey[100]}`
                            })}
                        >
                            <PointerButtons
                                pointerTime={playerTime}
                                chunks={chunks}
                                setChunks={setChunks}
                                videoPlayerElement={videoPlayerRef.current}
                            />
                            <PlayerControls
                                chunks={chunks}
                                playing={playing}
                                playerTime={playerTime}
                            />
                            <UndoRedoControls
                                undo={undo}
                                redo={redo}
                                canUndo={canUndo}
                                canRedo={canRedo}
                            />
                        </Stack>
                        <Timeline
                            duration={videoInfo.duration}
                            zoomPercentage={zoomPercentage}
                            chunks={chunks}
                            thumbnailUrl={videoInfo.thumbnailUrl}
                            pointerTime={playerTime}
                            onTimeChange={handleTimeChange}
                            setChunks={setChunks}
                        />
                    </Stack>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={theme => ({
                            py: 1.75,
                            px: 3,
                            borderTop: `1px solid ${theme.palette.grey[200]}`
                        })}
                    >
                        <Stack spacing={3} direction="row" alignItems="center">
                            <IconButton
                                label="Zoom out"
                                disabled={zoomPercentage <= 0}
                                onClick={() => setZoomPercentage(zoomPercentage - 1)}
                                variant="text"
                                color="secondary"
                                size="sm"
                            >
                                <Icon icon={faMinus} />
                            </IconButton>
                            <Slider
                                step={1}
                                value={zoomPercentage}
                                onChange={(_e, newValue) => setZoomPercentage(newValue as number)}
                                sx={{ width: 80 }}
                            />
                            <IconButton
                                label="Zoom in"
                                onClick={() => setZoomPercentage(zoomPercentage + 1)}
                                disabled={zoomPercentage >= 100}
                                variant="text"
                                color="secondary"
                                size="sm"
                            >
                                <Icon icon={faPlus} />
                            </IconButton>
                        </Stack>
                        <Stack spacing={1.5} direction="row" alignItems="center" width={236}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                    onClose()
                                    logToAnalytics('editVideoStep_cancelBtn_clicked')
                                }}
                            >
                                Cancel
                            </Button>
                            <SaveSplitsButton
                                chunks={chunks}
                                language={language}
                                onDone={onClose}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Dialog>
        </ThemeProvider>
    )
}
