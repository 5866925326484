import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { type RectangleShapeType, Shape } from 'app/types'
import { noBrandKitBackgroundId, useBrandKit } from 'hooks'

export const useScaleFactor = (): number => {
    const { steps, activeStep } = useSelector(state => state.qgEditor.present)

    const { layers } = steps[activeStep]

    const bgRectLayer = layers?.find(
        layer => layer.type === Shape.Rectangle && layer.isBackground
    ) as RectangleShapeType | undefined

    const { brandKitBackground } = useBrandKit()

    return useMemo(() => {
        if (bgRectLayer?.scaleFactor) return bgRectLayer.scaleFactor

        const isNoBackground = brandKitBackground?.id === noBrandKitBackgroundId
        if (!brandKitBackground || isNoBackground) return 1

        const hasBrandKitScaleFactor = 'scaleFactor' in brandKitBackground
        return hasBrandKitScaleFactor ? brandKitBackground.scaleFactor : 0.8
    }, [bgRectLayer, brandKitBackground])
}
