import { memo } from 'react'
import { type OptionType } from 'modules'

import { ThemeProvider, MultipleSelect } from '@guidde/design-system'
import { capitalize } from '@mui/material/utils'

type ValuesType = Array<string>

type Props = {
    value: ValuesType
    onChange: (newValues: ValuesType, oldValues: ValuesType) => void
    options: Array<OptionType>
    defaultLabel: string
    defaultOption: OptionType
    labelIcon?: null | JSX.Element
    multipleItemsLabel?: string
    width?: number | string
    minWidth?: number
}

export const NewMultipleSelect = memo(
    ({ value, onChange, options = [], multipleItemsLabel = '' }: Props) => (
        <ThemeProvider>
            <MultipleSelect
                options={options.filter(Boolean).map(o => ({ ...o, hasCheckbox: true }))}
                hasIndeterminate
                label={capitalize(multipleItemsLabel)}
                isIndeterminateWithCheckbox
                placeholder="Select"
                getInputValue={values => {
                    if (values.length === 0) {
                        return ''
                    }

                    if (values.length === 1) {
                        return values[0].label
                    }

                    return `Selected: ${values.length}`
                }}
                onChange={(_, currentValues) => {
                    const newValues = currentValues.map(o => String(o.value))

                    onChange(newValues, value)
                }}
            />
        </ThemeProvider>
    )
)
