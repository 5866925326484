import { useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button } from '@mui/material'
import ContentCutIcon from '@mui/icons-material/ContentCut'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { TrimDialog } from './TrimDialog'

import { useBoolean, useGlobalFeatureFlags } from 'hooks'
import { type VideoShapeType, Shape, type QuickGuiddeType } from 'app/types'

import { isDeepEqual, logToAnalytics, uuid } from 'modules'
import { setVideoStepRanges } from 'ducks'
import { SplitAndTrimDialog } from './SplitAndTrimDialog'
import { Tooltip } from '@guidde/design-system'
import { ChunksProvider } from './SplitAndTrimDialog/ManualSplitProvider'
import { TimelineChunkVariant } from './SplitAndTrimDialog/types'

type Props = {
    language: QuickGuiddeType['language']
}
export const EditVideo = ({ language }: Props) => {
    const dispatch = useDispatch()

    const { enableManualSplit } = useGlobalFeatureFlags()

    const { playbookId } = useParams<{ playbookId: string }>()

    const open = useBoolean()

    const { steps, activeStep } = useSelector(state => state.qgEditor.present)

    const { extractVideoStep } = useContext(VoiceOverContext)

    const { layers, id, audioNote } = steps[activeStep]

    const ignoreVoiceoverGenerate = useMemo(() => {
        if (!audioNote) return false
        return 'isUserEdited' in audioNote && Boolean(audioNote.isUserEdited)
    }, [audioNote])

    const videoLayer = layers.find(layer => layer.type === Shape.Video) as
        | VideoShapeType
        | undefined
    const isVideoEditDisabled = !videoLayer

    return (
        <>
            <Tooltip title={isVideoEditDisabled ? 'You can only edit a video step' : ''}>
                <Button
                    variant="text"
                    color="inherit"
                    onClick={() => {
                        open.setTrue()
                        logToAnalytics('editVideoStep_clicked', { playbookId, stepId: id })
                    }}
                    style={{ minWidth: 30 }}
                    startIcon={<ContentCutIcon fontSize="small" />}
                    disabled={isVideoEditDisabled}
                >
                    Edit video
                </Button>
            </Tooltip>

            {open.isTrue && !isVideoEditDisabled && (
                <>
                    {enableManualSplit ? (
                        <ChunksProvider
                            initialChunks={[
                                {
                                    type: TimelineChunkVariant.video,
                                    start: videoLayer.start,
                                    end: videoLayer.end,
                                    id: uuid()
                                }
                            ]}
                        >
                            <SplitAndTrimDialog
                                videoInfo={{
                                    duration: videoLayer.sourceDuration,
                                    url: videoLayer.sourceVideoUrl,
                                    thumbnailUrl: videoLayer.sourceVideoThumbnailPreview,
                                    start: videoLayer.start,
                                    end: videoLayer.end
                                }}
                                language={language}
                                onClose={open.setFalse}
                            />
                        </ChunksProvider>
                    ) : (
                        <TrimDialog
                            videoInfo={{
                                duration: videoLayer.sourceDuration,
                                url: videoLayer.sourceVideoUrl,
                                thumbnail: videoLayer.sourceVideoThumbnailPreview
                            }}
                            initialStart={videoLayer.start}
                            initialEnd={videoLayer.end}
                            onClose={open.setFalse}
                            onConfirm={(start, end) => {
                                open.setFalse()

                                const initialValues = {
                                    start: videoLayer.start,
                                    end: videoLayer.end
                                }
                                const newValues = { start, end }
                                if (isDeepEqual(initialValues, newValues)) return

                                dispatch(setVideoStepRanges({ start, end }))
                                logToAnalytics('videoStep_video_trimmed', {
                                    playbookId,
                                    start,
                                    end
                                })

                                if (ignoreVoiceoverGenerate) return

                                extractVideoStep({
                                    playbookId,
                                    start,
                                    end,
                                    languageCode: language?.langCode || 'en-US',
                                    languageName: language?.langName || 'English',
                                    stepId: id,
                                    videoUrl: videoLayer.sourceVideoUrl
                                })
                            }}
                        />
                    )}
                </>
            )}
        </>
    )
}
