import { useMemo, useCallback } from 'react'

import { useDocumentData } from 'react-firebase-hooks/firestore'
import { type Timestamp, doc } from 'firebase/firestore'
import { getDatabase, ref, set } from 'firebase/database'

import { type BillingPeriod } from '@stigg/api-client-js/src/generated/sdk'

import { useAuth } from 'hooks'
import { formatDate, calculateDaysLeft, logToAnalytics, db } from 'modules'

const generateTrialText = (time: number) => (
    <>
        Your free trial will expire in
        <b> {calculateDaysLeft(time * 1000)}</b> days on
        <b> {formatDate(time)}</b>
    </>
)

// TODO: get all data below from metadata when it's ready
export const planTypes = {
    individual: 'plan-guidde-individual',
    team: 'plan-guidde-team',
    connect: 'plan-guidde-connect',
    business: 'plan-guidde-business',
    pro: 'plan-guidde-pro',
    enterprise: 'plan-guidde-enterprise'
} as const

export type PlanIdType = (typeof planTypes)[keyof typeof planTypes]

export type ServicesUsageType = {
    usedPlaybooksPercent: number
    usedUsersSeats: number
    usedPlaybooksSlots: number
    usedVideoSeconds: number
    usedSpacesSlots: number
    usedUniqueViewers: number
    usedStorage: number
    userSeatsQuota: number | null
    videoSecondsQuota: number | null
    playbooksQuota: number | null
    spacesQuota: number | null
    uniqueViewersQuota: number | null
    activityFeedQuota: number | null
    insightsQuota: number | null
    magicCaptureQuota: number | null
    brandKitsLimit: number
    quotaLabels: {
        playbooksQuota: string
        spacesQuota: string
        userSeatsQuota: string
        videoMinutesQuota: string
        uniqueViewersQuota: string
        magicCaptureQuota: string
    }
    usedVideoPercents: number
    planId: (typeof planTypes)[keyof typeof planTypes]
    planName: string
    planPrice: number
    planDescription: string
    isTrialPlan: boolean
    isFreePlan: boolean
    isCustomPrice: boolean
    isDefaultValues: boolean
    hasCustomSubdomain: boolean | null
    canTranslateQuickGuidde: boolean | null
    hasSDK: boolean | null
    hasTrackLink: boolean | null
    hasGdrive: boolean | null
    hasOneDrive: boolean | null
    canRemoveWatermark: boolean | null
    canHideWatermarkEmbed: boolean | null
    hasVideoBlur: boolean | null
    hasStudioVoices: boolean | null
    hasVideoUploads: boolean | null
    canUploadCustomFont: boolean | null
    hasYoutube: boolean | null
    hasZoom: boolean | null
    hasMultipleCta: boolean | null
    allowSsoOnly: boolean | null
    hasCustomBranding: boolean | null
    hasPrivacyControl: boolean | null
    hasVideoExport: boolean | null
    hasVoiceOverTextToSpeech: boolean | null
    hasDesktopApp: boolean | null
    desktopAppTrialStartedAt: number | null
    billingPeriod: BillingPeriod | null
    billingContact: string | null
    nextBillingDate: Timestamp | null

    trial: {
        startedAt?: Timestamp
        trialEndDate?: Timestamp
        duration?: number
        isReversed?: boolean
    } | null
    upcomingPlan: {
        planId: string
        name: string
        startsAt: Timestamp
    } | null

    flags: {
        cannotUseTrial: boolean
        readOnly: {
            playbooks: boolean
            spaces: boolean
            users: boolean
            uniqueViewers: boolean
        }
    }
    triggerCount: number
    lastStorageThreshold: number
    [key: `feature-integration-${string}`]: boolean
}

export const useServiceUsage = () => {
    const { orgId, uid } = useAuth()

    const [serviceUsage, loading] = useDocumentData(doc(db, `servicesUsage/${orgId}`))

    const {
        planId: activePlanId,
        planName,
        planDescription,
        upcomingPlan,
        activityFeedQuota: activityFeedDays,
        insightsQuota: insightsDays,
        isCustomPrice,
        isTrialPlan,
        trial,
        billingContact: billingEmail,
        usedStorage,
        nextBillingDate,
        billingPeriod,
        flags,
        quotaLabels,
        // Boolean flags
        hasCustomBranding,
        hasSDK,
        hasTrackLink,
        hasGdrive,
        hasOneDrive,
        canUploadCustomFont,
        canRemoveWatermark,
        canTranslateQuickGuidde,
        hasVideoBlur,
        hasVideoUploads,
        hasYoutube,
        hasZoom,
        hasMultipleCta,
        allowSsoOnly,
        hasVoiceOverTextToSpeech,
        isDefaultValues,
        // Quotas
        playbooksQuota,
        usedPlaybooksSlots,
        usedSpacesSlots,
        usedUniqueViewers,
        usedUsersSeats,
        usedVideoPercents,
        usedVideoSeconds,
        videoSecondsQuota,
        usedPlaybooksPercent,
        userSeatsQuota,
        spacesQuota,
        uniqueViewersQuota,
        hasPrivacyControl,
        hasVideoExport,
        hasCustomSubdomain,
        hasStudioVoices,
        canHideWatermarkEmbed,
        hasDesktopApp,
        desktopAppTrialStartedAt,
        ...rest
    } = useMemo(() => (serviceUsage || {}) as ServicesUsageType, [serviceUsage])

    const { spaces, playbooks } = flags?.readOnly || {}

    const spaceCreatingLocked = spaces
    const playbookCreatingLocked = playbooks || spaces

    const canUseTrial = !isTrialPlan && !trial?.startedAt
    // if we in a trial plan that has end date we set its formatted text to trialDurationLabel
    const trialDurationLabel =
        trial?.trialEndDate && isTrialPlan
            ? generateTrialText(trial.trialEndDate.toMillis() / 1000)
            : ''

    const playbooksUsedPercent = useMemo(
        () =>
            usedPlaybooksPercent ||
            Math.ceil((usedPlaybooksSlots * 100) / (playbooksQuota || Infinity)),
        [usedPlaybooksSlots, usedPlaybooksPercent, playbooksQuota]
    )

    const disableTrialDialog = useCallback(
        async (dialogType: string) => {
            logToAnalytics(`close_trial_dialog`, {
                dialogType
            })

            return set(ref(getDatabase(), `stripe/${orgId}/showDialog/${uid}/${dialogType}`), null)
        },
        [orgId, uid]
    )

    return useMemo(
        () => ({
            ...rest,
            hasCustomSubdomain,
            isAllIntegrationsLocked:
                !hasGdrive && !hasOneDrive && !hasYoutube && !hasZoom && !hasVideoUploads,
            isLoading: loading || !orgId,
            usedPercent: usedStorage || 0,
            usersPercent: Math.ceil((usedUsersSeats / (userSeatsQuota || Infinity)) * 100),
            usedVideoMinutes: Math.ceil(usedVideoSeconds / 60),
            videoMinutesQuota: Math.ceil((videoSecondsQuota || 0) / 60),
            usedSpacesSlots: usedSpacesSlots || 0,
            usedUniqueViewers: usedUniqueViewers || 0,
            usedUsersSeats: usedUsersSeats || 0,
            usedPlaybooksSlots: usedPlaybooksSlots || 0,
            userSeatsQuota: userSeatsQuota || 0,
            spacesQuota: spacesQuota || 0,
            uniqueViewersQuota: uniqueViewersQuota || 0,
            usedVideoPercents,
            canHideWatermarkEmbed,
            playbooksUsedPercent,
            activePlanId,
            trialDurationLabel,
            planName,
            canUploadCustomFont,
            upcomingPlan,
            planDescription,
            isCustomPrice,
            isTrialPlan,
            isDefaultValues,
            isEnterprisePlan: activePlanId === planTypes.enterprise,
            isFreePlan: activePlanId === planTypes.individual,
            cannotStartTrial: flags?.cannotUseTrial,
            canUseTrial,
            quotaLabels: quotaLabels || {},
            spaceCreatingLocked,
            playbookCreatingLocked,
            activityFeedLocked: !activityFeedDays,
            insightsLocked: !insightsDays,
            disableTrialDialog,
            activityFeedDays,
            insightsDays,
            hasSDK,
            hasTrackLink,
            trialDuration: Math.floor(trial?.duration || 1),
            trialDaysLeft:
                trial?.trialEndDate && isTrialPlan
                    ? calculateDaysLeft(trial.trialEndDate.toMillis())
                    : '',
            billingEmail: billingEmail || '',
            nextBilling: (nextBillingDate?.toMillis() || 0) / 1000,
            nextBillingDateMs: nextBillingDate?.toMillis(),
            billingPeriod,
            isReversedTrial: Boolean(trial?.isReversed),
            hasCustomBranding,
            hasGdrive,
            hasOneDrive,
            canRemoveWatermark,
            canTranslateQuickGuidde,
            hasVideoBlur,
            hasVideoUploads,
            hasYoutube,
            hasZoom,
            hasMultipleCta,
            allowSsoOnly,
            hasPrivacyControl,
            hasVideoExport: Boolean(hasVideoExport),
            hasVoiceOverTextToSpeech,
            playbooksQuota: playbooksQuota === null ? Infinity : playbooksQuota,
            hasStudioVoices,
            hasDesktopApp: Boolean(hasDesktopApp),
            desktopAppTrialStartedAt
        }),
        [
            rest,
            hasCustomSubdomain,
            hasGdrive,
            hasOneDrive,
            hasYoutube,
            hasZoom,
            hasMultipleCta,
            hasVideoUploads,
            loading,
            orgId,
            usedStorage,
            usedUsersSeats,
            userSeatsQuota,
            usedVideoSeconds,
            videoSecondsQuota,
            usedSpacesSlots,
            usedUniqueViewers,
            canUploadCustomFont,
            usedPlaybooksSlots,
            canHideWatermarkEmbed,
            spacesQuota,
            uniqueViewersQuota,
            usedVideoPercents,
            playbooksUsedPercent,
            activePlanId,
            trialDurationLabel,
            planName,
            upcomingPlan,
            planDescription,
            isCustomPrice,
            isTrialPlan,
            isDefaultValues,
            flags?.cannotUseTrial,
            canUseTrial,
            quotaLabels,
            spaceCreatingLocked,
            playbookCreatingLocked,
            activityFeedDays,
            insightsDays,
            disableTrialDialog,
            hasSDK,
            hasTrackLink,
            trial,
            billingEmail,
            nextBillingDate,
            billingPeriod,
            hasCustomBranding,
            canRemoveWatermark,
            canTranslateQuickGuidde,
            hasVideoBlur,
            allowSsoOnly,
            hasPrivacyControl,
            hasVideoExport,
            hasVoiceOverTextToSpeech,
            playbooksQuota,
            hasStudioVoices,
            hasDesktopApp,
            desktopAppTrialStartedAt
        ]
    )
}
