import { ThemeProvider, Button, ButtonGroup, Icon } from '@guidde/design-system'
import { Box } from '@mui/material'

import { faGrid2 } from '@fortawesome/pro-regular-svg-icons/faGrid2'
import { faListUl } from '@fortawesome/pro-regular-svg-icons/faListUl'

const labels = {
    grid: 'grid-view',
    list: 'list-view'
}

const viewIconStyle = { height: 16, width: 16 }

type Props = {
    viewMode: 'grid' | 'list'
    onChangeView: (newViewMode: 'grid' | 'list') => void
}

export const GridListSwitcher = ({ viewMode, onChangeView }: Props) => (
    <ThemeProvider>
        <Box
            sx={theme => ({
                [`& [aria-label="${labels[viewMode]}"]`]: {
                    background: `${theme.palette.grey[50]} !important`
                }
            })}
        >
            <ButtonGroup>
                <Button
                    size="small"
                    label={labels.grid}
                    variant="outlined"
                    color="secondary"
                    onClick={() => onChangeView('grid')}
                    startIcon={<Icon icon={faGrid2} style={viewIconStyle} />}
                >
                    Grid
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    label={labels.list}
                    onClick={() => onChangeView('list')}
                    startIcon={<Icon icon={faListUl} style={viewIconStyle} />}
                >
                    List
                </Button>
            </ButtonGroup>
        </Box>
    </ThemeProvider>
)
