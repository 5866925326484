import { type ReactNode, memo, useState, useRef } from 'react'

import * as yup from 'yup'

import {
    Box,
    Button,
    ClickAwayListener,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Typography
} from '@mui/material'

import { Formik, type FormikProps } from 'formik'

import CloseIcon from '@mui/icons-material/Close'
import SendIcon from '@mui/icons-material/Send'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

import { ConnectedFreeSoloInput, ConnectedInput } from '../ConnectedFields'
import { useSubmitDialogWithEnter } from './use-submit-dialog-with-enter'

import { SpacedGroup } from 'UI/Components'

import { copyToClipboard, validateEmail } from 'modules'
import { useNotification } from 'hooks'

type SelectOptionType = {
    icon: ReactNode
    label: string
    value: string
}

type Props = {
    title: string
    subtitle: ReactNode
    isOpen: boolean
    selectorLabel: string
    shareLink: string
    onClose: () => void
    onInvite: (
        data: { message: string; role: number; emails: Array<string> },
        setFieldError: (error: string) => void
    ) => void
    isLoading: boolean
    showShareLink: boolean
    maxLength: number
    initialMessage: string
    initialRole?: SelectOptionType
    roles?: Array<SelectOptionType>
    linkStatus?: SelectOptionType
    isLinkDisabled?: boolean
    linkStatuses?: Array<SelectOptionType>
    onLinkStatusChange?: (value: number) => void
}

export const CommonInviteDialog = memo(
    ({
        title,
        subtitle,
        isOpen,
        isLoading,
        initialRole,
        shareLink,
        showShareLink,
        maxLength,
        initialMessage,
        selectorLabel,
        linkStatus,
        linkStatuses = [],
        onLinkStatusChange,
        roles,
        onInvite,
        onClose,
        isLinkDisabled = false
    }: Props) => {
        const { showSuccessNotification } = useNotification()

        const initialValues = {
            role: initialRole,
            emails: [],
            message: initialMessage
        }

        const formikRef = useRef<FormikProps<typeof initialValues>>(null)

        const submitFormikForm = () => formikRef.current?.submitForm()

        const { onKeyDown, onKeyUp } = useSubmitDialogWithEnter({ onSubmit: submitFormikForm })

        return (
            <Dialog
                open={isOpen}
                onClose={onClose}
                fullWidth={true}
                maxWidth="sm"
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
            >
                <Box height="4px">{isLoading && <LinearProgress />}</Box>

                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                        <Box>
                            <Typography data-test="invite-dialog-title" variant="h6">
                                {title}
                            </Typography>
                            <Typography
                                data-test="invite-dialog-subtitle"
                                color="textSecondary"
                                style={{ fontSize: 14, marginTop: 16 }}
                            >
                                {subtitle}
                            </Typography>
                        </Box>

                        <IconButton onClick={onClose} size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Box>
                    <Box mt={2} />
                    <Divider />
                </DialogTitle>

                <DialogContent>
                    <Formik
                        onSubmit={({ emails, message, role }, { setFieldError }) => {
                            onInvite(
                                {
                                    emails,
                                    message,
                                    role: Number(role?.value)
                                },
                                (error: string) => setFieldError('emails', error)
                            )
                        }}
                        innerRef={formikRef}
                        isInitialValid={false}
                        initialValues={initialValues}
                        validationSchema={yup.object().shape({
                            emails: yup
                                .array(yup.string().email('Must be a valid email format'))
                                .min(1, 'Field is required')
                        })}
                    >
                        {({ values, errors, setFieldValue }) => (
                            <>
                                <SpacedGroup flexDirection="column" spacing={4}>
                                    {roles && values.role && (
                                        <CustomSelect
                                            value={values.role}
                                            bgcolor="#F5F5F5"
                                            selectorLabel={selectorLabel}
                                            onSelect={val => setFieldValue('role', val)}
                                            options={roles}
                                        />
                                    )}
                                    <ConnectedFreeSoloInput
                                        allowPaste={true}
                                        disableBorderBottom={true}
                                        pasteValidator={validateEmail}
                                        data-test="invite-dialog-email-input"
                                        multiple
                                        name="emails"
                                        minHeight={54}
                                        size="small"
                                        variant="filled"
                                        placeholder="Email address (es)"
                                    />

                                    <ConnectedInput
                                        multiline
                                        inputProps={{ maxLength }}
                                        InputProps={{
                                            style: {
                                                padding: '20px 12px'
                                            }
                                        }}
                                        fullWidth
                                        placeholder="Message"
                                        name="message"
                                        size="small"
                                        variant="filled"
                                    />
                                </SpacedGroup>
                                <Box
                                    mt="60px"
                                    mb="20px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Button
                                        data-test="invite-dialog-cancel-button"
                                        onClick={onClose}
                                        style={{
                                            letterSpacing: 1.25,
                                            color: 'rgba(9, 12, 16, 0.6)',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'
                                        }}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        data-test="invite-dialog-submit-button"
                                        color="primary"
                                        variant="contained"
                                        disabled={
                                            values.emails.length < 1 ||
                                            Object.values(errors).length > 0
                                        }
                                        onClick={submitFormikForm}
                                        startIcon={<SendIcon />}
                                        style={{
                                            letterSpacing: 1.25
                                        }}
                                    >
                                        SEND INVITATION
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Formik>
                </DialogContent>
                {showShareLink && (
                    <Box
                        bgcolor="#F5F5F5"
                        py={2.5}
                        px={4}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Box>
                            <SpacedGroup spacing={1}>
                                <Typography style={{ fontSize: 14 }}>
                                    <b>Invite with link</b>
                                </Typography>
                                <Button
                                    data-test="invite-dialog-copy-link"
                                    disabled={isLinkDisabled}
                                    variant="text"
                                    color="secondary"
                                    onClick={() => {
                                        if (isLinkDisabled) return

                                        copyToClipboard(shareLink)
                                        showSuccessNotification('Link copied to the clipboard')
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        fontSize: 14
                                    }}
                                >
                                    <b>Copy link</b>
                                </Button>
                            </SpacedGroup>
                            <Typography color="textSecondary" style={{ fontSize: 14 }}>
                                Anyone can sign up using this link
                            </Typography>
                        </Box>
                        {linkStatuses.length > 0 && (
                            <CustomSelect
                                bgcolor="#FFF"
                                selectorLabel={selectorLabel}
                                options={linkStatuses}
                                value={
                                    linkStatuses.find(
                                        it => Number(it.value) === Number(linkStatus?.value)
                                    ) || linkStatuses[0]
                                }
                                onSelect={({ value }) => onLinkStatusChange?.(Number(value))}
                            />
                        )}
                    </Box>
                )}
            </Dialog>
        )
    }
)

type CustomSelectItem = { label: string; value: string; icon: ReactNode }
type CustomSelectProps = {
    options: Array<CustomSelectItem>
    value: CustomSelectItem
    selectorLabel: string
    bgcolor: string
    onSelect: (data: CustomSelectItem) => void
}
const CustomSelect = memo(
    ({ bgcolor, selectorLabel, options, value, onSelect }: CustomSelectProps) => {
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

        return (
            <>
                <SpacedGroup
                    style={{
                        cursor: 'pointer'
                    }}
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <Box
                        bgcolor={bgcolor}
                        borderRadius="50%"
                        width={40}
                        height={40}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {value.icon}
                    </Box>
                    <Box>
                        <SpacedGroup spacing={0.5}>
                            <Typography style={{ fontSize: 14 }}>
                                <b>{selectorLabel}</b>
                            </Typography>
                            <ArrowDropUpIcon
                                style={{
                                    transform: `rotate(${Boolean(anchorEl) ? 0 : '180deg'})`,
                                    transition: 'transform 0.2s linear'
                                }}
                            />
                        </SpacedGroup>
                        <Typography style={{ fontSize: 14 }} color="textSecondary">
                            {value.label}
                        </Typography>
                    </Box>
                </SpacedGroup>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left'
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                            <MenuList>
                                {options.map(o => (
                                    <MenuItem
                                        key={o.value}
                                        onClick={() => {
                                            onSelect(o)
                                            setAnchorEl(null)
                                        }}
                                    >
                                        <ListItemIcon style={{ color: '#1C1B1F', minWidth: 40 }}>
                                            {o.icon}
                                        </ListItemIcon>
                                        <Typography>{o.label}</Typography>
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popover>
            </>
        )
    }
)
