import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box } from '@mui/material'

import { ThemeProvider } from '@guidde/design-system'

import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { VoiceOverContext } from 'UI/Routes/quick-guidde/VoiceOverProvider'
import { generateStepIndex } from 'UI/Routes/quick-guidde/CanvasEditor'
import { DownloadCaptions } from 'UI/Routes/quick-guidde/LeftPanel/CaptionsPanel/DownloadCaptions'

import { CustomTabs } from 'UI/Components'
import { SpeechToTextTab } from './SpeechToText'
import { TextToSpeechTab } from './TextToSpeech'

import { ReactComponent as PremiumIcon } from 'assets/icons/premium.svg'

import { useServiceUsage } from 'hooks'
import { type QuickGuiddeType } from 'app/types'
import { logToAnalytics } from 'modules'

type Props = {
    playbook: QuickGuiddeType
}

export const VoiceOverPanel = ({ playbook }: Props) => {
    const {
        present: { activeStep, steps }
    } = useSelector(state => state.qgEditor)

    const { audioNote, tempAudioNote, kind } = steps?.[activeStep] || {}

    const { hasVoiceOverTextToSpeech } = useServiceUsage()

    const { voiceOverProcessing } = useContext(VoiceOverContext)

    const activeTab = useMemo(() => {
        if (voiceOverProcessing) return 0

        if (audioNote) {
            if (audioNote.type === 'textToSpeech') return 1
            return 0
        }

        if (tempAudioNote) {
            if (tempAudioNote.type === 'textToSpeech') return 1
            return 0
        }

        return history.state?.selectedTab || 0
    }, [audioNote, tempAudioNote, voiceOverProcessing])

    if (!steps?.[activeStep]) return null

    return (
        <>
            <Box
                p={2}
                height={62}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid rgba(9, 12, 16, 0.08)"
            >
                <Box style={{ fontSize: 20, fontWeight: 500 }}>
                    <TextOverflow lineClamp={1}>
                        Voiceover | {kind === 'step' && 'Step'}{' '}
                        {generateStepIndex(steps, activeStep)}
                    </TextOverflow>
                </Box>
                <ThemeProvider>
                    <DownloadCaptions
                        variant="captions"
                        playbookName={playbook.title}
                        playbookId={playbook.id}
                        langCode={playbook.language?.langCode || 'en'}
                    />
                </ThemeProvider>
            </Box>

            <Box p={2} pb={0}>
                <CustomTabs
                    px={0}
                    keepInDOM={false}
                    disabled={
                        Boolean(audioNote && audioNote.type !== 'defaultSubtitles') ||
                        voiceOverProcessing
                    }
                    onChange={tabNumber => {
                        logToAnalytics(
                            tabNumber === 0
                                ? 'voiceover_recordTab_clicked'
                                : 'voiceover_t2vTab_clicked',
                            { playbookId: playbook.id }
                        )
                    }}
                    value={activeTab}
                    tabs={[
                        {
                            label: 'RECORD',
                            component: <SpeechToTextTab playbook={playbook} />
                        },
                        {
                            label: 'TEXT TO VOICE',
                            icon: hasVoiceOverTextToSpeech ? '' : <PremiumIcon />,
                            component: (
                                <TextToSpeechTab
                                    playbook={playbook}
                                    hasVoiceOverTextToSpeech={hasVoiceOverTextToSpeech}
                                />
                            )
                        }
                    ]}
                />
            </Box>
        </>
    )
}
