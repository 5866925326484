import { type SyntheticEvent, useRef, useState, useEffect } from 'react'

import { delay, openLink } from 'modules'

import { Box, ClickAwayListener, Paper, Popover, type PopoverOrigin } from '@mui/material'

import { Typography, Button } from '@guidde/design-system'
import { type SpeakerType } from 'app/types'
import { links } from 'app/links'

import { useBoolean } from 'hooks'
import { SpeakerItem } from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

type Props = {
    options: Array<SpeakerType>
    anchorEl: HTMLElement | null
    transformOrigin?: PopoverOrigin
    onClose: () => void
}

export const UpgradePopup = ({
    anchorEl,
    options,
    onClose,
    transformOrigin = {
        vertical: 'top',
        horizontal: 'left'
    }
}: Props) => {
    const audioRef = useRef<HTMLMediaElement | null>(null)

    const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)
    const [currentTime, setCurrentTime] = useState(0)

    const playStatus = useBoolean()

    // on unmount
    useEffect(() => {
        return () => {
            reset()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleTimeUpdateAction = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
        const { currentTime } = event.target as HTMLAudioElement
        setCurrentTime(currentTime)
    }

    const handleAudioEndedAction = () => {
        playStatus.setFalse()
        setCurrentTime(0)
    }

    const handleSpeakerPlayClickAction = (speaker: SpeakerType) => {
        const isPlaying = playStatus.isTrue && speaker.id === playSpeaker?.id

        if (playSpeaker?.id !== speaker.id) {
            setPlaySpeaker(speaker)
        }

        const audioElement = audioRef.current
        if (!audioElement) return

        delay(0).then(() => {
            if (isPlaying) audioElement.pause()
            else audioElement.play()
        })
    }
    const handleSpeakerContainer = () => undefined

    const reset = () => {
        setPlaySpeaker(null)
        playStatus.setFalse()
        setCurrentTime(0)
    }

    const handleClickAway = () => {
        onClose()
        reset()
    }

    return (
        <>
            <audio
                src={playSpeaker?.sampleAudioUrl ?? ''}
                ref={audioRef}
                onPlay={playStatus.setTrue}
                onPause={playStatus.setFalse}
                onTimeUpdate={handleTimeUpdateAction}
                onEnded={handleAudioEndedAction}
            />

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                disablePortal
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={transformOrigin}
                PaperProps={{
                    style: {
                        padding: 16
                    }
                }}
            >
                <ClickAwayListener disableReactTree={false} onClickAway={handleClickAway}>
                    <Paper elevation={0} style={{ textAlign: 'center' }}>
                        <Typography variant="text" fontWeight="medium" size="xl">
                            Upgrade your account
                        </Typography>
                        <Typography variant="text">
                            Find the perfect studio voice <br /> for your video.
                        </Typography>

                        <Box
                            mt={1}
                            mb={2}
                            display="flex"
                            flexDirection="column"
                            sx={{ gridGap: 16 }}
                            justifyContent="center"
                        >
                            {options.map(speaker => {
                                const isPlaying =
                                    speaker.id === playSpeaker?.id && playStatus.isTrue

                                return (
                                    <SpeakerItem
                                        key={speaker.id}
                                        speaker={speaker}
                                        isActive={false}
                                        isPlaying={isPlaying}
                                        currentTime={currentTime}
                                        onContainerClick={handleSpeakerContainer}
                                        onActionButtonClick={handleSpeakerPlayClickAction}
                                    />
                                )
                            })}
                        </Box>
                        <Box width="100%" display="flex">
                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                onClick={() => openLink(links.contactSales)}
                            >
                                Contact Us
                            </Button>
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popover>
        </>
    )
}
