import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { paths } from 'app/paths'

import { CommonDialog } from 'UI/Components'

type Props = {
    isOpen: boolean
    redirectUrl: string
    onClose: () => void
}

export const RegionRedirectDialog = ({ isOpen, redirectUrl, onClose }: Props) => {
    const redirectInitials = redirectUrl === 'eu.guidde.com' ? 'EU' : 'US'

    const location = useLocation()
    const desktopAppSearchQueryParams = useMemo(() => {
        const searchParams = new URLSearchParams(location.search)

        const passedDesktopSearchParams = ['src', 'action']
            .map(key => [key, searchParams.get(key)])
            .filter(([_, value]) => Boolean(value))

        return new URLSearchParams(Object.fromEntries(passedDesktopSearchParams))
    }, [location.search])

    const redirectLink = useMemo(() => {
        const redirectUrlWithProtocol = `https://${redirectUrl}`

        const isCalledFromDesktop = desktopAppSearchQueryParams.get('src') === 'desktop'
        if (isCalledFromDesktop)
            return `${redirectUrlWithProtocol}${paths.login}?${desktopAppSearchQueryParams.toString()}`

        return redirectUrlWithProtocol
    }, [desktopAppSearchQueryParams, redirectUrl])

    return (
        <CommonDialog
            isOpen={isOpen}
            onClose={onClose}
            content={
                <div>
                    <p>Your account is registered in the {redirectInitials} region.</p>
                    <p style={{ marginBottom: '35px' }}>
                        To continue, click 'Switch to {redirectInitials}' and login again.
                    </p>
                    <p>Thank you.</p>
                </div>
            }
            hideCloseBtn
            confirmBtnText={`SWITCH TO ${redirectInitials}`}
            title="Region Access Update"
            titleSize="lg"
            onConfirm={() => (window.location.href = redirectLink)}
        />
    )
}
