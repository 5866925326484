import { useMemo } from 'react'

import { useQuery, useDataMutation } from 'hooks'

const requestURL = '/c/v1/user-preferences'

type TSpeaker = {
    lastSpeakerIdUsed: string
}

/**
  @deprecated

  This hook is deprecated and should not be used in new code.
  please use @useUserPreferences instead
*/
export const useLastSpeaker = () => {
    const $speaker = useQuery<TSpeaker>(requestURL, { method: 'GET' })

    const $setSpeaker = useDataMutation<TSpeaker, TSpeaker, Error>(requestURL, 'PUT', {
        onSuccess: () => $speaker.mutate()
    })

    const setLastSpeaker = $setSpeaker.mutate

    return useMemo(
        () => ({
            isSpeakerLoading: $speaker.isValidating || $setSpeaker.isLoading,
            speakerId: $speaker.data?.lastSpeakerIdUsed || '',
            setLastSpeaker: (speakerId: string) => {
                return setLastSpeaker({
                    lastSpeakerIdUsed: speakerId
                })
            }
        }),
        [
            $speaker.isValidating,
            $speaker.data?.lastSpeakerIdUsed,
            $setSpeaker.isLoading,
            setLastSpeaker
        ]
    )
}
