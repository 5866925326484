import { useState, useEffect, useMemo, useRef } from 'react'
import { type StepType, Shape } from 'app/types'

interface UseFakeProgressOptions {
    steps: Array<StepType>
    isFinished: boolean
    minProgress?: number
    maxProgress?: number
}

export const useFakeStepsProgress = ({
    steps,
    isFinished,
    minProgress = 0,
    maxProgress = 90
}: UseFakeProgressOptions) => {
    const [progress, setProgress] = useState(minProgress)
    const intervalRef = useRef<number | null>(null)

    const stepDuration = useMemo(() => {
        if (steps.length === 0) return 0

        const totalDuration = steps.reduce((acc, step) => {
            const isVideoStep = step.layers.some(layer => layer.type === Shape.Video)
            return acc + (isVideoStep ? step.duration * 0.6 : 5)
        }, 0)

        return totalDuration / steps.length
    }, [steps])

    useEffect(() => {
        if (isFinished || stepDuration === 0) return

        const stepPercent = maxProgress / (stepDuration * steps.length)

        intervalRef.current = window.setInterval(() => {
            setProgress(prev => {
                const newProgress = Math.min(prev + stepPercent, maxProgress)
                if (newProgress >= maxProgress && intervalRef.current !== null) {
                    clearInterval(intervalRef.current)
                    intervalRef.current = null
                }
                return newProgress
            })
        }, 1000)

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [isFinished, steps.length, stepDuration, maxProgress])

    useEffect(() => {
        if (isFinished) {
            setProgress(100)
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current)
                intervalRef.current = null
            }
        }
    }, [isFinished])

    return progress
}

export type FakeProgressRequiredData = {
    limit: number
    delay?: number
}

export const useFakeProgress = (data: FakeProgressRequiredData) => {
    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        let interval: ReturnType<typeof setInterval> | null = null

        if (progress < data.limit) {
            interval = setInterval(() => {
                setProgress(prevProgress => {
                    if (prevProgress < 10) {
                        return prevProgress + 1
                    }
                    interval && clearInterval(interval)
                    return prevProgress
                })
            }, data.delay ?? 1000)
        }

        return () => {
            interval && clearInterval(interval)
        }
    }, [data, progress])

    return progress
}
