import { type FunctionComponent, type MouseEvent, type ReactNode, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'

import {
    styled,
    ListItemButton,
    Popper,
    useTheme,
    Paper,
    ClickAwayListener,
    Divider,
    Avatar,
    List,
    ListItemText,
    ListItemIcon,
    Box,
    Typography,
    Stack
} from '@mui/material'
import { Badge, ThemeProvider } from '@guidde/design-system'

import PersonIcon from '@mui/icons-material/Person'
import AccountIcon from '@mui/icons-material/AccountBalance'
import NotificationsIcon from '@mui/icons-material/Notifications'
import ExitIcon from '@mui/icons-material/ExitToApp'
import MonitorIcon from '@mui/icons-material/Monitor'

import { ColoredCircularProgress, DownloadDesktopAppProvider, SpacedGroup } from 'UI/Components'
import { ExceededUsersNotification } from 'UI/Routes/account-settings/tabs/ExceededUsersNotification'

import { signOutGapi, logToAnalytics } from 'modules'
import { useAuth, useRoles, useServiceUsage, useStigg } from 'hooks'

import { paths } from 'app/paths'

const PREFIX = 'ProfilePopper'

const classes = {
    wrapper: `${PREFIX}-wrapper`,
    viewWrapper: `${PREFIX}-viewWrapper`,
    planInfo: `${PREFIX}-planInfo`,
    plan: `${PREFIX}-plan`,
    userInfo: `${PREFIX}-userInfo`,
    info: `${PREFIX}-info`,
    gutters: `${PREFIX}-gutters`,
    listItemIcon: `${PREFIX}-listItemIcon`,
    link: `${PREFIX}-link`
}

const StyledPopper = styled(Popper)(({ theme }) => ({
    [`& .${classes.wrapper}`]: {
        padding: theme.spacing(2, 3),
        borderRadius: '4px',
        minWidth: '420px',
        color: '#212121',
        boxShadow: theme.shadows[8],
        '& .MuiTypography-body2': {
            fontSize: '0.75rem'
        }
    },

    [`& .${classes.viewWrapper}`]: {
        minWidth: '200px',
        color: '#212121'
    },

    [`& .${classes.planInfo}`]: {
        padding: '1px 6px',
        borderRadius: '3px'
    },

    [`& .${classes.plan}`]: {
        color: '#fff',
        fontWeight: 600
    },

    [`& .${classes.userInfo}`]: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '250px',
        whiteSpace: 'nowrap'
    },

    [`& .${classes.info}`]: {
        fontSize: '10px',
        lineHeight: '16px',
        maxWidth: '250px'
    },

    [`& .${classes.gutters}`]: {
        padding: theme.spacing(1, 0.5)
    },

    [`& .${classes.listItemIcon}`]: {
        minWidth: '25px',
        marginRight: theme.spacing(1)
    },

    [`& .${classes.link}`]: {
        color: 'inherit',
        textDecoration: 'none'
    }
}))

const navigationLinks: Array<{
    title: string
    link: string
    Icon: FunctionComponent
    validate?: boolean
}> = [
    {
        title: 'My Profile',
        link: paths.profile,
        Icon: PersonIcon
    },
    {
        title: 'Account Settings',
        link: paths.accountSettings,
        Icon: AccountIcon,
        validate: true
    }
]

type DialogProps = {
    onClose: () => void
    anchorEl: HTMLElement | null
}

export const ProfilePopper = ({ anchorEl, onClose }: DialogProps) => {
    const {
        usedPercent,
        planDescription,
        planName,
        isCustomPrice,
        isTrialPlan,
        trialDurationLabel,
        quotaLabels,
        userSeatsQuota,
        usedUsersSeats,
        usersPercent
    } = useServiceUsage()

    const { planColor } = useStigg()

    const { displayName, email, photoURL, uid } = useAuth()
    const { isAdmin, isSuperAdmin, isMember, isContentManager, isViewer } = useRoles()

    const getUserRole = () => {
        if (isSuperAdmin) return 'Super Admin'
        if (isAdmin) return 'Admin'
        if (isMember && isContentManager) return 'Content Manager'
        if (isMember) return 'Creator'
        if (isViewer) return 'Viewer'
    }

    const logOut = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault()
        logToAnalytics('logout')
        signOutGapi(uid)
        signOut(getAuth()).then(onClose)
    }

    const textInsteadValue = useMemo(() => {
        const allMetricsUnlimited = Object.values(quotaLabels).every(label => label === 'unlimited')

        const style = {
            ...(planColor && { color: planColor }),
            fontWeight: 600,
            fontSize: isCustomPrice ? '10px' : '12px'
        }

        if (isTrialPlan && allMetricsUnlimited) {
            return <Typography style={style}>Trial</Typography>
        }

        if (allMetricsUnlimited) {
            return <Typography style={style}>Unlimited</Typography>
        }

        return ''
    }, [isTrialPlan, planColor, quotaLabels, isCustomPrice])

    const usersExceeded = Boolean(userSeatsQuota && usedUsersSeats > userSeatsQuota)

    const showPlanData = !isViewer

    return (
        <Wrapper anchorEl={anchorEl} onClose={onClose} className={classes.wrapper}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <SpacedGroup style={{ marginBottom: '8px' }}>
                    <Avatar src={`${photoURL}&time=${performance.now()}`} alt="avatar" />
                    <ListItemText
                        title={displayName || email}
                        primary={displayName}
                        secondary={getUserRole()}
                        classes={{
                            primary: classes.userInfo
                        }}
                    />
                </SpacedGroup>

                {showPlanData && (
                    <Box className={classes.planInfo} style={{ background: planColor }}>
                        <Typography className={classes.plan} variant="body2">
                            {planName}
                        </Typography>
                    </Box>
                )}
            </Box>
            <>
                {isAdmin && usersExceeded && (
                    <Box my={3}>
                        <ExceededUsersNotification onClick={onClose} />
                    </Box>
                )}
                {showPlanData && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                        <Typography className={classes.info}>
                            {planDescription}. {trialDurationLabel}
                        </Typography>
                        <Box position="relative" display="flex">
                            <ColoredCircularProgress
                                size={70}
                                value={Math.max(usedPercent, usersPercent)}
                                textInsteadValue={textInsteadValue}
                                valuesColorsMap={{
                                    0: '#58ee82',
                                    75: '#f57c00',
                                    100: '#cb0000'
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </>

            <Divider variant="fullWidth" />
            <List>
                {navigationLinks.map((link, i) => (
                    <NavigationLink {...link} onClick={onClose} key={i} />
                ))}
                <DownloadDesktopAppProvider source="profileMenu">
                    {handleDownloadDesktopAppClick => (
                        <ListItemButton
                            classes={{ gutters: classes.gutters }}
                            onClick={handleDownloadDesktopAppClick}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <MonitorIcon />
                            </ListItemIcon>
                            <ListItemText>
                                <Stack direction="row" spacing={1.5} alignItems="center">
                                    <Typography>Download Desktop App</Typography>
                                    <ThemeProvider>
                                        <Stack
                                            sx={{
                                                '& .MuiChip-root': {
                                                    border: '1px solid #D9D6FE',
                                                    height: 22,
                                                    fontSize: 12
                                                },
                                                '& .MuiChip-label': {
                                                    fontWeight: 500,
                                                    px: '7px'
                                                }
                                            }}
                                        >
                                            <Badge label="BETA" color="purple" />
                                        </Stack>
                                    </ThemeProvider>
                                </Stack>
                            </ListItemText>
                        </ListItemButton>
                    )}
                </DownloadDesktopAppProvider>
                <NavigationLink
                    title="Notifications Settings"
                    link={paths.notifications}
                    Icon={NotificationsIcon}
                    onClick={onClose}
                />
                <ListItemButton
                    data-test="menu-user-logout"
                    classes={{ gutters: classes.gutters }}
                    onClick={logOut}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <ExitIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItemButton>
            </List>
        </Wrapper>
    )
}

const NavigationLink = ({
    Icon,
    title,
    link,
    validate,
    onClick
}: (typeof navigationLinks)[number] & { onClick: () => void }) => {
    const { isAdmin } = useRoles()

    if (validate && !isAdmin) return null

    return (
        <Link to={link} className={classes.link} onClick={onClick}>
            <ListItemButton classes={{ gutters: classes.gutters }}>
                <ListItemIcon className={classes.listItemIcon}>
                    <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </Link>
    )
}

type WrapperProps = {
    anchorEl: HTMLElement | null
    onClose: () => void
    children: ReactNode | ReactNode[]
    className: string
}

const Wrapper = ({ anchorEl, onClose, children, className }: WrapperProps) => {
    const theme = useTheme()
    if (!anchorEl) return null

    return (
        <ClickAwayListener onClickAway={onClose}>
            <StyledPopper
                open={true}
                anchorEl={anchorEl}
                placement="top-start"
                sx={{
                    zIndex: theme.zIndex.drawer + 1
                }}
            >
                <Paper className={className}>{children}</Paper>
            </StyledPopper>
        </ClickAwayListener>
    )
}
