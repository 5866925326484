import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import { ref, update, getDatabase, runTransaction } from 'firebase/database'

import { db } from './firebase'

export const SPACE_ROLES = {
    VIEWER: 20,
    COLLABORATOR: 40,
    OWNER: 60
} as const

const spacesRef = (spaceId: string) => doc(db, `spaces`, spaceId)

export const updateSpace = (spaceId: string, space: {}) => updateDoc(spacesRef(spaceId), space)

export const deleteSpace = (spaceId: string) => deleteDoc(spacesRef(spaceId))

type SharePlaybookProps = {
    id: string
    spaceId: string
    orgId: string
    uid: string
}

export const shareRecordedPlaybookToSpace = ({
    id, // <- playbookId
    spaceId,
    orgId,
    uid
}: SharePlaybookProps) => {
    return update(ref(getDatabase(), `/autoShareToSpaces/${orgId}/${uid}/playbook`), {
        [id]: [spaceId]
    }).catch(console.error)
}

export const shareUploadedPlaybookToSpace = ({
    id, // <- batchUploadId
    spaceId,
    orgId,
    uid
}: SharePlaybookProps) => {
    return runTransaction(
        ref(getDatabase(), `/autoShareToSpaces/${orgId}/${uid}/batchUpload/${id}`),
        value => {
            if (!value) return [spaceId]
            return [...value, spaceId]
        }
    ).catch(console.error)
}
