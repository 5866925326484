import { memo, useCallback, useMemo, useState } from 'react'

import { type FormikHelpers } from 'formik'

import { Box, Dialog, Typography, LinearProgress } from '@mui/material'

import { ConfirmationDialog } from 'UI/Components'
import { useQuery } from 'hooks'

import { type UserOption, type FormSpaceType, type SubmitDataType } from './types'
import { type TabsType, SpaceFields, tabs } from './SpaceFields'

type Props = {
    title: string
    isOpen: boolean
    initialValues?: Omit<FormSpaceType, 'members'>
    ownerOption?: UserOption
    spaceId?: string
    onClose: () => void
    onSubmit: (data: SubmitDataType) => void
    isLoading?: boolean
}

export const initialSpaceValues = {
    name: '',
    isPrivate: false,
    description: '',
    color: '#FFFFFF'
}

export const CommonSpaceDialog = memo(
    ({
        title,
        isOpen,
        spaceId = '',
        ownerOption,
        isLoading = false,
        onClose,
        onSubmit,
        initialValues = initialSpaceValues
    }: Props) => {
        const [activeTab, setActiveTab] = useState<TabsType>(tabs.main)

        const [submitData, setSubmitData] = useState<{
            data: SubmitDataType
            externalEmails: Array<string>
        } | null>(null)

        const url = `/c/v1/space/get-users${spaceId ? `?spaceId=${spaceId}` : ''}`

        const $options = useQuery<Array<UserOption>>(
            isOpen ? url : '',
            {
                method: 'GET'
            },
            {
                dedupingInterval: 0
            }
        )

        const optionsList = useMemo(() => {
            const result = $options.data || []

            if (ownerOption) {
                result.push(ownerOption)
            }

            return result.reduce<{ [key: string]: UserOption }>(
                (acc, it) => ({ ...acc, [it.uid]: it }),
                {}
            )
        }, [$options.data, ownerOption])

        const handleSubmit = useCallback(
            (values: FormSpaceType, formHelpers: FormikHelpers<FormSpaceType>) => {
                if (values.name.trim().length < 1) {
                    formHelpers.setFieldError('name', 'Field is required')
                    return
                }

                const members = Object.values(values.members)
                    .filter(it => it.isSelected)
                    .map(it => ({
                        uid: it.uid,
                        email: it.email,
                        role: it.spaceRole
                    }))

                const data = {
                    name: values.name.trim(),
                    isPrivate: values.isPrivate,
                    description: values.description,
                    color: values.color,
                    members
                }

                const initialExternalUsers =
                    $options.data?.filter(it => !it.uid)?.map(it => it.email) || []

                const externalEmails = members
                    .filter(it => !it.uid && !initialExternalUsers.includes(it.email))
                    .map(it => it.email)

                if (externalEmails.length > 0) {
                    setSubmitData({
                        data,
                        externalEmails
                    })
                } else {
                    onSubmit(data)
                }
            },
            [$options.data, onSubmit]
        )

        const loading = $options.isValidating || isLoading

        return (
            <>
                <Dialog
                    open={isOpen}
                    onClose={onClose}
                    maxWidth="sm"
                    PaperProps={{
                        style: {
                            width: '100%'
                        }
                    }}
                >
                    <Box height="4px">{loading && <LinearProgress />}</Box>
                    <Box pt={3.5}>
                        <SpaceFields
                            handleSubmit={handleSubmit}
                            setActiveTab={setActiveTab}
                            onClose={onClose}
                            title={title}
                            activeTab={activeTab}
                            isLoading={loading}
                            disabled={$options.isValidating}
                            initialValues={{
                                ...initialValues,
                                members: optionsList
                            }}
                        />
                    </Box>
                </Dialog>
                <ConfirmationDialog
                    isOpen={Boolean(submitData)}
                    title="Share outside of your organization?"
                    body={
                        <Box pt={6} pb={6}>
                            <Typography style={{ paddingBottom: '8px' }}>
                                The following emails are not part of your organization:
                            </Typography>
                            <Typography component="span">
                                <b>{submitData?.externalEmails.join(', ')}</b>
                            </Typography>
                        </Box>
                    }
                    confirmBtnText="Share anyway"
                    maxWidth="sm"
                    onClose={() => setSubmitData(null)}
                    onConfirm={() => {
                        if (!submitData) return

                        onSubmit(submitData.data)
                        setSubmitData(null)
                        onClose()
                    }}
                />
            </>
        )
    }
)
