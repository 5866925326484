import { memo, useContext, useEffect } from 'react'

import {
    Button,
    Icon,
    IconButton,
    LinearProgress,
    ThemeProvider,
    Typography,
    spacings
} from '@guidde/design-system'
import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider
} from '@mui/material'
import { faTrashCan, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { generatePath } from 'react-router-dom'
import { paths } from 'app/paths'
import { VideoStatusContext, VideoStatusProvider } from 'UI/Components'
import { type QuickGuiddeType, type SpeakerType } from 'app/types'
import { useFakeStepsProgress } from './hooks'
import AutoTranslateAnalytics from 'analytics/autoTranslate'

export type TranslationProgressBoxProps = {
    onTryAgain: (speaker: SpeakerType) => void
    onDelete: (speaker: SpeakerType) => void
    onFinished: (speaker: SpeakerType) => void
} & ProgressData

export type ProgressData = {
    speaker: SpeakerType
    playbook?: QuickGuiddeType
    isFailed?: boolean
    isFinished?: boolean
}

export type TranslateProgressBarProps = {
    progress: number
    playbookId?: string
    language: string
}

const TranslateProgressBar = ({ progress, playbookId, language }: TranslateProgressBarProps) => {
    const handleClickReview = () => {
        if (!playbookId) return

        AutoTranslateAnalytics.reviewBtnClicked({ playbookId, language })

        const url = generatePath(paths.playbookDetails, {
            playbookId
        })
        window.open(url, '_blank')
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <LinearProgress value={progress} />
            </Box>
            {progress === 100 ? (
                <TempLinkButton text="Review" onClick={handleClickReview} />
            ) : (
                <Typography variant="text" size="sm" color="grey.700" fontWeight="medium">
                    {progress.toFixed(0)}%
                </Typography>
            )}
        </>
    )
}

const PlaybookProgress = memo(
    ({
        steps,
        playbookId,
        onFinished,
        language
    }: {
        playbookId: QuickGuiddeType['id']
        steps: QuickGuiddeType['steps']
        language: string
        onFinished?: () => void
    }) => {
        const { processFinished } = useContext(VideoStatusContext)
        const progress = useFakeStepsProgress({
            steps,
            maxProgress: 99,
            isFinished: processFinished
        })

        useEffect(() => {
            if (!processFinished) return

            onFinished && onFinished()
        }, [onFinished, processFinished])

        return (
            <TranslateProgressBar progress={progress} playbookId={playbookId} language={language} />
        )
    }
)

export const TempLinkButton = memo(({ text, onClick }: { text: string; onClick: () => void }) => (
    <Box
        sx={{
            '& button': {
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        }}
    >
        <Button variant="text" color="primary" onClick={onClick}>
            {text}
        </Button>
    </Box>
))

const TranslationProgressBox = memo(
    ({
        playbook,
        speaker,
        isFailed,
        onTryAgain,
        onDelete,
        onFinished
    }: TranslationProgressBoxProps) => {
        const handleTryAgain = () => onTryAgain(speaker)
        const handleDelete = () => onDelete(speaker)
        const handleFinished = () => onFinished(speaker)

        return (
            <Box
                sx={theme => ({
                    border: isFailed ? '2px solid' : '1px solid',
                    borderColor: isFailed ? 'error.600' : 'grey.200',
                    borderRadius: theme.spacing(spacings.md),
                    padding: theme.spacing(spacings.xl),
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(spacings.xs),
                    position: 'relative'
                })}
            >
                {isFailed && (
                    <Box
                        sx={theme => ({
                            position: 'absolute',
                            right: theme.spacing(spacings.xs),
                            top: theme.spacing(spacings.xs),
                            '& button': {
                                border: 'none'
                            }
                        })}
                    >
                        <IconButton
                            label="delete"
                            onClick={handleDelete}
                            color="error"
                            variant="text"
                        >
                            <Icon icon={faTrashCan} size="1x" />
                        </IconButton>
                    </Box>
                )}
                <Typography
                    variant="text"
                    size="sm"
                    fontWeight={isFailed ? 'regular' : 'medium'}
                    color={isFailed ? 'grey.600' : 'grey.700'}
                >
                    {speaker.langName}
                </Typography>

                <Box
                    sx={theme => ({
                        display: 'flex',
                        alignItems: 'center',
                        gap: theme.spacing(spacings.md)
                    })}
                >
                    {isFailed ? (
                        <TempLinkButton text="Try again" onClick={handleTryAgain} />
                    ) : !playbook ? (
                        <Box
                            sx={theme => ({
                                userSelect: 'none',
                                display: 'flex',
                                gap: theme.spacing(spacings.md),
                                alignItems: 'center'
                            })}
                        >
                            <CircularProgress size={10} />
                            <Typography variant="text" size="xs">
                                Preparing...
                            </Typography>
                        </Box>
                    ) : (
                        <VideoStatusProvider playbook={playbook}>
                            <PlaybookProgress
                                language={speaker.langName}
                                playbookId={playbook.id}
                                steps={playbook.steps}
                                onFinished={handleFinished}
                            />
                        </VideoStatusProvider>
                    )}
                </Box>
            </Box>
        )
    }
)

export type TranslateProgressDialogProps = {
    data: ProgressData[]
    isOpen: boolean
    onClose: () => void
    onTryAgain: TranslationProgressBoxProps['onTryAgain']
    onDelete: TranslationProgressBoxProps['onDelete']
    onFinished: TranslationProgressBoxProps['onFinished']
}

export const TranslateProgressDialog = memo(
    ({ data, onDelete, onTryAgain, onFinished, isOpen, onClose }: TranslateProgressDialogProps) => (
        <ThemeProvider>
            <Dialog
                open={isOpen}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        maxWidth: 820
                    }
                }}
            >
                <DialogTitle
                    component={'div'}
                    sx={theme => ({
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(spacings.md)
                    })}
                >
                    <Typography variant="text" size="lg" fontWeight="semibold">
                        Translating your Guidde
                    </Typography>
                    <Typography variant="text" size="sm">
                        You can close this window if you don’t want to wait. We will generate the
                        translated copies for you.
                    </Typography>
                    <Box
                        sx={{
                            textAlign: 'right',
                            position: 'absolute',
                            right: 5,
                            top: 5
                        }}
                    >
                        <IconButton
                            label="close"
                            color="secondary"
                            variant="text"
                            size="small"
                            onClick={onClose}
                        >
                            <Icon icon={faXmark} size="1x" />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: theme.spacing(spacings['2xl']),
                            padding: theme.spacing(spacings['3xl'])
                        })}
                    >
                        {data.map(props => (
                            <TranslationProgressBox
                                key={props.speaker.id}
                                onDelete={onDelete}
                                onTryAgain={onTryAgain}
                                onFinished={onFinished}
                                {...props}
                            />
                        ))}
                    </Box>
                </DialogContent>
                <Divider />

                <DialogActions
                    sx={theme => ({
                        padding: theme.spacing(spacings.xl)
                    })}
                >
                    <Box
                        sx={theme => ({
                            minWidth: theme.spacing(spacings['10xl'])
                        })}
                    >
                        <Button onClick={onClose} color="secondary" fullWidth variant="outlined">
                            Close
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
)
