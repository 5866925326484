import { useMemo } from 'react'

import { Stack } from '@mui/material'

import { Icon, IconButton, Typography } from '@guidde/design-system'
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause'
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay'

import { type UseBooleanType } from 'hooks'

import { type TimelineChunksType } from '../types'
import { getChunkDuration } from '../splitAndTrimUtils'
import { formatTime } from 'modules'

type Props = {
    playing: UseBooleanType
    playerTime: number
    chunks: TimelineChunksType
}

export const PlayerControls = ({ playerTime, playing, chunks }: Props) => {
    const totalResultingDuration = useMemo(() => {
        return chunks.reduce((acc, chunk) => {
            return acc + getChunkDuration(chunk)
        }, 0)
    }, [chunks])

    return (
        <Stack
            direction="row"
            alignItems="center"
            sx={theme => ({
                '& svg': {
                    color: theme.palette.grey[950] + '!important'
                }
            })}
        >
            <IconButton
                label={playing.isTrue ? 'Pause' : 'Play'}
                variant="text"
                color="secondary"
                onClick={playing.toggle}
            >
                <Icon icon={playing.isTrue ? faPause : faPlay} />
            </IconButton>
            <Stack direction="row" spacing={0.75} py={0.5} pl={0.5} alignItems="center">
                <Typography variant="text" size="md" fontWeight="medium">
                    {formatTime(playerTime)}
                </Typography>
                <Typography variant="text" size="md" color="grey.400" fontWeight="medium">
                    /
                </Typography>
                <Typography variant="text" size="md" color="grey.500" fontWeight="medium">
                    {formatTime(totalResultingDuration)}
                </Typography>
            </Stack>
        </Stack>
    )
}
