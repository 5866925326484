import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Stack } from '@mui/material'

import { useNotification } from 'hooks'
import { round, uuid } from 'modules'

import {
    TimelineChunkVariant,
    type TimelineChunksType,
    type TimelineVideoChunkType
} from '../types'
import { SplitButton } from './SplitButton'
import { SnapshotButton } from './SnapshotButton'
import { minChunkDuration } from '../Timeline'
import { checkIsSnapshotChunk } from '../splitAndTrimUtils'
import { useMapTimelineToVideoTime } from '../useMapTimelineToVideoTime'

const roundToTwoHundreds = (num: number) => Math.round(num * 5) / 5

type Props = {
    pointerTime: number
    chunks: TimelineChunksType
    setChunks: (value: TimelineChunksType) => void
    videoPlayerElement: HTMLVideoElement | null
}

export const PointerButtons = ({ pointerTime, chunks, setChunks, videoPlayerElement }: Props) => {
    const { showErrorNotification } = useNotification()

    const { activeStep, steps } = useSelector(state => state.qgEditor.present)
    const { kind } = steps[activeStep]

    const { activePointerChunkDetails: activeChunkDetails, pointerVideoTime } =
        useMapTimelineToVideoTime({
            pointerTime,
            chunks
        })

    const getChunkSplitResult = useCallback(() => {
        const activeChunk = activeChunkDetails?.originalChunk

        if (!activeChunk || checkIsSnapshotChunk(activeChunk) || !pointerVideoTime) return null

        const resultingChunks: [TimelineVideoChunkType, TimelineVideoChunkType] = [
            {
                id: uuid(),
                start: activeChunk.start,
                end: roundToTwoHundreds(pointerVideoTime), // Due to BE limitation
                type: TimelineChunkVariant.video
            },
            {
                id: uuid(),
                start: roundToTwoHundreds(pointerVideoTime), // Due to BE limitation
                end: round(activeChunk.end),
                type: TimelineChunkVariant.video
            }
        ]
        const isLessThanOneSecond = resultingChunks.some(
            chunk => chunk.end - chunk.start < minChunkDuration
        )

        if (isLessThanOneSecond) {
            showErrorNotification(`Video step duration can’t be under ${minChunkDuration} second`)
            return null
        }

        const activeChunkIndex = chunks.findIndex(chunk => chunk.id === activeChunk.id)

        return {
            resultingChunks,
            activeChunkIndex
        }
    }, [activeChunkDetails, chunks, pointerVideoTime, showErrorNotification])

    const disabledTooltip = useMemo(() => {
        const isIntroOrOutro = ['intro', 'outro'].includes(kind)
        if (!isIntroOrOutro) return ''

        return `Split is not available for the ${kind} step`
    }, [kind])

    const areButtonsDisabled = activeChunkDetails?.originalChunk
        ? checkIsSnapshotChunk(activeChunkDetails.originalChunk)
        : true

    return (
        <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            sx={theme => ({
                /* TODO: DS Button color */
                '& button': {
                    color: theme.palette.grey[800]
                }
            })}
        >
            <SplitButton
                chunks={chunks}
                setChunks={setChunks}
                isDisabled={areButtonsDisabled || Boolean(disabledTooltip)}
                disabledTooltip={disabledTooltip}
                getChunkSplitResult={getChunkSplitResult}
            />
            <SnapshotButton
                chunks={chunks}
                setChunks={setChunks}
                isDisabled={areButtonsDisabled || Boolean(disabledTooltip)}
                disabledTooltip={disabledTooltip}
                videoPlayerElement={videoPlayerElement}
                getChunkSplitResult={getChunkSplitResult}
            />
        </Stack>
    )
}
