import { memo, useEffect, useMemo } from 'react'
import { Box, List, ListItemText, ListItemButton, ListItem } from '@mui/material'
import { type OptionType } from 'modules'
import { Typography, Checkbox } from '@guidde/design-system'

type LanguageItemProps = {
    lang: OptionType
    onActive: (lang: OptionType) => void
    isActive: boolean

    isSelected?: boolean
    onSelect?: (lang: OptionType) => void
    checkboxDisabled?: boolean
}

export type LanguageListProps = {
    languages: OptionType[]
    activeLanguage: OptionType
    onActive: LanguageItemProps['onActive']

    selectedLanguages?: Set<OptionType>
    maxSelectedLanguages?: number
    onSelect?: LanguageItemProps['onSelect']
}

export const LanguageItem = memo(
    ({ lang, isSelected, isActive, onActive, onSelect, checkboxDisabled }: LanguageItemProps) => {
        const handleChangeActiveLanguage = () => {
            onActive(lang)
        }

        const handleChangeSelectedLanguage = () => {
            onSelect?.(lang)
        }

        return (
            <ListItem
                sx={{
                    gap: 1,
                    backgroundColor: isActive ? 'primary.25' : 'transparent',
                    '&:hover': {
                        backgroundColor: checkboxDisabled ? 'transparent' : 'primary.25'
                    }
                }}
                disablePadding
            >
                {onSelect && (
                    <Box marginLeft={1.5}>
                        <Checkbox
                            checked={!!isSelected}
                            onChange={handleChangeSelectedLanguage}
                            disabled={checkboxDisabled}
                        />
                    </Box>
                )}
                <ListItemButton
                    selected={isActive}
                    disableGutters
                    disableRipple
                    disabled={checkboxDisabled}
                    onClick={handleChangeActiveLanguage}
                    key={lang.value}
                    sx={{
                        padding: 1.5,
                        paddingLeft: onSelect ? 0 : 1.5,
                        height: '100%',
                        width: '100%',
                        backgroundColor: 'transparent',
                        '&:hover, &.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: 'transparent'
                        }
                    }}
                >
                    <ListItemText>
                        <Typography variant="text" size="sm" fontWeight="medium">
                            {lang.label}
                        </Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        )
    }
)

export const LanguageList = memo(
    ({
        languages,
        activeLanguage,
        onSelect,
        onActive,
        selectedLanguages,
        maxSelectedLanguages
    }: LanguageListProps) => {
        const maxSelectedLanguagesReached = useMemo(
            () =>
                selectedLanguages &&
                maxSelectedLanguages &&
                selectedLanguages.size === maxSelectedLanguages,
            [maxSelectedLanguages, selectedLanguages]
        )

        useEffect(() => {
            if (!maxSelectedLanguagesReached) return

            const firstSelected = Array.from(selectedLanguages!)[0]
            if (!selectedLanguages!.has(activeLanguage)) onActive(firstSelected)
        }, [activeLanguage, maxSelectedLanguagesReached, onActive, selectedLanguages])

        return (
            <List
                disablePadding
                sx={{
                    minWidth: 225,
                    overflow: 'auto'
                }}
            >
                {languages.map(lang => (
                    <LanguageItem
                        key={lang.value}
                        lang={lang}
                        isActive={activeLanguage.value === lang.value}
                        isSelected={selectedLanguages?.has(lang)}
                        checkboxDisabled={
                            maxSelectedLanguagesReached ? !selectedLanguages!.has(lang) : undefined
                        }
                        onActive={onActive}
                        onSelect={onSelect}
                    />
                ))}
            </List>
        )
    }
)
