import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'

import { Button, Stack, Typography } from '@mui/material'

import { Icon, IconButton, roundings, ThemeProvider } from '@guidde/design-system'
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'

import Interface from './img/interface.png'
import Star from './img/star.png'

import { getRoleName, useAuth, useLocalStorage, useOrgFeatureFlags, useServiceUsage } from 'hooks'
import { db, logToAnalytics } from 'modules'

import { DownloadDesktopAppProvider } from './DownloadDesktopAppProvider'

type Props = {
    paddingAround?: number
}

export const DesktopAppBanner = ({ paddingAround }: Props) => {
    const { uid } = useAuth()
    const [userAchievements, isAchievementsLoading] = useDocumentData(
        doc(db, `userAchievements/${uid}`)
    )
    const isDesktopInstalled = userAchievements?.desktop_sign_in

    const {
        isLoading: areFeatureFlagsLoading,
        hideDesktopAppDownload,
        hideDesktopDownloadBanner
    } = useOrgFeatureFlags()
    const { planName } = useServiceUsage()
    const { roles } = useAuth()

    const [hasClosedBanner, setHasClosedBanner] = useLocalStorage('desktopBannerClosed', false)

    const isLoading = areFeatureFlagsLoading || isAchievementsLoading
    const isDisabledFeatureFlag = hideDesktopAppDownload || hideDesktopDownloadBanner
    const hasInteractedWithFeature = hasClosedBanner || isDesktopInstalled

    if (isLoading || isDisabledFeatureFlag || hasInteractedWithFeature) return null

    return (
        <>
            <style>
                @import
                url('https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap');
            </style>
            <DownloadDesktopAppProvider source="banner">
                {handleDownloadDesktopAppClick => (
                    <Stack alignItems="center">
                        <Stack
                            height={84}
                            sx={{
                                background: '#F8C521',
                                borderRadius: roundings.md,
                                position: 'relative',
                                display: { xs: 'none', md: 'flex' },
                                cursor: 'pointer',
                                my: paddingAround,
                                maxWidth: 1280,
                                width: '100%'
                            }}
                            onClick={handleDownloadDesktopAppClick}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontFamily: '"Urbanist", sans-serif',
                                    fontSize: 29,
                                    lineHeight: 1,
                                    left: 34,
                                    bottom: 27,
                                    position: 'absolute'
                                }}
                            >
                                Guidde’s Desktop App
                            </Typography>
                            <Stack
                                sx={{
                                    position: 'absolute',
                                    top: 14,
                                    left: 338,
                                    '& img': {
                                        width: 42,
                                        height: 36
                                    },
                                    rotate: '-3.942deg'
                                }}
                            >
                                <img src={Star} />
                            </Stack>
                            <Stack
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 328,
                                    '& img': {
                                        width: 299,
                                        height: 70
                                    },
                                    display: { xs: 'none', lg: 'flex' }
                                }}
                            >
                                <img src={Interface} />
                            </Stack>
                            <Button
                                sx={{
                                    width: 168,
                                    height: 44,
                                    borderRadius: '84px',
                                    background: '#D92D20',
                                    position: 'absolute',
                                    right: 106,
                                    top: 21,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    border: 'none',
                                    '&:hover': {
                                        background: '#D92D20',
                                        border: 'none'
                                    },
                                    color: 'white'
                                }}
                            >
                                Download
                            </Button>
                            <ThemeProvider>
                                <Stack
                                    sx={{
                                        position: 'absolute',
                                        top: 10,
                                        right: 10,
                                        '& .MuiButtonBase-root': {
                                            width: 26,
                                            height: 26,
                                            '& svg': {
                                                width: 14,
                                                height: 14
                                            }
                                        }
                                    }}
                                >
                                    <IconButton
                                        isCircled
                                        variant="outlined"
                                        color="secondary"
                                        label="Close"
                                        onClick={event => {
                                            event.stopPropagation()
                                            setHasClosedBanner(true)
                                            logToAnalytics('desktopApp_XBtn_clicked', {
                                                plan: planName,
                                                role: getRoleName(roles.a)
                                            })
                                        }}
                                    >
                                        <Icon icon={faXmark} size="xs" />
                                    </IconButton>
                                </Stack>
                            </ThemeProvider>
                        </Stack>
                    </Stack>
                )}
            </DownloadDesktopAppProvider>
        </>
    )
}
