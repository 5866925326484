import { useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { noBrandKitBackgroundId, useNotification } from 'hooks'
import { cardLimits } from 'UI/Routes/brand-kit/PageBrandKit'
import {
    type BrandKitBackgroundType,
    type BrandKitType,
    type ImageAsBackgroundType
} from 'app/types'
import { type SectionProps } from '../components'
import BrandkitAnalytics from 'analytics/brandkit'

type SelectionOption = 'video' | 'document'

export const useStepAppearance = (
    values: {
        background: BrandKitType['background']
        qgHighlightColor: BrandKitType['qgHighlightColor']
        colors: BrandKitType['color']
        stepsNumberingColor: BrandKitType['stepsNumberingColor']
        documentOutputSettings: BrandKitType['documentOutputSettings']
        videoOutputSettings: BrandKitType['videoOutputSettings']
    },
    active: {
        background?: BrandKitBackgroundType
        qgHighlightColor: string
        stepsNumberingColor: string
    },
    onSetActive: SectionProps['onSetActive'],
    onAdd: SectionProps['onAdd'],
    onDelete: SectionProps['onDelete']
) => {
    const [selection, setSelection] = useState<SelectionOption>('video')
    const { id: brandKitId } = useParams<{ id: string }>()
    const { showSuccessNotification } = useNotification()

    const currentOutputSettings = useMemo(
        () => values[selection === 'video' ? 'videoOutputSettings' : 'documentOutputSettings'],
        [selection, values]
    )

    const isBackgroundOverLimit = values.background.data.length >= cardLimits.background
    const isQgHighlightColorOverLimit =
        values.qgHighlightColor.data.length >= cardLimits.qgHighlightColor
    const isStepNumberingColorOverLimit =
        values.stepsNumberingColor.data.length >= cardLimits.qgHighlightColor

    const setNewBackground = (newColor: string, type: 'color' | 'gradient' | 'image') => {
        BrandkitAnalytics.brandKitAppearanceBackgroundBtnClicked({
            workspaceId: brandKitId,
            color: newColor,
            type
        })

        const newActiveBackgroundId = values.background.data.find(color =>
            'url' in color ? newColor === color.url : newColor === color.gradient
        )?.id

        if (!newActiveBackgroundId) {
            console.error('Color not found in the list of colors', { newColor })
            return
        }

        onSetActive({
            background: newActiveBackgroundId
        }).then(() => showSuccessNotification('Background color changed'))
    }

    const setNewElementColor = (newColor: string) => {
        BrandkitAnalytics.brandKitAppearanceElementBtnClicked({
            workspaceId: brandKitId,
            newColor
        })

        const newActiveColorId = values.qgHighlightColor.data.find(
            item => newColor === item.value
        )?.id

        if (!newActiveColorId) {
            console.error('Color not found in the list of `qgHighlightColor`colors', {
                values: values.qgHighlightColor,
                newColor
            })
            return
        }

        onSetActive({
            qgHighlightColor: newActiveColorId
        }).then(() => showSuccessNotification('Element color changed'))
    }

    const setNewNumbersColor = (newColor: string) => {
        BrandkitAnalytics.brandKitAppearanceNumberBtnClicked({
            workspaceId: brandKitId,
            newColor
        })

        const newActiveColorId = values.stepsNumberingColor.data.find(
            item => newColor === item.value
        )?.id

        if (!newActiveColorId) {
            console.error('Color not found in the list of `stepsNumberingColor` colors', {
                values: values.stepsNumberingColor,
                newColor
            })
            return
        }

        onSetActive({
            stepsNumberingColor: newActiveColorId
        }).then(() => showSuccessNotification('Number color changed'))
    }

    const activeBackground =
        active.background && 'url' in active.background
            ? active.background.url
            : active.background?.gradient || ''
    const activeElementsColor = active.qgHighlightColor
    const activeStepNumberingColor = active.stepsNumberingColor

    const activeBackgroundIsNoBg = active.background?.id === noBrandKitBackgroundId
    const activeBackgroundIsImage = activeBackground.includes('http')

    const scaleFactor = useMemo(() => {
        if (!active.background) return 1
        if ('scaleFactor' in active.background) return active.background.scaleFactor
        return activeBackgroundIsNoBg ? 1 : 0.8
    }, [active.background, activeBackgroundIsNoBg])

    const colorPopperConfig = [
        {
            title: 'Background',
            activeColor: activeBackground,
            colors: values.background.data.map(it => ('url' in it ? it : it.gradient)),
            customAngle: values.background.customAngle,
            isOverLimit: isBackgroundOverLimit,
            overLimitTooltip: `Maximum number of background colors - ${cardLimits.background}`,
            onSetActiveColor: setNewBackground,
            onAddColor: (background: [string, string] | ImageAsBackgroundType) => {
                return onAdd({ background })
                    .then(res => {
                        if (!res) return
                        showSuccessNotification('Background added')
                    })
                    .catch(console.error)
            },
            onDeleteColor: (colorId: string) => {
                return onDelete({ background: colorId }).then(() => {
                    showSuccessNotification('Background deleted')
                })
            }
        },
        {
            title: 'Elements',
            activeColor: activeElementsColor,
            colors: values.qgHighlightColor.data.map(color => color.value),
            isOverLimit: isQgHighlightColorOverLimit,
            overLimitTooltip: `Maximum number of elements colors - ${cardLimits.qgHighlightColor}`,
            onSetActiveColor: setNewElementColor,
            onAddColor: (newColor: [string, string] | ImageAsBackgroundType) => {
                if ('url' in newColor) return Promise.resolve()
                return onAdd({ qgHighlightColor: newColor[0] }).then(() => {
                    showSuccessNotification('Elements color added')
                })
            }
        },
        {
            title: 'Numbers',
            activeColor: activeStepNumberingColor,
            colors: values.stepsNumberingColor.data.map(color => color.value),
            isOverLimit: isStepNumberingColorOverLimit,
            overLimitTooltip: `Maximum number of elements colors - ${cardLimits.stepsNumberingColor}`,
            onSetActiveColor: setNewNumbersColor,
            onAddColor: (newColor: [string, string] | ImageAsBackgroundType) => {
                if ('url' in newColor) return Promise.resolve()
                return onAdd({ stepsNumberingColor: newColor[0] }).then(() => {
                    showSuccessNotification('Numbers color added')
                })
            }
        }
    ]

    return {
        selection,
        setSelection,
        currentOutputSettings,
        colorPopperConfig,
        activeBackground,
        activeElementsColor,
        activeStepNumberingColor,
        activeBackgroundIsNoBg,
        activeBackgroundIsImage,
        scaleFactor
    }
}
