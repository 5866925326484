import {
    type SyntheticEvent,
    type ReactNode,
    useRef,
    useState,
    memo,
    useEffect,
    useCallback
} from 'react'

import { type SxProps } from '@mui/material/styles'

import { type BoxProps, Box, Stack } from '@mui/material'

import { ThemeProvider, Typography, type TTypographyProps } from '@guidde/design-system'

import { type SpeakerItemProps, SpeakerItem } from './SpeakerItem'

import { type SpeakerType } from 'app/types'

import { useBoolean } from 'hooks'
import { delay, logToAnalytics } from 'modules'
import { UpgradePopup } from '..'

type Props = {
    onAudioPlay?: (speaker: SpeakerType) => void
    onSelect: SpeakerItemProps['onContainerClick']
    currentSpeaker: SpeakerType | null
    hasStudioVoices: boolean | null
    speakerItemDataCy?: string
    width?: BoxProps['width']
    speakerCardSx?: SxProps
    startItems?: ReactNode
    endItems?: ReactNode
    gridGap?: number | string
    premiumDisplayMode?: 'lock' | 'upgrade'
    defaultSpeakerId?: string
    brandkitSpeakerId?: string
    recentlyUsedSpeakers: SpeakerType[]
    restSpeakers: SpeakerType[]
}

export const LanguageSpeakers = memo(
    ({
        width,
        currentSpeaker,
        hasStudioVoices,
        defaultSpeakerId,
        brandkitSpeakerId,
        recentlyUsedSpeakers,
        restSpeakers,
        speakerItemDataCy,
        onAudioPlay,
        onSelect,
        speakerCardSx,
        startItems,
        endItems,
        gridGap,
        premiumDisplayMode
    }: Props) => {
        const audioRef = useRef<HTMLMediaElement | null>(null)
        const [contactUsAnchorEl, setContactUsAnchorEl] = useState<HTMLElement | null>(null)

        // used for toggling the play pause icon for a specific speaker
        const [playSpeaker, setPlaySpeaker] = useState<SpeakerType | null>(null)

        // current playing time
        const [currentTime, setCurrentTime] = useState(0)

        // used to maintain the current playing state
        const playStatus = useBoolean()

        const setPlayStatus = playStatus.set

        // reset on close
        useEffect(() => {
            return () => {
                setPlaySpeaker(null)
                setPlayStatus(false)
            }
        }, [setPlayStatus])

        const closeUpgradePopup = () => setContactUsAnchorEl(null)

        const handlePlayAction = () => {
            playStatus.setTrue()

            if (playSpeaker) onAudioPlay?.(playSpeaker)
        }

        const handlePauseAction = () => {
            playStatus.setFalse()
        }

        const handleTimeUpdateAction = (event: SyntheticEvent<HTMLAudioElement, Event>) => {
            const { currentTime } = event.target as HTMLAudioElement
            setCurrentTime(currentTime)
        }

        const handleAudioEndedAction = () => {
            playStatus.setFalse()
            setCurrentTime(0)
        }

        const handleSpeakerPlayClickAction = useCallback(
            (speaker: SpeakerType) => {
                const isPlaying = playStatus.isTrue && speaker.id === playSpeaker?.id

                if (playSpeaker?.id !== speaker.id) {
                    setPlaySpeaker(speaker)
                }

                const audioElement = audioRef.current
                if (!audioElement) return

                delay(0).then(() => {
                    if (isPlaying) audioElement.pause()
                    else audioElement.play()
                })
            },
            [playSpeaker?.id, playStatus.isTrue]
        )

        const renderSpeakers = useCallback(
            (
                heading: string,
                speakers: SpeakerType[],
                headingFontWeight: TTypographyProps['fontWeight'] = 'semibold'
            ) => {
                if (!speakers.length) return null

                const handleSpeakerSelect: Props['onSelect'] = (event, speaker) => {
                    if (speaker.isStudio) {
                        logToAnalytics('studioVoiceSelected', {
                            speakerName: speaker.name,
                            speaker,
                            isSupported: Boolean(hasStudioVoices)
                        })

                        if (!hasStudioVoices) {
                            setContactUsAnchorEl(event.currentTarget)
                            return
                        }
                    }

                    onSelect(event, speaker)
                }

                return (
                    <Box display="flex" gap={1} flexDirection="column">
                        <Typography variant="text" size="md" fontWeight={headingFontWeight}>
                            {heading}
                        </Typography>
                        <Box
                            data-test="card-container"
                            width={width ?? '100%'}
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                                gap: gridGap ?? 2,
                                '&::-webkit-scrollbar': {
                                    width: '20px'
                                },
                                '&::-webkit-scrollbar-track': {
                                    backgroundColor: 'transparent'
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#D8D8D8',
                                    borderRadius: '20px',
                                    border: '6px solid transparent',
                                    backgroundClip: 'content-box'
                                }
                            }}
                        >
                            {speakers.map(speaker => (
                                <SpeakerItem
                                    key={speaker.id}
                                    dataCy={speakerItemDataCy}
                                    speaker={speaker}
                                    isActive={speaker.id === currentSpeaker?.id}
                                    isPremium={!hasStudioVoices && speaker.isStudio}
                                    isPlaying={playStatus.isTrue && speaker.id === playSpeaker?.id}
                                    isBrandkitSpeaker={speaker.id === brandkitSpeakerId}
                                    isDefaultSpeaker={speaker.id === defaultSpeakerId}
                                    currentTime={currentTime}
                                    onContainerClick={handleSpeakerSelect}
                                    onActionButtonClick={handleSpeakerPlayClickAction}
                                    sx={speakerCardSx}
                                    premiumDisplayMode={premiumDisplayMode}
                                />
                            ))}
                        </Box>
                    </Box>
                )
            },
            [
                handleSpeakerPlayClickAction,
                currentSpeaker,
                brandkitSpeakerId,
                defaultSpeakerId,
                hasStudioVoices,
                speakerItemDataCy,
                onSelect,
                speakerCardSx,
                width,
                gridGap,
                playStatus,
                currentTime,
                playSpeaker,
                premiumDisplayMode
            ]
        )

        return (
            <ThemeProvider>
                <Box width="100%">
                    <audio
                        src={playSpeaker?.sampleAudioUrl ?? ''}
                        ref={audioRef}
                        onPlay={handlePlayAction}
                        onPause={handlePauseAction}
                        onTimeUpdate={handleTimeUpdateAction}
                        onEnded={handleAudioEndedAction}
                    />
                    <Stack gap={1}>
                        {/** #TODO (remove) This has to be deleted verify with product. was implemented for Wizard */}
                        {startItems}
                        {renderSpeakers('Recently used Speakers', recentlyUsedSpeakers)}
                        {renderSpeakers('All Speakers', restSpeakers, 'medium')}
                        {/** #TODO (remove) This has to be deleted verify with product. was implemented for Wizard */}
                        {endItems}
                    </Stack>
                </Box>
                <UpgradePopup
                    anchorEl={contactUsAnchorEl}
                    options={[...restSpeakers, ...recentlyUsedSpeakers]
                        .filter(speaker => speaker.isStudio)
                        .slice(0, 3)}
                    onClose={closeUpgradePopup}
                />
            </ThemeProvider>
        )
    }
)
