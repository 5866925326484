import { celloConfig } from 'env'

import { logToAnalytics } from './analytics'
import { request } from './utils'

let isEventListenerRegistered = false

const addScript = (src: string) => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    script.type = 'module'
    document.body.appendChild(script)
}

export const addReferralScripts = () => {
    addScript(celloConfig.script)
    addScript(celloConfig.attributionScript)
}

const getCelloToken = async () => {
    try {
        const response = await request('/c/v1/celloToken', 'POST')
        const token = response.token as string

        return token
    } catch (error) {
        console.error('Failed to get Cello token', error)
    }
}

const updateToken = async () => {
    const celloToken = await getCelloToken()

    window.Cello && celloToken && window.Cello('updateToken', celloToken)
}

let isReferralProgramInit = false
export const initReferralProgram = async ({
    email,
    displayName
}: {
    email: string
    displayName: string
}) => {
    if (isReferralProgramInit) return

    window.cello = window.cello || { cmd: [] }
    const celloToken = await getCelloToken()

    if (celloToken) {
        window.cello.cmd.push((cello: any) => {
            cello.boot({
                productId: celloConfig.productId,
                token: celloToken,
                language: 'en',
                productUserDetails: {
                    firstName: displayName,
                    fullName: displayName,
                    email
                },
                onTokenExpiring: updateToken,
                onTokenExpired: updateToken,
                hideDefaultLauncher: true
            })
        })
        isReferralProgramInit = true
    }
}

export const logoutReferral = async () => {
    if (window.Cello) {
        window.Cello('shutdown')
        isReferralProgramInit = false
    }
}

const sendClickEvent = () => {
    const source = location.pathname.slice(1)
    logToAnalytics('cello_widget_clicked', { source })
}

const registerClickEventsOnce = () => {
    if (!isEventListenerRegistered) {
        const widgetElement = document.getElementById('cello-widget-app')
        widgetElement && widgetElement.addEventListener('click', sendClickEvent)
        isEventListenerRegistered = true
    }
}

export const toggleReferralState = (newState: 'show' | 'hide' | 'open') => {
    window.Cello && window.Cello(newState)
    if (newState === 'show') {
        registerClickEventsOnce()
    }
}

export const getReferralCode = () =>
    window.CelloAttribution && (window.CelloAttribution('getReferral') as string | undefined)

export const getPersonalReferralCode = async () => {
    if (!window.Cello) return null

    if (!isReferralProgramInit) return null

    const personalReferralInfo = await (window.Cello('getActiveUcc') as unknown as Promise<{
        ucc: string
    }>)

    return personalReferralInfo.ucc
}
