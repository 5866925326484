import { memo } from 'react'

import { SpacedGroup } from 'UI/Components'

import { type SpeakerType } from 'app/types'
import { Typography } from '@guidde/design-system'

export const SpeakerPreview = memo(({ speaker }: { speaker: SpeakerType }) => {
    return (
        <SpacedGroup id={speaker.id} spacing={3} alignItems="center">
            <img
                src={speaker.avatarUrlCompressed}
                width={60}
                height={60}
                alt="avatar"
                style={{ border: `1px solid #D0D5DD `, borderRadius: '100%' }}
            />
            <SpacedGroup
                flexDirection="column"
                justifyContent="center"
                spacing={0.5}
                textAlign="left"
            >
                <Typography variant="text" fontWeight="semibold" size="md">
                    {speaker.name}
                </Typography>
                <Typography variant="text" size="sm" fontWeight="medium">
                    {speaker.langName}
                </Typography>
            </SpacedGroup>
        </SpacedGroup>
    )
})
