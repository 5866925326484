import { spacings, Typography } from '@guidde/design-system'

import { Box, Fade, Popper, Button as MuiButton, styled } from '@mui/material'

const StyledLinkButton = styled(MuiButton)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: 0,
    color: theme.palette.primary['300'],
    border: 'none',
    fontSize: '12px',
    fontWeight: 500,

    '&:hover': {
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline'
    }
}))

export const TranslateInProgressPopper = ({
    isOpen,
    popperAnchorEl,
    onViewProgressClick
}: {
    isOpen: boolean
    popperAnchorEl: HTMLElement | null
    onViewProgressClick: () => void
}) => (
    <Popper open={isOpen} anchorEl={popperAnchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Box
                    sx={theme => ({
                        p: 2,
                        bgcolor: 'grey.900',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: theme.spacing(spacings.xs),
                        borderRadius: 1,
                        boxShadow: 3,
                        maxWidth: 300
                    })}
                >
                    <Typography variant="text" size="xs" fontWeight="semibold" color="common.white">
                        Translate in progress
                    </Typography>
                    <Typography variant="text" size="xs" fontWeight="medium" color="grey.300">
                        Feel free to continue your work. The translated Guiddes will be available on
                        your home page when ready.
                    </Typography>
                    <StyledLinkButton onClick={onViewProgressClick}>View progress</StyledLinkButton>
                </Box>
            </Fade>
        )}
    </Popper>
)
