import { useState } from 'react'

import { Box, Stack } from '@mui/material'
import { Icon, Input } from '@guidde/design-system'

import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons'

type Props = {
    onCreate: (name: string) => void
    target: 'space' | 'folder'
    maxLength?: number
    onDiscard: () => void
}

export const CreateNewTargetInput = ({ onCreate, onDiscard, maxLength, target }: Props) => {
    const [name, setName] = useState('')

    const handelCreate = () => {
        if (name.length) onCreate(name)
    }
    return (
        <Input
            value={name}
            placeholder={`New ${target} name`}
            onChange={(e: any) => {
                e.stopPropagation()
                setName(e.target.value)
            }}
            inputProps={{
                autoFocus: true,
                onKeyDown: (e: any) => {
                    e.stopPropagation()

                    if (e.key === 'Enter') handelCreate()
                }
            }}
            charsLimit={maxLength}
            endAdornment={
                <Stack direction="row" spacing={1.5} pl={1}>
                    <Box
                        onClick={handelCreate}
                        sx={theme => ({
                            cursor: name.length ? 'pointer' : 0,
                            color: theme.palette.primary[600]
                        })}
                    >
                        <Icon icon={faCheck} />
                    </Box>
                    <Box
                        onClick={onDiscard}
                        sx={theme => ({ cursor: 'pointer', color: theme.palette.common.black })}
                    >
                        <Icon icon={faXmark} />
                    </Box>
                </Stack>
            }
        />
    )
}
