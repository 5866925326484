import { useState } from 'react'

export const useConfirmation = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [resolvePromise, setResolvePromise] = useState<((value: boolean) => void) | null>(null)

    const confirm = () => {
        return new Promise<boolean>(resolve => {
            setIsOpen(true)
            setResolvePromise(() => resolve)
        })
    }

    const handleConfirm = () => {
        setIsOpen(false)
        resolvePromise?.(true)
    }

    const handleCancel = () => {
        setIsOpen(false)
        resolvePromise?.(false)
    }

    return {
        confirm,
        handleConfirm,
        handleCancel,
        isOpen
    }
}
