import { Stack } from '@mui/material'
import { useEffect, type Dispatch, type SetStateAction } from 'react'

type Props = {
    isPlaying: boolean
    setPlayerTime: Dispatch<SetStateAction<number>>
    imageUrl: string
}

export const SnapshotPlayer = ({ imageUrl, setPlayerTime, isPlaying }: Props) => {
    useEffect(() => {
        if (!isPlaying) return

        const millisecondsTimeDelta = 100
        const intervalId = setInterval(
            () => setPlayerTime(prevTime => prevTime + millisecondsTimeDelta / 1000),
            millisecondsTimeDelta
        )

        return () => clearInterval(intervalId)
    }, [isPlaying, setPlayerTime])

    return (
        <Stack sx={{ position: 'absolute', inset: 0, zIndex: 1 }}>
            <img src={imageUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </Stack>
    )
}
