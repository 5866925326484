import { useReducer } from 'react'

import { type SpeakerType } from 'app/types'
import { type OptionType } from 'modules'

export type MultiLanguageSpeakers = Record<OptionType['value'], SpeakerType | null>

export type MultiLanguageAction =
    | { type: 'addLanguage'; lang: OptionType['value']; speaker: SpeakerType | null }
    | { type: 'removeLanguage'; lang: OptionType['value'] }
    | { type: 'setSpeaker'; lang: OptionType['value']; speaker: SpeakerType | null }
    | { type: 'clear' }

const initialSelectedSpeakers: MultiLanguageSpeakers = {}

const reducer = (state: MultiLanguageSpeakers, action: MultiLanguageAction) => {
    switch (action.type) {
        case 'addLanguage':
            if (action.lang !== action.speaker?.langCode) return state
            return {
                ...state,
                [action.lang]: action.speaker
            }
        case 'removeLanguage':
            const { [action.lang]: _, ...rest } = state
            return rest
        case 'setSpeaker':
            if (!(action.lang in state)) return state

            return {
                ...state,
                [action.lang]: action.speaker
            }
        case 'clear':
            return {}
        default:
            return state
    }
}

export const useMultiLanguageReducer = () => {
    const [selectedLanguages, dispatch] = useReducer(reducer, initialSelectedSpeakers)

    return {
        selectedLanguages,
        addLanguage: (lang: OptionType['value'], speaker: SpeakerType | null) =>
            dispatch({ type: 'addLanguage', lang, speaker }),
        removeLanguage: (lang: OptionType['value']) => dispatch({ type: 'removeLanguage', lang }),
        setSpeaker: (lang: OptionType['value'], speaker: SpeakerType | null) =>
            dispatch({ type: 'setSpeaker', lang, speaker }),
        clear: () => dispatch({ type: 'clear' })
    }
}
