import { type SpeakerType } from 'app/types'
import { useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'

export const useLangaugeSpeakers = (speakers: SpeakerType[], langCode: string = 'en-US') => {
    const { getBrandkitSpeakerId, getDefaultSpeakerId, getParsedSpeakers, isLoading } =
        useSpeakerOptions()

    const defaultSpeakerId = getDefaultSpeakerId(langCode)
    const brandkitSpeakerId = getBrandkitSpeakerId(langCode)
    const parsedSpeakers = getParsedSpeakers(speakers)

    return {
        recentlyUsedSpeakers: parsedSpeakers?.recentlyUsedSpeakers || [],
        restSpeakers: parsedSpeakers?.restSpeakers || [],
        defaultSpeakerId,
        brandkitSpeakerId,
        isLoading
    }
}
