import { routes } from 'app/routes'
import { useAuth, useOrganizationUsers, useRoles, useServiceUsage, useWindowView } from 'hooks'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router-dom'

const pathsWithPlanNotification = routes
    .filter(route => route.hasUsageBanner)
    .map(route => route.path)

export const usePlanUsageNotification = () => {
    const { uid } = useAuth()

    const { isTabletView } = useWindowView()
    const {
        isLoading,
        isDefaultValues,
        isFreePlan,
        quotaLabels,
        isEnterprisePlan,
        isTrialPlan,
        usedUsersSeats
    } = useServiceUsage()

    const isRouteWithPlanNotification = pathsWithPlanNotification.some(
        paths => useRouteMatch(paths)?.isExact
    )

    const { isMember } = useRoles()
    const { orgUsers } = useOrganizationUsers()
    const currentOrgUser = orgUsers?.find(user => user.uid === uid)

    const hideDuePlanExptions = isDefaultValues || isEnterprisePlan || !isMember

    const showIsTrialPlan = isTrialPlan
    const showPlaybooksLimit = isFreePlan
    const showMaxSeatsLimit = usedUsersSeats > Number(quotaLabels.userSeatsQuota)
    const showRoleDowngrade = currentOrgUser?.viewerOnlyAfterMs
        ? currentOrgUser.viewerOnlyAfterMs > Date.now()
        : false

    const showSomeBanner =
        showPlaybooksLimit || showMaxSeatsLimit || showRoleDowngrade || showIsTrialPlan

    const canShowUsageBanner =
        isRouteWithPlanNotification && !hideDuePlanExptions && !isTabletView && showSomeBanner

    return useMemo(
        () => ({
            showPlaybooksLimit,
            showMaxSeatsLimit,
            showIsTrialPlan,
            showRoleDowngrade,
            showUsageBanner: isLoading ? false : canShowUsageBanner
        }),
        [
            isLoading,
            showPlaybooksLimit,
            showIsTrialPlan,
            showMaxSeatsLimit,
            showRoleDowngrade,
            canShowUsageBanner
        ]
    )
}
