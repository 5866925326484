import { memo, useEffect, useMemo, useState, type MouseEvent } from 'react'

import { Box, Dialog, Divider, DialogActions, DialogTitle, DialogContent } from '@mui/material'

import {
    useLangaugeSpeakers,
    LanguageSpeakers
} from 'UI/Routes/quick-guidde/LeftPanel/VoiceOverPanel/speakers'

import { useMultiLanguageReducer, useSpeakerOptions } from 'UI/Routes/quick-guidde/hooks'

import { type SpeakerType } from 'app/types'
import { type OptionType } from 'modules'
import {
    ThemeProvider,
    Button,
    Typography,
    IconButton,
    Icon,
    spacings
} from '@guidde/design-system'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import { LanguageList } from './LanguageList'

type Props = {
    isOpen: boolean
    languages: OptionType[]
    onClose: () => void
    onSave: (speakers: SpeakerType[]) => void
    title: string
    subtitle: string
    submitText: string
}

export const MAX_LANGUAGES = 5

export const SpeakersTranslationDialog = memo(
    ({ isOpen, onClose, onSave, languages, submitText, subtitle, title }: Props) => {
        const { hasStudioVoices, getSpeakersList, getInitialSpeaker, isLoading } =
            useSpeakerOptions()

        const {
            selectedLanguages,
            clear: clearMultiLanguage,
            removeLanguage,
            addLanguage,
            setSpeaker
        } = useMultiLanguageReducer()

        const [activeLanguage, setActiveLanguage] = useState(languages[0])
        const [activeSpeaker, setActiveSpeaker] = useState<SpeakerType | null>(null)

        const speakers = useMemo(() => {
            return getSpeakersList(activeLanguage.value)
        }, [getSpeakersList, activeLanguage.value])

        const { defaultSpeakerId, brandkitSpeakerId, recentlyUsedSpeakers, restSpeakers } =
            useLangaugeSpeakers(speakers, activeLanguage.value)

        useEffect(() => {
            if (isOpen) return

            clearMultiLanguage()
        }, [clearMultiLanguage, isOpen])

        useEffect(() => {
            if (isLoading) return

            const speaker =
                selectedLanguages[activeLanguage.value] ?? getInitialSpeaker(activeLanguage.value)

            setActiveSpeaker(speaker)
        }, [isLoading, activeLanguage, getInitialSpeaker, selectedLanguages])

        const handleSelectedLanguagesChange = (lang: OptionType) => {
            if (lang.value in selectedLanguages) {
                removeLanguage(lang.value)
            } else {
                const speaker =
                    activeSpeaker?.langCode === lang.value
                        ? activeSpeaker
                        : getInitialSpeaker(lang.value)
                addLanguage(lang.value, speaker)
            }
        }

        const handleSave = () => {
            const speakers = getSelectedSpeakers()
            if (speakers.length === 0) return

            onSave(speakers)
        }

        const handleSelectActiveSpeaker = (_: MouseEvent<HTMLElement>, speaker: SpeakerType) => {
            setActiveSpeaker(speaker)
            const { langCode } = speaker

            if (!(langCode in selectedLanguages)) return

            setSpeaker(langCode, speaker)
        }

        const handleAudioPlay = () => {}

        const handleChangeActiveLanguage = (lang: OptionType) => {
            setActiveLanguage(lang)
        }

        const getSelectedLanguages = () => {
            const selectedLanguageLables = new Set(Object.keys(selectedLanguages))

            return languages.filter(lang => selectedLanguageLables.has(lang.value))
        }
        const getSelectedSpeakers = () =>
            Object.values(selectedLanguages).filter(Boolean) as SpeakerType[]

        const selectedLanguagesCount = getSelectedLanguages().length

        const handleClose = () => {
            onClose()
        }

        return (
            <ThemeProvider>
                <Dialog
                    open={isOpen}
                    fullWidth
                    maxWidth="md"
                    PaperProps={{
                        style: {
                            maxWidth: 728
                        }
                    }}
                >
                    <DialogTitle
                        component={'div'}
                        sx={theme => ({
                            display: 'flex',
                            flexDirection: 'column',
                            gap: theme.spacing(spacings.md)
                        })}
                    >
                        <Typography variant="text" size="lg" fontWeight="semibold">
                            {title}
                        </Typography>
                        <Typography variant="text" size="sm" fontWeight="regular">
                            {subtitle}
                        </Typography>

                        <Box
                            sx={{
                                textAlign: 'right',
                                position: 'absolute',
                                right: 5,
                                top: 5
                            }}
                        >
                            <IconButton
                                label="close"
                                color="secondary"
                                variant="text"
                                size="small"
                                onClick={handleClose}
                            >
                                <Icon icon={faXmark} size="1x" />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ display: 'flex', maxHeight: 500, padding: 0 }}>
                        <LanguageList
                            languages={languages}
                            activeLanguage={activeLanguage}
                            maxSelectedLanguages={MAX_LANGUAGES}
                            onActive={handleChangeActiveLanguage}
                            selectedLanguages={new Set(getSelectedLanguages())}
                            onSelect={handleSelectedLanguagesChange}
                        />
                        <Divider orientation="vertical" flexItem />
                        <Box overflow="auto" p={2} width="100%">
                            <LanguageSpeakers
                                onAudioPlay={handleAudioPlay}
                                onSelect={handleSelectActiveSpeaker}
                                currentSpeaker={activeSpeaker}
                                hasStudioVoices={hasStudioVoices}
                                brandkitSpeakerId={brandkitSpeakerId}
                                defaultSpeakerId={defaultSpeakerId}
                                recentlyUsedSpeakers={recentlyUsedSpeakers}
                                restSpeakers={restSpeakers}
                                gridGap="10px 6px"
                            />
                        </Box>
                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Box p={1} display="flex" alignItems="center" gap={1}>
                            <Typography
                                variant="text"
                                size="sm"
                                fontWeight="semibold"
                                color="grey.600"
                            >
                                {selectedLanguagesCount}/{MAX_LANGUAGES}{' '}
                                {selectedLanguagesCount > 1
                                    ? 'languages selected'
                                    : 'language selected'}
                            </Typography>
                            <Button
                                variant="contained"
                                disabled={!selectedLanguagesCount}
                                onClick={handleSave}
                            >
                                {submitText}
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        )
    }
)
