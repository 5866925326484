import {
    Avatar,
    Box,
    Button,
    LinearProgress,
    Accordion,
    AccordionDetails,
    Tooltip,
    Dialog,
    Typography
} from '@mui/material'
import { TextOverflow } from '@guidde-co/shared.textoverflow'

import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde_red.svg'
import { ReactComponent as EmailSentSvg } from 'assets/images/email-sent.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmark.svg'
import { ReactComponent as DeclineCrossIcon } from 'assets/icons/decline-cross.svg'

import { useAuth, useBoolean, useDataMutation, useWindowView } from 'hooks'
import { logToAnalytics } from 'modules'

import { SpacedGroup } from 'UI/Components'

import {
    type PostOnboardingPayloadType,
    type TeamType,
    type WorkspacesResponseType,
    OnboardingSteps,
    OrgRequestStatus
} from './PageOnboarding'

type Props = {
    isLoading: boolean
    workspaces: WorkspacesResponseType
    canCreateWorkspace: boolean
    joinTeam: (data: PostOnboardingPayloadType) => Promise<{} | undefined>
}

const MIN_COUNT = 3

export const SelectTeams = ({ joinTeam, isLoading, canCreateWorkspace, workspaces }: Props) => {
    const { isMobileView } = useWindowView()

    const expanded = useBoolean()
    const showRequestSentDialog = useBoolean()

    const { orgId, uid } = useAuth()

    const handleJoinTeam = (orgId: string | null) => {
        if (orgId) {
            const workspace = workspaces.find(workspace => workspace.orgId === orgId)
            if (!workspace) return

            logToAnalytics('teamDiscovery_joinButton_clicked', {
                teamName: workspace.orgName,
                teamId: workspace.orgId,
                page: 'onboarding',
                uid
            })
        }

        joinTeam({
            activeStep: OnboardingSteps.Teams,
            data: {
                orgId
            }
        }).catch(console.error)
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" minHeight="100vh">
            <Box width="100%" height={5}>
                {isLoading && <LinearProgress />}
            </Box>
            <Box>
                <Box width={isMobileView ? '100%' : 610} px={2}>
                    <Box
                        display="flex"
                        justifyContent={isMobileView ? 'flex-start' : 'center'}
                        mt={isMobileView ? '32px' : '40px'}
                    >
                        <GuiddeLogo height={30} />
                    </Box>
                    <Box mt={2.5} />
                    <Typography
                        data-test="team-discovery-title"
                        variant="h4"
                        align={isMobileView ? 'left' : 'center'}
                    >
                        We found your teammates
                    </Typography>
                    <Box mt={isMobileView ? 1.2 : 3} />
                    <Typography
                        data-test="team-discovery-subtitle"
                        style={{ fontSize: 20, color: '#090C1099' }}
                    >
                        Collaborate with your team. Create, comment on, and share guiddes.
                    </Typography>

                    <Box mt={3} />

                    <Accordion
                        expanded={expanded.isTrue}
                        sx={{
                            '&:before': {
                                display: 'none'
                            }
                        }}
                        onChange={(_, val) => expanded.set(val)}
                        style={{ boxShadow: 'none', border: 'none' }}
                    >
                        <SpacedGroup flexDirection="column">
                            {workspaces.slice(0, MIN_COUNT).map(workspace => (
                                <WorkspaceItem
                                    key={workspace.orgId}
                                    workspace={workspace}
                                    disabled={isLoading}
                                    joinTeam={handleJoinTeam}
                                    onActionSuccess={showRequestSentDialog.setTrue}
                                />
                            ))}
                        </SpacedGroup>

                        <AccordionDetails style={{ padding: 0 }}>
                            <SpacedGroup flexDirection="column" mt={2} width="100%">
                                {workspaces.slice(MIN_COUNT).map(workspace => (
                                    <WorkspaceItem
                                        key={workspace.orgId}
                                        workspace={workspace}
                                        disabled={isLoading}
                                        joinTeam={handleJoinTeam}
                                        onActionSuccess={showRequestSentDialog.setTrue}
                                    />
                                ))}
                            </SpacedGroup>
                        </AccordionDetails>
                    </Accordion>
                    {workspaces.length > MIN_COUNT && (
                        <Box mt={1}>
                            <Button
                                onClick={expanded.toggle}
                                variant="text"
                                style={{ color: '#2D9CDB', textDecoration: 'underline' }}
                                color="secondary"
                            >
                                Show{' '}
                                {expanded.isTrue
                                    ? 'less'
                                    : `more (${workspaces.length - MIN_COUNT})`}
                            </Button>
                        </Box>
                    )}
                    {canCreateWorkspace && (
                        <Box
                            my={4}
                            display="flex"
                            justifyContent="center"
                            flexDirection="column"
                            alignItems="center"
                        >
                            <Button
                                color="inherit"
                                style={{
                                    background: '#090C10',
                                    color: '#FFF',
                                    fontWeight: 500,
                                    letterSpacing: 1.25
                                }}
                                size="large"
                                variant="contained"
                                disabled={isLoading}
                                onClick={() => {
                                    logToAnalytics('teamDiscovery_createButton_clicked', {
                                        page: 'onboarding',
                                        existingOrgId: orgId,
                                        uid
                                    })

                                    handleJoinTeam(null)
                                }}
                            >
                                CREATE A NEW WORKSPACE
                            </Button>
                            <Box mt={1} />
                            <Typography style={{ color: 'rgba(9, 12, 16, 0.60)' }} align="center">
                                Start your 14 days free trial
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {showRequestSentDialog.isTrue && (
                <Dialog
                    open
                    fullWidth={true}
                    maxWidth="sm"
                    PaperProps={{
                        style: {
                            overflowY: 'hidden'
                        }
                    }}
                >
                    <Box pt={6} px={4} pb={3} textAlign="center">
                        <EmailSentSvg />
                        <Typography
                            variant="h1"
                            style={{
                                color: '#090C10',
                                fontSize: '34px',
                                lineHeight: '42px',
                                fontWeight: 700,
                                marginTop: '32px'
                            }}
                        >
                            Your request was sent
                        </Typography>
                        <Typography style={{ color: 'rgba(9, 12, 16, 0.6)', marginTop: '32px' }}>
                            The admin will receive your request. Please re-check your email for
                            further instructions.
                        </Typography>
                    </Box>
                    <Box textAlign="center" padding={2} borderTop="1px solid rgba(9, 12, 16, 0.08)">
                        <Button
                            size="small"
                            style={{
                                letterSpacing: '1.25px'
                            }}
                            onClick={showRequestSentDialog.setFalse}
                        >
                            GOT IT
                        </Button>
                    </Box>
                </Dialog>
            )}
        </Box>
    )
}

type WorkspaceItemProps = {
    workspace: TeamType
    disabled: boolean
    joinTeam: (orgId: string | null) => void
    onActionSuccess: () => void
}

const WorkspaceAction = ({
    disabled,
    joinTeam,
    workspace,
    onActionSuccess
}: WorkspaceItemProps) => {
    const { uid } = useAuth()
    const requestSentPlaceholder = useBoolean()

    const $sendRequestToJoin = useDataMutation<{ workspaceId: string }, { message: string }, Error>(
        '/c/v1/workspaces/request',
        'POST'
    )

    if (workspace.requestStatus === OrgRequestStatus.Pending || requestSentPlaceholder.isTrue) {
        return (
            <Box
                display="flex"
                alignItems="center"
                style={{
                    fontSize: 12,
                    color: '#090C10'
                }}
            >
                <CheckmarkIcon style={{ marginRight: 8 }} />
                Pending approval
            </Box>
        )
    }

    if (workspace.requestStatus === null) {
        return (
            <Button
                size="small"
                sx={{
                    letterSpacing: '1.25px',
                    '&:not(:disabled)': {
                        color: '#CB0000',
                        background: '#fff'
                    }
                }}
                disabled={disabled || $sendRequestToJoin.isLoading}
                onClick={() => {
                    logToAnalytics('teamDiscovery_requestBtn_clicked', {
                        teamName: workspace.orgName,
                        teamId: workspace.orgId,
                        uid
                    })

                    $sendRequestToJoin.mutate({ workspaceId: workspace.orgId }).then(() => {
                        requestSentPlaceholder.setTrue()
                        onActionSuccess()
                    })
                }}
            >
                REQUEST TO JOIN
            </Button>
        )
    }

    if (workspace.requestStatus === OrgRequestStatus.Approved) {
        return (
            <Box
                display="flex"
                alignItems="center"
                style={{
                    fontSize: 12,
                    color: '#73CC6D'
                }}
            >
                <CheckmarkIcon style={{ marginRight: 2 }} />
                Approved
            </Box>
        )
    }

    if (workspace.requestStatus === OrgRequestStatus.Declined) {
        return (
            <Box
                display="flex"
                alignItems="center"
                style={{
                    fontSize: 12,
                    color: '#CB0000'
                }}
            >
                <DeclineCrossIcon style={{ marginRight: 2 }} />
                Declined
            </Box>
        )
    }

    return (
        <Button
            data-test="team-discovery-join-button"
            size="small"
            variant="contained"
            sx={{
                letterSpacing: '1.25px'
            }}
            disabled={disabled}
            onClick={() => {
                joinTeam(workspace.orgId)
            }}
        >
            JOIN
        </Button>
    )
}

const WorkspaceItem = ({ disabled, joinTeam, workspace, onActionSuccess }: WorkspaceItemProps) => {
    const { isMobileView } = useWindowView()

    const delta = isMobileView ? 2 : 5
    const additional = workspace.membersCount - delta

    return (
        <Box
            bgcolor="#F5F5F5"
            px={2}
            py={isMobileView ? 2 : 1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
        >
            <SpacedGroup width="100%">
                <Avatar
                    data-test="team-discvoery-avatar"
                    imgProps={{
                        style: {
                            objectFit: 'contain',
                            border: '1px solid rgba(9, 12, 16, 0.08)'
                        }
                    }}
                    style={{
                        width: 80,
                        height: 80
                    }}
                    src={workspace.orgLogo}
                >
                    {workspace.orgName.slice(0, 2).toUpperCase()}
                </Avatar>
                <Box width="100%">
                    <SpacedGroup spacing={1}>
                        <TextOverflow lineClamp={1}>
                            <Typography
                                data-test="team-discovery-org-name"
                                style={{
                                    fontWeight: 500,
                                    fontSize: 20
                                }}
                            >
                                {workspace.orgName}
                            </Typography>
                        </TextOverflow>
                        {workspace.planName && (
                            <Box
                                p={0.5}
                                bgcolor="#CB00001A"
                                fontSize={12}
                                color="#CB0000"
                                borderRadius={2}
                            >
                                {workspace.planName}
                            </Box>
                        )}
                    </SpacedGroup>
                    <Box
                        display="flex"
                        alignItems={isMobileView ? 'flex-end' : 'center'}
                        justifyContent="space-between"
                        width="100%"
                    >
                        <SpacedGroup spacing={1.2}>
                            {workspace.members.slice(0, delta).map((member, i) => (
                                <Tooltip key={i} title={member.name}>
                                    <Avatar style={{ width: 40, height: 40 }} src={member.photoURL}>
                                        <Typography
                                            style={{
                                                fontWeight: 700,
                                                fontSize: 12,
                                                color: '#090C10'
                                            }}
                                        >
                                            {member.name.slice(0, 2).toUpperCase()}
                                        </Typography>
                                    </Avatar>
                                </Tooltip>
                            ))}
                            {additional > 0 && (
                                <Typography style={{ fontSize: 14 }} id="additional">
                                    +{additional}
                                </Typography>
                            )}
                        </SpacedGroup>
                        <WorkspaceAction
                            workspace={workspace}
                            disabled={disabled}
                            joinTeam={joinTeam}
                            onActionSuccess={onActionSuccess}
                        />
                    </Box>
                </Box>
            </SpacedGroup>
        </Box>
    )
}
