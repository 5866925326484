import { useEffect, useState } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { initTapfiliate } from 'modules/tapfiliate'

import { doc, getDoc } from 'firebase/firestore'

import { AppContainer, AppRouter, GuiddeLoad, MenuStatusProvider } from 'UI/Components'

import { paths } from 'app/paths'
import { useAuth, useQuery, useSignIn, useWorkspaceAutoswitcher, useReferralProgram } from 'hooks'
import {
    checkIfExtIsConnected,
    createGapi,
    envConfig,
    isBraveBrowser,
    logToAnalytics,
    registerToFirebaseAuth,
    registerUserInAnalytics,
    isDev,
    db
} from 'modules'
import { useDispatch } from 'react-redux'
import { type UpdateAuthPayload, resetAppAuth, updateAppAuth } from 'ducks'

import ServiceUsageController from 'UI/Components/ServiceUsageController'
import UploadProgressPopup from 'UI/Components/UploadProgressPopup'
import OnboardingDialog from 'UI/Components/Onboarding/OnboardingDialog'

const App = () => {
    const { showPageLoader } = useWorkspaceAutoswitcher()
    const location = useLocation()
    useReferralProgram()

    const magicLinkMatch = useRouteMatch(paths.emailLink)

    const { isLoading: isSessionLoading } = useSignIn({
        withoutSessionCall: Boolean(magicLinkMatch)
    })

    const { uid, displayName, email, roles, orgId, isLoading } = useAuth()
    const [activePlanId, setActivePlanId] = useState('')
    const { update } = useIntercom()

    const { data: hash, isValidating } = useQuery<{ data: string }>(
        '/c/v1/intercom/identityVerification',
        {
            method: 'POST'
        }
    )

    useEffect(() => {
        if (!isDev) return
        // on production tapfiliate is initialized on guidde.com
        // on dev and staging we need to initialize it here with a different key for testing
        initTapfiliate()
    }, [])

    useEffect(() => {
        if (isLoading || !email) return
        isBraveBrowser().then(isBrave => {
            if (!isBrave) return

            logToAnalytics('brave-browser', {
                browser: 'Brave',
                email
            })
        })
    }, [email, isLoading])

    useEffect(() => {
        window.addEventListener('focus', checkIfExtIsConnected)
        checkIfExtIsConnected()
    }, [])

    // send Intercom an update event on any route change
    useEffect(() => {
        if (isValidating || !hash || !orgId) return

        update({
            name: displayName,
            email: email,
            customAttributes: {
                api_base: 'https://api-iam.intercom.io',
                org_id: orgId,
                user_id: uid,
                location: location.pathname,
                last_request_at: parseInt(String(new Date().getTime() / 1000)),
                user_hash: hash.data // HMAC using SHA-256
            }
        })
    }, [location, uid, update, displayName, email, hash, orgId, isValidating])

    useEffect(() => {
        if (!uid) return

        try {
            window.guidde?.init({
                userId: uid, // unique
                userEmail: email,
                userName: displayName
            })
        } catch (error) {
            console.error(error)
        }
    }, [uid, displayName, email, roles])

    useEffect(() => {
        if (!orgId) return

        const getPlanId = async () => {
            const docSnapshot = await getDoc(doc(db, `servicesUsage/${orgId}`))
            if (docSnapshot.exists()) {
                const data = docSnapshot.data()
                setActivePlanId(data?.planId)
            }
        }

        getPlanId()
    }, [orgId])

    useEffect(() => {
        if (!uid) return
        // Identify user in Sentry + FullStory + Mixpanel with the same props
        registerUserInAnalytics({
            plan: activePlanId || '',
            uid,
            displayName,
            email,
            orgId: roles?.o || '',
            env: envConfig.firebaseConfig.projectId
        })
    }, [activePlanId, displayName, email, roles, uid])

    const dispatch = useDispatch()

    // Run on App Init
    useEffect(() => {
        registerToFirebaseAuth((payload: UpdateAuthPayload | null) => {
            if (payload) dispatch(updateAppAuth(payload))
            else dispatch(resetAppAuth())
        })
        createGapi()
    }, [dispatch])

    if (isLoading || isSessionLoading || showPageLoader) {
        return <GuiddeLoad message="Magically create video documentation with AI in seconds!" />
    }

    return (
        <MenuStatusProvider>
            <AppContainer>
                {uid && !roles?.n && (
                    <>
                        {orgId && <ServiceUsageController />}
                        <UploadProgressPopup />
                        <OnboardingDialog />
                    </>
                )}
                <AppRouter />
            </AppContainer>
        </MenuStatusProvider>
    )
}

export default App
