import { useCallback, useMemo } from 'react'

import { brandKitRequestPath } from 'UI/Routes/brand-kit/PageBrandKit'

import {
    type AddBrandKitType,
    type BrandKitType,
    type DeleteBrandKitType,
    type EditBrandKitType,
    type SetActiveBrandKitType,
    type MultiBrandKitItem,
    type OutputSettings
} from 'app/types'

import { useDataMutation, useQuery } from 'hooks'
import { logToAnalytics } from 'modules'

export const noBrandKitBackgroundId = 'NO_BACKGROUND_ID'

export type BrandKitResponseType =
    | {
          message: 'string'
      }
    | undefined

type ActionType = 'add' | 'edit' | 'set-active' | 'delete' | 'add-multi-brandKit'

type LogBrandKitEventType =
    | EditBrandKitType
    | DeleteBrandKitType
    | AddBrandKitType
    | SetActiveBrandKitType

const logBrandKitEvent = (action: ActionType, data: LogBrandKitEventType) => {
    logToAnalytics('brandkitEdit', {
        actionType: action,
        objectType: Object.keys(data)[0],
        userSelection: Object.values(data)[0]
    })
}

export const useBrandKit = (selectedBrandKitId?: string) => {
    // get all brand kits
    const {
        data: multiBrandKitData,
        mutate: revalidateMultiBrandKit,
        isValidating: multiBrandKitLoading
    } = useQuery<Array<MultiBrandKitItem>>(`/c/v1/brand-kits`, {
        method: 'GET'
    })

    const activeKitId = useMemo(() => {
        const userBrandKit = multiBrandKitData?.find(data => data.isUserDefault)?.id
        const orgBrandKit = multiBrandKitData?.find(data => data.isDefault)?.id

        return selectedBrandKitId || userBrandKit || orgBrandKit
    }, [multiBrandKitData, selectedBrandKitId])

    const {
        mutate: revalidateBrandKit,
        isValidating,
        data
    } = useQuery<BrandKitType>(
        activeKitId ? `${brandKitRequestPath}?id=${activeKitId}` : '',
        {
            method: 'GET'
        },
        {
            revalidateOnFocus: false,
            revalidateIfStale: false
        }
    )

    const $updateMultiBrandKit = useDataMutation<
        Partial<MultiBrandKitItem>,
        BrandKitResponseType,
        Error
    >(`${brandKitRequestPath}`, 'PATCH', {
        onSuccess: () => revalidateMultiBrandKit()
    })

    const callBack = useMemo(
        () => (action: ActionType) => ({
            onSuccess: (_: BrandKitResponseType, input: LogBrandKitEventType) => {
                logBrandKitEvent(action, input)
                revalidateBrandKit()
            }
        }),
        [revalidateBrandKit]
    )

    const $editBrandKit = useDataMutation<EditBrandKitType, BrandKitResponseType, Error>(
        `${brandKitRequestPath}/edit`,
        'POST',
        callBack('edit')
    )

    const $deleteFromBrandKit = useDataMutation<DeleteBrandKitType, BrandKitResponseType, Error>(
        `${brandKitRequestPath}/delete`,
        'POST',
        callBack('delete')
    )

    const $addToBrandKit = useDataMutation<AddBrandKitType, BrandKitResponseType, Error>(
        `${brandKitRequestPath}/add`,
        'POST',
        callBack('add')
    )

    const $setActiveBrandKit = useDataMutation<SetActiveBrandKitType, BrandKitResponseType, Error>(
        `${brandKitRequestPath}/set-active`,
        'POST',
        callBack('set-active')
    )

    const findActiveOptionValue = useCallback(
        (
            property:
                | 'fontColor'
                | 'fontSize'
                | 'fontFillColor'
                | 'logoAndFavicon'
                | 'color'
                | 'qgHighlightColor'
                | 'stepsNumberingColor',
            field?: string
        ) => {
            const option = data?.[property]?.data?.find(({ id }) => id === data[property].active)
            if (!option) return ''

            const fieldName = (field || 'value') as keyof typeof option

            return fieldName in option ? option[fieldName] : ''
        },
        [data]
    )

    return useMemo(() => {
        const { audioBackground, color, font, defaultKit, background } = data || {}
        const defaultOutputSettings: OutputSettings = {
            isCalloutsEnabled: true,
            isArrowEnabled: true,
            isOverlayEnabled: true
        }

        return {
            multiBrandKitData,
            revalidateMultiBrandKit,
            multiBrandKitLoading,
            revalidateBrandKit,
            $updateMultiBrandKit,
            $editBrandKit,
            $deleteFromBrandKit,
            $addToBrandKit,
            $setActiveBrandKit,
            brandKitLoading: isValidating,
            videoOutputSettings: data?.videoOutputSettings ?? defaultOutputSettings,
            documentOutputSettings:
                data?.documentOutputSettings ?? data?.videoOutputSettings ?? defaultOutputSettings,
            brandKitData: data,
            brandKitVoiceover: data?.voiceover.data || [],
            brandKitGenerateVoiceoverForNewQG: data?.voiceover.generateVoiceoverForNewQG || false,
            brandKitColors: color?.data.map(item => item.value) || [],
            activeAudio: audioBackground?.data?.find(it => it.id === audioBackground.active),
            logoAndFavicon: findActiveOptionValue('logoAndFavicon', 'url'),
            brandKitBackgrounds: background?.data || [],
            brandKitFont: font?.data?.find(({ id }) => id === font.active),
            brandKitFonts: font?.data || [],
            brandKitFontColor: findActiveOptionValue('fontColor'),
            brandKitFontSize: findActiveOptionValue('fontSize') as unknown as number,
            brandKitFontFillColor: findActiveOptionValue('fontFillColor'),
            brandKitBackground: background?.data?.find(bg => bg.id === background?.active),
            brandKitStepNumberingColor: findActiveOptionValue('stepsNumberingColor'),
            brandKitHighlightColor: findActiveOptionValue('qgHighlightColor'),
            brandKitColor: findActiveOptionValue('color') || defaultKit?.color || ''
        }
    }, [
        data,
        multiBrandKitData,
        revalidateMultiBrandKit,
        multiBrandKitLoading,
        revalidateBrandKit,
        $updateMultiBrandKit,
        $editBrandKit,
        $deleteFromBrandKit,
        $addToBrandKit,
        $setActiveBrandKit,
        isValidating,
        findActiveOptionValue
    ])
}
